import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useBankContext } from "../../util/BankContext";
import { useReactOidc } from "@axa-fr/react-oidc-context";
import Api from "../../util/api";

import AdminHeader from "../../components/AdminHeader";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import Grid from "@material-ui/core/Grid";

// スタイル指定
const useStyles = makeStyles((theme) => ({
    root: {
        padding: "0 15px",
        margin: "0 auto",
    },
    content: {
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        height: "100vh",
        minHeight: "100vh",
    },
    titleBox: {
        marginBottom: 10,
        padding: "0 15px",
    },
    title: {
        color: "inherit",
        fontFamily: "inherit",
        fontSize: 18,
        fontWeight: 500,
        lineHeight: "1.1",
        marginTop: 10,
        marginBottom: 10,
        textAlign: "center",
    },
    container: {
        width: "90%",
        margin: "0 auto",
        marginTop: 20,
        minWidth: 650,
        borderBottom: "1px solid",
        borderBottomColor: "#e7e7e7",
        paddingBottom: "10px",
    },
    leftColum: {
        textAlign: "center",
        width: "90%",
    },
    button: {
        background: "#457ab2",
        color: theme.palette.primary.color,
        height: 40,
        margin: "10px 10px",
    },
    textBox: {
        lineHeight: "36px",
        listStyleType: "none",
        margin: "0 auto",
        marginTop: 10,
        padding: 0,
        width: "90%",
    },
    imgBox: {
        width: "90%",
        height: "300px",
        border: "2px dotted #888",
        marginBottom: "10px",
    },
    center: {
        textAlign: "center",
    },
    previewImg: {
        display: "block",
        background: "#FFFFFF",
        width: "90%",
        height: "300px",
        border: "2px solid #888",
        marginBottom: "10px",
    },
    img: {
        display: "block",
        height: "100%",
        width: "100%",
        objectFit: "contain",
    },
}));

const ImageSettingRegister = (props) => {
    // styleを読み込む
    const classes = useStyles();
    const bank = useBankContext();
    const bankID = bank.realm.replace("bank", "");
    window.document.title = "BA Portal";
    const { oidcUser } = useReactOidc();
    const [selectedFile, setSelectedFile] = useState(null);
    const [previewImage, setPreviewImage] = useState(null);
    const history = useHistory();

    // 画像アップロード用useEffect
    useEffect(() => {
        if (selectedFile) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setPreviewImage(reader.result);
            };
            reader.readAsDataURL(selectedFile);
        } else {
            setPreviewImage(null);
        }
    }, [selectedFile]);

    // 画像ファイルのバリデーション
    const validateFileExtensions = ["png", "jpg", "jpeg", "gif", "svg"];
    const validateFileExtension = (file) => {
        const fileExtension = file.name.split(".").pop().toLowerCase();
        if (!validateFileExtensions.includes(fileExtension)) {
            alert(
                "無効なファイル形式です。png, jpg, jpeg, gif, svg形式の画像を選択してください。"
            );
            return false;
        }
        return true;
    };

    // 画像ファイルサイズの制限
    const fileSizeLimit = 5 * 1024 * 1024; // 5MBを制限とする
    // 画像ファイルサイズバリデーション
    const validateFileSize = (file) => {
        if (file.size > fileSizeLimit) {
            alert(
                "ファイルサイズが大きすぎます。5MB以下の画像を選択してください。"
            );
            return false;
        }
        return true;
    };

    // 画像ファイルを選択した時の処理
    const handleFileSelect = async (event) => {
        const file = event.target.files[0];
        if (validateFileExtension(file) && validateFileSize(file)) {
            setSelectedFile(file);
        }
    };

    // 画像ドラッグアンドドロップした時の処理
    const handleDrop = async (event) => {
        event.preventDefault();
        const file = event.dataTransfer.files[0];
        if (validateFileExtension(file) && validateFileSize(file)) {
            setSelectedFile(file);
        }
    };

    // 画像ドラッグアンドドロップした時の処理をブラウザデフォルトの処理なくす
    const handleDragOver = (event) => {
        event.preventDefault();
    };

    // クリアボタンクリック時の処理
    const removeFile = (event) => {
        setSelectedFile(null);
        setPreviewImage(null);
    };

    // URLセット
    const [destinationURL, setDestinationURL] = useState("");
    const handleChangeDestinationURL = (event) => {
        setDestinationURL(event.target.value);
    };

    // 別タブで開く
    const [openInNewTab, setOpenInNewTab] = useState(false);
    const handleChangeOpenInNewTab = (event) => {
        setOpenInNewTab(event.target.checked);
    };

    // ファイルbase64に変換
    function convertFileToBase64(file) {
        return new Promise((resolve, reject) => {
            const canvas = document.createElement("canvas");
            const img = new Image();
            img.onload = () => {
                canvas.width = img.width;
                canvas.height = img.height;
                const ctx = canvas.getContext("2d");
                ctx.drawImage(img, 0, 0);
                resolve(canvas.toDataURL());
            };
            img.onerror = reject;
            img.src = file;
        });
    }

    // 編集内容保存
    const handleSave = async () => {
        if (previewImage === null) {
            alert("画像を選択してください");
            return;
        }
        let img = selectedFile
            ? await convertFileToBase64(previewImage).then(
                  (base64String) => base64String
              )
            : null;
        const requestBody = {
            display_order: props.location.state.image,
            destination_url: destinationURL,
            open_in_new_tab: openInNewTab,
            bank_id: bankID,
        };
        if (img) {
            requestBody.image = img;
        }
        const response = await Api.postBodyParam(
            bank.realm,
            process.env.REACT_APP_BANNER_IMAGE_URL + "/banner_image",
            requestBody,
            {},
            oidcUser
        );
        if (response.error) {
            alert("編集内容を保存できませんでした。");
            return;
        }
        history.push("/admin/image_setting"); // 画面遷移
    };

    return (
        <div className={classes.root}>
            <AdminHeader />
            <main className={classes.content}>
                <Box maxWidth="lg" className={classes.titleBox}>
                    <h4 className={classes.title}>
                        表示{props.location.state.image}の画像編集
                    </h4>
                </Box>
                <Box className={classes.container}>
                    <Grid container>
                        <Grid item xs={6} className={classes.leftColum}>
                            <div
                                onDrop={handleDrop}
                                onDragOver={handleDragOver}
                            >
                                <Box
                                    component="button"
                                    className={classes.imgBox}
                                >
                                    <p>画像をドラッグ＆ドロップしてください</p>
                                </Box>
                            </div>
                            <Button
                                className={classes.button}
                                variant="contained"
                                component="label"
                                color="default"
                            >
                                画像ファイルを選択
                                <input
                                    type="file"
                                    accept="image/*"
                                    onChange={handleFileSelect}
                                    style={{ display: "none" }}
                                />
                            </Button>
                            <Button
                                className={classes.button}
                                onClick={removeFile}
                                variant="contained"
                                component="label"
                                color="default"
                            >
                                画像をクリア
                            </Button>
                            <p>
                                対応ファイルはpng, jpg, jpeg, gif,
                                svgを用意お願いします。
                                <br />
                                ※画像サイズが異なると綺麗に表示されない場合があります。
                            </p>
                            <Box className={classes.textBox}>
                                <TextField
                                    onChange={handleChangeDestinationURL}
                                    id="url"
                                    fullWidth
                                    label="URL"
                                    defaultValue={destinationURL}
                                    variant="outlined"
                                    size={"small"}
                                ></TextField>
                            </Box>
                            <Checkbox
                                checked={openInNewTab}
                                onChange={(event) =>
                                    handleChangeOpenInNewTab(event)
                                }
                                color="default"
                                inputProps={{
                                    "aria-label": "checkbox with default color",
                                }}
                            />
                            別タブで開く
                        </Grid>
                        <Grid item xs={6}>
                            <Box
                                className={classes.previewImg}
                                component="button"
                            >
                                {previewImage !== null && (
                                    <>
                                        <a
                                            key={props.location.state.image}
                                            href={destinationURL}
                                            target="_blank"
                                        >
                                            <img
                                                src={previewImage}
                                                className={classes.img}
                                            />
                                        </a>
                                    </>
                                )}
                            </Box>
                            プレビュー
                            <br />
                            画像をクリックすると、設定しているURLが別タブで表示されます
                        </Grid>
                    </Grid>
                </Box>
                <Box className={classes.center}>
                    <Link
                        to={{
                            pathname: "/admin/image_setting",
                        }}
                    >
                        <Button variant="outlined" className={classes.button}>
                            キャンセル
                        </Button>
                    </Link>
                    <Button
                        variant="outlined"
                        className={classes.button}
                        onClick={handleSave}
                    >
                        保存
                    </Button>
                </Box>
            </main>
        </div>
    );
};

export default ImageSettingRegister;
