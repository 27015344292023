import React from 'react'
import { Link } from "react-router-dom";

const NotFound = () =>(
  <html>
    <head><title>404 Not Found</title></head>
    <body>
      <h1 style={{fontSize: 24, color: '#ccc', marginTop: 40 + 'vh', textAlign: 'center'}}>
        申し訳ありません。お探しのページはみつかりませんでした。<br />
        404 Not Found
      </h1>
      <p style={{marginTop: 30, textAlign: 'center'}}>
        <Link to={"/"}>トップに戻る</Link>
      </p>
    </body>
  </html>
)

export default NotFound
