import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { format } from 'date-fns'
import CustomBirthdatePicker from "./CustomBirthdatePicker";

const useStyles = makeStyles((theme) => ({
    mb12: {
        marginBottom: 12,
    },
    error: {
        border: "solid 2px #ff6e6e !important",
    },
    caption: {
        "font-size": "13px",
        "color": "#666",
        width: "400px",
    },
}));

export default function VisitorInformationInputForm(props) {
    const matches = useMediaQuery('(min-width:600px)');
    const classes = useStyles();

    const lastNameInput = clsx("size-s", !!matches ? "": classes.mb12, props.isErrorLastName ? classes.error : '');
    const firstNameInput = clsx("size-s", props.isErrorFirstName ? classes.error : '');
    const lastNameKanaInput = clsx("size-s", !!matches ? "": classes.mb12, props.isErrorLastNameKana ? classes.error : '');
    const firstNameKanaInput = clsx("size-s", props.isErrorFirstNameKana ? classes.error : '');
    const telNoInput = clsx("size-s", props.isErrorTelNo ? classes.error : '');
    const emailAddressInput = clsx("size-l", props.isErrorEmailAddress ? classes.error : '');
    const confirmEmailAddressInput = clsx("size-l", props.isErrorConfirmEmailAddress ? classes.error : '');
    const birthdayInput = clsx("size-s", props.isErrorBirthday ? classes.error : '');

    const handleChangeLastName = (event) => {
        props.setLastName(event.target.value);
    };

    const handleChangeFirstName = (event) => {
        props.setFirstName(event.target.value);
    };

    const handleChangeLastNameKana = (event) => {
        props.setLastNameKana(event.target.value);
    };

    const handleChangeFirstNameKana = (event) => {
        props.setFirstNameKana(event.target.value);
    };

    const handleChangeCompanyName = (event) => {
        props.setCompanyName(event.target.value);
    };
    
    const handleChangeBirthday = (birthday) => {
        if (birthday === null) {
            props.setBirthdate('');
            props.setBirthday('');
            return;
        }

        props.setBirthdate(birthday);
        const formatDate = format(birthday, "yyyy-MM-dd");
        props.setBirthday(formatDate);
    };

    const handleChangeTelNo = (event) => {
        props.setTelNo(hankaku2Zenkaku(event.target.value).replace(/[ー]/g, "-"));
    };

    const handleChangeEmailAddress = (event) => {
        props.setEmailAddress(event.target.value);
    };

    const handleChangeConfirmEmailAddress = (event) => {
        props.setConfirmEmailAddress(event.target.value);
    };

    const handleChangeNote = (event) => {
        props.setNote(event.target.value);
    };

    const hankaku2Zenkaku = (str) => {
        const regex = /[Ａ-Ｚａ-ｚ０-９！＂＃＄％＆＇（）＊＋，－．／：；＜＝＞？＠［＼］＾＿｀｛｜｝]/g;

        return str
            .replace(regex, function (s) {
                return String.fromCharCode(s.charCodeAt(0) - 0xfee0);
            })
            .replace(/[‐－―]/g, "-")
            .replace(/[～〜]/g, "~")
            .replace(/　/g, " ");
    }

    return (
        <>
            {/*<p className="req-hint">*は入力必須項目です</p>*/}
            <div className="input-group">
                <p className="input-label">お名前<span>必須</span></p>
                <div className="input-wrapper">
                    <input type="text" className={lastNameInput} onChange={handleChangeLastName} value={props.lastName} />
                    <input type="text" className={firstNameInput} onChange={handleChangeFirstName} value={props.firstName} />
                </div>
            </div>
            <div className="input-group">
                <p className="input-label">お名前(カナ)<span>必須</span></p>
                <div className="input-wrapper">
                    <input type="text" className={lastNameKanaInput} onChange={handleChangeLastNameKana} value={props.lastNameKana} />
                    <input type="text" className={firstNameKanaInput} onChange={handleChangeFirstNameKana} value={props.firstNameKana} />
                </div>
            </div>
            <div className="input-group">
                <p className="input-label">生年月日<span>必須</span></p>
                <div className="input-wrapper">
                    <CustomBirthdatePicker
                        isAdmin={false}
                        classes={birthdayInput}
                        birthday={props.birthday}
                        birthdate={props.birthdate}
                        handleChangeBirthday={handleChangeBirthday}
                    />
                    <p className="input-hint">
                        生年月日は西暦で半角数値にて入力願います。 <span className="dIB">例：19800101（1980年1月1日の場合）</span>
                        <br/>
                        ※法人のお客様：ご来店される方の生年月日をご入力ください。
                    </p>
                </div>
            </div>
            <div className="input-group">
                <p className="input-label">会社名</p>
                <div className="input-wrapper">
                    <input type="text" className="size-l" onChange={handleChangeCompanyName} value={props.companyName} />
                </div>
            </div>
            <div className="input-group">
                <p className="input-label">電話番号<span>必須</span></p>
                <div className="input-wrapper">
                    <input type="text" className={telNoInput} onChange={handleChangeTelNo} value={props.telNo} />
                    <p className="input-hint">ハイフンありで入力してください。　<span className="dIB">記入例）080-1234-5678</span></p>
                </div>
            </div>
            <div className="input-group">
                <p className="input-label">メールアドレス<span>必須</span></p>
                <div className="input-wrapper">
                    <input type="text" className={emailAddressInput} onChange={handleChangeEmailAddress} value={props.emailAddress} />
                </div>
            </div>
            <div className="input-group">
                <p className="input-label">メールアドレス確認<span>必須</span></p>
                <div className="input-wrapper">
                    <input type="text" className={confirmEmailAddressInput} onChange={handleChangeConfirmEmailAddress} value={props.confirmEmailAddress} />
                </div>
            </div>
            <div className="input-group">
                <p className="input-label">ご用件</p>
                <div className="input-wrapper">
                    <input type="text" className="size-l" onChange={handleChangeNote} value={props.note} />
                    <p className={classes.caption}>「ご用件」欄には、お手続きの内容等の詳細をご入力ください。<br />
                    また、当行に口座をお持ちの方は、店名（店番）・科目・口座番号をご入力ください。</p>
                </div>
            </div>
        </>
    )
}
