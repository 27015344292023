import { useState, useCallback } from "react";

export default function useSafeState(unmountRef, defaultValue) {
    const [state, changeState] = useState(defaultValue);
    const wrapChangeState = useCallback(
        (v) => {
        if (!unmountRef.current) {
            changeState(v);
        }
        },
        [changeState, unmountRef]
    );

    return [state, wrapChangeState];
};