import axios from "axios";

export default class XbaApi {
    static async _request(method, url, params, userData, options) {
        let { headers, isGuest } = options
        if( !headers || typeof headers !== 'object' ){
            headers = {};
        }

        if (!isGuest && !!userData) {
            headers['Authorization'] = `Bearer ${userData.id_token}`;
        }

        let axiosparams = {
            method: method,
            url: url,
            headers: headers
        };

        if (method == 'get') {
            axiosparams.params = params;
        } else {
            axiosparams.data = params;
        }
        return axios(axiosparams)
    }

    static async get(url, params, userData, options = {}) {

        params = {
            ...params,
            _:Date.now()
        };
        return XbaApi._request('get', url, params, userData, options)
            .then(r => {
                if (r.headers['location'] !== undefined) {
                    return r.headers['location'];
                }
                if ('content-disposition' in r.headers) {
                    let filename = r.headers['content-disposition'].match(/filename=([\w-]+\.\w+)/)[1]
                    return {data: r.data, filename: filename};
                }
                return r.data;
            })
            .catch(e => {
                if( e.response && e.response.status === 401  ){
                    // ログインが必要なとき
                    sessionStorage.clear();
                    window.location.href = "/";
                }
            });
    }

    static async post(realm, url, params, userData, options = {}) {
        return XbaApi._request('post', url, params, userData, options)
            .then(r => {
                return r.data;
            })
            .catch(e => {
                console.log(e)
                if( e.response && e.response.status === 401  ){
                    // ログインが必要なとき
                    sessionStorage.clear();
                    window.location.href = "/";
                }
            });
    }
}
