import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useBankContext } from "../util/BankContext";
import useUnmountRef from "../helpers/useUnmountRef";
import useSafeState from "../helpers/useSafeState";
import { useReactOidc } from '@axa-fr/react-oidc-context';
import Api from "../util/api";
import Carousel from 'react-material-ui-carousel';

const AfterLoginTopImage = (props) => {
    const bank = useBankContext();
    const bankID = bank.realm.replace("bank", "");
    const unmountRef = useUnmountRef();
    const { oidcUser } = useReactOidc();
    const [bannerImages, setBannerImages] = useSafeState(unmountRef, []);
    const ktbaID = 'bank0158';

    useEffect( () => {
        const fetchData = async () => {
            const response = await Api.get(
                bank.realm,
                process.env.REACT_APP_BANNER_IMAGE_URL + '/banner_images',
                {
                    bank_id:bankID,
                }, {}, oidcUser);
            if (response === null || response === undefined) {
                setBannerImages([]);
            } else {
                setBannerImages(response);
            };
        };
        if (props.realm === ktbaID) fetchData();
    }, []);
    // 表示フラグtrue画像
    const  isActiveImages = bannerImages.filter( data => data.DisplayFlag === true);

    return (
        <div className="main-carousel">
            {props.realm === 'bank0188' && (
            <div className="carousel-image-wrapper">
                <div className="catch-and-image">
                    <div className="catch">
                    <Link to={"/ba_chat"} className="key-color btn size-l arrow">ご相談はコチラから</Link>
                    </div>
                    <div className="image" />
                </div>
            </div>)}
            {props.realm === ktbaID && (
            <Carousel>
                {isActiveImages.map((image) => (
                    <div key={image.DisplayOrder} className="carousel-image-wrapper">
                        <a href={image.DestinationURL} target={image.OpenInNewTab ? "_blank" : "_self"}>
                            <img src= {image.ImagePath} height="100%" /> 
                        </a>
                    </div>
                ))}
            </Carousel>
            )}
            <div className="carousel-dot" />
        </div>
    )
};

export default AfterLoginTopImage;
