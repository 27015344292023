import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from "react-router-dom";
import moment from "moment";
import 'moment/locale/ja';
import { useReactOidc } from '@axa-fr/react-oidc-context';

import Api from "../util/api";
import { useBankContext } from "../util/BankContext";


const useStyles = makeStyles((theme) => ({
    cmiUl: {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-between",
        marginTop: 15,
    },
    cmiList: {
        borderBottom: "1px dashed #ccc",
        display: "flex",
        marginBottom: 0,
        padding: "20px 0",
        width: "100%",
    },
    cmiListDate: {
        color: "#666",
        fontFamily: "'Noto Sans Japanese', sans-serif",
        fontSize: 12,
        letterSpacing: "0 !important",
        marginRight: 20,
        padding: "3px 0",
    },
    cmiLinkText: {
        color: "#000 !important",
        fontSize: 16,
        fontWeight: "bold",
        "&:hover": {
            textDecoration: "none",
        },
    },
    unreadMark: {
        background: "#F18D06",
        borderRadius: 4,
        color: "#fff",
        display: "inline-block",
        fontSize: 12,
        marginLeft: 6,
        padding: "2px 4px",
    },
    moreView: {
        marginTop: 30,
        textAlign: "center",
    },
    linkText: {
        color: "#3039f1",
        cursor: "pointer",
        textDecoration: "underline",
        '&:hover': {
            color: "#262626",
        },
    },
}));

const CmiList = (props) => {
    moment.locale('ja');
    const classes = useStyles();
    const { oidcUser } = useReactOidc();
    const bank = useBankContext();

    const moreViewCmi = async (nextPage, event) => {
        // ご案内一覧取得API
        const newInformationList = await Api.get(props.realm, process.env.REACT_APP_INFORMATION_BASE_URL + '/information/search', {r: bank.params.information.listRows, p: nextPage}, {}, oidcUser);
        props.setCmiListPagingData(newInformationList.pagingInfo);
        props.setCmiList(props.cmiList.concat(newInformationList.informationList));
    };

    return (
        <div>
            <div>
                <div>
                    <ul className={classes.cmiUl}>
                        {props.cmiList.map((cmi) => (
                            <li key={cmi.id} className={classes.cmiList}>
                                <p className={classes.cmiListDate}>{moment(cmi.notifiedAt).format('YYYY年M月D日(dd)')}</p>
                                <p>
                                    <Link to={"/cmi/detail/" + cmi.id} className={classes.cmiLinkText}>{cmi.title}</Link>
                                    {!cmi.isRead && (
                                        <span className={classes.unreadMark}>未読</span>
                                    )}
                                </p>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
            {!props.cmiListPagingData.isLastPage && (
                <div className={classes.moreView}>
                    <span className={classes.linkText} onClick={moreViewCmi.bind(this, props.cmiListPagingData.nextPage)}>もっと見る</span>
                </div>
            )}
        </div>
    )
};

export default CmiList;
