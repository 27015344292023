import React, {useEffect} from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import {Link} from "react-router-dom";
import moment from "moment";
import 'moment/locale/ja';
import { useReactOidc } from '@axa-fr/react-oidc-context';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import Header from "../../components/Header";
import LeftColumn from "../../components/LeftColumn";
import Footer from "../../components/Footer";
import Api from "../../util/api";
import { useBankContext } from "../../util/BankContext";


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        '& table': {
            border: "1px solid #000",
            borderCollapse: "collapse",
            width: "100%",
        },
        '& tr': {
            height: "50px",
        },
        '& th': {
            background: "#E8E8E8",
        },
        '& td': {
            textAlign: "center",
        },
    },
    mainContent: {
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        height: '100vh',
        minHeight: "100vh",
        overflow: 'auto',
    },
    title: {
        backgroundColor: theme.palette.text.primary,
        padding: "10px 8px",
        '& p': {
            color: theme.palette.background.default,
        },
    },
    content: {
        fontFamily: "'Noto Sans Japanese', sans-serif",
        margin: "0 auto",
        width: "95%",
    },
    section: {
        marginTop: 30,
    },
    text: {
        lineHeight: "1,75em",
    },
    cmiTitle: {
        borderTop: "none",
        display: "block",
        fontSize: 18,
        fontWeight: 200,
        letterSpacing: 1,
        margin: "0 auto",
        padding: "15px 15px 15px 20px",
        position: "relative",
        width: "100%",
        '&::before': {
            background: "#19AAC4",
            content: "''",
            height: 8,
            left: 0,
            position: "absolute",
            top: 26,
            width: 8,
        },
        '&::after': {
            background: "#D1D1D1",
            content: "''",
            height: 8,
            left: 4,
            position: "absolute",
            top: 21,
            width: 8,
        },
    },
    cmiDate: {
        marginBottom: 20,
        textAlign: "right",
    },
    cmiContent: {
        lineHeight: "1.75em",
        whiteSpace: "pre-wrap",
    },
    backNewsListLinkBox: {
        marginTop: 40,
        textAlign: "center",
    },
    backNewsListLink: {
        color: "#19AAC4",
        '&:hover': {
            textDecoration: "none",
        },
    },
    footer: {
        marginTop: "auto",
    },
}));

const CmiDetail = (props) => {
    const matches = useMediaQuery('(min-width:600px)');
    const bank = useBankContext();
    window.document.title = 'ご案内|' + bank.baPortalName;
    moment.locale('ja');
    const classes = useStyles();
    const { oidcUser } = useReactOidc();

    const [cmiDetail, setCmiDetail] = React.useState({});

    useEffect( () => {
        if (!oidcUser) {
            return;
        }
        if (bank.canUseGA) {
            window.gtag('config', bank.gaTrackingCode, {
                'page_path': `${props.location.pathname}${props.location.search}`
            });
        }

        Api.post(bank.realm, process.env.REACT_APP_INFORMATION_BASE_URL + '/information/read', {i: props.match.params.id}, {}, oidcUser);

        const fetchData = async (id) => {
            // ご案内詳細取得API
            const cmiDetail = await Api.get(bank.realm, process.env.REACT_APP_INFORMATION_BASE_URL + '/information', {i: id}, {}, oidcUser);
            setCmiDetail(cmiDetail);
        }
        fetchData(
            props.match.params.id,
        )
            .then()
            .catch(e => {
                // todo エラーハンドリング
                if( e.response ){
                    if( e.response.status === 403 ){
                        // todo 「ログインしてください」を表示
                        // todo ユーザー情報の削除
                        // location.href = "/";
                    }
                    //todo エラーであることを知らせる表示
                }
            })
    }, [oidcUser, bank, props]);

    return (
        <div className="wrapper">
            <Header />
            <div className="content">
                {(!!oidcUser && matches) && (
                    <LeftColumn />
                )}
                <div className="main">
                    <Box className={classes.title}>
                        <Typography variant="body2" color="textSecondary">
                            お知らせ
                        </Typography>
                    </Box>
                    <div>
                        <div className={classes.content}>
                            <section className={classes.section}>
                                <div className={classes.text}>
                                    <h2 className={classes.cmiTitle}>
                                        {cmiDetail.title}
                                    </h2>
                                    <div className={classes.cmiDate}>
                                        {moment(cmiDetail.notifiedAt).format('YYYY年M月D日(dd)')}
                                    </div>
                                    <div className={classes.cmiContent}  dangerouslySetInnerHTML={{__html : cmiDetail.content}} />
                                </div>
                            </section>
                        </div>
                    </div>
                    <Box className={classes.backNewsListLinkBox}>
                        <Link to={"/news"} className={classes.backNewsListLink}>一覧に戻る</Link>
                    </Box>
                    <Box className={classes.footer}>
                        <Footer />
                    </Box>
                </div>
            </div>
        </div>
    )
};

export default CmiDetail;
