
import React, { useEffect, useState } from "react";
import moment from "moment";
import 'moment/locale/ja';
import { useReactOidc } from '@axa-fr/react-oidc-context';
import { Link } from "react-router-dom";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import { useSnackbar } from "notistack";
import useMediaQuery from '@material-ui/core/useMediaQuery';

import Header from '../../components/Header';
import Footer from '../../components/Footer';
import LeftColumn from "../../components/LeftColumn";
import Api from "../../util/api";
import { useBankContext } from "../../util/BankContext";
import ReservationConfirm from "../../components/ReservationConfirm";


const useStyles = makeStyles((theme) => ({
  linkWrapper: {
    [theme.breakpoints.up('sm')]: {
      display: "flex",
      justifyContent: "space-evenly",
    },
    [theme.breakpoints.down('xs')]: {
      textAlign: "center",
    },
  },
  linkText: {
    display: "block",
    textDecoration: "none",
  },
  cancelPopup: {
    display: "inline-block",
    textAlign: "center",
  },
  cancelButton: {
    background: "#fff",
    margin: "20px 0",
  },
  okButton: {
    background: "#606060",
    margin: "20px 0",
  },
  footer: {
    marginTop: "auto",
  },
}));

const Detail = (props) => {
  const matches = useMediaQuery('(min-width:600px)');
  const bank = useBankContext();
  window.document.title = '来店予約|' + bank.baPortalName;
  moment.locale('ja');
  const classes = useStyles();
  const { oidcUser } = useReactOidc();
  const { enqueueSnackbar } = useSnackbar();

  const [appointmentId, setAppointmentId] = useState('');
  const [branchName, setBranchName] = useState('');
  const [menuName, setMenuName] = useState('');
  const [menuDuration, setMenuDuration] = useState(0);
  const [day, setDay] = useState('');
  const [fromTime, setFromTime] = useState('');
  const [toTime, setToTime] = useState('');
  const [lastName, setLastName] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastNameKana, setLastNameKana] = useState('');
  const [firstNameKana, setFirstNameKana] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [telNo, setTelNo] = useState('');
  const [emailAddress, setEmailAddress] = useState('');
  const [note, setNote] = useState('');
  const [open, setOpen] = useState(false);

  const handleClickOpenCancel = (event) => {
    setOpen(true);
  };

  const handleCloseCancel = () => {
    setOpen(false);
  };

  const cancelAppointment = async () => {
    let params = {};
    if(appointmentId !== '') params["a"] = appointmentId;

    const result = await Api.put(bank.realm, process.env.REACT_APP_APPOINTMENT_BASE_URL + '/appointment/cancel', params, {}, oidcUser, false);

    setOpen(false);

    if (result.status === 500) {
      enqueueSnackbar('エラーが発生しました', { variant: 'error' });
    }else if (result.ok) {
      // キャンセル成功の通知表示
      enqueueSnackbar('予約をキャンセルしました', { variant: 'success' });
      props.history.push("/appointment");
    } else {
      enqueueSnackbar('エラーが発生しました', { variant: 'error' });
    }
  }

  useEffect(() => {
    const appointmentId = props.match.params.appointment_id;
    setAppointmentId(appointmentId);

    if (typeof window !== 'undefined' && bank.canUseGA && window.gtag) {
      window.gtag('config', bank.gaTrackingCode, {
        'page_path': `${props.location.pathname}${props.location.search}`
      });
    }

    if (bank.realm) {
      // 支店一覧
      Api.get(bank.realm, process.env.REACT_APP_APPOINTMENT_BASE_URL + '/branches/withmenus', {}, {}, oidcUser)
        .then(branchListResult => {
          let params = {};
          params["a"] = appointmentId;
          Api.get(bank.realm, process.env.REACT_APP_APPOINTMENT_BASE_URL + '/appointment', params, {}, oidcUser)
            .then(appointmentResult => {
              if (appointmentResult.appointment.id === "") {
                props.history.push("/");
              }

              const appointment = appointmentResult.appointment;
              const branch = branchListResult.branches.find(branch => branch.id === appointment.branchId);

              if (branch.menus !== null) {
                const menu = branch.menus.find(menu => menu.id === appointment.menuId);
                setMenuName(menu.name);
                setMenuDuration(menu.duration);
              }

              setBranchName(branch.name);
              setDay(appointment.date);
              setFromTime(appointment.slot.from);
              setToTime(appointment.slot.to);
              setLastName(appointment.guest.kanjiName.lastName);
              setFirstName(appointment.guest.kanjiName.firstName);
              setLastNameKana(appointment.guest.kanaName.lastName);
              setFirstNameKana(appointment.guest.kanaName.firstName);
              setCompanyName(appointment.guest.companyName);
              setTelNo(appointment.guest.tel);
              setEmailAddress(appointment.guest.mail);
              setNote(appointment.guest.note);
            })
            .catch(e => {
              console.log(e);
              props.history.push("/");
            })
        })
        .catch(e => {
          console.log(e);
          props.history.push("/");
        })
    }
  }, [oidcUser, bank, props]);

  return (
    <div className="wrapper">
      <Header />
      <div className="content">
        {(!!oidcUser && matches) && (
          <LeftColumn />
        )}
        <div className="main">
          <ul className="breadcrumb">
            <li><Link to={"/"}>トップ</Link></li>
            <li><Link to={"/appointment"}>来店予約</Link></li>
          </ul>
          <div className="box">
            <h2 className="box-title shop">来店予約</h2>
            <h3 className="box-title">予約内容をご確認ください</h3>
            <form className="left">
              <ReservationConfirm
                branchName={branchName}
                menuName={menuName}
                menuDuration={menuDuration}
                day={day}
                fromTime={fromTime}
                toTime={toTime}
                lastName={lastName}
                firstName={firstName}
                lastNameKana={lastNameKana}
                firstNameKana={firstNameKana}
                companyName={companyName}
                telNo={telNo}
                emailAddress={emailAddress}
                note={note}
              />
            </form>
            <div className={classes.linkWrapper}>
              <Link className={classes.linkText} to={"/appointment/edit/" + appointmentId}>
                <button className="btn key-color size-l arrow mt16 btnSp">予約内容を変更する</button>
              </Link>
              <Link className={classes.linkText} to={"/appointment/detail/" + appointmentId} onClick={handleClickOpenCancel}>
                <button className="btn key-color size-l arrow mt16 btnSp">予約をキャンセルする</button>
              </Link>
              <Dialog
                open={open}
                onClose={handleCloseCancel}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
              >
                <DialogTitle id="alert-dialog-slide-title">{"ご確認ください"}</DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-slide-description">
                    ご予約をキャンセルします。<br/>
                    よろしいですか？
                  </DialogContentText>
                </DialogContent>
                <DialogActions className={classes.cancelPopup}>
                  <Button variant="contained" className={classes.cancelButton} onClick={handleCloseCancel}>
                    いいえ
                  </Button>
                  <Button variant="contained" className={classes.okButton} onClick={cancelAppointment} color="primary">
                    はい
                  </Button>
                </DialogActions>
              </Dialog>
            </div>
          </div>
          <Box className={classes.footer}>
            <Footer />
          </Box>
        </div>
      </div>
    </div>
  )
}

export default Detail;
