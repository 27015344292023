import React from 'react';
import DatePicker from 'react-datepicker';
import {TextField} from '@material-ui/core';
import Box from "@material-ui/core/Box";

const CustomBirthdatePicker = (props) => {
    const years = Array.from({length: (new Date().getFullYear() - 1900 + 1)}, (_, i) => 1900 + i);
    const months = [
        "1月",
        "2月",
        "3月",
        "4月",
        "5月",
        "6月",
        "7月",
        "8月",
        "9月",
        "10月",
        "11月",
        "12月",
    ];

    // デフォルトは1970年1月1日だが、propsがあれば上書き
    let defaultDate = new Date(1970, 0, 1);
    if (props.defaultDate) {
        defaultDate = props.defaultDate;
    }
  
    const handleRawInput = (event) => {
      const inputValue = event.target.value;
      let newDate = null;
      
      // 入力値が "YYYY-MM-DD" 形式の場合
      if (/^\d{4}-\d{2}-\d{2}$/.test(inputValue)) {
        newDate = new Date(inputValue);
      }
      // 入力値が "YYYYMMDD" 形式の場合
      else if (/^\d{8}$/.test(inputValue)) {
        newDate = new Date(
          `${inputValue.substring(0, 4)}-${inputValue.substring(4, 6)}-${inputValue.substring(6, 8)}`
        );
      }
      
      if (newDate && !isNaN(newDate.getTime())) {
        props.handleChangeBirthday(newDate);
      }
    };
    
    return (
        <DatePicker
            openToDate={defaultDate}
            className={props.classes}
            dateFormat="yyyy-MM-dd"
            maxDate={new Date()}
            onChangeRaw={handleRawInput}
            customInput={
                props.isAdmin
                    ?
                    <TextField value={props.birthday} error={props.isErrorBirthday} label="生年月日" id="birthday"
                               variant="outlined" fullWidth={true} size={"small"} required={true}/>
                    : <input type="text" className="size-l" value={props.birthday} id="birthday"/>
            }
            selected={props.birthdate}
            onChange={props.handleChangeBirthday}
            renderCustomHeader={({
                                     date,
                                     changeYear,
                                     changeMonth,
                                 }) => (
                <div
                    style={{
                        margin: 10,
                        display: "flex",
                        justifyContent: "center",
                    }}
                >
                    <select
                        value={date.getFullYear()}
                        onChange={({target: {value}}) => changeYear(value)}
                    >
                        {years.map((option) => (
                            <option key={option} value={option}>
                                {option}
                            </option>
                        ))}
                    </select>
                    <select
                        value={months[date.getMonth()]}
                        onChange={({target: {value}}) =>
                            changeMonth(months.indexOf(value))
                        }
                    >
                        {months.map((option) => (
                            <option key={option} value={option}>
                                {option}
                            </option>
                        ))}
                    </select>
                </div>
            )}
        />
    );
};

export default CustomBirthdatePicker;
