import React from 'react';
import {makeStyles} from "@material-ui/core/styles";

import MainListItems from "./MainListItems";
import clsx from "clsx";


const useStyles = makeStyles((theme) => ({
    menu: {
        height: "calc(100vh - 60px)",
        overflow: "auto",
    },
}));

export default function LeftColumn(props) {
    const classes = useStyles();
    const menu = clsx('menu ' + classes.menu)

    return (
        <div className={menu}>
            <MainListItems chatUnreadCount={props.chatUnreadCount} setChatUnreadCount={props.setChatUnreadCount} />
        </div>
    );
}
