import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from "react-router-dom";
import moment from "moment";
import 'moment/locale/ja';
import { useReactOidc } from '@axa-fr/react-oidc-context';

import Api from "../util/api";
import { useBankContext } from "../util/BankContext";


const useStyles = makeStyles((theme) => ({
    newsUl: {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-between",
        marginTop: 15,
    },
    newsList: {
        borderBottom: "1px dashed #ccc",
        display: "flex",
        marginBottom: 0,
        padding: "20px 0",
        width: "100%",
    },
    newsListDate: {
        color: "#666",
        fontFamily: "'Noto Sans Japanese', sans-serif",
        fontSize: 12,
        letterSpacing: "0 !important",
        marginRight: 20,
        padding: "3px 0",
    },
    newsLinkText: {
        color: "#000 !important",
        fontSize: 16,
        fontWeight: "bold",
        "&:hover": {
            textDecoration: "none",
        },
    },
    moreView: {
        marginTop: 30,
        textAlign: "center",
    },
    linkText: {
        color: "#3039f1",
        cursor: "pointer",
        textDecoration: "underline",
        '&:hover': {
            color: "#262626",
        },
    },
}));

const NewsList = (props) => {
    moment.locale('ja');
    const classes = useStyles();
    const { oidcUser } = useReactOidc();
    const bank = useBankContext();

    const moreViewNews = async (nextPage, event) => {
        // お知らせ一覧取得API
        const newNewsList = await Api.get(props.realm, process.env.REACT_APP_NEWS_BASE_URL + '/news/search', {r: bank.params.news.listRows, p: nextPage}, {}, oidcUser);
        props.setNewsListPagingData(newNewsList.pagingInfo);
        props.setNewsList(props.newsList.concat(newNewsList.newsList));
    };

    return (
        <div>
            <div>
                <div>
                    <ul className={classes.newsUl}>
                        {props.newsList.map((news) => (
                            <li key={news.id} className={classes.newsList}>
                                <p className={classes.newsListDate}>{moment(news.publishedDate).format('YYYY年M月D日(dd)')}</p>
                                <p>
                                    <Link to={"/news/detail/" + news.id} className={classes.newsLinkText}>{news.title}</Link>
                                </p>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
            {!props.newsListPagingData.isLastPage && (
                <div className={classes.moreView}>
                    <span className={classes.linkText} onClick={moreViewNews.bind(this, props.newsListPagingData.nextPage)}>もっと見る</span>
                </div>
            )}
        </div>
    )
};

export default NewsList;
