import React from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import { useReactOidc } from '@axa-fr/react-oidc-context';

import Header from "../../components/Header";
import LeftColumn from "../../components/LeftColumn";
import Footer from "../../components/Footer";
import { useBankContext } from "../../util/BankContext";


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        '& table': {
            border: "1px solid #000",
            borderCollapse: "collapse",
            width: "100%",
        },
        '& tr': {
            height: "50px",
        },
        '& th': {
            background: "#E8E8E8",
        },
        '& td': {
            textAlign: "center",
        },
    },
    footer: {
        marginTop: "auto",
    },
}));

const Settings = () => {
    const bank = useBankContext();
    window.document.title = 'アカウント設定|' + bank.baPortalName;
    const classes = useStyles();
    const { oidcUser, logout, events } = useReactOidc();

    const removeBizSOL = async (url, events) => {
        events.addUserSignedOut(() => {
            window.location = url
        });
        logout();
    };

    return (
        <div className="wrapper">
            <Header />
            <div className={"content"}>
                {!!oidcUser && (
                    <LeftColumn />
                )}
                <div className="main">
                    <ul className="breadcrumb">
                        <li><Link to={"/"}>トップ</Link></li>
                        <li>アカウント設定
                        </li>
                    </ul>
                    <div className="box">
                        <h2 className="box-title">アプリ連携</h2>
                        <div className="seminar-list">
                            <ul>
                            {bank.idps.map((idp) => (
                                <li>
                                <div className="detail">
                                    <div>{idp.name} 連携</div>
                                    <div>
                                        <button　className="btn key-color size-l arrow mt40" onClick={removeBizSOL.bind(this, idp.disconnectUrl, events)}>連携解除</button>
                                    </div>
                                </div>
                                </li>
                            ))}
                            </ul>
                        </div>
                    </div>
                    <Box className={classes.footer}>
                        <Footer />
                    </Box>
                </div>
            </div>
        </div>
    )
}

export default Settings;
