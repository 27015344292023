import React, { useEffect, useState } from "react";
import moment from "moment";
import 'moment/locale/ja';
import { useReactOidc } from '@axa-fr/react-oidc-context';
import { Link } from "react-router-dom";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";

import Footer from '../../components/Footer';
import Api from "../../util/api";
import { useBankContext } from "../../util/BankContext";
import ReservationConfirm from "../../components/ReservationConfirm";


const useStyles = makeStyles(() => ({
  notLoginMain: {
    width: "100vw !important",
    height: "100vh !important",
  },
  footer: {
    marginTop: "auto",
  },
}));

const GuestDetail = (props) => {
  const bank = useBankContext();
  window.document.title = '来店予約|' + bank.baPortalName;
  moment.locale('ja');
  const classes = useStyles();
  const { oidcUser } = useReactOidc();

  const [branchName, setBranchName] = useState('');
  const [menuName, setMenuName] = useState('');
  const [menuDuration, setMenuDuration] = useState(0);
  const [day, setDay] = useState('');
  const [fromTime, setFromTime] = useState('');
  const [toTime, setToTime] = useState('');
  const [lastName, setLastName] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastNameKana, setLastNameKana] = useState('');
  const [firstNameKana, setFirstNameKana] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [telNo, setTelNo] = useState('');
  const [emailAddress, setEmailAddress] = useState('');
  const [note, setNote] = useState('');

  const mainClass = clsx("main", classes.notLoginMain);

  useEffect(() => {

    if (typeof window !== 'undefined' && bank.canUseGA && window.gtag) {
      window.gtag('config', bank.gaTrackingCode, {
        'page_path': `${props.location.pathname}${props.location.search}`
      });
    }

    const appointmentId = props.match.params.appointment_id;
    if (bank.realm) {
      // 支店一覧
      Api.get(bank.realm, process.env.REACT_APP_APPOINTMENT_BASE_URL + '/branches/withmenus', {}, {}, oidcUser)
        .then(branchListResult => {
          let params = {};
          params["a"] = appointmentId;
          Api.get(bank.realm, process.env.REACT_APP_APPOINTMENT_BASE_URL + '/appointment', params, {}, oidcUser)
            .then(appointmentResult => {
              if (appointmentResult.appointment.id === "") {
                props.history.push("/");
              }

              const appointment = appointmentResult.appointment;
              const branch = branchListResult.branches.find(branch => branch.id === appointment.branchId);

              if (branch.menus !== null) {
                const menu = branch.menus.find(menu => menu.id === appointment.menuId);
                setMenuName(menu.name);
                setMenuDuration(menu.duration);
              }

              setBranchName(branch.name);
              setDay(appointment.date);
              setFromTime(appointment.slot.from);
              setToTime(appointment.slot.to);
              setLastName(appointment.guest.kanjiName.lastName);
              setFirstName(appointment.guest.kanjiName.firstName);
              setLastNameKana(appointment.guest.kanaName.lastName);
              setFirstNameKana(appointment.guest.kanaName.firstName);
              setCompanyName(appointment.guest.companyName);
              setTelNo(appointment.guest.tel);
              setEmailAddress(appointment.guest.mail);
              setNote(appointment.guest.note);
            })
            .catch(e => {
              console.log(e);
              props.history.push("/");
            })
        })
        .catch(e => {
          console.log(e);
          props.history.push("/");
        })
    }
  }, [bank, oidcUser, props]);

  return (
    <div className="wrapper">
      <div className="content">
        <div className={mainClass}>
          <ul className="breadcrumb">
            <li><Link to={"/"}>トップ</Link></li>
            <li><Link to={"/appointment"}>来店予約</Link></li>
          </ul>
          <div className="box">
            <h2 className="box-title shop">来店予約</h2>
            <h3 className="box-title">予約内容をご確認ください</h3>
            <form className="left">
              <ReservationConfirm
                branchName={branchName}
                menuName={menuName}
                menuDuration={menuDuration}
                day={day}
                fromTime={fromTime}
                toTime={toTime}
                lastName={lastName}
                firstName={firstName}
                lastNameKana={lastNameKana}
                firstNameKana={firstNameKana}
                companyName={companyName}
                telNo={telNo}
                emailAddress={emailAddress}
                note={note}
              />
            </form>
          </div>
          <Box className={classes.footer}>
            <Footer />
          </Box>
        </div>
      </div>
    </div>
  )
}

export default GuestDetail;
