import React, { useEffect } from "react";
import { Redirect, useLocation } from "react-router-dom";
import { useCookies } from 'react-cookie';
import { useReactOidc } from '@axa-fr/react-oidc-context';

const REDIRECT_TO_COOKIE = 'bap_redirect_to';


const Index = () => {
  const location = useLocation();
  const { from } = location.state || { from: { pathname: "/" } };

  const { oidcUser, login } = useReactOidc();
  const [ cookies, setCookie ] = useCookies();

  useEffect(() => {
    if (oidcUser == null) {
      setCookie(REDIRECT_TO_COOKIE, from.pathname);
      login();
      return <div />;
    }
  }, [from, login, oidcUser, setCookie]);

  const redirect_to = cookies['bap_redirect_to'];

  return (
    !!redirect_to ? (
      <Redirect to={redirect_to} />
    ) : (
      <Redirect to="/" />
    )
  );
};

export default Index;
