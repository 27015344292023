import React, { useEffect } from "react";
import KtbaCookiePolicy from "../../components/ktba/CookiePolicy";
import OkbaCookiePolicy from "../../components/okba/CookiePolicy";
import { useBankContext } from "../../util/BankContext";

const CookiePolicy = (props) => {
  const bank = useBankContext();

  window.document.title = 'クッキーポリシー|' + bank.baPortalName;
  
  useEffect(() => {
    if (bank.canUseGA) {
      if (!!window.gtag) {
        window.gtag('config', bank.gaTrackingCode, {
          'page_path': `${props.location.pathname}${props.location.search}`
        });
      }
    }
  }, [bank, props]);
  return (
    <>
    {bank.realm === 'bank0158' && (
      <KtbaCookiePolicy/>
    )}
    {bank.realm === 'bank0188' && (
      <OkbaCookiePolicy/>
    )}
    </>
  )
}

export default CookiePolicy;