import React, {useEffect, useState} from "react";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import Box from '@material-ui/core/Box';
import { withRouter } from "next/router";
import Typography from "@material-ui/core/Typography";
import { useReactOidc } from '@axa-fr/react-oidc-context';
import { useSnackbar } from 'notistack';
import moment from "moment";

import AdminDatePicker from "../../components/AdminDatePicker";
import Api from "../../util/api";
import AdminHeader from "../../components/AdminHeader";
import AppointmentForm from "../../components/AppointmentForm";
import { useBankContext } from "../../util/BankContext";
import FindADayFromSundayOfTheWeek from "../../util/FindADayFromSundayOfTheWeek";
import AppointmentValidation from "../../util/AppointmentValidation";


const useStyles = makeStyles((theme) => ({
    root: {
        padding: "0 15px",
        margin: "0 auto",
        '&:before': {
            display: "table",
            content: '" "',
        },
        '& table': {
            border: "1px solid #000",
            borderCollapse: "collapse",
            width: "100%",
        },
        '& tr': {
            height: "50px",
        },
        '& th': {
            background: "#E8E8E8",
        },
        '& td': {
            textAlign: "center",
        },
    },
    content: {
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        height: '100vh',
        minHeight: "100vh",
    },
    titleBox: {
        marginBottom: 10,
        padding: "0 15px",
    },
    title: {
        color: "inherit",
        fontFamily: "inherit",
        fontSize: 18,
        fontWeight: 500,
        lineHeight: "1.1",
        marginTop: 10,
        marginBottom: 10,
    },
    button: {
        background: "#457ab2",
        color: theme.palette.primary.color,
        height: 40,
        marginBottom: 20,
        width: 100,
    },
    registerButtonBox: {
        textAlign: "center",
    },
    deleteLinkBox: {
        marginBottom: 20,
        textAlign: "center",
    },
    cancelLink: {
        cursor: "pointer",
        textDecoration: "underline",
        '&:hover': {
            color: "#262626",
        },
    },
    errorMessage: {
        color: "#ff6e6e",
        fontSize: 13,
        textAlign: "center",
    },
}));

const AppointmentEdit = (props) => {
    const bank = useBankContext();
    window.document.title = 'BA Portal';
    const classes = useStyles();
    const [branchList, setBranchList] = React.useState([]);
    const [menuList, setMenuList] = React.useState([]);
    const [calendar, setCalendar] = React.useState({
        timeTable: [],
        availabilities: {}
    });
    const [duration, setDuration] = React.useState('');
    const [showDatePicker, setShowDatePicker] = React.useState(false);
    const [reservationDate, setReservationDate] = React.useState('');
    const [reservationFromTime, setReservationFromTime] = React.useState('');
    const [reservationToTime, setReservationToTime] = React.useState('');
    const [displayReservedDate, setDisplayReservedDate] = React.useState('');
    const [id, setId] = React.useState('');
    const [branchId, setBranchId] = React.useState('');
    const [menuId, setMenuId] = React.useState('');
    const [lastName, setLastName] = React.useState('');
    const [firstName, setFirstName] = React.useState('');
    const [lastNameKana, setLastNameKana] = React.useState('');
    const [firstNameKana, setFirstNameKana] = React.useState('');
    const [companyName, setCompanyName] = React.useState('');
    const [birthdate, setBirthdate] = React.useState('');
    const [birthday, setBirthday] = React.useState('');
    const [telNo, setTelNo] = React.useState('');
    const [emailAddress, setEmailAddress] = React.useState('');
    const [note, setNote] = React.useState('');
    const { oidcUser } = useReactOidc();
    const { enqueueSnackbar } = useSnackbar();
    const [branch, setBranch] = useState(null);
    const [appointment, setAppointment] = useState('');

    const [isErrorLastName, setIsErrorLastName] = useState(false);
    const [isErrorFirstName, setIsErrorFirstName] = useState(false);
    const [isErrorLastNameKana, setIsErrorLastNameKana] = useState(false);
    const [isErrorFirstNameKana, setIsErrorFirstNameKana] = useState(false);
    const [isErrorTelNo, setIsErrorTelNo] = useState(false);
    const [isErrorEmailAddress, setIsErrorEmailAddress] = useState(false);
    const [isErrorBirthday, setIsErrorBirthday] = useState(false);
    const [errorMessages, setErrorMessages] = useState([]);
    const [defaultDate, setDefaultDate] = useState(new Date(1970, 0, 1));
    const [isSubmitAppointment, setIsSubmitAppointment] = useState(false);
    
    const submitAppointment = () => {
        setIsSubmitAppointment(true);

        // 入力情報バリデーション
        const validation = AppointmentValidation(
          true,
          false,
          setIsErrorLastName,
          setIsErrorFirstName,
          setIsErrorLastNameKana,
          setIsErrorFirstNameKana,
          setIsErrorTelNo,
          setIsErrorEmailAddress,
          setIsErrorEmailAddress,
          setIsErrorBirthday,
          lastName,
          firstName,
          lastNameKana,
          firstNameKana,
          telNo,
          emailAddress,
          emailAddress,
          birthday
        );

        // バリデーション結果がfalseの場合はエラーメッセージを表示して終了
        if (!validation.isValid) {
            setErrorMessages(validation.errorMessages);
            // エラーメッセージ表示
            enqueueSnackbar('入力内容に不備があります', { variant: 'error' });

            setIsSubmitAppointment(false);
            return false;
        }

        let params = {};
        if(id !== '') params["a.i"] = id;
        if(branchId !== '') params["a.br"] = branchId;
        if(menuId !== '') params["a.m"] = menuId;
        if(reservationDate !== '') params["a.d"] = reservationDate;
        if(reservationFromTime !== '') params["a.s.f"] = reservationFromTime;
        if(reservationToTime !== '') params["a.s.t"] = reservationToTime;
        if(lastName !== '') params["a.g.n.ln"] = lastName;
        if(firstName !== '') params["a.g.n.fn"] = firstName;
        if(lastNameKana !== '') params["a.g.kn.ln"] = lastNameKana;
        if(firstNameKana !== '') params["a.g.kn.fn"] = firstNameKana;
        if(companyName !== '') params["a.g.cn"] = companyName;
        if(birthday !== '') params["a.g.b"] = birthday;
        if(telNo !== '') params["a.g.t"] = telNo;
        if(emailAddress !== '') params["a.g.m"] = emailAddress;
        if(note !== '') params["a.g.nt"] = note;

        Api.put(bank.realm, process.env.REACT_APP_APPOINTMENT_BASE_URL + '/appointment/change', params, {}, oidcUser)
            .then((response) => {
                if (response.error) {
                    enqueueSnackbar('来店予約の編集に失敗しました', { variant: 'error' });
                    setIsSubmitAppointment(false);
                    return;
                }
                
                // 作成成功のトースト表示
                enqueueSnackbar('来店予約の編集が完了しました', { variant: 'success' });

                return props.history.push('/admin/appointment_list');
            })
            .catch((errorResponse) => {
                enqueueSnackbar('エラーが発生しました', { variant: 'error' });
                setIsSubmitAppointment(false);
            });
    }

    const cancelAppointment = async () => {
        let params = {};
        if(id !== '') params["a"] = id;

        await Api.put(bank.realm, process.env.REACT_APP_APPOINTMENT_BASE_URL + '/appointment/cancel', params, {}, oidcUser);

        // todo 削除成功のトースト表示
        return props.history.push('/admin/appointment_list');
    }

    useEffect(() => {

        if (bank.canUseGA) {
            if (!!window.gtag) {
                window.gtag('config', bank.gaTrackingCode, {
                    'page_path': `${props.location.pathname}${props.location.search}`
                });
            }
        }

        const reservedDate = moment(props.location.state.reservedDate).toDate();

        let f = FindADayFromSundayOfTheWeek(reservedDate).format('YYYY-MM-DD');
        let t = FindADayFromSundayOfTheWeek(reservedDate).add(6, 'd').format('YYYY-MM-DD');

        const fetchData = async (reservedId, f, t) => {
            let branch = null;
    
            // 予約詳細
            const reservedList = await Api.get(bank.realm, process.env.REACT_APP_APPOINTMENT_BASE_URL + '/appointment', {a: reservedId}, {}, oidcUser);
    
            // 支店一覧
            const branchList = await Api.get(bank.realm, process.env.REACT_APP_APPOINTMENT_BASE_URL + '/branches', {}, {}, oidcUser);
            // 支店一覧を設定
            if (!('branches' in branchList) || branchList.branches === null) {
                setBranchList([]);
            } else {
                setBranchList(branchList.branches);
                // 支店取得
                branch = branchList.branches.find(branch => branch.id === reservedList.appointment.branchId);
            }
    
            // メニュー一覧
            const menuList = await Api.get(bank.realm, process.env.REACT_APP_APPOINTMENT_BASE_URL + '/menus', {br: reservedList.appointment.branchId}, {}, oidcUser);
            // メニュー一覧を設定
            if (!('menus' in menuList) || menuList.menus === null) {
                setMenuList([]);
            } else {
                setMenuList(menuList.menus);
            }
    
            // 予約状況一覧
            const availabilityList = await Api.get(bank.realm, process.env.REACT_APP_APPOINTMENT_BASE_URL + '/availabilities', {br: reservedList.appointment.branchId, m: reservedList.appointment.menuId, f: f, t: t}, {}, oidcUser);
            // 予約詳細を設定
            setReservationDate(reservedList.appointment.date)
            setReservationFromTime(reservedList.appointment.slot.from)
            setReservationToTime(reservedList.appointment.slot.to)
            setDisplayReservedDate(reservedList.appointment.date + " " + reservedList.appointment.slot.from + " 〜 " + reservedList.appointment.slot.to)
            setId(reservedList.appointment.id)
            setBranchId(reservedList.appointment.branchId)
            setBranch(branch)
            setMenuId(reservedList.appointment.menuId)
            setLastName(reservedList.appointment.guest.kanjiName.lastName)
            setFirstName(reservedList.appointment.guest.kanjiName.firstName)
            setLastNameKana(reservedList.appointment.guest.kanaName.lastName)
            setFirstNameKana(reservedList.appointment.guest.kanaName.firstName)
            setCompanyName(reservedList.appointment.guest.companyName)
            setBirthday(reservedList.appointment.guest.birthday)
            if (reservedList.appointment.guest.birthday) {
                setDefaultDate(reservedList.appointment.guest.birthdate)
                setBirthdate(new Date(reservedList.appointment.guest.birthday));
            }
            setTelNo(reservedList.appointment.guest.tel)
            setEmailAddress(reservedList.appointment.guest.mail)
            setNote(reservedList.appointment.guest.note)
            setAppointment(reservedList.appointment);
            const selectedMenu = menuList.menus.find((menu) => {
                return (menu.id === reservedList.appointment.menuId);
            });
            setDuration(selectedMenu.duration);
            // 予約状況一覧を設定
            let timeTable = [];
            if (('timeTable' in availabilityList.calendar) && availabilityList.calendar.timeTable !== null) {
                timeTable = availabilityList.calendar.timeTable;
            }
            let availabilities = {};
            if (('availabilities' in availabilityList.calendar) && availabilityList.calendar.availabilities !== null) {
                availabilities = availabilityList.calendar.availabilities;
            }
            setCalendar({
                timeTable: timeTable,
                availabilities: availabilities
            });
            // DatePickerの表示
            setShowDatePicker(true);
        }
    
        fetchData(
            props.location.state.reservedId,
            f,
            t
        )
            .then()
            .catch(e => {
                // todo エラーハンドリング
                if( e.response ){
                    if( e.response.status === 403 ){
                        // todo 「ログインしてください」を表示
                        // todo ユーザー情報の削除
                        // location.href = "/";
                    }
                    //todo エラーであることを知らせる表示
                }
            })
    }, [props, bank, oidcUser]);

    return (
        <div className={classes.root}>
            <AdminHeader />
            <main className={classes.content}>
                <Box maxWidth="lg" className={classes.titleBox}>
                    <h4 className={classes.title}>来店予約編集</h4>
                </Box>
                {errorMessages.map((errorMessage, index) => (
                  <p key={index} className={classes.errorMessage}>{errorMessage}</p>
                ))}
                <AppointmentForm
                    displayReservedDate={displayReservedDate}
                    branches={branchList}
                    menus={menuList}
                    setMenuList={setMenuList}
                    lastName={lastName}
                    firstName={firstName}
                    lastNameKana={lastNameKana}
                    firstNameKana={firstNameKana}
                    companyName={companyName}
                    birthdate={birthdate}
                    birthday={birthday}
                    telNo={telNo}
                    emailAddress={emailAddress}
                    note={note}
                    branch={branch}
                    branchId={branchId}
                    menuId={menuId}
                    setLastName={setLastName}
                    setFirstName={setFirstName}
                    setLastNameKana={setLastNameKana}
                    setFirstNameKana={setFirstNameKana}
                    setCompanyName={setCompanyName}
                    setBirthdate={setBirthdate}
                    setBirthday={setBirthday}
                    defaultDate={defaultDate}
                    setTelNo={setTelNo}
                    setBranch={setBranch}
                    setBranchId={setBranchId}
                    setMenuId={setMenuId}
                    setEmailAddress={setEmailAddress}
                    setNote={setNote}
                    setShowDatePicker={setShowDatePicker}
                    setDuration={setDuration}
                    duration={duration}
                    reservationDate={reservationDate}
                    reservationFromTime={reservationFromTime}
                    setReservationToTime={setReservationToTime}
                    setDisplayReservedDate={setDisplayReservedDate}
                    setCalendar={setCalendar}
                    realm={bank.realm}
                    isErrorLastName={isErrorLastName}
                    isErrorFirstName={isErrorFirstName}
                    isErrorLastNameKana={isErrorLastNameKana}
                    isErrorFirstNameKana={isErrorFirstNameKana}
                    isErrorTelNo={isErrorTelNo}
                    isErrorEmailAddress={isErrorEmailAddress}
                    isErrorBirthday={isErrorBirthday}
                    setAppointment={setAppointment}
                />
                {showDatePicker && (
                    <div>
                        <Box maxWidth="lg" className={classes.titleBox}>
                            <h4 className={classes.title}>予約日時：{displayReservedDate}</h4>
                        </Box>
                        <AdminDatePicker
                            calendar={calendar}
                            setCalendar={setCalendar}
                            setReservationDate={setReservationDate}
                            setReservationFromTime={setReservationFromTime}
                            setReservationToTime={setReservationToTime}
                            setDisplayReservedDate={setDisplayReservedDate}
                            setDuration={setDuration}
                            duration={duration}
                            date={reservationDate}
                            from={reservationFromTime}
                            realm={bank.realm}
                            appointment={appointment}
                        />
                        <Box className={classes.registerButtonBox}>
                            <Button disabled={isSubmitAppointment} onClick={submitAppointment} variant="contained" className={classes.button}>保存</Button>
                        </Box>
                        <Box className={classes.deleteLinkBox}>
                            <span onClick={cancelAppointment} className={classes.cancelLink}>この予約を削除する</span>
                        </Box>
                    </div>
                )}
                {!showDatePicker && (
                    <Typography variant="h6" color="textSecondary">
                        支店名、メニューを選択してください
                    </Typography>
                )}
            </main>
        </div>
    )
}

export default withRouter(AppointmentEdit);
