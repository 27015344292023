import React, { useEffect } from "react";
import KtbaRule from "../../components/ktba/Rule";
import OkbaRule from "../../components/okba/Rule";
import { useBankContext } from "../../util/BankContext";

const Rule = (props) => {
  const bank = useBankContext();

  window.document.title = '利用規約|' + bank.baPortalName;
  
  useEffect(() => {
    if (bank.canUseGA) {
      if (!!window.gtag) {
        window.gtag('config', bank.gaTrackingCode, {
          'page_path': `${props.location.pathname}${props.location.search}`
        });
      }
    }
  }, [bank, props]);
  return (
    <>
    {bank.realm === 'bank0158' && (
      <KtbaRule/>
    )}
    {bank.realm === 'bank0188' && (
      <OkbaRule/>
    )}
    </>
  )
}

export default Rule;