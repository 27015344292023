import React, { useEffect } from "react";
import {connect, useDispatch, useSelector} from "react-redux";
import { useCookies } from 'react-cookie';
import { makeStyles } from "@material-ui/core/styles";
import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from "@material-ui/core/Button";
import clsx from "clsx";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useReactOidc, AuthenticationContext } from '@axa-fr/react-oidc-context';
import moment from 'moment';
import 'moment/locale/ja';
import { Link } from "react-router-dom";
import uuid from 'react-uuid'

import Header from '../../components/Header';
import Footer from '../../components/Footer';
import LeftColumn from "../../components/LeftColumn";
import ShopMenuSelect from "../../components/ShopMenuSelect";
import Api from "../../util/api";
import { useBankContext } from "../../util/BankContext";
import useSafeState from "../../helpers/useSafeState";
import useUnmountRef from "../../helpers/useUnmountRef";


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        '& table': {
            border: "1px solid #000",
            borderCollapse: "collapse",
            width: "100%",
        },
        '& tr': {
            height: "50px",
        },
        '& th': {
            background: "#E8E8E8",
        },
        '& td': {
            textAlign: "center",
        },
    },
    content: {
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        height: '100vh',
        minHeight: "100vh",
        overflow: 'auto',
    },
    container: {
        paddingTop: theme.mixins.toolbar.lineHeight,
    },
    title: {
        backgroundColor: theme.palette.text.primary,
        padding: "10px 8px",
        '& p': {
            color: theme.palette.background.default,
        },
    },
    paper: {
        background: "#E8E8E8",
        display: 'flex',
        flexDirection: 'column',
        marginTop: 50,
        overflow: 'auto',
    },
    table: {
        border: "1px solid #000",
        borderCollapse: "collapse",
        width: "100%",
        '& tr': {
            height: "50px",
        },
        '& th': {
            background: "#E8E8E8",
            textAlign: "center",
        },
        '& td': {
            textAlign: "center",
        },
    },
    height100: {
        height: 100,
    },
    height250: {
        height: 260,
    },
    white: {
        background: "#fff",
    },
    annotation: {
        padding: 20,
    },
    paperBoxCaption: {
        background: "#606060",
        color: "#fff",
        height: 50,
        textAlign: "center",
    },
    captionHeight: {
        lineHeight: "50px",
    },
    center: {
        textAlign: "center",
    },
    p4: {
        padding: 10,
    },
    subTitle: {
        margin: "80px 0 20px 20px",
    },
    description: {
        marginTop: 20,
        textAlign: "center",
    },
    footer: {
        marginTop: "auto",
    },
    m40: {
        margin: 40,
    },
    cancelPopup: {
        display: "inline-block",
        textAlign: "center",
    },
    cancelButton: {
        background: "#fff",
        margin: "20px 0",
    },
    okButton: {
        background: "#606060",
        margin: "20px 0",
    },
    linkText: {
        color: "#3039f1",
        cursor: "pointer",
        textDecoration: "underline",
        '&:hover': {
            color: "#262626",
        },
    },
    notLoginMain: {
        width: "100vw !important",
        height: "100vh !important",
    },
}));

const useIsEdit = () => {
    const isEdit = useSelector((state) => state.isEdit);
    const dispatch = useDispatch();
    const setIsEdit = (isEdit) =>
        dispatch({
            type: 'SET_IS_EDIT',
            isEdit: isEdit,
        });
    return { isEdit, setIsEdit }
}

const useBranchId = () => {
    const branchId = useSelector((state) => state.branchId);
    const dispatch = useDispatch();
    const setBranchId = (branchId) =>
        dispatch({
            type: 'SET_BRANCH_ID',
            branchId: branchId,
        });
    return { branchId, setBranchId }
}

const useMenuId = () => {
    const menuId = useSelector((state) => state.menuId);
    const dispatch = useDispatch();
    const setMenuId = (menuId) =>
        dispatch({
            type: 'SET_MENU_ID',
            menuId: menuId,
        });
    return { menuId, setMenuId }
}

const useBranchName = () => {
    const branchName = useSelector((state) => state.branchName);
    const dispatch = useDispatch();
    const setBranchName = (branchName) =>
        dispatch({
            type: 'SET_BRANCH_NAME',
            branchName: branchName,
        });
    return { branchName, setBranchName }
}

const useMenuName = () => {
    const menuName = useSelector((state) => state.menuName);
    const dispatch = useDispatch();
    const setMenuName = (menuName) =>
        dispatch({
            type: 'SET_MENU_NAME',
            menuName: menuName,
        });
    return { menuName, setMenuName }
}

const useMenuDuration = () => {
    const menuDuration = useSelector((state) => state.menuDuration);
    const dispatch = useDispatch();
    const setMenuDuration = (menuDuration) =>
        dispatch({
            type: 'SET_MENU_DURATION',
            menuDuration: menuDuration,
        });
    return { menuDuration, setMenuDuration }
}

const useAppointmentId = () => {
    const appointmentId = useSelector((state) => state.appointmentId);
    const dispatch = useDispatch();
    const setAppointmentId = (appointmentId) =>
        dispatch({
            type: 'SET_APPOINTMENT_ID',
            appointmentId: appointmentId,
        });
    return { appointmentId, setAppointmentId }
}

const useReservedDate = () => {
    const dispatch = useDispatch();
    const setReservedDate = (reservedDate) =>
        dispatch({
            type: 'SET_RESERVED_DATE',
            reservedDate: reservedDate,
        });
    return { setReservedDate }
}

const Index = (props) => {
    const matches = useMediaQuery('(min-width:600px)');
    const bank = useBankContext();
    window.document.title = '来店予約|' + bank.baPortalName;
    moment.locale('ja');
    const classes = useStyles();
    const unmountRef = useUnmountRef();

    const { isEdit, setIsEdit } = useIsEdit();
    const { branchId, setBranchId } = useBranchId();
    const { menuId, setMenuId } = useMenuId();
    const { branchName, setBranchName } = useBranchName();
    const { menuName, setMenuName } = useMenuName();
    const { menuDuration, setMenuDuration } = useMenuDuration();
    const { setReservedDate } = useReservedDate();
    // eslint-disable-next-line no-unused-vars
    const { appointmentId, setAppointmentId } = useAppointmentId();
    const [branch, setBranch] = useSafeState(unmountRef, null);
    const [branchList, setBranchList] = useSafeState(unmountRef,[]);
    const [menuList, setMenuList] = useSafeState(unmountRef,[]);
    const [appointmentList, setAppointmentList] = useSafeState(unmountRef,[]);
    const [open, setOpen] = useSafeState(unmountRef, false);
    const [openCancelResult, setOpenCancelResult] = useSafeState(unmountRef, false);
    const [openError, setOpenError] = useSafeState(unmountRef, false);
    const [cookie, _] = useCookies();
    const { oidcUser } = useReactOidc();

    const notLoginMainClass = (!oidcUser ? classes.notLoginMain : '');
    const mainClass = clsx("main", notLoginMainClass);

    const handleClickOpenCancel = (id, event) => {
        setAppointmentId(id);
        setOpen(true);
    };

    const handleCloseCancel = () => {
        setOpen(false);
    };

    const cancelAppointment = async () => {
        let params = {};
        if(props.appointmentId !== '') params["a"] = props.appointmentId;

        let userId = '';
        let isGuest = false;

        if (!oidcUser) {
            userId = uuid();
            isGuest = true;
        } else {
            userId = oidcUser;
        }
        if (bank.realm) {
            const result = await Api.put(bank.realm, process.env.REACT_APP_APPOINTMENT_BASE_URL + '/appointment/cancel', params, {}, userId, isGuest);

            setAppointmentId('');
            setOpen(false);

            if (result.status === 500) {
                setOpenError(true);
            } else if (result.ok) {
                // キャンセル成功の通知表示
                setOpenCancelResult(true);

                const appointmentList = await Api.get(bank.realm, process.env.REACT_APP_APPOINTMENT_BASE_URL + '/appointments', {}, {}, userId, isGuest);
                setAppointmentList(appointmentList.appointments);
            } else {
                setOpenError(true);
            }
    }
    }

    const handleCloseCancelResult = (event) => {
        setOpenCancelResult(false);
    }

    const handleCloseError = (event) => {
        setOpenError(false);
    }

    useEffect( () => {

        if (typeof window !== 'undefined' && bank.canUseGA && window.gtag) {
            window.gtag('config', bank.gaTrackingCode, {
                'page_path': `${props.location.pathname}${props.location.search}`
            });
        }
      
        const handleIsEdit = (appointmentId) => {
            setBranch(null);
            setBranchId('');
            setMenuId('');
    
            if (bank.realm) {
                // 支店一覧
                Api.get(bank.realm, process.env.REACT_APP_APPOINTMENT_BASE_URL + '/branches/withmenus', {}, {}, oidcUser)
                    .then(branchListResult => {
                        if (branchListResult.branches === null) {
                            setBranchList([]);
                        } else {
                            setBranchList(branchListResult.branches);
                        }
    
                        let params = {};
                        if(appointmentId !== '') params["a"] = appointmentId;
                        if (bank.realm) {
                            Api.get(bank.realm, process.env.REACT_APP_APPOINTMENT_BASE_URL + '/appointment', params, {}, oidcUser)
                                .then(appointmentResult => {
                                    const appointment = appointmentResult.appointment;
                                    setIsEdit(true);
                                    setAppointmentId(appointment.id);
                                    setReservedDate({
                                        day: appointment.date,
                                        fromTime: appointment.slot.from,
                                        toTime: appointment.slot.to,
                                    });
    
                                    const branch = branchListResult.branches.find(branch => branch.id === appointment.branchId);
                                    setBranch(branch);
                                    setBranchId(branch.id);
                                    setBranchName(branch.name);
    
                                    // メニュー一覧設定
                                    if (branch.menus !== null) {
                                        setMenuList(branch.menus);
                                        const menu = branch.menus.find(menu => menu.id === appointment.menuId);
                                        setMenuId(menu.id);
                                        setMenuName(menu.name);
                                        setMenuDuration(menu.duration);
                                    }
                                })
                                .catch(e => {
                                    console.log(e);
                                    // todo エラー処理
                                    props.history.push("/404");
                                })
                            }
                    })
                    .catch(e => {
                        console.log(e);
                        // todo エラー処理
                        props.history.push("/404");
                    })
            }
        }    

        if (props.match.params.appointment_id !== undefined) {
            handleIsEdit(props.match.params.appointment_id);
        } else {
            fetchData()
                .then()
                .catch(e => {
                    // todo エラーハンドリング
                    if( e.response ){
                        if( e.response.status === 403 ){
                            // todo 「ログインしてください」を表示
                            // todo ユーザー情報の削除
                            // location.href = "/";
                        }
                        //todo エラーであることを知らせる表示
                    }
                })
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props, oidcUser, bank]);

    const fetchData = async () => {
        setBranch(null);
        setBranchId('');
        setMenuId('');
        setAppointmentId('');
        setReservedDate('');
        setMenuList([]);

        if (bank.realm) {
            // 支店一覧
            const branchListResult = await Api.get(bank.realm, process.env.REACT_APP_APPOINTMENT_BASE_URL + '/branches/withmenus', {}, {}, oidcUser);

            if (!('branches' in branchListResult) || branchListResult.branches === null) {
                setBranchList([]);
            } else {
                setBranchList(branchListResult.branches);
                const branch = branchListResult.branches.find(branch => branch.id === cookie.last_appointment?.branchId);
                if (branch) {
                    setBranch(branch);
                    setBranchId(branch.id);
                    setBranchName(branch.name);
                    // メニュー一覧設定
                    if (branch.menus !== null) {
                        setMenuList(branch.menus);
                    }
                }
            }

            if (!!oidcUser) {
                // 予約済み一覧
                const appointmentList = await Api.get(bank.realm, process.env.REACT_APP_APPOINTMENT_BASE_URL + '/appointments', {}, {}, oidcUser);
                if (!('appointments' in appointmentList) || appointmentList.appointments === null) {
                    setAppointmentList([]);
                } else {
                    setAppointmentList(appointmentList.appointments);
                }
            }
        }
    };

    return (
        <div className="wrapper">
            {!!oidcUser && (
                <Header />
            )}
            <div className="content">
                {(!!oidcUser && matches) && (
                    <LeftColumn />
                )}
                <div className={mainClass}>
                    <ul className="breadcrumb">
                        {!!oidcUser && (
                            <li><Link to={"/"}>トップ</Link></li>
                        )}
                        <li>来店予約</li>
                    </ul>
                    <div className="box">
                        <h2 className="box-title shop">来店予約</h2>
                        {!!oidcUser && !isEdit && (
                            <>
                                <p className="box-hint">来店予約をしていただいたお客様を優先的にご案内させていただきます。</p>
                                {/*予約済みのアイテムがない場合は非表示*/}
                                {appointmentList.length > 0 && (
                                    <div className="reserved-info">
                                        {appointmentList.map((appointment, index) => (
                                            <div key={index}>
                                                <ul>
                                                    <li>
                                                        <div className="status-label">予約済み</div>
                                                        <div className="body">
                                                            <div className="detail">
                                                                <p className="branch">店舗：{appointment.branch}</p>
                                                                <p className="date">来店日時：{moment(appointment.date).format('YYYY/M/D(dd)')} {appointment.from}〜{appointment.to}</p>
                                                                <p className="menu">メニュー：{appointment.menu}</p>
                                                            </div>
                                                            <div className="user-info">
                                                                <p className="company-name">会社名：{appointment.company}</p>
                                                                <p className="user-name">氏名：{appointment.guest}</p>
                                                                <p className="tel">電話番号：{appointment.tel}</p>
                                                            </div>
                                                        </div>
                                                    </li>
                                                </ul>
                                                <div className="cancel-and-edit">
                                                    <Link to={"/appointment"} onClick={handleClickOpenCancel.bind(this, appointment.id)}>予約をキャンセルする</Link>
                                                    <Dialog
                                                        open={open}
                                                        onClose={handleCloseCancel}
                                                        aria-labelledby="simple-modal-title"
                                                        aria-describedby="simple-modal-description"
                                                    >
                                                        <DialogTitle id="alert-dialog-slide-title">{"ご確認ください"}</DialogTitle>
                                                        <DialogContent>
                                                            <DialogContentText id="alert-dialog-slide-description">
                                                                ご予約をキャンセルします。<br/>
                                                                よろしいですか？
                                                            </DialogContentText>
                                                        </DialogContent>
                                                        <DialogActions className={classes.cancelPopup}>
                                                            <Button variant="contained" className={classes.cancelButton} onClick={handleCloseCancel}>
                                                                いいえ
                                                            </Button>
                                                            <Button variant="contained" className={classes.okButton} onClick={cancelAppointment} color="primary">
                                                                はい
                                                            </Button>
                                                        </DialogActions>
                                                    </Dialog>
                                                    <Snackbar open={openCancelResult} autoHideDuration={6000} onClose={handleCloseCancelResult}>
                                                        <MuiAlert elevation={6} variant="filled" onClose={handleCloseCancelResult} severity="success">
                                                            予約をキャンセルしました
                                                        </MuiAlert>
                                                    </Snackbar>
                                                    <Link to={"/appointment/edit/" + appointment.id}>予約内容を変更する</Link>
                                                </div>
                                            </div>
                                        ))}
                                        <p className="reserved-attention">
                                            ※ご予約の時間までにご来店いただけなかった場合、店舗状況により順番が前後する場合がございます。<br />
                                            あらかじめご了承いただけますようお願い申し上げます。
                                        </p>
                                    </div>
                                )}
                            </>
                        )}
                        <ShopMenuSelect
                            branchList={branchList}
                            branch={branch}
                            setBranch={setBranch}
                            branchName={branchName}
                            setBranchName={setBranchName}
                            menuName={menuName}
                            setMenuName={setMenuName}
                            menuDuration={menuDuration}
                            setMenuDuration={setMenuDuration}
                            menuList={menuList}
                            setMenuList={setMenuList}
                            branchId={branchId}
                            setBranchId={setBranchId}
                            menuId={menuId}
                            setMenuId={setMenuId}
                        />
                    </div>
                    <Snackbar open={openError} autoHideDuration={6000} onClose={handleCloseError}>
                        <MuiAlert elevation={6} variant="filled" onClose={handleCloseError} severity="error">
                            エラーが発生しました
                        </MuiAlert>
                    </Snackbar>
                    <Box className={classes.footer}>
                        <Footer />
                    </Box>
                </div>
            </div>
        </div>
    )
}

export default connect(state => state)(Index);
