import axios from "axios";


export default class Api {
    static async get(realm, url, params, headers, userData, isGuest = false) {
        if( !headers || typeof headers !== 'object' ){
            headers = {};
        }

        if (!isGuest && !!userData) {
            headers['Authorization'] = `Bearer ${userData.id_token}`;
        }

        headers['REALM'] = realm;

        params['_'] = Date.now();

        return axios({
            method: 'get',
            url: url,
            params: params,
            headers: headers
        })
            .then(r => {
                if (r.headers['location'] !== undefined) {
                    return r.headers['location'];
                }
                if ('content-disposition' in r.headers) {
                    let filename = r.headers['content-disposition'].match(/filename=([\w-]+\.\w+)/)[1]
                    return {data: r.data, filename: filename};
                }
                if (r.data.result !== undefined) {
                    return r.data.result;
                } else {
                    return r.data;
                }
            })
            .catch(e => {
                if( e.response ){
                    console.log(e);
                    if( e.response.status === 403 ){
                        // todo 「ログインしてください」を表示
                        // todo ユーザー情報の削除
                        // location.href = "/";
                    }
                    //todo エラーであることを知らせる表示
                }
            })
            ;
    }

    static async post(realm, url, params, headers, userData, isGuest = false) {
        if( !headers || typeof headers !== 'object' ){
            headers = {};
        }

        if( !isGuest && !!userData) {
            headers['Authorization'] = `Bearer ${userData.id_token}`;
        }

        headers['REALM'] = realm;

        return axios({
            method: 'post',
            url: url,
            params: params,
            headers: headers,
        })
            .then(r => {
                return r.data;
            })
            .catch(e => {
                console.log(e)
                if( e.response ){
                    if( e.response.status === 403 ){
                        // todo 「ログインしてください」を表示
                        // todo ユーザー情報の削除
                        // location.href = "/";
                    }
                    //todo エラーであることを知らせる表示
                }
            })
            ;
    }

    static async postBodyParam(realm, url, params, headers, userData, cancelSource = null) {
        if( !headers || typeof headers !== 'object' ){
            headers = {};
        }

        if (!!userData) {
            headers['Authorization'] = `Bearer ${userData.id_token}`;
        }

        headers['REALM'] = realm;

        try {
            const r = await axios({
                method: 'post',
                url: url,
                headers: headers,
                data: params,
                cancelToken: cancelSource?.token,
            });

            if (r.data.result !== undefined) {
                return r.data.result;
            } else {
                return r.data;
            }

        } catch(e) {
            if (axios.isCancel(e)){
                return null;
            }
            throw e;
        }
    }

    static async put(realm, url, params, headers, userData, isGuest = false) {
        if( !headers || typeof headers !== 'object' ){
            headers = {};
        }

        if (!isGuest && !!userData) {
            headers['Authorization'] = `Bearer ${userData.id_token}`;
        }

        headers['REALM'] = realm;

        return axios({
            method: 'put',
            url: url,
            params: params,
            headers: headers,
        })
            .then(r => {
                return r.data;
            })
            .catch(e => {
                if( e.response ){
                    if( e.response.status === 403 ){
                        // todo 「ログインしてください」を表示
                        // todo ユーザー情報の削除
                        // location.href = "/";
                    }
                    // エラーであることを知らせる
                    return e.response;
                }
            })
            ;
    }

    static async putBodyParam(realm, url, data, headers, userData, params) {
        if( !headers || typeof headers !== 'object' ){
            headers = {};
        }

        if (!!userData) {
            headers['Authorization'] = `Bearer ${userData.id_token}`;
        }

        headers['REALM'] = realm;

        return axios({
            method: 'put',
            url: url,
            headers: headers,
            data: data,
            params: params,
        })
            .then(r => {
                if (r.data.result !== undefined) {
                    return r.data.result;
                } else {
                    return r.data;
                }
            })
            .catch(e => {
                if( e.response ){
                    if( e.response.status === 403 ){
                        // todo 「ログインしてください」を表示
                    }
                    //todo エラーであることを知らせる表示
                }
            })
            ;
    }

    static async delete(realm, url, params, headers, userData) {
        if( !headers || typeof headers !== 'object' ){
            headers = {};
        }

        if (!!userData) {
            headers['Authorization'] = `Bearer ${userData.id_token}`;
        }

        headers['REALM'] = realm;

        return axios({
            method: 'delete',
            url: url,
            params: params,
            headers: headers,
        })
            .then(r => {
                return r.data;
            })
            .catch(e => {
                if( e.response ){
                    if( e.response.status === 403 ){
                        // todo 「ログインしてください」を表示
                        // todo ユーザー情報の削除
                        // location.href = "/";
                    }
                    //todo エラーであることを知らせる表示
                }
            })
            ;
    }
}
