import { red } from '@material-ui/core/colors';
import { unstable_createMuiStrictModeTheme as createMuiTheme } from '@material-ui/core';

// A custom theme for this app
const theme = createMuiTheme({
    overrides: {
        MuiCssBaseline: {
            '@global': {
                '*': {
                    boxSizing: "border-box",
                },
                ':after,:before': {
                    boxSizing: "border-box",
                },
                html: {
                    padding: 0,
                    margin: 0,
                    // fontFamily: "sans-serif",
                    WebkitFontSmoothing: "",
                },
                body: {
                    overflowY: "hidden",
                    minWidth: 1000,
                    // fontFamily: '"Helvetica Neue",Helvetica,Arial,sans-serif',
                    letterSpacing: "",
                },
                h1: {
                    margin: 0,
                },
                h2: {
                    margin: 0,
                },
                h3: {
                    margin: 0,
                },
                ul: {
                    margin: 0,
                    padding: 0,
                },
                p: {
                    margin: 0,
                },
                header: {
                    position: "relative",
                    textAlign: "center",
                },
            }
        }
    },
    palette: {
        primary: {
            main: '#556cd6',
            color: '#fff',
        },
        secondary: {
            main: '#fff',
        },
        error: {
            main: red.A400,
        },
        background: {
            default: '#fff',
        },
    },
    mixins: {
        toolbar: {
            lineHeight: "50px",
        },
    },
});

export default theme;
