import React from 'react';
import { connect } from "react-redux";
import { withRouter } from "react-router";
import MenuIcon from '@material-ui/icons/Menu';
import Popper from '@material-ui/core/Popper';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import MainListItems from "./MainListItems";
import useSafeState from "../helpers/useSafeState";
import useUnmountRef from "../helpers/useUnmountRef";


const HeaderMenu = (props) => {
    const unmountRef = useUnmountRef();
    const [open, setOpen] = useSafeState(unmountRef, false);
    const anchorRef = React.useRef(null);
    
    const handleToggle = () => {
        if (open) {
            window.document.querySelector('body').classList.remove('no-scroll');
        } else {
            window.document.querySelector('body').classList.add('no-scroll');
        }
        setOpen((prevOpen) => !prevOpen);
    };
    
    // return focus to the button when we transitioned from !open -> open
    const prevOpen = React.useRef(open);
    React.useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }

        prevOpen.current = open;
    }, [open]);

    return (
        <div style={{ position: "relative", zIndex: 100 }}>
            <IconButton
                ref={anchorRef}
                aria-controls={open ? 'menu-list-grow' : undefined}
                aria-haspopup="true"
                onClick={handleToggle}
            >
                <MenuIcon />
            </IconButton>
        <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
            {({ TransitionProps, placement }) => (
            <Grow
                {...TransitionProps}
                style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
            >
                <Paper>
                    <MainListItems isResponsive={true}/>
                </Paper>
            </Grow>
            )}
        </Popper>
        </div>
    );
}

export default withRouter(connect(state => state)(HeaderMenu));
