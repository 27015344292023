import Box from "@material-ui/core/Box";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { withRouter } from "next/router";
import moment from 'moment';
import { connect } from "react-redux";
import { useReactOidc } from '@axa-fr/react-oidc-context';
import clsx from "clsx";

import Api from "../util/api";
import useSafeState from "../helpers/useSafeState";
import useUnmountRef from "../helpers/useUnmountRef";


const useStyles = makeStyles((theme) => ({
    root: {
        background: "#fff",
        marginBottom: 20,
        marginTop: 20,
        width: "100%",
    },
    relativeBox: {
        height: 20,
        position: "relative",
    },
    beforeWeek: {
        color: "#19AAC4",
        cursor: "pointer",
        left: 0,
        position: "absolute",
        textDecoration: "underline",
        '&:hover': {
            textDecoration: "none",
        },
    },
    afterWeek: {
        color: "#19AAC4",
        cursor: "pointer",
        position: "absolute",
        right: 0,
        textDecoration: "underline",
        '&:hover': {
            textDecoration: "none",
        },
    },
    tableContainer: {
        maxHeight: "60vh",
        overflowY: "auto",
    },
    table: {
        border: "none",
        borderCollapse: "collapse",
        minWidth: 650,
        width: "100%",
        '& .selected': {
            background: "#fcc483",
        },
        '& tr': {
            borderCollapse: "collapse",
            height: "50px",
        },
        '& th': {
            borderCollapse: "collapse",
            background: "#E8E8E8",
            textAlign: "center",
        },
        '& td': {
            borderCollapse: "collapse",
            textAlign: "center",
        },
    },
    tableBody: {
    },
    tableCell: {
        borderBottom: "1px solid rgba(224, 224, 224, 1)",
        padding: 0,
        '&:hover': {
            background: "#fcc483",
        }
    },
    stickyColCell: {
        zIndex: 3,
        borderRight: "none",
        top : 0,
        left : 1,
        '&::before': {
            content : "''",
            position : "absolute",
            top : 0,
            left : -1,
            width : "100%",
            height : "100%",
            borderRight: "1px solid #000",
            borderLeft: "1px solid #000",
        },
    },
    stickyRowCell: {
        zIndex: 4,
        borderTop: "none",
        borderBottom: "none",
        top : 1,
        left : 0,

        '&::before': {
            content : "''",
            position : "absolute",
            top : -1,
            left : 0,
            width : "100%",
            height : "100%",
            borderTop: "1px solid #000",
            borderBottom: "1px solid #000",
        },
    },
    stickyCornerCell: {
        zIndex: 5,
        borderTop: "none",
        borderBottom: "none",
        top : 1,
        left : 1,

        '&::before': {
            content : "''",
            position : "absolute",
            top : -1,
            left : -1,
            width : "100%",
            height : "100%",
            border: "1px solid #000",
        },
    },
    linkCell: {
        lineHeight: "52px",
        display: "block",
        cursor: "pointer",
    },
}));

const TableCellLink = ({ index, handleSelectDate, activeIndex, classes, dayList, time, duration, availability, branch, menu }) => {
    let selected = "";
    let tableCell = classes.tableCell;
    let click = function() {};
    if (availability === 1 || availability === 2) {
        selected = (activeIndex ? 'selected' : '');
        tableCell = clsx(classes.tableCell, selected);
        click = handleSelectDate.bind(this, branch, menu, dayList[index], time, duration);
    }
    return (
        <TableCell
            align="right"
            key={index}
            className={tableCell}
            onClick={click}
        >   
            {availability === 0 && <span className={classes.linkCell}>✕</span>}
            {availability === 1 && <span className={classes.linkCell}>△</span>}
            {availability === 2 && <span className={classes.linkCell}>◯</span>}
            {availability === 3 && <span className={classes.linkCell}>TEL</span>}
            {availability === 4 && <span className={classes.linkCell}>✕</span>}
        </TableCell>
    );
};

const DatePicker = (props) => {
    const unmountRef = useUnmountRef();
    const classes = useStyles();
    const [activeIndex, setActiveIndex] = useSafeState(unmountRef, -1);
    const [activeDayIndex, setActiveDayIndex] = useSafeState(unmountRef, -1);
    const { oidcUser } = useReactOidc();

    let list = [];
    let dayList = [];
    let availabilityList = [];
    let timeList = props.calendar.timeTable;

    const userAgent = window.navigator.userAgent.toLowerCase();
    const isIE = () => {
        if (userAgent.includes('msie') || userAgent.includes('trident')) {
            return true;
        }
        return false;
    };
    const isSticky = !isIE();

    // 日付ヘッダセルの生成
    for (let key of Object.keys(props.calendar.availabilities)) {
        dayList.push(key);
        let mmdd = moment(key);
        list.push(<TableCell  className={isSticky && classes.stickyRowCell} variant="head" align="right" key={key}>{mmdd.format("M/DD")}({mmdd.format("ddd")})</TableCell>);
        // eslint-disable-next-line no-loop-func
        props.calendar.availabilities[key].forEach((availability, availabilityIndex) => {
            let availabilities = [];
            for (let dayKey of Object.keys(props.calendar.availabilities)) {
                availabilities.push(props.calendar.availabilities[dayKey][availabilityIndex]);
            }
            availabilityList.push(availabilities);
        });
    }

    // 現在の予約日を選択状態にする
    let initialActiveIndex = -1;
    let initialActiveDayIndex = -1;
    if (activeIndex === -1 && activeDayIndex === -1) {
        dayList.forEach((day, dayIndex) => {
            timeList.forEach((time, timeIndex) => {
                if(props.reservedDate.day === day && props.reservedDate.fromTime === time){
                    initialActiveIndex = timeIndex;
                    initialActiveDayIndex = dayIndex;
                }
            });
        });
        if (initialActiveIndex !== -1 && initialActiveDayIndex !== -1) {
            setActiveIndex(initialActiveIndex);
            setActiveDayIndex(initialActiveDayIndex);
        }
    }

    const handleChangeWeek = async (isBefore, event) => {
        let startDate = moment(dayList[0]);
        let endDate = moment(dayList[list.length - 1]);

        if (isBefore) {
            startDate = startDate.subtract(1, 'w').format('YYYY-MM-DD');
            endDate = endDate.subtract(1, 'w').format('YYYY-MM-DD');
        } else {
            startDate = startDate.add(1, 'w').format('YYYY-MM-DD');
            endDate = endDate.add(1, 'w').format('YYYY-MM-DD');
        }

        const availabilityListResponse = await Api.get(props.realm, process.env.REACT_APP_APPOINTMENT_BASE_URL + '/availabilities', {br: props.branchId, m: props.menuId, f: startDate, t: endDate}, {}, oidcUser);

        let newTimeTable = [];
        if (('timeTable' in availabilityListResponse.calendar) && availabilityListResponse.calendar.timeTable !== null) {
            newTimeTable = availabilityListResponse.calendar.timeTable;
        }

        let availabilities = {};
        if (('availabilities' in availabilityListResponse.calendar) && availabilityListResponse.calendar.availabilities !== null) {
            availabilities = availabilityListResponse.calendar.availabilities;
        }

        props.setCalendar({
            timeTable: newTimeTable,
            availabilities: availabilities
        });

        list = [];
        dayList = [];
        availabilityList = [];
        timeList = availabilityListResponse.calendar.timeTable === null ? [] : availabilityListResponse.calendar.timeTable;

        // 日付ヘッダセルの生成
        for (let key of Object.keys(availabilityListResponse.calendar.availabilities === null ? {} : availabilityListResponse.calendar.availabilities)) {
            dayList.push(key);
            list.push(<TableCell className={isSticky && classes.stickyRowCell} variant="head" align="right" key={key}>{key}</TableCell>);
            // eslint-disable-next-line no-loop-func
            availabilityListResponse.calendar.availabilities[key].forEach((availability, availabilityIndex) => {
                let availabilities = [];
                for (let dayKey of Object.keys(availabilityListResponse.calendar.availabilities)) {
                    availabilities.push(availabilityListResponse.calendar.availabilities[dayKey][availabilityIndex]);
                }
                availabilityList.push(availabilities);
            });
        }
    };

    return (
        <Box className={classes.root}>
            <Box className={classes.relativeBox}>
                <span onClick={handleChangeWeek.bind(this, true)} className={classes.beforeWeek}>＜前週</span>
                <span onClick={handleChangeWeek.bind(this, false)} className={classes.afterWeek}>次週＞</span>
            </Box>
            <TableContainer className={classes.tableContainer} component={Paper}>
                <Table className={classes.table} stickyHeader={isSticky} aria-label={isSticky ? "sticky table": "table"} border={1}>
                    <TableBody className={classes.tableBody}>
                        <TableRow className={"time-table-header"}>
                            <TableCell className={isSticky && classes.stickyCornerCell} variant="head" />
                            {list}
                        </TableRow>
                        {timeList.map((time, timeIndex) => (
                            <TableRow key={time}>
                                <TableCell className={isSticky && classes.stickyColCell} variant="head" align="right">{time}〜</TableCell>
                                {availabilityList[timeIndex].map((availability, index) => (
                                    <TableCellLink
                                        key={index}
                                        index={index}
                                        handleSelectDate={props.handleSelectDate}
                                        activeIndex={activeIndex === timeIndex && activeDayIndex === index}
                                        classes={classes}
                                        dayList={dayList}
                                        time={time}
                                        duration={props.duration}
                                        availability={availability}
                                        branch={props.branchId}
                                        menu={props.menuId}
                                    />
                                ))}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    )
}

export default connect(state => state)(withRouter(DatePicker));
