import React, { useState } from 'react';
import { useCookies } from 'react-cookie';
import {Dialog, DialogContent, Typography} from "@material-ui/core";
import LaunchIcon from '@material-ui/icons/Launch';
import { makeStyles } from "@material-ui/core/styles";
import { useBankContext } from "../util/BankContext";

const useStyles = makeStyles(() => ({
  ieDialogContent: {
    padding: "60px 60px 80px 60px !important",
    fontSize: "18px",
    "& div": {
      lineHeight: "1.75em !important",
      "& img.close": {
        position: "absolute",
        top: "20px",
        right: "20px",
        width: "48px",
      },
      "& p": {
        marginTop: "4px",
        fontSize: "16px",
        "& a": {
          display: "inline-flex",
          color: "#26AB9D",
          textDecoration: "none",
          "&:hover": {
            color: "#1B867B",
          }
        }
      }
    },
  },
  ieDialogLaunchIcon: {
    width: "1rem",
  },
}));

const AlertIEDialog = () => {
  // BAP-408 IE利用時にアラートダイアログを表示する
  const [cookie, setCookie] = useCookies();

  const userAgent = window.navigator.userAgent.toLowerCase();
  const isIE = () => {
    if (userAgent.includes('msie') || userAgent.includes('trident')) {
      return true;
    }
    return false;
  };

  const showDialog = () => {
    if (isIE() && !cookie['ie_alert_shown']) {
      return true;
    }
    return false;
  };

  const [openCancelDialog, setOpenCancelDialog] = useState(showDialog());
  const handleCloseCancelDialog = () => {
    setOpenCancelDialog(false);
    // 1時間経過後のアクセス時に再表示する
    const expires = new Date();
    expires.setHours(expires.getHours() + 1);
    setCookie('ie_alert_shown', true, { expires: expires });
  };

  const classes = useStyles();
  const bank = useBankContext();

  if (!isIE()) {
    return false;
  }

  return (
    <Dialog
      open={openCancelDialog}
      onClose={handleCloseCancelDialog}
      maxWidth="lg"
      className={classes.ieDialog}
    >
      <DialogContent className={classes.ieDialogContent}>
        <div>
          <Typography align="right" color="textSecondary">
            <img className="close" src={`/img/${bank.staticFilePathPrefix}/close-button.png`} onClick={handleCloseCancelDialog} alt="x" />
          </Typography>
          ご利用中のブラウザ「Internet Explorer（IE）」は、<br />
          2021年10月8日をもちまして、当サイトでご利用できなくなりました。<br />
          お手数ですが下記の対応ブラウザでのご利用をお願いいたします。<br />
          <br />
          対応ブラウザ: Microsft Edge（最新版）、Google Chrome（最新版）<br />
          <p>
            Microsoft Edgeのダウンロード<br />
            <a href="https://www.microsoft.com/ja-jp/edge" >https://www.microsoft.com/ja-jp/edge&nbsp;<LaunchIcon className={classes.ieDialogLaunchIcon} /></a><br />
          </p>
          <p>
            Google Chromeのダウンロード<br />
            <a href="https://www.google.com/chrome/" >https://www.google.com/chrome/&nbsp;<LaunchIcon className={classes.ieDialogLaunchIcon} /></a><br />
          </p>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default AlertIEDialog;
