import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from '@material-ui/core/Box';
import Grid from "@material-ui/core/Grid";
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import clsx from "clsx";
import Radio from '@material-ui/core/Radio';
import FormControl from "@material-ui/core/FormControl";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { useReactOidc } from '@axa-fr/react-oidc-context';
import moment from "moment";
import 'moment/locale/ja';
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ja from 'date-fns/locale/ja'

import AdminHeader from '../../components/AdminHeader';
import Api from "../../util/api";
import { useBankContext } from "../../util/BankContext";


const useStyle = makeStyles((theme) => ({
    root: {
        padding: "0 15px",
        margin: "0 auto",
        '&:before': {
            display: "table",
            content: '" "',
        },
    },
    content: {
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        height: '100vh',
        minHeight: "100vh",
    },
    titleBox: {
        marginBottom: 10,
        padding: "0 15px",
    },
    title: {
        color: "inherit",
        fontFamily: "inherit",
        fontSize: 18,
        fontWeight: 500,
        lineHeight: "1.1",
        marginTop: 10,
        marginBottom: 10,
    },
    panel: {
        backgroundColor: "#fff",
        border: "1px solid transparent",
        borderColor: "#ddd",
        borderRadius: 4,
        boxShadow: "0 1px 1px rgba(0,0,0,.05)",
        marginBottom: 20,
    },
    panelHeader: {
        backgroundColor: "#e8e8e9",
        height: 56,
        lineHeight: "56px",
        paddingLeft: 15,
    },
    panelBody: {
        padding: 15,
    },
    button: {
        background: "#457ab2",
        color: theme.palette.primary.color,
        width: "auto",
        height: 40,
    },
    settingsButton: {
        background: "#52a451",
        borderRadius: 12,
        color: theme.palette.primary.color,
        height: 25,
    },
    deleteButton: {
        background: "#ca5b55",
        borderRadius: 12,
        color: theme.palette.primary.color,
        height: 25,
    },
    textFieldBox: {
        listStyleType: "none",
        marginBottom: 20,
        padding: 0,
        width: "auto",
    },
    ml10: {
        marginLeft: 20,
    },
    w300: {
        width: 300,
    },
    floatLeft: {
        float: "left",
    },
    radio: {
        flexDirection: "unset",
        flexWrap: "unset",
        height: 20,
    },
    availableBranchSettingsButtonBox: {
        margin: 20,
        textAlign: "center",
    },
    availableBranchSettingsTextBoxLeft: {
        margin: 20,
        textAlign: "left"
    },
}))

const AllSettings = (props) => {
    const bank = useBankContext();
    window.document.title = 'BA Portal';
    moment.locale('ja');
    registerLocale('ja', ja);
    const classes = useStyle();
    const [menuList, setMenuList] = React.useState([]);
    const [holidayList, setHolidayList] = React.useState([]);
    const [branchList, setBranchList] = React.useState([]);
    const [menuName, setMenuName] = React.useState('');
    const [holiday, setHoliday] = React.useState('');
    const [date, setDate] = React.useState('');

    const textFieldBoxW300 = clsx(classes.textFieldBox, classes.w300, classes.floatLeft);
    const addMenuButton = clsx(classes.button, classes.ml10);
    const addHolidayButton = clsx(classes.button, classes.ml10);

    const { oidcUser } = useReactOidc();

    const handleChangeMenuName = (event) => {
        setMenuName(event.target.value);
    }

    const handleChangeHoliday = (date) => {
        if (date === null) {
            setDate('');
            setHoliday('');
            return;
        }

        setDate(moment.utc(date, 'YYYY-MM-DDTHH:mm:00Z', 'ja').utcOffset(9).toDate());
        const formatDate = moment(date).format('YYYY-MM-DD');
        setHoliday(formatDate);
    }

    const handleChangeBranchAvailable = (branchId, index, event) => {
        let copyBranchList = [];
        branchList.forEach((obj, objIndex) => {
            if(objIndex === index){
                obj["isReservable"] = event.target.value === "1";
            }
            copyBranchList.push(obj);
        });
        setBranchList(copyBranchList);
    }

    // メニュー追加
    const addMenu = async () => {
        if (menuName === '') return;

        let params = {};
        params["m.n"] = menuName;

        await Api.post(bank.realm, process.env.REACT_APP_APPOINTMENT_BASE_URL + '/menu_master', params, {}, oidcUser);

        const menuList = await Api.get(bank.realm, process.env.REACT_APP_APPOINTMENT_BASE_URL + '/menu_masters', {}, {}, oidcUser);
        if (!('masters' in menuList) || menuList.masters === null) {
            setMenuList([]);
        } else {
            setMenuList(menuList.masters);
        }

        setMenuName('');
    }

    // 休業日追加
    const addHoliday = async () => {
        let params = {};
        if(holiday !== '') params["d"] = holiday;

        await Api.post(bank.realm, process.env.REACT_APP_APPOINTMENT_BASE_URL + '/holiday', params, {}, oidcUser);

        // 休業日一覧取得
        const holidayList = await Api.get(bank.realm, process.env.REACT_APP_APPOINTMENT_BASE_URL + '/holidays', {}, {}, oidcUser);
        if (!('holidays' in holidayList) || holidayList.holidays === null) {
            setHolidayList([]);
        } else {
            setHolidayList(holidayList.holidays);
        }

        setHoliday('');
        setDate('');
    }

    // 休業日削除
    const deleteHoliday = async (holidayId, event) => {
        let params = {};
        if(holidayId !== '') params["h"] = holidayId;

        await Api.delete(bank.realm, process.env.REACT_APP_APPOINTMENT_BASE_URL + '/holiday', params, {}, oidcUser);

        // 休業日一覧取得
        const holidayList = await Api.get(bank.realm, process.env.REACT_APP_APPOINTMENT_BASE_URL + '/holidays', {}, {}, oidcUser);
        if (!('holidays' in holidayList) || holidayList.holidays === null) {
            setHolidayList([]);
        } else {
            setHolidayList(holidayList.holidays);
        }
    }

    // 来店予約可能支店設定
    const updateReservationAvailableBranch = async () => {
        let branches = [];
        branchList.forEach(obj => {
            branches.push({id: obj.id, reservability: obj.isReservable});
        });
        await Api.putBodyParam(
            bank.realm,
            process.env.REACT_APP_APPOINTMENT_BASE_URL + '/branch/reservabilities',
            { branches: branches },
            {},
            oidcUser,
        );
    }

    // 来店予約可能支店設定
    const syncBranch = async () => {
        Api.post(bank.realm, process.env.REACT_APP_APPOINTMENT_BASE_URL + '/branch/sync_ba', {}, {}, oidcUser)
            .then(
                alert('支店情報を同期しました。')
            )
            .catch(e => {
                console.log(e);
                if (e.status === 500) {
                    alert(e);
                }
            });
    }

    useEffect( () => {
        if (bank.canUseGA) {
            if (!!window.gtag) {
                window.gtag('config', bank.gaTrackingCode, {
                    'page_path': `${props.location.pathname}${props.location.search}`
                });
            }
        }

        const fetchData = async () => {
            // メニュー一覧取得
            const menuList = await Api.get(bank.realm, process.env.REACT_APP_APPOINTMENT_BASE_URL + '/menu_masters', {}, {}, oidcUser);
            if (!('masters' in menuList) || menuList.masters === null) {
                setMenuList([]);
            } else {
                setMenuList(menuList.masters);
            }
    
            // 休業日一覧取得
            const holidayList = await Api.get(bank.realm, process.env.REACT_APP_APPOINTMENT_BASE_URL + '/holidays', {}, {}, oidcUser);
            if (!('holidays' in holidayList) || holidayList.holidays === null) {
                setHolidayList([]);
            } else {
                setHolidayList(holidayList.holidays);
            }
    
            // 支店一覧取得
            // 来店予約可能支店設定
            const branchList = await Api.get(bank.realm, process.env.REACT_APP_APPOINTMENT_BASE_URL + '/branches', {}, {}, oidcUser);
            if (!('branches' in branchList) || branchList.branches === null) {
                setBranchList([]);
            } else {
                setBranchList(branchList.branches);
            }
        }
    
        fetchData()
            .then()
            .catch(e => {
                // todo エラーハンドリング
                if( e.response ){
                    if( e.response.status === 403 ){
                        // todo 「ログインしてください」を表示
                        // todo ユーザー情報の削除
                        // location.href = "/";
                    }
                    //todo エラーであることを知らせる表示
                }
            })
    }, [bank, oidcUser, props]);

    return (
        <div className={classes.root}>
            <AdminHeader />
            <main className={classes.content}>
                <Box maxWidth="lg" className={classes.titleBox}>
                    <h4 className={classes.title}>全体設定</h4>
                </Box>
                <Grid container spacing={4}>
                    <Grid item xs={6}>
                        <Box className={classes.panel}>
                            <Box className={classes.panelHeader}>
                                メニュー設定
                            </Box>
                            <Box className={classes.panelBody}>
                                <Box className={textFieldBoxW300}>
                                    <TextField onChange={handleChangeMenuName} value={menuName} label="メニュー名称" variant="outlined" fullWidth={true} size={"small"} />
                                </Box>
                                <Box>
                                    <Button onClick={addMenu} variant="contained" className={addMenuButton}>メニュー追加</Button>
                                </Box>
                                <TableContainer component={Paper}>
                                    <Table aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>メニュー</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {menuList.map((menu) => (
                                                <TableRow key={menu.id}>
                                                    <TableCell>{menu.name}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Box>
                        </Box>
                        <Box className={classes.panel}>
                            <Box className={classes.panelHeader}>
                                休業日設定
                            </Box>
                            <Box className={classes.panelBody}>
                                <Box className={textFieldBoxW300}>
                                    <DatePicker
                                        locale="ja"
                                        selected={date}
                                        dateFormat="yyyy/MM/dd"
                                        onChange={handleChangeHoliday}
                                        customInput={<TextField value={holiday} label="休業日" variant="outlined" fullWidth={true} size={"small"} />}
                                    />
                                </Box>
                                <Box>
                                    <Button onClick={addHoliday} variant="contained" className={addHolidayButton}>追加</Button>
                                </Box>
                                <TableContainer component={Paper}>
                                    <Table aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell width={"80%"}>休業日</TableCell>
                                                <TableCell width={"20%"} />
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {holidayList.map((holiday, index) => (
                                                <TableRow key={holiday.id}>
                                                    <TableCell>{holiday.date}</TableCell>
                                                    <TableCell>
                                                        <Button onClick={deleteHoliday.bind(this, holiday.id)} variant="contained" className={classes.deleteButton}>削除</Button>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={6}>
                        <Box className={classes.panel}>
                            <Box className={classes.panelHeader}>
                                来店予約可能支店設定
                            </Box>
                            <Box className={classes.panelBody}>
                                <TableContainer component={Paper}>
                                    <Table aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell width={"60%"}>支店名</TableCell>
                                                <TableCell width={"30%"}></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {branchList.map((branch, index) => (
                                                <TableRow key={branch.id}>
                                                    <TableCell>{branch.name}</TableCell>
                                                    <TableCell>
                                                        <FormControl component="fieldset">
                                                            <RadioGroup className={classes.radio} aria-label="status" name="status" value={branch.isReservable === true ? "1" : "0"} onChange={handleChangeBranchAvailable.bind(this, branch.id, index)}>
                                                                <FormControlLabel value="1" control={<Radio color={'default'} />} label="有効" />
                                                                <FormControlLabel value="0" control={<Radio color={'default'} />} label="無効" />
                                                            </RadioGroup>
                                                        </FormControl>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                    <Box className={classes.availableBranchSettingsButtonBox}>
                                        <Button onClick={updateReservationAvailableBranch} variant="contained" className={classes.button}>設定</Button>
                                    </Box>
                                </TableContainer>
                            </Box>
                        </Box>
                        <Box className={classes.panel}>
                            <Box className={classes.panelHeader}>
                                支店マスタデータ連携
                            </Box>
                            <Box className={classes.panelBody}>
                                <Box className={classes.availableBranchSettingsTextBoxLeft}>
                                    Big Advance 管理画面で登録した支店マスタデータを来店予約システムの支店マスタデータとして取り込みます。<br />
                                    【支店が削除される場合の注意点】
                                    過去の予約情報に登録されている支店情報はそのまま保持されますが、削除された支店を指定して検索することはできなくなりますのでご了承ください。
                                </Box>
                                <Box className={classes.availableBranchSettingsButtonBox}>
                                <Button onClick={syncBranch} variant="contained" className={classes.button}>連携する</Button>
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </main>
        </div>
    );
}

export default AllSettings;
