import React from 'react';
import {Link} from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Box from '@material-ui/core/Box';
import Button from "@material-ui/core/Button";
import TableContainer from '@material-ui/core/TableContainer';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';


const useStyles = makeStyles((theme) => ({
    root: {
        marginBottom: 20,
    },
    buttonBox: {
        float: "left",
    },
    button: {
        background: "#457ab2",
        color: theme.palette.primary.color,
        height: 40,
        margin: "10px 0",
        width: 150,
    },
    paginationBox: {
        float: "right",
        height: 60,
        lineHeight: "60px",
    },
    table: {
        minWidth: 650,
    },
    tableHead: {
        background: "#e1e0e1",
    },
    linkText: {
        textDecoration: "none",
    },
}));

const AppointmentDate = ({appointmentDate, from, to}) => {
    appointmentDate = appointmentDate.replace(/-/g, '/');
    const dayOfWeek = new Date(appointmentDate).getDay();
    const dayOfWeekStr = [ "日", "月", "火", "水", "木", "金", "土" ][dayOfWeek];

    return (
    <TableCell>{appointmentDate + "(" + dayOfWeekStr + ") " + from + " 〜 " + to}</TableCell>
    );
};

const SearchedList = (props) => {
    const classes = useStyles();

    return (
        <Box className={classes.root}>
            <Box className={classes.buttonBox}>
                <Link className={classes.linkText} to={{
                    pathname: "/admin/appointment_register",
                }}>
                    <Button variant="contained" className={classes.button}>予約を作成する</Button>
                </Link>
            </Box>
            <Box>
                <TableContainer component={Paper}>
                    <Table className={classes.table} aria-label="simple table">
                        <TableHead className={classes.tableHead}>
                            <TableRow>
                                <TableCell></TableCell>
                                <TableCell>支店名</TableCell>
                                <TableCell>来店日時</TableCell>
                                <TableCell>企業名</TableCell>
                                <TableCell>氏名</TableCell>
                                <TableCell>お名前(カナ)</TableCell>
                                <TableCell>生年月日</TableCell>
                                <TableCell>メニュー</TableCell>
                                <TableCell>メニュー種別</TableCell>
                                <TableCell>電話番号</TableCell>
                                <TableCell>メールアドレス</TableCell>
                                <TableCell>ご用件</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {props.searchedItemList.map((searchedItem, index) => (
                                <TableRow key={searchedItem.id}>
                                    <TableCell>
                                        <Link to={{
                                            pathname: "/admin/appointment_edit",
                                            state: {
                                                reservedDate: searchedItem.date,
                                                reservedId: searchedItem.id,
                                            },
                                        }}>
                                            編集
                                        </Link>
                                    </TableCell>
                                    <TableCell>{searchedItem.branch}</TableCell>
                                    <AppointmentDate appointmentDate={searchedItem.date} from={searchedItem.from} to={searchedItem.to} />
                                    <TableCell>{searchedItem.company}</TableCell>
                                    <TableCell>{searchedItem.guest}</TableCell>
                                    <TableCell>{searchedItem.guestKana}</TableCell>
                                    <TableCell>{searchedItem.birthday}</TableCell>
                                    <TableCell>{searchedItem.menu}</TableCell>
                                    <TableCell>{searchedItem.group}</TableCell>
                                    <TableCell>{searchedItem.tel}</TableCell>
                                    <TableCell>{searchedItem.mail}</TableCell>
                                    <TableCell>{searchedItem.note}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        </Box>
    )
}

export default SearchedList;
