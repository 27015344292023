import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from '@material-ui/core/Box';
import Typography from "@material-ui/core/Typography";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import clsx from "clsx";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import Button from "@material-ui/core/Button";
import Grid from '@material-ui/core/Grid';
import TextField from "@material-ui/core/TextField";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import { useReactOidc } from '@axa-fr/react-oidc-context';
import moment from "moment";
import 'moment/locale/ja';
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Autocomplete from '@material-ui/lab/Autocomplete';
import ja from 'date-fns/locale/ja';
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";

import AdminHeader from '../../components/AdminHeader';
import Api from "../../util/api";
import { useBankContext } from "../../util/BankContext";


const useStyle = makeStyles((theme) => ({
    root: {
        padding: "0 15px",
        margin: "0 auto",
        '&:before': {
            display: "table",
            content: '" "',
        },
    },
    content: {
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        height: '100vh',
        minHeight: "100vh",
    },
    titleBox: {
        marginBottom: 10,
        padding: "0 15px",
    },
    title: {
        color: "inherit",
        fontFamily: "inherit",
        fontSize: 18,
        fontWeight: 500,
        lineHeight: "1.1",
        marginTop: 10,
        marginBottom: 10,
    },
    label: {
        height: 36,
        lineHeight: "36px",
        listStyleType: "none",
        marginLeft: 10,
        padding: 0,
    },
    panel: {
        backgroundColor: "#fff",
        border: "1px solid transparent",
        borderColor: "#ddd",
        borderRadius: 4,
        boxShadow: "0 1px 1px rgba(0,0,0,.05)",
        marginBottom: 20,
    },
    panelHeader: {
        backgroundColor: "#e8e8e9",
        height: 56,
        lineHeight: "56px",
        paddingLeft: 15,
    },
    panelBody: {
        padding: 15,
    },
    formControl: {
        margin: "0 10px",
        minWidth: 120,
    },
    button: {
        background: "#457ab2",
        color: theme.palette.primary.color,
        margin: "0 10px 20px",
        width: 100,
        height: 40,
    },
    settingsButton: {
        background: "#52a451",
        borderRadius: 12,
        color: theme.palette.primary.color,
        height: 25,
    },
    floatLeft: {
        float: "left",
    },
    buttonBox: {
        textAlign: "center",
        display: "block",
    },
    dl: {
        margin: 0,
        padding: "13px 0 0 0",
        position: "relative",
        width: "100%",
    },
    dd: {
        color: "#5f5f5f",
        display: "inline-block",
        fontSize: "100%",
        fontWeight: "bold",
        margin: 0,
        padding: "36px 0 0 0",
        textAlign: "left",
        width: 550,
    },
    dialogContentTitleBox: {
        float: "left",
        height: 56,
        lineHeight: "56px",
        listStyleType: "none",
        margin: "0 10px 0 0",
        padding: 0,
        width: 150,
    },
    textFieldBox: {
        float: "left",
        listStyleType: "none",
        margin: "0 10px",
        padding: 0,
        width: "auto",
    },
    w70: {
        width: 70,
    },
    w130: {
        width: 130,
    },
    w150: {
        width: 150,
    },
    w200: {
        width: 200,
    },
    w300: {
        width: 300,
    },
    lh40: {
        lineHeight: "40px",
    },
    lh60: {
        lineHeight: "60px",
    },
    radio: {
        flexDirection: "unset",
        flexWrap: "unset",
        height: 20,
    },
    deleteButton: {
        background: "#ca5b55",
        borderRadius: 12,
        color: theme.palette.primary.color,
        height: 25,
    },
    addButton: {
        background: "#457ab2",
        color: theme.palette.primary.color,
        height: 40,
        marginLeft: 20,
        width: "auto",
    },
    floatLeftMb20: {
        float: "left",
        marginBottom: 20,
    },
    globalIcon: {
        backgroundColor: "#616161",
        borderRadius: 5,
        color: "#fff",
        display: "inline-block",
        fontSize: 12,
        marginRight: 15,
        padding: "5px 10px 3px",
    },
    bgGray: {
        backgroundColor: "#e0e0e0",
    },
    textAlignRight: {
        textAlign: "right",
    },
    reservationPeriodBox: {
        float: "left",
        lineHeight: "40px",
    },
    autocomplete: {
        display: "inline-flex",
        margin: "0 10px",
        verticalAlign: "top",
    },
    autocompleteTextField: {
        height: 40,
        '& div': {
            height: 40,
        },
        '& div input': {
            padding: "0 !important",
        },
    },
    required: {
        color: "#ff4500",
    },
}));

const timeList = [
    "09:00",
    "09:30",
    "10:00",
    "10:30",
    "11:00",
    "11:30",
    "12:00",
    "12:30",
    "13:00",
    "13:30",
    "14:00",
    "14:30",
    "15:00",
    "15:30",
    "16:00",
    "16:30",
    "17:00",
    "17:30",
    "18:00",
    "18:30",
    "19:00",
    "19:30",
    "20:00",
];

const DurationCell = ({ duration }) => {
    let hour;
    let minute;
    let displayDuration;

    if (duration !== 0) {
        hour = Math.floor(duration / 60) !== 0 ? Math.floor(duration / 60) + "時間" : "";
        minute = duration % 60;
        displayDuration = hour + minute + '分';
    }

    return (
        <TableCell align={"center"}>{duration !== 0 ? displayDuration : '-'}</TableCell>
    );
}

const BranchSettings = (props) => {
    const bank = useBankContext();
    window.document.title = 'BA Portal';
    moment.locale('ja');
    registerLocale('ja', ja);
    const classes = useStyle();
    const [branchList, setBranchList] = useState([]);
    const [reservationTargetDateAndTime, setReservationTargetDateAndTime] = useState({
        sunday: {},
        monday: {},
        tuesday: {},
        wednesday: {},
        thursday: {},
        friday: {},
        saturday: {},
    });
    const [dailyReservationTargetDateAndTime, setDailyReservationTargetDateAndTime] = useState([]);
    const [showDetail, setShowDetail] = useState(false);
    const [branch, setBranch] = useState(null);
    const [branchId, setBranchId] = useState('');
    const [branchName, setBranchName] = useState('');
    const [menuList, setMenuList] = useState([]);
    const [menuMasterId, setMenuMasterId] = useState('');
    const [menuId, setMenuId] = useState('');
    const [menuGroupList, setMenuGroupList] = useState([]);
    const [menuAvailable, setMenuAvailable] =useState('');
    const [menuGroupId, setMenuGroupId] = useState('');
    const [requiredTimeHour, setRequiredTimeHour] = useState('');
    const [requiredTimeMinute, setRequiredTimeMinute] = useState('');
    const [limitDay, setLimitDay] = useState('');
    const [limitHour, setLimitHour] = useState('');
    const [menuGroupName, setMenuGroupName] = useState('');
    const [capacity, setCapacity] = useState('');
    const [reservationAvailablePeriod, setReservationAvailablePeriod] = useState('');
    const [holidayList, setHolidayList] = useState([]);
    const [holiday, setHoliday] = useState('');
    const [isDaily, setIsDaily] = useState(false);
    const [targetDate, setTargetDate] = useState('');
    const [startTime, setStartTime] = useState("");
    const [endTime, setEndTime] = useState("");
    const [openTimeList, setOpenTimeList] = useState([]);
    const [target, setTarget] = useState('');
    const [openMenuSettings, setOpenMenuSettings] = useState(false);
    const [openMenuGroupSettings, setOpenMenuGroupSettings] = useState(false);
    const [openReservationTargetDateAndTimeSettings, setOpenReservationTargetDateAndTimeSettings] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const requiredTimeFloatFormControl = clsx(classes.formControl, classes.w130, classes.floatLeft);
    const formControlW150 = clsx(classes.formControl, classes.w150);
    const formControlW300 = clsx(classes.formControl, classes.w300);
    const textFieldBoxW70 = clsx(classes.textFieldBox, classes.w70);
    const textFieldBoxW200 = clsx(classes.textFieldBox, classes.w200);
    const ineffectiveHoliday = clsx(classes.bgGray, classes.textAlignRight);
    const [date, setDate] = useState('');
    const [holidayDate, setHolidayDate] = useState('');
    const [isErrorMenuGroup, setIsErrorMenuGroup] = useState(false);
    const [isErrorRequiredTimeHour, setIsErrorRequiredTimeHour] = useState(false);
    const [isErrorRequiredTimeMinute, setIsErrorRequiredTimeMinute] = useState(false);
    const [isError, setIsError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] = useState(false);
    const menuGroupSettingsButton = isSubmitButtonDisabled ? '' : classes.addButton;

    const { oidcUser } = useReactOidc();

    const handleChangeBranchId = (event, branch) => {
        if (branch === null) {
            setBranch(null);
            setBranchId('');
        } else {
            setBranch(branch);
            setBranchId(branch.id);
        }
    }

    const handleClickBranchSearchButton = async () => {
        if (branchId !== '' && branchId !== undefined){
            const menuList = await Api.get(bank.realm, process.env.REACT_APP_APPOINTMENT_BASE_URL + '/menus', {br: branchId}, {}, oidcUser);
            const menuGroupList = await Api.get(bank.realm, process.env.REACT_APP_APPOINTMENT_BASE_URL + '/menu_groups', {br: branchId}, {}, oidcUser);
            const branchDetail = await Api.get(bank.realm, process.env.REACT_APP_APPOINTMENT_BASE_URL + '/branch', {br: branchId}, {}, oidcUser);
            // 休業日一覧取得
            const holidayList = await Api.get(bank.realm, process.env.REACT_APP_APPOINTMENT_BASE_URL + '/branch/holidays', {br: branchId}, {}, oidcUser);
            // 営業時間（曜日別）取得
            const reservationTargetDateAndTime = await Api.get(bank.realm, process.env.REACT_APP_APPOINTMENT_BASE_URL + '/branch/opening_hours/weekly', {br: branchId}, {}, oidcUser);
            // 営業時間（日別）取得
            const dailyReservationTargetDateAndTime = await Api.get(bank.realm, process.env.REACT_APP_APPOINTMENT_BASE_URL + '/branch/opening_hours/daily', {br: branchId}, {}, oidcUser);

            if (!('menus' in menuList) || menuList.menus === null) {
                setMenuList([]);
            } else {
                setMenuList(menuList.menus);
            }

            if (!('groups' in menuGroupList) || menuGroupList.groups === null) {
                setMenuGroupList([]);
            } else {
                setMenuGroupList(menuGroupList.groups);
            }

            if (!('branch' in branchDetail) || branchDetail.branch === null) {
                setReservationAvailablePeriod('');
            } else {
                // 予約可能期間
                if (!('preReservableMonth' in branchDetail.branch) || branchDetail.branch.preReservableMonth === null) {
                    setReservationAvailablePeriod('');
                } else {
                    setReservationAvailablePeriod(branchDetail.branch.preReservableMonth);
                }
            }

            if (!('holidays' in holidayList) || holidayList.holidays === null) {
                setHolidayList([]);
            } else {
                setHolidayList(holidayList.holidays);
            }

            if (!reservationTargetDateAndTime || !('Sunday' in reservationTargetDateAndTime.hours)) {
                setReservationTargetDateAndTime(
                    {
                        Sunday: {
                            open: false,
                            slots: [],
                        },
                        Monday: {
                            open: false,
                            slots: [],
                        },
                        Tuesday: {
                            open: false,
                            slots: [],
                        },
                        Wednesday: {
                            open: false,
                            slots: [],
                        },
                        Thursday: {
                            open: false,
                            slots: [],
                        },
                        Friday: {
                            open: false,
                            slots: [],
                        },
                        Saturday: {
                            open: false,
                            slots: [],
                        },
                    }
                );
            } else {
                setReservationTargetDateAndTime(reservationTargetDateAndTime.hours);
            }

            if (!dailyReservationTargetDateAndTime || !('hours' in dailyReservationTargetDateAndTime)) {
                setDailyReservationTargetDateAndTime([]);
            } else {
                setDailyReservationTargetDateAndTime(dailyReservationTargetDateAndTime.hours);
            }

            setBranchName(branch.name);
            setShowDetail(true);
        } else {
            setMenuList([]);
            setReservationAvailablePeriod('');
            setShowDetail(false);
            setBranchName('');
        }
    }

    const handleClickOpenMenuSettings = (menu, event) => {
        setIsErrorMenuGroup(false);
        setIsErrorRequiredTimeHour(false);
        setIsErrorRequiredTimeMinute(false);
        setIsError(false);
        setErrorMessage('');

        // メニューマスタid
        setMenuMasterId(menu.master.id);
        // メニューid
        setMenuId(menu.id);
        // 有効/無効
        menu.available ? setMenuAvailable('1') : setMenuAvailable('0');
        // メニュー種別
        menu.group.id !== 0 ? setMenuGroupId(menu.group.id) : setMenuGroupId('');
        // 所要時間（時間）
        menu.duration !== 0 ? setRequiredTimeHour(Math.floor(menu.duration / 60)) : setRequiredTimeHour('');
        // 所要時間（分）
        menu.duration !== 0 ? setRequiredTimeMinute(menu.duration % 60) : setRequiredTimeMinute('');
        // 予約受付締め切り期限（日前）
        setLimitDay(menu.closingTime.day);
        // 予約受付締め切り期限（時まで）
        setLimitHour(menu.closingTime.hour);

        setOpenMenuSettings(true);
    };

    const handleChangeMenuGroupId = (event) => {
        setMenuGroupId(event.target.value);
    }

    const handleChangeMenuAvailable = (event) => {
        setMenuAvailable(event.target.value);
    }

    const handleChangeRequiredTimeHour = (event) => {
        setRequiredTimeHour(event.target.value);
    }

    const handleChangeRequiredTimeMinute = (event) => {
        setRequiredTimeMinute(event.target.value);
    }

    const handleChangeLimitDay = (event) => {
        if (event.target.value < 0) {
            setLimitDay('0');
        } else {
            setLimitDay(event.target.value);
        }
    }

    const handleChangeLimitHour = (event) => {
        setLimitHour(event.target.value);
    }

    const handleCloseMenuSettings = () => {
        setOpenMenuSettings(false);
    };

    // メニュー設定編集
    const updateMenuSettings = async () => {
        let params = {};
        let hour = 0;
        let minute = 0;

        if(branchId !== '') params["br"] = branchId;
        if(menuAvailable !== ''){
            if(menuAvailable === '0'){
                params["m.a"] = false;
            }else if(menuAvailable === '1'){
                params["m.a"] = true;
            }
        }
        if(menuGroupId !== '') params["m.g"] = menuGroupId;
        if(requiredTimeHour !== ''){
            hour = (requiredTimeHour*60);
        }
        if(requiredTimeMinute !== ''){
            minute = requiredTimeMinute;
        }
        if(!(requiredTimeHour === '' && requiredTimeMinute === '')){
            params["m.d"] = hour + minute;
        }
        // TODO バックエンドで不要となれば削除
        // 補足欄注意書き
        params["m.c"] = '';
        // TODO バックエンドで不要となれば削除
        // 補足欄入力必須
        params["m.r"] = false;
        // 予約受付締め切り期限
        if(limitDay !== '') params["m.ct.d"] = limitDay;
        if(limitHour !== '') params["m.ct.h"] = limitHour;

        if (menuId !== '' && Number(menuId) === 0) {
            if(menuId !== ''){
                params["m.m.i"] = menuMasterId;
            }
            await Api.post(bank.realm, process.env.REACT_APP_APPOINTMENT_BASE_URL + '/menu', params, {}, oidcUser);
        } else {
            if(menuId !== ''){
                params["m.i"] = menuId;
            }
            await Api.put(bank.realm, process.env.REACT_APP_APPOINTMENT_BASE_URL + '/menu', params, {}, oidcUser);
        }
        params = {};
        const menuList = await Api.get(bank.realm, process.env.REACT_APP_APPOINTMENT_BASE_URL + '/menus', {br: branchId}, {}, oidcUser);
        if (!('menus' in menuList) || menuList.menus === null) {
            setMenuList([]);
        } else {
            setMenuList(menuList.menus);
        }
        setOpenMenuSettings(false);
    }

    const menuSettingValidation = (event) => {
        // submitイベントをキャンセル
        event.preventDefault();

        setIsErrorMenuGroup(false);
        setIsErrorRequiredTimeHour(false);
        setIsErrorRequiredTimeMinute(false);

        let isValid = true;

        // メニュー種別
        if (menuGroupId === '') {
            isValid = false;
            setIsErrorMenuGroup(true);
        }

        // 所要時間（時間）
        if (requiredTimeHour === '') {
            isValid = false;
            setIsErrorRequiredTimeHour(true);
        }

        // 所要時間（分）
        if (requiredTimeMinute === '') {
            isValid = false;
            setIsErrorRequiredTimeMinute(true);
        }

        if (!isValid) {
            setIsError(true);
            setErrorMessage('必須項目を入力してください');
            return false;
        }

        updateMenuSettings();
    }

    const handleClickOpenMenuGroupSettings = (menuGroup, event) => {
        if(menuGroup !== null) {
            setMenuGroupId(menuGroup.id);
            setMenuGroupName(menuGroup.name);
            setCapacity(menuGroup.capacity);
        } else {
            setMenuGroupId('');
            setMenuGroupName('');
            setCapacity('');
        }

        setOpenMenuGroupSettings(true);
    };

    const handleChangeMenuGroupName = (event) => {
        setMenuGroupName(event.target.value);
    }

    const handleChangeCapacity = (event) => {
        if (event.target.value < 0) {
            setCapacity('0');
        } else {
            setCapacity(event.target.value);
        }
    }

    const handleCloseMenuGroupSettings = () => {
        setOpenMenuGroupSettings(false);
    };

    // メニューグループ設定
    const updateMenuGroupSettings = async () => {
        let button = window.document.getElementById("menuGoupsSettingButton");
        button.disabled = true;
        setIsSubmitButtonDisabled(true);
        
        let params = {};
        if(branchId !== '') params["br"] = branchId;
        if(menuGroupName !== '') params["gr.n"] = menuGroupName.trim();
        if(capacity !== '') params["gr.c"] = capacity.trim();

        if (menuGroupName === "" ||  capacity === "") {
            setIsError(true);
            setErrorMessage('不正な動作です。');
            return false;
        }
        // 新規追加時
        if(menuGroupId === ''){
            await Api.post(bank.realm, process.env.REACT_APP_APPOINTMENT_BASE_URL + '/menu_group', params, {}, oidcUser);
        }
        // 編集時
        else {
            params["gr.i"] = menuGroupId;
            await Api.put(bank.realm, process.env.REACT_APP_APPOINTMENT_BASE_URL + '/menu_group', params, {}, oidcUser);
        }
        params = {};
        setBranchId('');
        setMenuGroupName('');
        setCapacity('');

        const menuList = await Api.get(bank.realm, process.env.REACT_APP_APPOINTMENT_BASE_URL + '/menus', {br: branchId}, {}, oidcUser);
        const menuGroupList = await Api.get(bank.realm, process.env.REACT_APP_APPOINTMENT_BASE_URL + '/menu_groups', {br: branchId}, {}, oidcUser);

        if (!('menus' in menuList) || menuList.menus === null) {
            setMenuList([]);
        } else {
            setMenuList(menuList.menus);
        }

        if (!('groups' in menuGroupList) || menuGroupList.groups === null) {
            setMenuGroupList([]);
        }else {
            setMenuGroupList(menuGroupList.groups);
        }

        button.disabled = false;
        setIsSubmitButtonDisabled(false);
        setOpenMenuGroupSettings(false);
    }

    const handleChangeReservationAvailablePeriod = (event) => {
        if (event.target.value < 0) {
            setReservationAvailablePeriod('');
            return;
        }

        setReservationAvailablePeriod(event.target.value);
    }

    // 予約可能期間設定
    const updateReservationAvailablePeriod = async () => {
        await Api.put(bank.realm, process.env.REACT_APP_APPOINTMENT_BASE_URL + '/branch/prereservable_month', {br: branchId, m: reservationAvailablePeriod}, {}, oidcUser);
    }

    // 休業日テキスト編集
    const handleChangeHoliday = (holidayDate) => {
        if (holidayDate === null) {
            setHolidayDate('');
            setHoliday('');
            return;
        }

        setHolidayDate(moment.utc(holidayDate, 'YYYY-MM-DDTHH:mm:00Z', 'ja').utcOffset(9).toDate());
        const formatDate = moment(holidayDate).format('YYYY-MM-DD');
        setHoliday(formatDate);
    }

    // 休業日追加
    const addHoliday = async () => {
        let params = {};
        if(holiday !== '') params["d"] = holiday;
        if(branchId !== '') params["br"] = branchId;

        await Api.post(bank.realm, process.env.REACT_APP_APPOINTMENT_BASE_URL + '/branch/holiday', params, {}, oidcUser);

        // 休業日一覧取得
        const holidayList = await Api.get(bank.realm, process.env.REACT_APP_APPOINTMENT_BASE_URL + '/branch/holidays', {br: branchId}, {}, oidcUser);
        if (!('holidays' in holidayList) || holidayList.holidays === null) {
            setHolidayList([]);
        } else {
            setHolidayList(holidayList.holidays);
        }

        setHoliday('');
        setHolidayDate('');
    }

    // 休業日、有効/無効、変更
    const changeHoliday = async (holidayId, isOpen, event) => {
        let params = {};
        if(branchId !== '') params["br"] = branchId;
        if(holidayId !== '') params["h"] = holidayId;
        if(isOpen !== '') params["e"] = isOpen;

        await Api.put(bank.realm, process.env.REACT_APP_APPOINTMENT_BASE_URL + '/holiday', params, {}, oidcUser);

        // 休業日一覧取得
        const holidayList = await Api.get(bank.realm, process.env.REACT_APP_APPOINTMENT_BASE_URL + '/branch/holidays', {br: branchId}, {}, oidcUser);
        if (!('holidays' in holidayList) || holidayList.holidays === null) {
            setHolidayList([]);
        } else {
            setHolidayList(holidayList.holidays);
        }
    }

    // 休業日削除
    const deleteHoliday = async (date, event) => {
        let params = {};
        if(branchId !== '') params["br"] = branchId;
        if(date !== '') params["d"] = date;

        await Api.delete(bank.realm, process.env.REACT_APP_APPOINTMENT_BASE_URL + '/branch/holiday', params, {}, oidcUser);

        // 休業日一覧取得
        const holidayList = await Api.get(bank.realm, process.env.REACT_APP_APPOINTMENT_BASE_URL + '/branch/holidays', {br: branchId}, {}, oidcUser);
        if (!('holidays' in holidayList) || holidayList.holidays === null) {
            setHolidayList([]);
        } else {
            setHolidayList(holidayList.holidays);
        }
    }

    // 営業時間（日別）日付テキスト編集
    const handleChangeTargetDate = (date) => {
        if (date === null) {
            setDate('');
            setTargetDate('');
            return;
        }

        setDate(moment.utc(date, 'YYYY-MM-DDTHH:mm:00Z', 'ja').utcOffset(9).toDate());
        const formatDate = moment(date).format('YYYY-MM-DD');
        setTargetDate(formatDate);
    }

    // 営業時間（日別）の日付追加
    const addTargetDate = async () => {
        let params = {};
        if(branchId !== '') params["br"] = branchId;
        if(targetDate !== '') params["d"] = targetDate;

        await Api.post(bank.realm, process.env.REACT_APP_APPOINTMENT_BASE_URL + '/branch/opening_hours/daily', params, {}, oidcUser);

        // 営業時間（日別）取得
        const dailyReservationTargetDateAndTime = await Api.get(bank.realm, process.env.REACT_APP_APPOINTMENT_BASE_URL + '/branch/opening_hours/daily', {br: branchId}, {}, oidcUser);
        if (!dailyReservationTargetDateAndTime || !('hours' in dailyReservationTargetDateAndTime)) {
            setDailyReservationTargetDateAndTime([]);
        } else {
            setDailyReservationTargetDateAndTime(dailyReservationTargetDateAndTime.hours);
        }

        setTargetDate('');
        setDate('');
    }

    // 営業時間（曜日別・日別）編集のモーダル表示
    const handleClickOpenWeeklyReservationTargetDateAndTimeSettings = (isOpen, openTimeList, target, isDaily, event) => {
        setIsDaily(isDaily);
        setIsOpen(isOpen);
        setStartTime('');
        setEndTime('');

        if (openTimeList === null) {
            setOpenTimeList([]);
        } else {
            setOpenTimeList(openTimeList);
        }

        setTarget(target);

        setOpenReservationTargetDateAndTimeSettings(true);
    };

    // 営業時間（曜日別・日別）編集のモーダル閉じる
    const handleCloseReservationTargetDateAndTimeSettings = () => {
        setOpenReservationTargetDateAndTimeSettings(false);
    };

    // 営業時間（曜日別）編集の受付可否編集
    const handleChangeIsOpen = (event) => {
        if (event.target.value === 'true') {
            setIsOpen(true);
        } else {
            setIsOpen(false);
        }
    };

    // 営業時間（曜日別・日別）編集の開始時間編集
    const handleChangeStartTime = (event) => {
        setStartTime(event.target.value);
    };

    // 営業時間（曜日別・日別）編集の終了時間編集
    const handleChangeEndTime = (event) => {
        setEndTime(event.target.value);
    };

    // 営業時間（曜日別・日別）編集の時間追加
    const handleAddOpenTime = () => {
        if (startTime === '' || endTime === '' || startTime === '未選択' || endTime === '未選択') {
            return;
        }

        let list = [...openTimeList, {
            from: startTime,
            to: endTime,
        }];

        setOpenTimeList(list);
        setStartTime('');
        setEndTime('');
    };

    // 営業時間（曜日別・日別）編集の時間削除
    const handleDeleteOpenTime = (index, event) => {
        let list = openTimeList.filter((time, timeIndex) => timeIndex !== index);
        setOpenTimeList(list);
    };

    // 営業時間（曜日別）編集の更新処理
    const updateWeeklyReservationTargetDateAndTimeSettings = async () => {
        await Api.putBodyParam(
            bank.realm,
            process.env.REACT_APP_APPOINTMENT_BASE_URL + '/branch/opening_hours/weekly',
            {
                "open": isOpen,
                "slots": openTimeList,
            },
            {},
            oidcUser,
            {
                br: branchId,
                w: target,
            },
        );

        // 営業時間（曜日別）取得
        const reservationTargetDateAndTime = await Api.get(bank.realm, process.env.REACT_APP_APPOINTMENT_BASE_URL + '/branch/opening_hours/weekly', {br: branchId}, {}, oidcUser);

        if (!reservationTargetDateAndTime || !('Sunday' in reservationTargetDateAndTime.hours)) {
            setReservationTargetDateAndTime(
                {
                    Sunday: {
                        open: false,
                        slots: [],
                    },
                    Monday: {
                        open: false,
                        slots: [],
                    },
                    Tuesday: {
                        open: false,
                        slots: [],
                    },
                    Wednesday: {
                        open: false,
                        slots: [],
                    },
                    Thursday: {
                        open: false,
                        slots: [],
                    },
                    Friday: {
                        open: false,
                        slots: [],
                    },
                    Saturday: {
                        open: false,
                        slots: [],
                    },
                }
            );
        } else {
            setReservationTargetDateAndTime(reservationTargetDateAndTime.hours);
        }

        setOpenReservationTargetDateAndTimeSettings(false);
    };

    // 営業時間（日別）編集の更新処理
    const updateDailyReservationTargetDateAndTimeSettings = async () => {
        await Api.putBodyParam(
            bank.realm,
            process.env.REACT_APP_APPOINTMENT_BASE_URL + '/branch/opening_hours/daily',
            {
                "open": isOpen,
                "slots": openTimeList,
            },
            {},
            oidcUser,
            {
                br: branchId,
                d: target,
            },
        );

        // 営業時間（日別）取得
        const dailyReservationTargetDateAndTime = await Api.get(bank.realm, process.env.REACT_APP_APPOINTMENT_BASE_URL + '/branch/opening_hours/daily', {br: branchId}, {}, oidcUser);
        if (!dailyReservationTargetDateAndTime || !('hours' in dailyReservationTargetDateAndTime)) {
            setDailyReservationTargetDateAndTime([]);
        } else {
            setDailyReservationTargetDateAndTime(dailyReservationTargetDateAndTime.hours);
        }

        setOpenReservationTargetDateAndTimeSettings(false);
    };

    const deleteDailyReservationTargetDateAndTimeSettings = async (date, event) => {
        let params = {};
        if(branchId !== '') params["br"] = branchId;
        if(date !== '') params["d"] = date;

        await Api.delete(bank.realm, process.env.REACT_APP_APPOINTMENT_BASE_URL + '/branch/opening_hours/daily', params, {}, oidcUser);

        // 営業時間（日別）取得
        const dailyReservationTargetDateAndTime = await Api.get(bank.realm, process.env.REACT_APP_APPOINTMENT_BASE_URL + '/branch/opening_hours/daily', {br: branchId}, {}, oidcUser);
        if (!dailyReservationTargetDateAndTime || !('hours' in dailyReservationTargetDateAndTime)) {
            setDailyReservationTargetDateAndTime([]);
        } else {
            setDailyReservationTargetDateAndTime(dailyReservationTargetDateAndTime.hours);
        }
    }

    const handleCloseIsError = (event) => {
        setIsError(false);
    }

    useEffect( () => {
        if (!oidcUser) return;
        if (bank.canUseGA) {
            if (!!window.gtag) {
                window.gtag('config', bank.gaTrackingCode, {
                    'page_path': `${props.location.pathname}${props.location.search}`
                });
            }
        }

        const fetchData = async () => {
            // 支店一覧
            const branchList = await Api.get(bank.realm, process.env.REACT_APP_APPOINTMENT_BASE_URL + '/branches', {}, {}, oidcUser);
            // 支店一覧を設定
            if (!('branches' in branchList) || branchList.branches === null) {
                setBranchList([]);
            } else {
                setBranchList(branchList.branches);
            }
        };

        fetchData()
            .then()
            .catch(e => {
                // todo エラーハンドリング
                if( e.response ){
                    if( e.response.status === 403 ){
                        // todo 「ログインしてください」を表示
                        // todo ユーザー情報の削除
                        // location.href = "/";
                    }
                    //todo エラーであることを知らせる表示
                }
            })
    }, [bank, oidcUser, props]);

    return (
        <div className={classes.root}>
            <AdminHeader />
            <main className={classes.content}>
                <Box maxWidth="lg" className={classes.titleBox}>
                    <h4 className={classes.title}>支店設定</h4>
                </Box>
                <Box>
                    <Box className={classes.label}>
                        <Typography variant="overline" color="textSecondary" className={classes.title}>
                            支店名
                        </Typography>
                    </Box>
                    <Autocomplete
                        className={classes.autocomplete}
                        id="combo-box-branch"
                        options={branchList}
                        getOptionLabel={(option) => option.name?option.name:''}
                        style={{ width: 400 }}
                        renderInput={(params) => <TextField placeholder={'支店名'} {...params} variant="outlined" className={classes.autocompleteTextField} />}
                        onChange={handleChangeBranchId}
                        value={branch}
                        getOptionSelected={(option, value) => {
                            return value === null || option?.name === value?.name || option?.id === value?.id
                        }}
                    />
                    <Button onClick={handleClickBranchSearchButton} variant="contained" className={classes.button}>検索</Button>
                </Box>
                {showDetail && (
                    <div>
                        <Box className={classes.label}>
                            <Typography variant="overline" color="textSecondary" className={classes.title}>
                                {branchName}
                            </Typography>
                        </Box>
                        <Box className={classes.panel}>
                            <Box className={classes.panelHeader}>
                                メニュー設定
                            </Box>
                            <Box className={classes.panelBody}>
                                <TableContainer component={Paper}>
                                    <Table className={classes.table} aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell width={"40%"}>メニュー</TableCell>
                                                <TableCell width={"35%"}>種別</TableCell>
                                                <TableCell width={"10%"} align={"center"}>所要時間</TableCell>
                                                <TableCell width={"10%"} align={"center"}>有効/無効</TableCell>
                                                <TableCell width={"5%"} align={"center"}>設定</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {menuList.map((menu, index) => (
                                                <TableRow key={menu.master.id}>
                                                    <TableCell>{menu.master.name}</TableCell>
                                                    <TableCell>{menu.group.id !== 0 ? menu.group.name : '-'}</TableCell>
                                                    <DurationCell duration={menu.duration} />
                                                    <TableCell align={"center"}>{menu.available ? '有効' : '無効'}</TableCell>
                                                    <TableCell>
                                                        <Button onClick={handleClickOpenMenuSettings.bind(this, menu)} variant="contained" className={classes.settingsButton}>設定</Button>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Box>
                            <Dialog
                                open={openMenuSettings}
                                onClose={handleCloseMenuSettings}
                                aria-labelledby="simple-modal-title"
                                aria-describedby="simple-modal-description"
                            >
                                <DialogTitle id="alert-dialog-slide-title">{"メニュー設定"}</DialogTitle>
                                <DialogContent>
                                    <Box className={classes.dl}>
                                        <Box className={classes.dd}>
                                            <Box className={classes.dialogContentTitleBox}>
                                                <Typography variant="overline" color="textSecondary">
                                                    有効/無効
                                                </Typography>
                                            </Box>
                                            <Box>
                                                <FormControl component="fieldset">
                                                    <RadioGroup className={classes.radio} aria-label="status" name="status" value={menuAvailable} onChange={handleChangeMenuAvailable}>
                                                        <FormControlLabel value="1" control={<Radio color={'default'} />} label="有効" />
                                                        <FormControlLabel value="0" control={<Radio color={'default'} />} label="無効" />
                                                    </RadioGroup>
                                                </FormControl>
                                            </Box>
                                        </Box>
                                    </Box>
                                    <Box className={classes.dl}>
                                        <Box className={classes.dd}>
                                            <Box className={classes.dialogContentTitleBox}>
                                                <Typography variant="overline" color="textSecondary">
                                                    メニュー種別 <span className={classes.required}>[必須]</span>
                                                </Typography>
                                            </Box>
                                            <Box>
                                                <FormControl variant="outlined" className={formControlW300} size={"small"}>
                                                    <InputLabel>メニュー種別</InputLabel>
                                                    <Select
                                                        error={isErrorMenuGroup}
                                                        value={menuGroupId}
                                                        onChange={handleChangeMenuGroupId}
                                                        label="MenuGroupId"
                                                    >
                                                        <MenuItem value="">
                                                            <em>未選択</em>
                                                        </MenuItem>
                                                        {menuGroupList.map((menuGroup) => (
                                                            <MenuItem key={menuGroup.id} value={menuGroup.id}>{menuGroup.name}</MenuItem>
                                                        ))}
                                                    </Select>
                                                 </FormControl>
                                            </Box>
                                        </Box>
                                    </Box>
                                    <Box className={classes.dl}>
                                        <Box className={classes.dd}>
                                            <Box className={classes.dialogContentTitleBox}>
                                                <Typography variant="overline" color="textSecondary">
                                                    所要時間 <span className={classes.required}>[必須]</span>
                                                </Typography>
                                            </Box>
                                            <Box>
                                                <FormControl variant="outlined" className={requiredTimeFloatFormControl} size={"small"}>
                                                    <InputLabel>時間</InputLabel>
                                                    <Select
                                                        error={isErrorRequiredTimeHour}
                                                        value={requiredTimeHour}
                                                        onChange={handleChangeRequiredTimeHour}
                                                        label="RequiredTimeHour"
                                                    >
                                                        <MenuItem value="">
                                                            <em>未選択</em>
                                                        </MenuItem>
                                                        {[0, 1, 2, 3, 4, 5].map((hour) => (
                                                            <MenuItem key={hour} value={hour}>{hour}</MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </Box>
                                            <Box className={classes.floatLeft}>
                                                <Typography className={classes.lh60} variant="overline" color="textSecondary">
                                                    時間
                                                </Typography>
                                            </Box>
                                            <Box>
                                                <FormControl variant="outlined" className={requiredTimeFloatFormControl} size={"small"}>
                                                    <InputLabel>分</InputLabel>
                                                    <Select
                                                        error={isErrorRequiredTimeMinute}
                                                        value={requiredTimeMinute}
                                                        onChange={handleChangeRequiredTimeMinute}
                                                        label="RequiredTimeHour"
                                                    >
                                                        <MenuItem value="">
                                                            <em>未選択</em>
                                                        </MenuItem>
                                                        {[0, 15, 30, 45].map((minute) => (
                                                            <MenuItem key={minute} value={minute}>{minute}</MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </Box>
                                            <Box className={classes.floatLeft}>
                                                <Typography className={classes.lh60} variant="overline" color="textSecondary">
                                                    分
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Box>
                                    <Box className={classes.dl}>
                                        <Box className={classes.dd}>
                                            <Box className={classes.dialogContentTitleBox}>
                                                <Typography variant="overline" color="textSecondary">
                                                    予約受付締め切り期限
                                                </Typography>
                                            </Box>
                                            <Box className={textFieldBoxW70}>
                                                <TextField
                                                    value={limitDay}
                                                    onChange={handleChangeLimitDay}
                                                    type="number"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    variant="outlined"
                                                    size={"small"}
                                                />
                                            </Box>
                                            <Box className={classes.floatLeft}>
                                                <Typography className={classes.lh60} variant="overline" color="textSecondary">
                                                    営業日前の
                                                </Typography>
                                            </Box>
                                            <FormControl variant="outlined" className={requiredTimeFloatFormControl} size={"small"}>
                                                <InputLabel>時間</InputLabel>
                                                <Select
                                                    value={limitHour}
                                                    onChange={handleChangeLimitHour}
                                                    label="LimitHour"
                                                >
                                                    <MenuItem value="">
                                                        <em>未選択</em>
                                                    </MenuItem>
                                                    {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24].map((hour) => (
                                                        <MenuItem key={hour} value={hour}>{hour}</MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                            <Box className={classes.floatLeft}>
                                                <Typography className={classes.lh60} variant="overline" color="textSecondary">
                                                    時まで
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Box>
                                </DialogContent>
                                <DialogActions className={classes.buttonBox}>
                                    <Button variant="contained" onClick={menuSettingValidation} color="primary">
                                        保存
                                    </Button>
                                </DialogActions>
                            </Dialog>
                        </Box>
                        <Grid container spacing={4}>
                            <Grid item xs={6}>
                                <Box className={classes.panel}>
                                    <Box className={classes.panelHeader}>
                                        メニュー種別設定
                                    </Box>
                                    <Box className={classes.panelBody}>
                                        <Box>
                                            <Button onClick={handleClickOpenMenuGroupSettings.bind(this, null)} variant="contained" className={classes.button}>追加</Button>
                                        </Box>
                                        <TableContainer component={Paper}>
                                            <Table className={classes.table} aria-label="simple table">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell width={"45%"}>種別名</TableCell>
                                                        <TableCell width={"45%"}>対応可能人数</TableCell>
                                                        <TableCell width={"10%"}>設定</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {menuGroupList.map((menuGroup, index) => (
                                                        <TableRow key={menuGroup.id}>
                                                            <TableCell>{menuGroup.name}</TableCell>
                                                            <TableCell>{menuGroup.capacity}名</TableCell>
                                                            <TableCell>
                                                                <Button onClick={handleClickOpenMenuGroupSettings.bind(this, menuGroup)} variant="contained" className={classes.settingsButton}>設定</Button>
                                                            </TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Box>
                                    <Dialog
                                        open={openMenuGroupSettings}
                                        onClose={handleCloseMenuGroupSettings}
                                        aria-labelledby="simple-modal-title"
                                        aria-describedby="simple-modal-description"
                                    >
                                        <DialogTitle id="alert-dialog-slide-title">{"メニュー種別設定"}</DialogTitle>
                                            <DialogContent>
                                                <Box className={classes.dl}>
                                                    <Box className={classes.dd}>
                                                        <Box className={classes.dialogContentTitleBox}>
                                                            <Typography variant="overline" color="textSecondary">
                                                                種別名
                                                            </Typography>
                                                        </Box>
                                                    <Box className={textFieldBoxW200}>
                                                        <TextField value={menuGroupName} onChange={handleChangeMenuGroupName} label="種別名" variant="outlined" fullWidth={true} size={"small"} />
                                                    </Box>
                                                </Box>
                                            </Box>
                                            <Box className={classes.dl}>
                                                <Box className={classes.dd}>
                                                    <Box className={classes.dialogContentTitleBox}>
                                                        <Typography variant="overline" color="textSecondary">
                                                            対応可能人数
                                                        </Typography>
                                                    </Box>
                                                    <Box className={textFieldBoxW70}>
                                                        <TextField
                                                            value={capacity}
                                                            onChange={handleChangeCapacity}
                                                            type="number"
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                            variant="outlined"
                                                            size={"small"}
                                                        />
                                                    </Box>
                                                    <Box className={classes.floatLeft}>
                                                        <Typography className={classes.lh60} variant="overline" color="textSecondary">
                                                            名
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </DialogContent>
                                        <DialogActions className={classes.buttonBox}>
                                            <Button id="menuGoupsSettingButton" variant="contained" onClick={updateMenuGroupSettings} color="primary" className={menuGroupSettingsButton}>
                                                保存
                                            </Button>
                                        </DialogActions>
                                    </Dialog>
                                </Box>
                                <Box className={classes.panel}>
                                    <Box className={classes.panelHeader}>
                                        予約可能期間
                                    </Box>
                                    <Box className={classes.panelBody}>
                                        <Box className={classes.reservationPeriodBox}>
                                            <TextField
                                                value={reservationAvailablePeriod}
                                                onChange={handleChangeReservationAvailablePeriod}
                                                id="outlined-number"
                                                type="number"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                variant="outlined"
                                                size={"small"}
                                            />
                                            ヶ月
                                        </Box>
                                        <Box>
                                            <Button onClick={updateReservationAvailablePeriod} variant="contained" className={classes.button}>設定</Button>
                                        </Box>
                                    </Box>
                                </Box>
                                <Box className={classes.panel}>
                                    <Box className={classes.panelHeader}>
                                        休業日設定
                                    </Box>
                                    <Box className={classes.panelBody}>
                                        <Box className={classes.floatLeftMb20}>
                                            <DatePicker
                                                locale="ja"
                                                selected={holidayDate}
                                                dateFormat="yyyy/MM/dd"
                                                onChange={handleChangeHoliday}
                                                customInput={<TextField value={holiday} label="休業日" variant="outlined" fullWidth={false} size={"small"} />}
                                            />
                                        </Box>
                                        <Box className={classes.floatLeftMb20}>
                                            <Button onClick={addHoliday} variant="contained" className={classes.addButton}>休業日追加</Button>
                                        </Box>
                                        <TableContainer component={Paper}>
                                            <Table aria-label="simple table">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell width={"65%"}>休業日</TableCell>
                                                        <TableCell width={"35%"} />
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {holidayList.map((holiday, index) => (
                                                        <TableRow key={index}>
                                                            {holiday.isEffective && (
                                                                <TableCell>
                                                                    {holiday.type === 'global' && (
                                                                        <span className={classes.globalIcon}>全体設定</span>
                                                                    )}
                                                                    {holiday.date}
                                                                </TableCell>
                                                            )}
                                                            {!holiday.isEffective && (
                                                                <TableCell className={classes.bgGray}>
                                                                    {holiday.type === 'global' && (
                                                                        <span className={classes.globalIcon}>全体設定</span>
                                                                    )}
                                                                    {holiday.date}
                                                                </TableCell>
                                                            )}
                                                            {holiday.isEffective && (
                                                                <TableCell className={classes.textAlignRight}>
                                                                    {holiday.type === 'global' && (
                                                                        <Button onClick={changeHoliday.bind(this, holiday.id, true)} variant="contained" className={classes.settingsButton}>営業日にする</Button>
                                                                    )}
                                                                    {holiday.type === 'local' && (
                                                                        <Button onClick={deleteHoliday.bind(this, holiday.date)} variant="contained" className={classes.settingsButton}>営業日にする</Button>
                                                                    )}
                                                                </TableCell>
                                                            )}
                                                            {!holiday.isEffective && (
                                                                <TableCell className={ineffectiveHoliday}>
                                                                    <Button onClick={changeHoliday.bind(this, holiday.id, false)} variant="contained" className={classes.settingsButton}>戻す</Button>
                                                                </TableCell>
                                                            )}
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item xs={6}>
                                <Box className={classes.panel}>
                                    <Box className={classes.panelHeader}>
                                        営業時間設定（曜日別）
                                    </Box>
                                    <Box className={classes.panelBody}>
                                        <TableContainer component={Paper}>
                                            <Table className={classes.table} aria-label="simple table">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell width={"30%"}>曜日</TableCell>
                                                        <TableCell width={"30%"}>予約時間</TableCell>
                                                        <TableCell width={"20%"}>受付可否</TableCell>
                                                        <TableCell width={"20%"} />
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    <TableRow>
                                                        <TableCell>日曜日</TableCell>
                                                        <TableCell>
                                                            {reservationTargetDateAndTime.Sunday.slots !== null && (reservationTargetDateAndTime.Sunday.slots.map((time, index) => (
                                                                <Typography key={index} variant="body2" color="textSecondary">
                                                                    {time.from}-{time.to}
                                                                </Typography>
                                                            )))}
                                                        </TableCell>
                                                        <TableCell>{reservationTargetDateAndTime.Sunday.open ? '○' : '-'}</TableCell>
                                                        <TableCell>
                                                            <Button onClick={handleClickOpenWeeklyReservationTargetDateAndTimeSettings.bind(this, reservationTargetDateAndTime.Sunday.open, reservationTargetDateAndTime.Sunday.slots, 'Sunday', false)} variant="contained" className={classes.settingsButton}>設定</Button>
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell>月曜日</TableCell>
                                                        <TableCell>
                                                            {reservationTargetDateAndTime.Monday.slots !== null && (reservationTargetDateAndTime.Monday.slots.map((time, index) => (
                                                                <Typography key={index} variant="body2" color="textSecondary">
                                                                    {time.from}-{time.to}
                                                                </Typography>
                                                            )))}
                                                        </TableCell>
                                                        <TableCell>{reservationTargetDateAndTime.Monday.open ? '○' : '-'}</TableCell>
                                                        <TableCell>
                                                            <Button onClick={handleClickOpenWeeklyReservationTargetDateAndTimeSettings.bind(this, reservationTargetDateAndTime.Monday.open, reservationTargetDateAndTime.Monday.slots, 'Monday', false)} variant="contained" className={classes.settingsButton}>設定</Button>
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell>火曜日</TableCell>
                                                        <TableCell>
                                                            {reservationTargetDateAndTime.Tuesday.slots !== null && (reservationTargetDateAndTime.Tuesday.slots.map((time, index) => (
                                                                <Typography key={index} variant="body2" color="textSecondary">
                                                                    {time.from}-{time.to}
                                                                </Typography>
                                                            )))}
                                                        </TableCell>
                                                        <TableCell>{reservationTargetDateAndTime.Tuesday.open ? '○' : '-'}</TableCell>
                                                        <TableCell>
                                                            <Button onClick={handleClickOpenWeeklyReservationTargetDateAndTimeSettings.bind(this, reservationTargetDateAndTime.Tuesday.open, reservationTargetDateAndTime.Tuesday.slots, 'Tuesday', false)} variant="contained" className={classes.settingsButton}>設定</Button>
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell>水曜日</TableCell>
                                                        <TableCell>
                                                            {reservationTargetDateAndTime.Wednesday.slots !== null && (reservationTargetDateAndTime.Wednesday.slots.map((time, index) => (
                                                                <Typography key={index} variant="body2" color="textSecondary">
                                                                    {time.from}-{time.to}
                                                                </Typography>
                                                            )))}
                                                        </TableCell>
                                                        <TableCell>{reservationTargetDateAndTime.Wednesday.open ? '○' : '-'}</TableCell>
                                                        <TableCell>
                                                            <Button onClick={handleClickOpenWeeklyReservationTargetDateAndTimeSettings.bind(this, reservationTargetDateAndTime.Wednesday.open, reservationTargetDateAndTime.Wednesday.slots, 'Wednesday', false)} variant="contained" className={classes.settingsButton}>設定</Button>
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell>木曜日</TableCell>
                                                        <TableCell>
                                                            {reservationTargetDateAndTime.Thursday.slots !== null && (reservationTargetDateAndTime.Thursday.slots.map((time, index) => (
                                                                <Typography key={index} variant="body2" color="textSecondary">
                                                                    {time.from}-{time.to}
                                                                </Typography>
                                                            )))}
                                                        </TableCell>
                                                        <TableCell>{reservationTargetDateAndTime.Thursday.open ? '○' : '-'}</TableCell>
                                                        <TableCell>
                                                            <Button onClick={handleClickOpenWeeklyReservationTargetDateAndTimeSettings.bind(this, reservationTargetDateAndTime.Thursday.open, reservationTargetDateAndTime.Thursday.slots, 'Thursday', false)} variant="contained" className={classes.settingsButton}>設定</Button>
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell>金曜日</TableCell>
                                                        <TableCell>
                                                            {reservationTargetDateAndTime.Friday.slots !== null && (reservationTargetDateAndTime.Friday.slots.map((time, index) => (
                                                                <Typography key={index} variant="body2" color="textSecondary">
                                                                    {time.from}-{time.to}
                                                                </Typography>
                                                            )))}
                                                        </TableCell>
                                                        <TableCell>{reservationTargetDateAndTime.Friday.open ? '○' : '-'}</TableCell>
                                                        <TableCell>
                                                            <Button onClick={handleClickOpenWeeklyReservationTargetDateAndTimeSettings.bind(this, reservationTargetDateAndTime.Friday.open, reservationTargetDateAndTime.Friday.slots, 'Friday', false)} variant="contained" className={classes.settingsButton}>設定</Button>
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell>土曜日</TableCell>
                                                        <TableCell>
                                                            {reservationTargetDateAndTime.Saturday.slots !== null && (reservationTargetDateAndTime.Saturday.slots.map((time, index) => (
                                                                <Typography key={index} variant="body2" color="textSecondary">
                                                                    {time.from}-{time.to}
                                                                </Typography>
                                                            )))}
                                                        </TableCell>
                                                        <TableCell>{reservationTargetDateAndTime.Saturday.open ? '○' : '-'}</TableCell>
                                                        <TableCell>
                                                            <Button onClick={handleClickOpenWeeklyReservationTargetDateAndTimeSettings.bind(this, reservationTargetDateAndTime.Saturday.open, reservationTargetDateAndTime.Saturday.slots, 'Saturday', false)} variant="contained" className={classes.settingsButton}>設定</Button>
                                                        </TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Box>
                                </Box>
                                <Box className={classes.panel}>
                                    <Box className={classes.panelHeader}>
                                        営業時間設定（日別）
                                    </Box>
                                    <Box className={classes.panelBody}>
                                        <Box className={classes.floatLeftMb20}>
                                            <DatePicker
                                                locale="ja"
                                                selected={date}
                                                dateFormat="yyyy/MM/dd"
                                                onChange={handleChangeTargetDate}
                                                customInput={<TextField value={targetDate} label="日付" variant="outlined" fullWidth={false} size={"small"} />}
                                            />
                                        </Box>
                                        <Box className={classes.floatLeftMb20}>
                                            <Button onClick={addTargetDate} variant="contained" className={classes.addButton}>日付追加</Button>
                                        </Box>
                                        <TableContainer component={Paper}>
                                            <Table className={classes.table} aria-label="simple table">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell width={"30%"}>日付</TableCell>
                                                        <TableCell width={"30%"}>予約時間</TableCell>
                                                        <TableCell width={"20%"} />
                                                        <TableCell width={"20%"} />
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {dailyReservationTargetDateAndTime.map((dailyTarget, index) => (
                                                        <TableRow key={index}>
                                                            <TableCell>{dailyTarget.date}</TableCell>
                                                            <TableCell>
                                                                {dailyTarget.slots !== null && (dailyTarget.slots.map((time, index) => (
                                                                    <Typography key={index} variant="body2" color="textSecondary">
                                                                        {time.from}-{time.to}
                                                                    </Typography>
                                                                )))}
                                                            </TableCell>
                                                            <TableCell>
                                                                <Button onClick={handleClickOpenWeeklyReservationTargetDateAndTimeSettings.bind(this, true, dailyTarget.slots, dailyTarget.date, true)} variant="contained" className={classes.settingsButton}>設定</Button>
                                                            </TableCell>
                                                            <TableCell>
                                                                <Button onClick={deleteDailyReservationTargetDateAndTimeSettings.bind(this, dailyTarget.date)} variant="contained" className={classes.deleteButton}>削除</Button>
                                                            </TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Box>
                                </Box>
                                <Dialog
                                    open={openReservationTargetDateAndTimeSettings}
                                    onClose={handleCloseReservationTargetDateAndTimeSettings}
                                    aria-labelledby="simple-modal-title"
                                    aria-describedby="simple-modal-description"
                                >
                                    <DialogTitle id="alert-dialog-slide-title">{"営業時間編集"}</DialogTitle>
                                    <DialogContent>
                                        {!isDaily && (
                                            <Box className={classes.dl}>
                                                <Box className={classes.dd}>
                                                    <Box className={classes.dialogContentTitleBox}>
                                                        <Typography variant="overline" color="textSecondary">
                                                            受付可否
                                                        </Typography>
                                                    </Box>
                                                    <Box>
                                                        <FormControl component="fieldset">
                                                            <RadioGroup className={classes.radio} aria-label="status" name="status" value={isOpen} onChange={handleChangeIsOpen}>
                                                                <FormControlLabel control={<Radio value={true} color={'default'} />} label="可" />
                                                                <FormControlLabel control={<Radio value={false} color={'default'} />} label="不可" />
                                                            </RadioGroup>
                                                        </FormControl>
                                                    </Box>
                                                </Box>
                                            </Box>
                                        )}
                                        <Box className={classes.dl}>
                                            <Box className={classes.dd}>
                                                {/*<Box className={classes.dialogContentTitleBox}>*/}
                                                {/*    <Typography variant="overline" color="textSecondary">*/}
                                                {/*        予約時間*/}
                                                {/*    </Typography>*/}
                                                {/*</Box>*/}
                                                <Box className={classes.floatLeft}>
                                                    <FormControl variant="outlined" className={formControlW150} size={"small"}>
                                                        <InputLabel>開始時間</InputLabel>
                                                        <Select
                                                            value={startTime}
                                                            onChange={handleChangeStartTime}
                                                            label="StartTime"
                                                        >
                                                            <MenuItem value="未選択">
                                                                <em>未選択</em>
                                                            </MenuItem>
                                                            {timeList.map((time, index) => (
                                                                <MenuItem key={index} value={time}>{time}</MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                </Box>
                                                <Box className={classes.floatLeft}>
                                                    <Typography className={classes.lh40} variant="overline" color="textSecondary">
                                                        〜
                                                    </Typography>
                                                </Box>
                                                <Box className={classes.floatLeft}>
                                                    <FormControl variant="outlined" className={formControlW150} size={"small"}>
                                                        <InputLabel>終了時間</InputLabel>
                                                        <Select
                                                            value={endTime}
                                                            onChange={handleChangeEndTime}
                                                            label="EndTime"
                                                        >
                                                            <MenuItem value="未選択">
                                                                <em>未選択</em>
                                                            </MenuItem>
                                                            {timeList.map((time, index) => (
                                                                <MenuItem key={index} value={time}>{time}</MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                </Box>
                                                <Box className={classes.floatLeft}>
                                                    <Button variant="contained" onClick={handleAddOpenTime} color="primary">
                                                        追加
                                                    </Button>
                                                </Box>
                                            </Box>
                                        </Box>
                                        <Box className={classes.dl}>
                                            <Box className={classes.dd}>
                                                <TableContainer component={Paper}>
                                                    <Table aria-label="simple table">
                                                        <TableBody>
                                                            {openTimeList.map((openTime, index) => (
                                                                <TableRow key={index}>
                                                                    <TableCell width={"80%"}>{openTime.from}-{openTime.to}</TableCell>
                                                                    <TableCell width={"20%"}>
                                                                        <Button variant="contained" onClick={handleDeleteOpenTime.bind(this, index)} className={classes.deleteButton}>削除</Button>
                                                                    </TableCell>
                                                                </TableRow>
                                                            ))}
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                            </Box>
                                        </Box>
                                    </DialogContent>
                                    <DialogActions className={classes.buttonBox}>
                                        {isDaily && (
                                            <Button variant="contained" onClick={updateDailyReservationTargetDateAndTimeSettings} color="primary">保存</Button>
                                        )}
                                        {!isDaily && (
                                            <Button variant="contained" onClick={updateWeeklyReservationTargetDateAndTimeSettings} color="primary">保存</Button>
                                        )}
                                    </DialogActions>
                                </Dialog>
                            </Grid>
                        </Grid>
                    </div>
                )}
            </main>
            <Snackbar open={isError} autoHideDuration={6000} onClose={handleCloseIsError}>
                <MuiAlert elevation={6} variant="filled" onClose={handleCloseIsError} severity="error">
                    {errorMessage}
                </MuiAlert>
            </Snackbar>
        </div>
    );
}

export default BranchSettings;
