import moment from "moment";
import 'moment/locale/ja';

const AppointmentSearchValidation = (
  setIsErrorBranchId,
  setIsErrorReservationFromDate,
  setIsErrorReservationToDate,
  branchId,
  reservationFromDate,
  reservationToDate,
) => {
  moment.locale('ja');
  setIsErrorBranchId(false);
  setIsErrorReservationFromDate(false);
  setIsErrorReservationToDate(false);

  let isValid = true;
  let messages = [];

  // 支店
  if (branchId === '') {
    isValid = false;
    messages.push('支店を選択してください');
    setIsErrorBranchId(true);
  }
  // 日付（From）
  if (reservationFromDate !== '') {
    const oneMonthAgo = moment().subtract(1, 'months').format('YYYY-MM-DD');
    if (!moment(reservationFromDate).isSameOrAfter(oneMonthAgo)) {
      isValid = false;
      messages.push('Fromには1ヶ月前までの日付を入力してください');
      setIsErrorReservationFromDate(true);
    }
  }

  if (!isValid) {
    return {
      isValid: false,
      errorMessages: messages,
    };
  } else {
    return {
      isValid: true,
    }
  }
}

export default AppointmentSearchValidation;
