import React from 'react';
import {Link} from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";


const useStyles = makeStyles((theme) => ({
    navbarNavLi: {
        float: "left",
        position: "relative",
        display: "block",
        textAlign: "-webkit-match-parent",
    },
    navbarNav: {
        backgroundColor: "transparent",
        color: "#777",
        cursor: "pointer",
        display: "block",
        lineHeight: "20px",
        padding: "15px 15px",
        position: "relative",
        textDecoration: "none",
    },
    dropdownMenu: {
        marginTop: 0,
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        position: "absolute",
        top: "100%",
        left: 0,
        zIndex: 1000,
        display: "none",
        float: "left",
        minWidth: "160px",
        padding: "5px 0",
        margin: "2px 0 0",
        fontSize: 14,
        textAlign: "left",
        listStyle: "none",
        backgroundColor: "#fff",
        backgroundClip: "padding-box",
        border: "1px solid rgba(0,0,0,.15)",
        borderRadius: 4,
        boxShadow: "0 6px 12px rgba(0,0,0,.175)",
        '.open>&': {
            display: "block",
        },
    },
    list: {
        '&>a': {
            display: "block",
            padding: "3px 20px",
            clear: "both",
            fontWeight: 400,
            lineHeight: "1.42857143",
            color: "#333",
            whiteSpace: "nowrap",
            textDecoration: "none",
            '&:hover': {
                color: "#262626",
                backgroundColor: "#f5f5f5",
            },
        },
    },
}));

const AdminHeaderList = (props) => {
    const classes = useStyles();
    const open = (props.activeChildIndex ? 'open' : '');
    const navbarNavLi = clsx(classes.navbarNavLi, open);

    return (
        <li onClick={props.onChangeActive} className={navbarNavLi}>
            {/*<Link className={classes.navbarNav} to={"#"}>{props.parentTitle}<span className={"caret"}></span></Link>*/}
            <span className={classes.navbarNav}>{props.parentTitle}<span className={"caret"}>▽</span></span>
            <ul className={classes.dropdownMenu}>
                {props.childTitleList.map((childTitle, index) => (
                    <li key={index} className={classes.list}>
                        <Link to={props.childUrlList[index]}>{childTitle}</Link>
                    </li>
                ))}
            </ul>
        </li>
    )
}

export default AdminHeaderList;
