import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Box from '@material-ui/core/Box';
import Button from "@material-ui/core/Button";
import moment from 'moment';
import 'moment/locale/ja';
import { useReactOidc } from '@axa-fr/react-oidc-context';
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import clsx from "clsx";
import useMediaQuery from '@material-ui/core/useMediaQuery';

import Header from '../../components/Header';
import Footer from '../../components/Footer';
import LeftColumn from "../../components/LeftColumn";
import Api from "../../util/api";
import { useBankContext } from "../../util/BankContext";


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        '& table': {
            border: "1px solid #000",
            borderCollapse: "collapse",
            width: "100%",
        },
        '& tr': {
            height: "50px",
        },
        '& th': {
            background: "#E8E8E8",
        },
        '& td': {
            textAlign: "center",
        },
    },
    content: {
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        height: '100vh',
        minHeight: "100vh",
        overflow: 'auto',
    },
    container: {
        paddingTop: theme.mixins.toolbar.lineHeight,
    },
    title: {
        backgroundColor: theme.palette.text.primary,
        padding: "10px 8px",
        '& p': {
            color: theme.palette.background.default,
        },
    },
    paper: {
        background: "#E8E8E8",
        display: 'flex',
        flexDirection: 'column',
        marginTop: 20,
        overflow: 'auto',
    },
    height100: {
        height: "auto",
    },
    height250: {
        height: 260,
    },
    white: {
        background: "#fff",
    },
    information: {
        padding: 20,
    },
    annotationBox: {
        display: "inline-box",
        margin: "0 calc((100% - 700px) / 2)",
        width: 700,
    },
    annotation: {
        lineHeight: "40px",
        textAlign: "left",
    },
    message: {
        lineHeight: "40px",
        textAlign: "center",
    },
    paperBoxCaption: {
        background: "#606060",
        color: "#fff",
        height: 50,
        textAlign: "center",
    },
    captionHeight: {
        lineHeight: "50px",
    },
    center: {
        textAlign: "center",
    },
    p4: {
        padding: 10,
    },
    subTitle: {
        margin: "30px 0 0 0",
    },
    description: {
        marginTop: 20,
        textAlign: "center",
    },
    buttonBox: {
        textAlign: "center",
    },
    button: {
        background: "#fff",
        margin: "20px 0",
        width: 300,
    },
    footer: {
        marginTop: "auto",
    },
    linkText: {
        textDecoration: "none",
    },
    notLoginMain: {
        width: "100vw !important",
        height: "100vh !important",
    },
    cancelPopup: {
        display: "inline-block",
        textAlign: "center",
    },
    cancelButton: {
        background: "#fff",
        margin: "20px 0",
    },
    okButton: {
        background: "#606060",
        margin: "20px 0",
    },
}));

export default function Complete(props) {
    const matches = useMediaQuery('(min-width:600px)');
    const bank = useBankContext();
    const location = useLocation();
    window.document.title = '来店予約|' + bank.baPortalName;
    moment.locale('ja');
    const classes = useStyles();
    const [openCancelDialog, setOpenCancelDialog] = React.useState(false);
    const [openCancelResult, setOpenCancelResult] = useState(false);
    const [openError, setOpenError] = useState(false);
    const { oidcUser } = useReactOidc();

    const notLoginMainClass = (!oidcUser ? classes.notLoginMain : '');
    const mainClass = clsx("main", notLoginMainClass);
    const [appointmentId, setAppoointmentId] = useState('');
    const [displayReservedDate, setDisplayReservedDate] = useState('');
    const [branchName, setBranchName] = useState('');
    const [menuName, setMenuName] = useState('');
    const [lastName, setLastName] = useState('');
    const [firstName, setFirstName] = useState('');
    const [companyName, setCompanyName] = useState('');
    const [telNo, setTelNo] = useState('');

    const handleOpenCancelDialog = (event) => {
        event.preventDefault();
        setOpenCancelDialog(true);
    };

    const handleCloseCancelDialog = () => {
        setOpenCancelDialog(false);
    };

    const cancelAppointment = async (id, event) => {
        let params = {};
        if(id !== '') params["a"] = id;

        const result = await Api.put(bank.realm, process.env.REACT_APP_APPOINTMENT_BASE_URL + '/appointment/cancel', params, {}, oidcUser);

        setOpenCancelDialog(false);

        if (result.status === 500) {
            setOpenError(true);
        }else if (result.ok) {
            // キャンセル成功の通知表示
            setOpenCancelResult(true);

            return props.history.push("/appointment");
        } else {
            setOpenError(true);
        }
    }

    const handleCloseCancelResult = (event) => {
        setOpenCancelResult(false);
    }

    const handleCloseError = (event) => {
        setOpenError(false);
    }

    useEffect(() => {
        if (typeof window !== 'undefined' && bank.canUseGA && window.gtag) {
            window.gtag('config', bank.gaTrackingCode, {
                'page_path': `${props.location.pathname}${props.location.search}`
            });
        }

        let appointmentId = props.match.params.id;
        if (appointmentId === '') {
            let params = new URLSearchParams(location.search)
            appointmentId = params.get("id");
        }
        setAppoointmentId(appointmentId);
        // 支店一覧
        if (bank.realm) {
            Api.get(bank.realm, process.env.REACT_APP_APPOINTMENT_BASE_URL + '/branches/withmenus', {}, {}, oidcUser)
                .then(branchListResult => {
                    let params = {};
                    params["a"] = appointmentId;
                    Api.get(bank.realm, process.env.REACT_APP_APPOINTMENT_BASE_URL + '/appointment', params, {}, oidcUser)
                        .then(appointmentResult => {
                            if (appointmentResult === "") {
                                props.history.push("/");
                            }
                            const appointment = appointmentResult.appointment;
                            const branch = branchListResult.branches.find(branch => branch.id === appointment.branchId);
                            if (branch.menus !== null) {
                                const menu = branch.menus.find(menu => menu.id === appointment.menuId);
                                setMenuName(menu.name);
                            }
                            setBranchName(branch.name);
                            setDisplayReservedDate(appointment.date + " " + appointment.slot.from + " 〜 " + appointment.slot.to)
                            setLastName(appointment.guest.kanjiName.lastName)
                            setFirstName(appointment.guest.kanjiName.firstName)
                            setCompanyName(appointment.guest.companyName)
                            setTelNo(appointment.guest.tel)
                        })
                        .catch(e => {
                            console.log(e);
                            props.history.push("/");
                        })
                })
        }
    }, [bank, location, oidcUser, props]);

    return (
        <div className="wrapper">
            {!!oidcUser && (
                <Header />
            )}
            <div className="content">
                {(!!oidcUser && matches) && (
                    <LeftColumn />
                )}
                <div className={mainClass}>
                    <ul className="breadcrumb">
                        {!!oidcUser && (
                            <li><Link to={"/"}>トップ</Link></li>
                        )}
                        <li><Link to={"/appointment"}>来店予約</Link></li>
                        <li>予約完了</li>
                    </ul>
                    <div className="box">
                        <h2 className="box-title shop">来店予約</h2>
                        <div className="thankyou-message">
                            <img src={`/img/${bank.staticFilePathPrefix}/check-circle2.gif`} alt="来店予約が完了しました" className="check-circle" />
                            <p className="thankyou-title">来店予約が完了しました</p>
                            {/*<p className="thankyou-body">ご確認のメールを送りましたので、予約内容をご確認ください。</p>*/}
                        </div>
                        <div className="reserved-info mt80">
                            <ul>
                                <li>
                                    <div className="status-label">予約済み</div>
                                    <div className="body">
                                        <div className="detail">
                                            <p className="branch">店舗：{branchName}</p>
                                            <p className="date">来店日時：{displayReservedDate}</p>
                                            <p className="menu">メニュー：{menuName}</p>
                                        </div>
                                        <div className="user-info">
                                            <p className="company-name">会社名：{companyName}</p>
                                            <p className="user-name">氏名：{lastName}　{firstName}</p>
                                            <p className="tel">電話番号：{telNo}</p>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                            {!!oidcUser && (
                            <div className="cancel-and-edit">
                                <Link to={"/appointment/complete"} onClick={handleOpenCancelDialog}>予約をキャンセルする</Link>
                                <Dialog
                                    open={openCancelDialog}
                                    onClose={handleCloseCancelDialog}
                                    aria-labelledby="simple-modal-title"
                                    aria-describedby="simple-modal-description"
                                >
                                    <DialogTitle id="alert-dialog-slide-title">{"ご確認ください"}</DialogTitle>
                                    <DialogContent>
                                        <DialogContentText id="alert-dialog-slide-description">
                                            ご予約をキャンセルします。<br/>
                                            よろしいですか？
                                        </DialogContentText>
                                    </DialogContent>
                                    <DialogActions className={classes.cancelPopup}>
                                        <Button variant="contained" className={classes.cancelButton} onClick={handleCloseCancelDialog}>
                                            いいえ
                                        </Button>
                                        <Button variant="contained" className={classes.okButton} onClick={cancelAppointment.bind(this, appointmentId)} color="primary">
                                            はい
                                        </Button>
                                    </DialogActions>
                                </Dialog>
                                <Snackbar open={openCancelResult} autoHideDuration={6000} onClose={handleCloseCancelResult}>
                                    <MuiAlert elevation={6} variant="filled" onClose={handleCloseCancelResult} severity="success">
                                        予約をキャンセルしました
                                    </MuiAlert>
                                </Snackbar>
                                <Link to={"/appointment/edit/" + appointmentId}>予約内容を変更する</Link>
                            </div>)}
                            <p className="check-message">
                                ※ご予約の時間までにご来店いただけなかった場合、店舗状況により順番が前後する場合がございます。<br />
                                あらかじめご了承いただけますようお願い申し上げます。
                            </p>
                        </div>
                        {!!oidcUser && (
                            <>
                                <p className="check-message">{bank.baPortalName}ご利用者様は、予約状況を「<Link to={"/appointment"} className="key-color">来店予約トップ</Link>」からいつでも確認できます。</p>
                                <div className="text-center">
                                    <Link to={"/appointment"} className="btn key-color size-l arrow mt40 btnSp">来店予約トップへ</Link>
                                </div>
                            </>
                        )}
                        {!oidcUser && (
                            <div className="text-center">
                                <a href={bank.bankUrl} className="btn key-color size-l arrow mt40 btnSp">{bank.bankName}トップへ</a>
                            </div>
                        )}
                    </div>
                    <Snackbar open={openError} autoHideDuration={6000} onClose={handleCloseError}>
                        <MuiAlert elevation={6} variant="filled" onClose={handleCloseError} severity="error">
                            エラーが発生しました
                        </MuiAlert>
                    </Snackbar>
                    <Box className={classes.footer}>
                        <Footer />
                    </Box>
                </div>
            </div>
        </div>
    )
}
