import Box from "@material-ui/core/Box";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { withRouter } from "next/router";
import clsx from "clsx";
import moment from 'moment';
import { useReactOidc } from '@axa-fr/react-oidc-context';
import { connect } from "react-redux";

import TimeMathSum from '../util/TimeMathSum';
import Api from "../util/api";
import useSafeState from "../helpers/useSafeState";
import useUnmountRef from "../helpers/useUnmountRef";


const useStyles = makeStyles((theme) => ({
    root: {
        background: "#fff",
        marginBottom: 40,
        width: "100%",
    },
    relativeBox: {
        height: 20,
        position: "relative",
    },
    left: {
        left: 0,
        position: "absolute",
    },
    right: {
        position: "absolute",
        right: 0,
    },
    table: {
        minWidth: 650,
        '& .selected': {
            background: "#fcc483",
        },
    },
    tableCell: {
        padding: 0,
        '&:hover': {
            background: "#fcc483",
        }
    },
    linkCell: {
        lineHeight: "52px",
        display: "block",
        cursor: "pointer",
    },
    beforeWeek: {
        color: "#19AAC4",
        cursor: "pointer",
        left: 0,
        position: "absolute",
        textDecoration: "underline",
        '&:hover': {
            textDecoration: "none",
        },
    },
    afterWeek: {
        color: "#19AAC4",
        cursor: "pointer",
        position: "absolute",
        right: 0,
        textDecoration: "underline",
        '&:hover': {
            textDecoration: "none",
        },
    },
}));

const TableCellLink = ({ onChangeActive, onChangeActiveDay, activeIndex, timeIndex, index, classes, setReservationDate, setReservationFromTime, setReservationToTime, duration, setDisplayReservedDate, setDuration, dayList, time, availability }) => {
    const handleTableCellClick = (day, time, timeIndex, duration, event) => {
        const toTime = TimeMathSum((time + ':00'), ('00:' + duration + ':00'));
        setReservationDate(day);
        setReservationFromTime(time);
        setReservationToTime(toTime);
        setDuration(duration);

        setDisplayReservedDate(day + " " + time + " 〜 " + toTime);

        onChangeActive(timeIndex);
        onChangeActiveDay(index);
    }

    let selected = "";
    let tableCell = classes.tableCell;
    let click = void(0);
    if (availability === 1 || availability === 2 || availability === 3) {
        selected = (activeIndex ? 'selected' : '');
        tableCell = clsx(classes.tableCell, selected);
        click = handleTableCellClick.bind(this, dayList[index], time, timeIndex, duration);
    }

    return (
        <TableCell align="right" className={tableCell} onClick={click}>
            {availability === 0 && <span className={classes.linkCell}>✕</span>}
            {availability === 1 && <span className={classes.linkCell}>△</span>}
            {availability === 2 && <span className={classes.linkCell}>◯</span>}
            {availability === 3 && <span className={classes.linkCell}>TEL</span>}
            {availability === 4 && <span className={classes.linkCell}>✕</span>}
        </TableCell>
    );
};

const AdminDatePicker = (props) => {
    const classes = useStyles();
    const { oidcUser } = useReactOidc();

    let list = [];
    let dayList = [];
    let availabilityList = [];
    let timeList = props.calendar.timeTable;
    let appointment = props.appointment;
    for (let key of Object.keys(props.calendar.availabilities)) {
        dayList.push(key);
        let mmdd = moment(key);
        list.push(<TableCell align="right" key={key}>{mmdd.format("M/DD")}({mmdd.format("ddd")})</TableCell>);
        // eslint-disable-next-line no-loop-func
        props.calendar.availabilities[key].forEach((availability, availabilityIndex) => {
            let availabilities = [];
            for (let dayKey of Object.keys(props.calendar.availabilities)) {
                availabilities.push(props.calendar.availabilities[dayKey][availabilityIndex]);
            }
            availabilityList.push(availabilities);
        });
    }

    let initialActiveIndex = -1;
    let initialActiveDayIndex = -1;
    dayList.forEach((day, dayIndex) => {
        timeList.forEach((time, timeIndex) => {
            if(props.date === day && props.from === time){
                initialActiveIndex = timeIndex;
                initialActiveDayIndex = dayIndex;
            }
        });
    });
    const unmountRef = useUnmountRef();
    const [activeIndex, setActiveIndex] = useSafeState(unmountRef, initialActiveIndex);
    const [activeDayIndex, setActiveDayIndex] = useSafeState(unmountRef, initialActiveDayIndex);

    const onChangeActive = timeIndex => {
        if (timeIndex === activeIndex) {
            return;
        }
        setActiveIndex(timeIndex);
    }

    const onChangeActiveDay = dayIndex => {
        if (dayIndex === activeDayIndex) {
            return;
        }
        setActiveDayIndex(dayIndex);
    }

    const handleChangeWeek = async (appointment, isBefore, event) => {
        let startDate = moment(dayList[0]);
        let endDate = moment(dayList[list.length - 1]);
        let branchId = appointment.branchId;
        let menuId = appointment.menuId;

        if (isBefore) {
            startDate = startDate.subtract(1, 'w').format('YYYY-MM-DD');
            endDate = endDate.subtract(1, 'w').format('YYYY-MM-DD');
        } else {
            startDate = startDate.add(1, 'w').format('YYYY-MM-DD');
            endDate = endDate.add(1, 'w').format('YYYY-MM-DD');
        }
        const availabilityListResponse = await Api.get(props.realm, process.env.REACT_APP_APPOINTMENT_BASE_URL + '/availabilities', {br: branchId, m: menuId, f: startDate, t: endDate}, {}, oidcUser);

        let newTimeTable = [];
        if (('timeTable' in availabilityListResponse.calendar) && availabilityListResponse.calendar.timeTable !== null) {
            newTimeTable = availabilityListResponse.calendar.timeTable;
        }

        let availabilities = {};
        if (('availabilities' in availabilityListResponse.calendar) && availabilityListResponse.calendar.availabilities !== null) {
            availabilities = availabilityListResponse.calendar.availabilities;
        }

        props.setCalendar({
            timeTable: newTimeTable,
            availabilities: availabilities
        });

        list = [];
        dayList = [];
        availabilityList = [];
        timeList = availabilityListResponse.calendar.timeTable === null ? [] : availabilityListResponse.calendar.timeTable;

        for (let key of Object.keys(availabilityListResponse.calendar.availabilities === null ? {} : availabilityListResponse.calendar.availabilities)) {
            dayList.push(key);
            list.push(<TableCell align="right" key={key}>{key}</TableCell>);
            // eslint-disable-next-line no-loop-func
            availabilityListResponse.calendar.availabilities[key].forEach((availability, availabilityIndex) => {
                let availabilities = [];
                for (let dayKey of Object.keys(availabilityListResponse.calendar.availabilities)) {
                    availabilities.push(availabilityListResponse.calendar.availabilities[dayKey][availabilityIndex]);
                }
                availabilityList.push(availabilities);
            });
        }
    };

    return (
        <Box className={classes.root}>
            <Box className={classes.relativeBox}>
                <span onClick={handleChangeWeek.bind(this, appointment, true)} className={classes.beforeWeek}>＜前週</span>
                <span onClick={handleChangeWeek.bind(this, appointment, false)} className={classes.afterWeek}>次週＞</span>
            </Box>
            <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="simple table" border={1}>
                    <TableBody>
                        <TableRow className={"time-table-header"}>
                            <TableCell />
                            {list}
                        </TableRow>
                        {timeList.map((time, timeIndex) => (
                            <TableRow key={time}>
                                <TableCell align="right" className={"time"}>{time}〜</TableCell>
                                {availabilityList[timeIndex].map((availability, index) => (
                                    <TableCellLink
                                        key={index}
                                        onChangeActive={onChangeActive}
                                        onChangeActiveDay={onChangeActiveDay}
                                        activeIndex={activeIndex === timeIndex && activeDayIndex === index}
                                        setActiveIndex={setActiveIndex}
                                        timeIndex={timeIndex}
                                        index={index}
                                        classes={classes}
                                        setReservationDate={props.setReservationDate}
                                        setReservationFromTime={props.setReservationFromTime}
                                        setReservationToTime={props.setReservationToTime}
                                        duration={props.duration}
                                        setDisplayReservedDate={props.setDisplayReservedDate}
                                        dayList={dayList}
                                        time={time}
                                        availability={availability}
                                        setDuration={props.setDuration}
                                    />
                                ))}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    )
}

export default connect(state => state)(withRouter(AdminDatePicker));

