import React from "react";
import { useBankContext } from "../../util/BankContext";

const BeforeLoginFooter = () => {
    const bank = useBankContext();
    return (
        <>
            <div className="footer">
                <div className="footer-wrapper">
                    <div className="bank-logo">
                        <img src={`/img/${bank.staticFilePathPrefix}/bank_logo.png`} alt={bank.bankName} />
                    </div>
                    <ul>
                        <li><a href={bank.bankUrl}>沖縄銀行公式サイト</a></li>
                        <li><a href={bank.bankUrl + "policy/privacy/index2.html"}>プライバシーポリシー</a></li>
                        <li><a href={bank.baUrl + "page/rule_portal"}>利用規約</a></li>
                        <li><a href={"/page/cookie_policy"}>クッキーポリシー</a></li>
                    </ul>
                </div>
                <div className="footer-other">
                    <p className="copy-right">
                        Copyright ©2020 The Bank of Okinawa,Ltd. All Rights Reserved.
                    </p>
                </div>
            </div>
        </>
    );
};

export default BeforeLoginFooter;
