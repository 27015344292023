import React, {useEffect} from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import clsx from "clsx";
import { useReactOidc } from '@axa-fr/react-oidc-context';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import Header from "../../components/Header";
import LeftColumn from "../../components/LeftColumn";
import Footer from "../../components/Footer";
import CmiList from "../../components/CmiList";
import NewsList from "../../components/NewsList";
import Api from "../../util/api";
import { useBankContext } from "../../util/BankContext";


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        '& table': {
            border: "1px solid #000",
            borderCollapse: "collapse",
            width: "100%",
        },
        '& tr': {
            height: "50px",
        },
        '& th': {
            background: "#E8E8E8",
        },
        '& td': {
            textAlign: "center",
        },
    },
    mainContent: {
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        height: '100vh',
        minHeight: "100vh",
        overflow: 'auto',
    },
    title: {
        backgroundColor: theme.palette.text.primary,
        padding: "10px 8px",
        '& p': {
            color: theme.palette.background.default,
        },
    },
    container: {
        margin: 0,
        padding: 0,
    },
    content: {
        margin: "0 auto",
        width: "95%",
    },
    newsTab: {
        alignItems: "center",
        borderBottom: "1px solid #ddd",
        display: "flex",
        marginTop: 30,
        position: "relative",
    },
    newsTabList: {
        background: "#f6f6f6",
        border: "1px solid #ddd",
        borderBottom: "none",
        borderRadius: "4px 4px 0 0",
        bottom: -1,
        cursor: "pointer",
        lineHeight: "1.71",
        listStyle: "none",
        marginRight: 10,
        position: "relative",
        padding: "4px 8px",
        textAlign: "center",
        width: 120,
        '&::marker':  {
            cursor: "pointer",
            textAlign: "center",
        },
        '&.active': {
            background: "#fff",
            borderBottomColor: "#fff",
            borderTop: "4px solid #19AAC4",
            position: "relative",
        },
    },
    footer: {
        marginTop: "auto",
    },
}));

const NewsTabList = (props) => {
    const classes = useStyles();
    const active = (props.activeIndex ? 'active' : '');
    const newsTabList = clsx(classes.newsTabList, active);

    return (
        <li onClick={props.onChangeActive} className={newsTabList}>{props.title}</li>
    )
};

const News = (props) => {
    const matches = useMediaQuery('(min-width:600px)');
    const bank = useBankContext();
    window.document.title = 'お知らせ|' + bank.baPortalName;
    const classes = useStyles();
    const { oidcUser } = useReactOidc();
    const [activeIndex, setActiveIndex] = React.useState(1);
    // ご案内一覧
    const [cmiListPagingData, setCmiListPagingData] = React.useState({});
    const [cmiList, setCmiList] = React.useState([]);
    // お知らせ一覧
    const [newsListPagingData, setNewsListPagingData] = React.useState({});
    const [newsList, setNewsList] = React.useState([]);

    const onChangeActive = async (active) => {
        if (active === 1) {
            // ご案内一覧取得API
            const informationList = await Api.get(bank.realm, process.env.REACT_APP_INFORMATION_BASE_URL + '/information/search', {r: bank.params.information.listRows, p: 1}, {}, oidcUser);
            if (informationList.informationList === null) {
                setCmiList([]);
            } else {
                setCmiList(informationList.informationList);
            }
            setCmiListPagingData(informationList.pagingInfo);
        } else if (active === 2) {
            // お知らせ一覧取得API
            const newsList = await Api.get(bank.realm, process.env.REACT_APP_NEWS_BASE_URL + '/news/search', {r: bank.params.news.listRows, p: 1}, {}, oidcUser);
            if (newsList.newsList === null) {
                setNewsList([]);
            } else {
                setNewsList(newsList.newsList);
            }
            setNewsListPagingData(newsList.pagingInfo);
        }

        setActiveIndex(active);
    }

    useEffect(() => {
        if (!oidcUser) {
            return;
        }
        if (bank.canUseGA) {
            window.gtag('config', bank.gaTrackingCode, {
                'page_path': `${props.location.pathname}${props.location.search}`
            });
        }

        const fetchData = async () => {
            // ご案内一覧取得API
            const informationList = await Api.get(bank.realm, process.env.REACT_APP_INFORMATION_BASE_URL + '/information/search', {r: bank.params.information.listRows, p: 1}, {}, oidcUser);
            if (informationList.informationList === null) {
                setCmiList([]);
            } else {
                setCmiList(informationList.informationList);
            }
            setCmiListPagingData(informationList.pagingInfo);
        }

        fetchData()
            .then()
            .catch(e => {
                // todo エラーハンドリング
                if( e.response ){
                    if( e.response.status === 403 ){
                        // todo 「ログインしてください」を表示
                        // todo ユーザー情報の削除
                        // location.href = "/";
                    }
                    //todo エラーであることを知らせる表示
                }
            })
    }, [oidcUser, bank, props]);

    return (
        <div className="wrapper">
            <Header />
            <div className="content">
                {(!!oidcUser && matches) && (
                    <LeftColumn />
                )}
                <div className="main">
                    <Box className={classes.title}>
                        <Typography variant="body2" color="textSecondary">
                            お知らせ
                        </Typography>
                    </Box>
                    <Container className={classes.container}>
                        <div className={classes.content}>
                            <div>
                                <ul className={classes.newsTab}>
                                    <NewsTabList title={"ご案内"} onChangeActive={() => onChangeActive(1)} activeIndex={activeIndex === 1} />
                                    <NewsTabList title={"お知らせ"} onChangeActive={() => onChangeActive(2)} activeIndex={activeIndex === 2} />
                                </ul>
                                {activeIndex === 1 && (
                                    <CmiList
                                        cmiListPagingData={cmiListPagingData}
                                        cmiList={cmiList}
                                        setCmiListPagingData={setCmiListPagingData}
                                        setCmiList={setCmiList}
                                        realm={bank.realm}
                                    />
                                )}
                                {activeIndex === 2 && (
                                    <NewsList
                                        newsListPagingData={newsListPagingData}
                                        newsList={newsList}
                                        setNewsListPagingData={setNewsListPagingData}
                                        setNewsList={setNewsList}
                                        realm={bank.realm}
                                    />
                                )}
                            </div>
                        </div>
                    </Container>
                    <Box className={classes.footer}>
                        <Footer />
                    </Box>
                </div>
            </div>
        </div>
    )
}

export default News;
