import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import { render } from 'react-dom';
import { createStore } from 'redux'
import { Provider } from "react-redux";
import { ThemeProvider } from "@material-ui/core/styles";
import { CookiesProvider } from 'react-cookie';
import { SnackbarProvider } from 'notistack';
import * as Sentry from "@sentry/react";

import App from './App';
import theme from "./util/theme";
import reducer from "./util/reducer";

if (process.env.NODE_ENV != "development") {
  Sentry.init({
    dsn: "https://27165ef0c3144b8d9e6d5b4b33c37c77@o408639.ingest.sentry.io/5601164",
    release: "bap-frontend@" + process.env.npm_package_version,
  });
}
const store = createStore(reducer);

render(
    <React.StrictMode>
        <ThemeProvider theme={theme}>
            <Provider store={store}>
                <CookiesProvider>
                    <SnackbarProvider>
                        <App />
                    </SnackbarProvider>
              </CookiesProvider>
            </Provider>
        </ThemeProvider>
    </React.StrictMode>,
    document.getElementById('root')
);
