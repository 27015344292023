import React from "react";
import { Link } from 'react-router-dom';
import { useReactOidc } from '@axa-fr/react-oidc-context';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import 'moment/locale/ja';
import clsx from "clsx";

import { useBankContext } from "../util/BankContext";
import Header from "./Header";
import Footer from "./Footer";
import LeftColumn from "./LeftColumn";
import KtbaBeforeLoginHeader from "./ktba/BeforeLoginHeader";
import OkbaBeforeLoginHeader from "./okba/BeforeLoginHeader";
import KtbaBeforeLoginFooter from "./ktba/BeforeLoginFooter";
import OkbaBeforeLoginFooter from "./okba/BeforeLoginFooter";


const useStyles = makeStyles((theme) => ({
  notLoginMain: {
    width: "100vw !important",
    height: "100vh !important",
  },
}));

const StaticPageLayout = (props) => {
  const title = props.title
  const matches = useMediaQuery('(min-width:600px)');
  const bank = useBankContext();
  window.document.title = title + '|' + bank.baPortalName;
  const classes = useStyles();
  const { oidcUser } = useReactOidc();
  const mainClass = clsx("main", (!oidcUser ? classes.notLoginMain : ''));

  let header = "";
  if (!!oidcUser) {
    header = <Header />
  } else if (bank.realm === 'bank0158') {
    header = <KtbaBeforeLoginHeader/>
  } else if (bank.realm === 'bank0188') {
    header = <OkbaBeforeLoginHeader/>
  }
  let footer = ""
  if (!!oidcUser) {
    footer = <Footer />
  } else if (bank.realm === 'bank0158') {
    footer = <KtbaBeforeLoginFooter/>
  } else if (bank.realm === 'bank0188') {
    footer = <OkbaBeforeLoginFooter/>
  }
  return (
    <div className="wrapper">
      {header}
      <div className="content">
        {!!oidcUser && matches && <LeftColumn />}
        <div className={mainClass}>
          <ul className="breadcrumb">
            <li><Link to={"/"}>トップ</Link></li>
            <li>{title}</li>
          </ul>
          {props.children}
        </div>
      </div>
      <Box className={classes.footer}>
        {footer}
      </Box>
    </div>
  )}
    
export default StaticPageLayout;
