import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useBankContext } from "../../util/BankContext";
import StaticPageLayout from "../StaticPageLayout";


const useStyles = makeStyles((theme) => ({
  notLoginMain: {
    width: "100vw !important",
    height: "100vh !important",
  },
  contents:  {
    "&> h2": {
      paddingLeft: "0",
    },
    "&> h3": {
      letterSpacing: "2px",
      fontSize: "18px",
      borderBottom: "1px solid #ccc",
      paddingBottom: "4px",
      marginTop: "30px",
      marginBottom: "32px",
      position: "relative",
    },
    "&> h4": {
      fontWeight: "700",
      display: "block",
      marginBlockStart: "1.33em",
      marginBlockEnd: "1.33em",
      marginInlineStart: "0px",
      marginInlineEnd: "0px",
    },
    "&> p": {
      textAlign: "right",
      lineHeight: "1.41em",
      marginBottom: "16px",
    }
  }
}));

const Rule = (props) => {
  const bank = useBankContext();
  const classes = useStyles();
  return (
    <StaticPageLayout title="利用規約">
      <div className={"box " + classes.contents}>
        <h2>利用規約</h2>
        <h3>１. 会員規約</h3>
        <h4>（本規約の適用について）</h4>
        本規約は、当行が運営・管理する当ＷＥＢサイトで展開する各種機能（以下「本サービス」といいます）を利用する全ての会員に適用されるものです。本サービスを利用する全ての方を会員と言います。会員は、本サービスの利用に当たり、本規約を遵守し、また、本サービスを利用する会員及びその利用を希望する方は、いずれも本規約を承諾したうえで利用や申し込みを行うものとします。<br />
        <br />
        <h4>（会員登録について）</h4>
        　会員とは、本規約に同意し、当行が別途定める方法により会員登録を行い、当行がこれを承認した方をいいます。<br />
        <br />
        会員登録をする方は、本規約を熟読し本サービスの利用に関して本規約が適用されることを承認のうえ、会員登録の申し込みを行っていただきます。<br />
        　本サービスの利用に関し、当行が本規約以外の利用条件・注意事項・会員と当行で個別に定めた契約が存在する場合（以下、利用条件等という）は、本規約と一体をなすものとして当該利用条件等を遵守するものとします。（ただし、個別に定めた契約と本規約の規定とが抵触する場合は、個別に定めた契約が優先するものとします。）<br />
        　会員が未成年者となる場合は、事前に法定代理人（親権者あるいは未成年後見人）の同意を得た上で会員登録を行うものとします。<br />
        会員は一つのアカウントを保有するものとします。複数のアカウントを保有することはできません。ただし、当行が認めたものを除きます。<br />
        　会員登録決定には、当行の審査基準があります。ご要望に添えない場合がありますので、あらかじめご了承ください。<br />
        　当行は、会員登録審査において、資料などの提出を求めることができるほか、当行との取引に関する情報を利用することができるものとします。<br />
        　　会員にご登録いただく場合、次に該当する方はお断りしております。もし該当する方のご登録があった場合は、予告なく削除されることがありますので、あらかじめご了承ください。<br />
        ・反社会的勢力（※）と認められる個人・団体など<br />
        ・各種法律・公序良俗・倫理に反する恐れのある個人・団体など<br />
        ・その他当行が会員として不適当と判断した場合<br />
        前項の定めに基づき不承諾となった場合、会員登録の申し込み者は、この不承諾につき異議申立などを行うことができないものとします。<br />
        　会員登録をする方は、当WEBサイトに情報を公開した場合、当行と取引があるという事実が当WEBサイトを閲覧する方に推察され得ることを事前に理解したうえで、会員登録の申し込みを行うものとします。<br />
        <br />
        <h4>※反社会的勢力の定義について</h4>
        反社会的勢力とは、以下に定める団体又は個人をいう。<br />
        （１）属性要件<br />
        暴力団、暴力団員、暴力団員でなくなった時から５年を経過しない者、暴力団準構成員、暴力団関係企業、総会屋等、社会運動等標ぼうゴロ又は特殊知能暴力集団等、その他これらに準ずる者（以下これらを「暴力団員等」という）、又は次の各号のいずれかに該当する者<br />
        ①暴力団員等が経営を支配していると認められる関係を有すること。<br />
        ②暴力団員等が経営に実質的に関与していると認められる関係を有すること。<br />
        ③自己、自社若しくは第三者の不正の利益を図る目的又は第三者に損害を加える目的をもってするなど、不当に暴力団員等を利用していると認められる関係を有すること。<br />
        ④暴力団員等に対して資金等を提供し、又は便宜を供与するなどの関与をしていると認められる関係を有すること。<br />
        ⑤役員又は経営に実質的に関与している者が暴力団員等と社会的に非難されるべき関係を有すること。<br />
        <br />
        （２）行為要件<br />
        自ら又は第三者を利用して次の各号のいずれかに該当する行為を行った団体又は個人<br />
        ①暴力的な要求行為<br />
        ②法的な責任を超えた不当な要求行為<br />
        ③取引に関して、脅迫的な言動をし、又は暴力を用いる行為<br />
        ④風説を流布し、偽計を用い又は威力を用いて当行の信用を毀損し、又は当行の業務を妨害する行為<br />
        ⑤その他前各号に準ずる行為<br />
        <br />
        <h4>（ＩＤ及びパスワードの管理について）</h4>
        　会員は、自己の責任において、ＩＤ及びパスワードを設定するものとします。<br />
        　会員は、ＩＤ及びパスワードの管理責任を負うものとし、会員以外の第三者に開示、漏洩しないものとします。<br />
        　ＩＤ及びパスワードの管理不十分、使用上の過誤、第三者の使用などによる損害の責任は会員が負うものとし、当行は一切責任を負いません。<br />
        　ＩＤ及びパスワードによりなされた本サービスの提供は、当該ＩＤ及びパスワードの管理責任を負う会員により利用されたものとみなし、当該会員は本サービスの利用により生じた債務の一切を負担するものとします。<br />
        <br />
        <h4>（登録事項の変更について）</h4>
        　会員は、会員登録申し込み時などに登録した内容に変更があった場合、速やかにチャットにて登録情報の変更内容を報告するものとします。<br />
        　会員が前項の変更手続を怠ったことにより不利益を被っても、当行は一切の責任を負いません。<br />
        　本サービスにおける登録情報の変更手続は、当行との取引に関する各種変更手続とは連動していません。取扱本支店に届出している情報に変更があった場合、別途取扱本支店に直接連絡のうえ、変更手続を行うものとします。<br />
        <br />
        <h4>（会員情報の取り扱いについて）</h4>
        　会員が登録した又は届け出た情報及び利用履歴などの情報（以下「会員情報」といいます）は、外部のクラウドサービス業者のサーバにて管理します。<br />
        　当行は会員情報を厳正に管理し、会員のプライバシー保護のために十分注意するとともに、次の場合を除きこれを第三者に開示し、又は利用させないものとします。<br />
        1.　本サービスの円滑な提供のために、当行が本サービス運用サポートなどを目的として業務委託する者（以下「業務委託先」といいます）に対し必要な範囲内で提供する場合<br />
        2.　あらかじめ会員の同意が得られた場合<br />
        3.　当行の法的義務を履行するために必要な場合<br />
        4.　裁判所、検察庁、警察署その他の司法・行政機関から法令に基づいて開示を求められた場合<br />
        5.　個別の会員を識別できない状態で提供する場合<br />
        <br />
        会員は、当行又は業務委託先が次の目的のために、会員情報を利用することをあらかじめ承諾するものとします。<br />
        1.　新サービスの企画・開発<br />
        2.　ダイレクトメール・電子メールなどの発送・送信<br />
        3.　会員の管理<br />
        4.　その他本サービスの内容を向上させるために必要な行為<br />
        <br />
        <h4>（会員による情報などの取り扱いについて）</h4>
        　当WEBサイトを通じて提供・公開される情報は、当行又は情報などに関して権利を持つ各会員が保有するものであり、会員は、当行が事前に承諾した場合を除き、当行または他の会員の情報を、自己使用以外の目的にこれを使用しないものとし、第三者に開示しないものとします。<br />
        <br />
        <h4>（会員設備などについて）</h4>
        　会員は、本サービスを利用するに当たり必要となるインターネット接続環境（プロバイダー・電話会社との契約などを含みます）、コンピュータその他機器、ソフトウェアなどを自らの費用で設置し、維持するものとします。<br />
        <br />
        <h4>（情報の削除などについて）</h4>
        　当行は、次のいずれかに該当すると判断した場合には、会員への事前の通知又は承諾を要することなく、会員が登録した情報の全部又は一部の削除、変更、複写及び移動を行うことができるものとします。<br />
        ・当WEBサイトに公開された情報の公開期間又は情報量が当行の定める期間又は量を超えると判断した場合<br />
        ・登録された情報が、当規約の定める禁止事項に該当し、又は該当するおそれがあると判断した場合<br />
        ・その他当行が必要と判断した場合<br />
        <br />
        <h4>（会員資格の停止・抹消について）</h4>
        　当行は、会員が次の事由のいずれかに該当する場合、会員に事前に通知することなく会員の資格を停止し、又は抹消することができるものとします。<br />
        1.　倒産、廃業などの信用状況の悪化によって、会員として当行が不適当と判断した場合<br />
        2.　破産手続開始、会社更生手続開始又は民事再生手続が開始され、もしくはその申立があった場合、清算に入った場合その他これに準ずる場合<br />
        3.　手形の不渡り又は手形交換所の取引停止処分もしくは、電子記録債権の支払い不能処分を受けた場合<br />
        4.　仮差押、差押若しくは競売手続の開始があった場合又は租税公課を滞納して保全差押を受けた場合<br />
        5.　登録住所の変更を怠るなど会員の責めに帰すべき事由により、当行において会員の所在が不明となった場合<br />
        6.　解散その他営業活動を休止した場合<br />
        7.　ID・パスワードその他登録された情報を不正に使用した場合<br />
        8.　禁止事項に該当し、又は該当するおそれがあると当行が判断した場合<br />
        9.　本規約のいずれかの条項に違反した場合<br />
        10.　反社会的勢力などである場合又は反社会的勢力などに関与した場合<br />
        11.　登録情報が虚偽又は事実に反することが判明した場合<br />
        12.　その他会員として不適当と当行が判断した場合<br />
        会員は、当行が前項の措置について判断するに際し、会員と当行との取引に関する情報を利用することをあらかじめ承諾するものとします。<br />
        <br />
        <h4>（会員への通知について）</h4>
        　会員は、本サービスにおける当行からの通知・確認・案内などの手段として、当WEBサイトへの掲示、電話、電子メール及び郵送などが利用されることに同意するものとします。<br />
        <br />
        <h4>（免責事項などについて）</h4>
        　当行は、会員に対し、情報提供やアドバイスを行うことがありますが、情報の信用力を補完、保証するものではなく、あくまで会員の判断に委ねます。<br />
        　当WEBサイトの利用によって会員又は第三者が被った損害について、理由を問わず、当行は一切の責任を負いません。<br />
        当WEBサイトに掲載している情報を、当WEBサイト以外で会員又は第三者に取得され、会員又は第三者が被った損害について、理由や情報取得の経路、経緯を問わず、当行は一切の責任を負いません。<br />
        当WEBサイトに掲載している情報を、不正アクセス等で第三者に取得され、会員又は第三者が被った被害について、理由や情報取得の経路、経緯を問わず、当行は一切の責任を負いません。<br />
        　会員が本規約に違反する行為又は不正もしくは違法な行為によって当行に損害を与えた場合、当行は、当該会員などに対してその損害の賠償を請求できるものとします。<br />
        　通信回線やコンピュータなどの障害によるシステムの中断・遅滞・中止・データの消失、データへの不正アクセスにより生じた損害その他当WEBサイトの利用に関して会員などに生じた損害について、当行に故意・重過失のある場合を除いて当行は一切の責任を負いません。<br />
        　当行は、当WEBサイトのウェブページ、サーバ、ドメインなどから送られるメール、当WEBサイト内のコンテンツなどに、コンピュータ・ウィルスなどの有害なものが含まれないことを保証いたしません。<br />
        <br />
        <h4>（サービスの一時中断又は停止について）</h4>
        　当行は、次の場合には本サービスの提供を一時中断又は停止することがあります。<br />
        1.　システムや設備の保守、点検、修理、変更などを行う場合<br />
        2.　火災、停電などによりシステムや設備などに障害が生じた場合<br />
        3.　地震、噴火、洪水、津波などの天災、戦争、変乱、暴動、騒乱、労働争議などにより本サービスの提供ができなくなった場合<br />
        4.　その他やむを得ず本サービスの提供の一時中断又は停止が必要となった場合<br />
        　本サービスの提供の一時中断又は停止により、会員などに不利益又は損害が生じた場合であっても、当行は一切の責任を負いません。<br />
        　当行は、本サービスの提供を一時中断又は停止する場合は、当WEBサイトへの掲示その他の方法をもってあらかじめ会員へ通知します。ただし、緊急の場合は、会員に事前に通知することなく、本サービスの提供を一時中断又は停止することができるものとします。<br />
        <br />
        <h4>（退会について）</h4>
        　会員が退会を希望する場合は、別途、当行が定める手続により退会できるものとします。<br />
        <br />
        <h4>（規約の変更について）</h4>
        1.　当行が当WEBサイトへの掲示その他の方法により定める個別規定は、本規約の一部を構成します。本規約と個別規定が矛盾・抵触する場合には、原則として個別規定が優先するものとします。<br />
        2.　当行は、以下に掲げる場合、民法548条の4の規定により、 会員の承諾なく本規約を変更できるものとし、会員は、あらかじめこれを承諾するものとします。本規約の変更の結果、会員に不利益又は損害が生じた場合であっても、当行は一切の責任を負いません。<br />
        （１）本規約の変更が会員の一般の利益に適合する場合。<br />
        （２）本規約の変更が、契約をした目的に反せず、かつ、変更の必要性、変更後の内容の相当性その他の変更に係る事情に照らして合理的なものである場合。<br />
        3.　上記２による本規約の変更は、インターネットの利用その他の適切な方法により会員へ周知します。<br />
        4.　上記による本規約の変更は、周知の際に定める１か月以上の相当な期間を経過した日から適用するものとします。<br />
        <br />
        <h4>（協議事項について）</h4>
        　本規約に定めのない事項について紛議などが生じた場合又は本規約の各条項の解釈について疑義が生じた場合には、会員と当行は、誠意を持って協議し解決するものとします。<br />
        <br />
        <h3>２. 情報提供機能の利用について</h3>
        　情報提供機能で掲載する情報は当行が勧奨するものではございません。情報提供機能に掲載しているセミナー情報などへの参加は当サイトを経由してお申し込みをお願いします。情報提供機能の利用には当サイトへ会員登録を行っていただく必要がございます。<br />
        　<br />
        　当行は、情報提供機能に掲載している情報について、その内容の完全性・正確性・有用性・安全性等についていかなる保証を行うものでもありません。又、当該情報を利用したことに起因する当事者間のトラブルについて、当行は一切の責任を負いかねます。トラブルにならないよう、自己の責任において的確な情報の活用をお願いいたします。<br />
        <br />
        <h3>３. コミュニケーション機能の利用について</h3>
        　コミュニケーション機能を利用した情報の伝達や資料の送受信などは当行が勧奨するものではございません。コミュニケーション機能を利用した資料の誤送信、情報の誤送信などを起因として損失等が発生しても、当行は一切の責任を負いかねます。コミュニケーション機能の利用には当サイトへ会員登録を行っていただく必要がございます。<br />
        　当行は、コミュニケーション機能を利用して送受信した資料等にコンピュータ・ウィルスなどの有害なものが含まれないことを保証いたしません。<br />
        <br />
        　コミュニケーション機能を利用したことに起因する当事者間のトラブルについて、当行は一切の責任を負いかねます。トラブルにならないよう、自己の責任において的確な活用をお願いいたします。<br />
        <br />
        <h3>４. 当WEBサイト閲覧の環境について</h3>
        　当WEBサイトでは、より安全で快適にご利用いただくために下記のブラウザを推奨いたします。<br />
        Microsoft Edge最新版，Firefox最新版，Google Chrome最新版，Safari最新版<br />
        <br />
        <h3>５. 著作権について</h3>
        　当WEBサイトのご利用者は、権利者の承諾を得ないで、いかなる方法においても当WEBサイトを通じて提供されるいかなる情報も、著作権法で定めるご利用者個人の私的使用の範囲を超える複製、販売、出版、その他の用途に使用することはできないものとします。ご利用者は、権利者の承諾を得ないで、いかなる方法においても、第三者を通じて、当WEBサイトを通じて提供されるいかなる情報を使用させたり、公開させたりすることはできないものとします。<br />
        　当WEBサイトに提供・掲示された情報が著作権を侵害している事実が判明した場合は、当行の判断で削除する場合があります。<br />
        　本内容に違反して問題が発生した場合、ご利用者は、自己の責任と費用において係る問題を解決するとともに、当行ならびに「おきぎん Big Advance」事務局に何らの迷惑または損害を与えないものとします。<br />
        <br />
        <h3>６. 内容の変更について</h3>
        　当行ならびに「おきぎん Big Advance」事務局は、当WEBサイトに掲載する情報の正確性、最新性、有用性などその他一切の事項について、いかなる保証もするものではありません。また、当行ならびに「おきぎん Big Advance」事務局は、利用者に事前に通知することなく、当行ならびに「おきぎんBig Advance」事務局の判断によって当WEBサイトで公開されている情報の追加、変更、修正、削除を行う場合があります。当行ならびに「おきぎんBig Advance」事務局は、いかなる場合においても、当WEBサイトの情報の追加、変更、修正、削除などによりお客さまに生じたいかなる損害についても一切の責任を負いません。<br />
        <br />
        <h3>７. リンクについて</h3>
        　当WEBサイトへのリンクは原則として自由です。ただし、以下の条件をお守りください。<br />
        　<br />
        　当WEBサイトから他のWEBサイトへリンクをしたり、第三者が当WEBサイトへのリンクを提供している場合、当行ならびに「おきぎん Big Advance」事務局は当WEBサイト外のWEBサイトについては、何ら責任は負いません。この場合、当該WEBサイトに包括され、また当該WEBサイト上で利用が可能となっているコンテンツ、広告、商品、サービスなどについても同様に一切責任を負いません。<br />
        　当行ならびに「おきぎん Big Advance」事務局は、それらのコンテンツ、広告、商品、サービスなどに起因または関連して生じた一切の損害についても賠償する責任は負いません。<br />
        　当WEBサイトへリンクする場合は、当WEBサイトがリンク元のフレーム内に表示されることのないよう、別ウィンドウにて表示されるように設定してください。<br />
        　個別のページへのリンクは、WEBサイト構造やサーバ構成の変更にともなって予告無くリンク先が無くなる可能性があります。<br />
        <br />
        <h3>８. クッキー(Cookie)の使用について</h3>
        　当WEBサイトではお客さまへの適切な情報提供などのためにクッキー (Cookie) を使用することがあります。クッキーとはホームページがお客さまのパソコンなどのブラウザに情報を保存し、あとで取り出すことができる機能のことです。ただし、当WEBサイトで設定するクッキーにはお客さまの連絡先が判明するような情報は含まれていませんのでご安心ください。<br />
        <br />
        <h3>９. 個人情報の取り扱いについて</h3>
        　当WEBサイトにてお預かりした個人情報については、当行の定める 個人情報保護宣言に則り取り扱います。お客さまより収集させていただいた情報は、不正アクセス、紛失、改ざん、窃取がないように適切に管理し、収集目的の範囲内でのみ利用させていただきます。<br />
        　当行は、お客さまの個人情報にかかわる特定の業務（WEBサイトの構築・管理、各企業さまとの連絡、アンケート分析、ID管理など）を別の会社に業務委託することがあります。当行と当該業務委託会社は、個人情報の取り扱いについて厳正な契約を交わしたうえで、個人情報の取り扱いを委託します。ただし、取り扱いを委託するお客さまの個人情報は、当該業務委託に必要となる最小限の個人情報のみとし、かつ利用範囲もその範囲に限定されます。<br />
        　当行は、次のいずれかの場合に、お客さまの個人情報を開示することがあります。<br />
        <br />
        ・法令に基づき開示しなければならないとき（司法機関または警察からの要請に応じる場合を含む）。<br />
        ・当行、お客さま、または他の第三者の安全確保などのため、緊急に行動をとる必要があるとき。<br />
        <br />
        <h3>１０. SSLについて</h3>
        ・当WEBサイトでは企業情報の登録や送受信をする際、SSL（Secure Socket Layer）を利用しております。SSLとは、通信上の暗号化と認証をおこなう技術であり、対応するブラウザでは、リアルタイムに暗号化されているメッセージを確認することができるので、安心して個人情報を送信いただけます。<br />
        <br />
        ・このSSLによるセキュリティー強化により、WEBサイトからの発信データ改ざん（なりすまし）の防止や、 ユーザーの入力・送信データの暗号化による内容の保護を実現しております。<br />
        <br />
        ・SSLに対応していないブラウザではご利用になれませんので、ご了承ください。<br />
        <br />
        <h3>１１. 禁止事項について</h3>
        　当WEBサイトの利用に際し、次の行為はしてはならないものとします。<br />
        <br />
        1.　第三者または当行の社会的信用・名誉・財産・プライバシーなどをき損・侵害する行為、またはその恐れのある行為。<br />
        2.　各種法律・公序良俗・倫理に反する行為、またはその恐れのある行為。<br />
        3.　営業活動や営利を目的とする行為、またはその準備を目的とする行為。<br />
        4.　他人のメールアドレスを登録するなど、虚偽の申告、届出を行う行為。<br />
        5.　コンピュータ・ウィルスなど有害なプログラムを利用または提供する行為、またはその恐れのある行為（スパム行為を含みます）。<br />
        6.　その他、当行ならびに「おきぎん Big Advance事務局」が不適切であると判断する行為。<br />
        <br />
        <h3>１２.準拠法及び管轄裁判所</h3>
        　当WEBサイトの利用ならびに本利用条件の解釈及び適用は、日本国法に準拠するものとします。また、当WEBサイトの利用に関して訴訟の必要が生じた場合の管轄裁判所は、那覇地方裁判所とします。<br />
        <br />
        <p>
        ２０２０年１１月１６日　施行<br />
        以上<br />
        </p>
        <br />
        <br />
        ＜サイト運営企業＞<br />
        沖縄銀行<br />
        <br />
        ＜事務局＞<br />
        「おきぎん Big Advance」事務局<br />
        住所：沖縄県那覇市久茂地3-10-1<br />
        TEL： 098-869-1296　受付時間9:00~17:00/土日・祝日は除く<br />
      </div>
    </StaticPageLayout>
  )
}

export default Rule;
