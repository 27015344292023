import React, { useEffect } from "react";
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { useReactOidc, withOidcSecure } from '@axa-fr/react-oidc-context';

import { useBankContext } from './util/BankContext';
import AppointmentList from "./pages/admin/appointment_list";
import AppointmentRegister from "./pages/admin/appointment_register";
import AppointmentEdit from "./pages/admin/appointment_edit";
import BranchSettings from "./pages/admin/branch_settings";
import AllSettings from "./pages/admin/all_settings";
import ImageSetting from "./pages/admin/image_setting";
import ImageSettingRegister from "./pages/admin/image_setting_register";
import ImageSettingEdit from "./pages/admin/image_setting_edit";
import AutoReplyMailSetting from "./pages/admin/auto_reply_mail_settings";
import Index from "./pages";
import Appointment from "./pages/appointment";
import DateSelect from "./pages/appointment/date_select";
import InformationRegister from "./pages/appointment/information_register";
import Confirm from "./pages/appointment/confirm";
import Complete from "./pages/appointment/complete";
import AppointmentDetail from "./pages/appointment/detail";
import AppointmentGuestDetail from "./pages/appointment/guest_detail";
import BaChat from "./pages/ba_chat";
import Seminar from "./pages/seminar";
import SeminarDetail from "./pages/seminar/detail";
import ManagementColumn from "./pages/management_column";
import ManagementColumnDetail from "./pages/management_column/detail";
import SuccessionColumn from "./pages/succession_column";
import SuccessionColumnDetail from "./pages/succession_column/detail";
import SuccessionFaq from "./pages/succession_faq";
import News from "./pages/news";
import NewsDetail from "./pages/news/detail";
import CmiDetail from "./pages/cmi/detail";
import Settings from "./pages/settings";
import Login from "./pages/login";
import CookiePolicy from "./pages/page/cookie_policy";
import Rule from "./pages/page/rule";
import NotFound from "./404";


const Router = () => {
  const { oidcUser, logout, events } = useReactOidc();
  const bank = useBankContext();

  function isSystemUser() {
    const ba_user_type = oidcUser?.profile?.ba_user_type ?? "";
    return ba_user_type === "system_admin" ||
      ba_user_type === "bank_admin" ||
      ba_user_type === "branch_admin" ||
      ba_user_type === "branch_user";
  }

  function isOwnerUser() {
    const ba_user_type = oidcUser?.profile?.ba_user_type ?? "";
    return ba_user_type === "company_owner" ||
      ba_user_type === "partner_company_owner" ||
      ba_user_type === "company_user";
  }

  useEffect(() => {
    if (oidcUser?.profile && !oidcUser.profile.ba_user_unique_code) {
      // AnserBizSOLでのログインしてきたが未登録のユーザー
      const sub = oidcUser.profile.sub;
      const bizsol_sub = oidcUser.profile.bizsol_sub;
      events.addUserUnloaded(() => {
        window.location = `${bank.baUrl}register/portal?baidp_user_id=${sub}&bizsol_sub=${bizsol_sub}`;
      });
      logout();
    }
    /*
    if (oidcUser?.profile && !isSystemUser() &&
        !oidcUser.profile.ba_company_enable_baportal) {
      // ポータルの利用同意がまだのユーザー
      events.addUserUnloaded(() => {
        const loginUri = encodeURIComponent(`${bank.baLoginRedirectUri}login`);
        const registerPortalUri = `${bank.baUrl}register/portal/enable?r=${loginUri}`;
        window.location = registerPortalUri;
      });
      logout();
    }*/
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function PrivateRoute({ component, ...rest }) {
    const ba_user_type = oidcUser?.profile?.ba_user_type ?? "";
    return (
      <Route
        {...rest}
        render={props => !!oidcUser ? (
          (ba_user_type === "system_admin" ||
           ba_user_type === "bank_admin" ||
           ba_user_type === "branch_admin" ||
           ba_user_type === "branch_user") ? (
             <Redirect to="/admin/appointment_list" />
           ) : (
             <Route component={component} {...rest} />
           )
        ) : (
          <Route component={withOidcSecure(component)} {...rest} />
        )}
      />
    );
  }

  function PrivateSuccesionRoute({ component, ...rest }) {
    return (
      <Route
        {...rest}
        render={() => !!oidcUser ? (
          (isSystemUser()) ? (
            <Redirect to="/admin/appointment_list" />
          ) : (
            // 事業承継がONかつオーナーのみページ表示（それ以外ならnot found)
            (bank.canUseBS && isOwnerUser()) ? (<Route component={component} {...rest} />) : (<Route component={NotFound} />)
          )
        ) : (
          <Route component={withOidcSecure(component)} {...rest} />
        )}
      />
    );
  }

  function BankRoute({ component, ...rest }) {
    return (
      <Route
        {...rest}
        render={props => !!oidcUser && isSystemUser() ? (
          bank.canUseAppointment ?
            (
              <Route component={component} {...rest} />
            ) : (
              window.location.href = `${bank.baUrl}admin/dashboard`
            )
        ) : (
          <Route component={NotFound} />
        )}
      />
    );
  }

  function BankAdminRoute({ component, ...rest }) {
    const ba_user_type = oidcUser?.profile?.ba_user_type ?? "";
    return (
      <Route
        {...rest}
        render={props => !!oidcUser && 
            (ba_user_type === "system_admin" ||
            ba_user_type === "bank_admin") ? (
          bank.canUseAppointment ?
            (
              <Route component={component} {...rest} />
            ) : (
              window.location.href = `${bank.baUrl}admin/dashboard`
            )
        ) : (
          <Route component={NotFound} />
        )}
      />
    );
  }

  function AppointmentRoute({ component, ...rest }) {
    return (
      <Route
        {...rest}
        render={props => bank.canUseAppointment === false ? (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: props.location }
            }}
          />
        ) : (
          <Route component={component} {...rest} />
        )}
      />
    );
  }

  function PrivateAppointmentRoute({ component, ...rest }) {
    return (
      <Route
        {...rest}
        render={props => !!oidcUser ? (
          bank.canUseAppointment ? (
              <Route component={component} {...rest} />
            ) : (
              <Redirect to="/" />
            )
          ) : (
            <Route component={withOidcSecure(component)} {...rest} />
          )}
      />
    );
  }

  const prefix = process.env.REACT_APP_PATH_PREFIX;

  return (
    <BrowserRouter basename={prefix}>
        <Switch>
            <Route exact path={"/"} component={Index} />
            <BankRoute exact path={"/admin/appointment_list"} component={AppointmentList} />
            <BankRoute exact path={"/admin/appointment_register"} component={AppointmentRegister} />
            <BankRoute exact path={"/admin/appointment_edit"} component={AppointmentEdit} />
            <BankRoute exact path={"/admin/branch_settings"} component={BranchSettings} />
            <BankAdminRoute exact path={"/admin/all_settings"} component={AllSettings} />
            <BankAdminRoute exact path={"/admin/image_setting"} component={ImageSetting} />
            <BankAdminRoute exact path={"/admin/image_setting_register/:displayOrder"} component={ImageSettingRegister} />
            <BankAdminRoute exact path={"/admin/image_setting_edit/:id"} component={ImageSettingEdit} />
            <BankRoute exact path={"/admin/auto_reply_mail_settings"} component={AutoReplyMailSetting} />
            <PrivateRoute exact path={"/ba_chat"} component={BaChat} />
            <PrivateRoute path={"/chat_room/:bachatroom_unique_code"} component={BaChat} />
            <PrivateRoute exact path={"/seminar"} component={Seminar} />
            <PrivateRoute path={"/seminar/detail/:unique_code"} component={SeminarDetail} />
            <PrivateRoute exact path={"/management_column"} component={ManagementColumn} />
            <PrivateRoute path={"/management_column/detail/:unique_code"} component={ManagementColumnDetail} />
            <PrivateSuccesionRoute exact path={"/succession_column"} component={SuccessionColumn} />
            <PrivateSuccesionRoute exact path={"/succession_column/detail/:unique_code"} component={SuccessionColumnDetail} />            
            <PrivateSuccesionRoute exact path={"/succession_faq"} component={SuccessionFaq} />
            <PrivateRoute exact path={"/news"} component={News} />
            <PrivateRoute path={"/cmi/detail/:id"} component={CmiDetail} />
            <PrivateRoute path={"/news/detail/:id"} component={NewsDetail} />
            <PrivateRoute path={"/settings"} component={Settings} />
            <AppointmentRoute exact path={"/appointment"} component={Appointment} />
            <AppointmentRoute exact path={"/appointment/edit/:appointment_id"} component={Appointment} />
            <AppointmentRoute exact path={"/appointment/date_select"} component={DateSelect} />
            <AppointmentRoute exact path={"/appointment/information_register"} component={InformationRegister} />
            <AppointmentRoute exact path={"/appointment/confirm"} component={Confirm} />
            <AppointmentRoute exact path={"/appointment/complete/:id"} component={Complete} />
            <AppointmentRoute exact path={"/appointment/detail_guest/:appointment_id"} component={AppointmentGuestDetail} />
            <PrivateAppointmentRoute exact path={"/appointment/detail/:appointment_id"} component={AppointmentDetail} />
            <Route exact path={"/page/cookie_policy"} component={CookiePolicy} />
            <Route exact path={"/page/rule"} component={Rule} />
            <Route path={"/login"} component={Login} />
            <Route exact path={"/404"} component={NotFound} />
            <Route component={NotFound} />
        </Switch>
    </BrowserRouter>
  );
};

export default Router;
