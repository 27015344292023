import React, {useEffect} from "react";
import { Redirect } from 'react-router-dom';
import { useReactOidc } from '@axa-fr/react-oidc-context';

import KtbaBeforeLogin from '../components/ktba/BeforeLogin';
import OkbaBeforeLogin from '../components/okba/BeforeLogin';
import AfterLogin from '../components/AfterLogin';
import { useBankContext } from "../util/BankContext";
import AlertIEDialog from "../components/AlertIEDialog";

export default function Index(props) {
    const bank = useBankContext();

    const { oidcUser } = useReactOidc();
    const ba_user_type = oidcUser?.profile?.ba_user_type ?? "";

    window.document.title = 'TOP|' + bank.baPortalName;

    useEffect(() => {
        if (bank.canUseGA) {
            if (!!window.gtag) {
                window.gtag('config', bank.gaTrackingCode, {
                    'page_path': `${props.location.pathname}${props.location.search}`
                });
            }
        }
    }, [bank, props]);

    return (
          <>
            {oidcUser && (
              (ba_user_type === "system_admin" ||
               ba_user_type === "bank_admin" ||
               ba_user_type === "branch_admin" ||
               ba_user_type === "branch_user"
              ) ? (
                <Redirect to="/admin/appointment_list" />
              ) : (
                <AfterLogin/>
              )
            )}
            {!oidcUser && (
              <>
                {bank.realm === 'bank0158' && (
                  <KtbaBeforeLogin/>
                )}
                {bank.realm === 'bank0188' && (
                  <OkbaBeforeLogin/>
                )}
              </>
            )}
            <AlertIEDialog />
          </>
    );
}
