import React, {useCallback, useEffect} from 'react';
import { withRouter } from "react-router";
import { makeStyles } from '@material-ui/core/styles';
import {connect, useDispatch, useSelector} from "react-redux";
import { useReactOidc } from '@axa-fr/react-oidc-context';

import Api from "../util/api";
import { useBankContext } from "../util/BankContext";
import useSafeState from "../helpers/useSafeState";
import useUnmountRef from "../helpers/useUnmountRef";


const useStyles = makeStyles((theme) => ({
    unreadCount: {
        paddingRight: 5,
        lineHeight: "35px",
    },
    nonDecorationLink: {
        color: "rgba(0, 0, 0, 0.87) !important",
        cursor: "pointer",
        textDecoration: "none",
    },
    expandIcon: {
        position: "absolute",
        top: 23,
    },
    expandList: {
        paddingLeft: 30,
    },
}));

const useIsEdit = () => {
    const dispatch = useDispatch();
    const setIsEdit = (isEdit) =>
        dispatch({
            type: 'SET_IS_EDIT',
            isEdit: isEdit,
        });
    return { setIsEdit }
}

const useChatUnreadCount = () => {
    const chatUnreadCount = useSelector((state) => state.chatUnreadCount);
    const dispatch = useDispatch();
    const setChatUnreadCount = (chatUnreadCount) =>
        dispatch({
            type: 'SET_CHAT_UNREAD_COUNT',
            chatUnreadCount: chatUnreadCount,
        });
    return { chatUnreadCount, setChatUnreadCount }
}

const MainListItems = (props) => {
    const unmountRef = useUnmountRef();
    const classes = useStyles();

    const { setIsEdit } = useIsEdit();
    const { chatUnreadCount, setChatUnreadCount } = useChatUnreadCount();
    const { oidcUser, logout } = useReactOidc();
    const { profile } = oidcUser;
    const [openKeiei, setOpenKeiei] = useSafeState(unmountRef, false);
    const bank = useBankContext();
    const [canUseChat, setCanUseChat] = useSafeState(unmountRef, false);
    const [openBS, setOpenBS] = useSafeState(unmountRef, false);

    const handleToggle = () => {
        if (!!props.isResponsive) {
          window.document.querySelector('body').classList.remove('no-scroll');
        }
    };
    
    const [confirmDialog, setConfirmDialog] = useSafeState(unmountRef, {});
    const handleConfirmDialog = (options) => {
        handleToggle();
        const baseAttr = {
            open: true,
            message: <>ここからは外部サービス「{options.title}」へ遷移します。<br />よろしいですか？</>,
            positiveSub: "(外部サービスを開く)",
            negativeSub: "(この画面を閉じる)",
            onPositive: () => {
                window.open(options.url, "_blank");
            }
        };
        setConfirmDialog({...baseAttr, 
            handleClose: () => {
                setConfirmDialog({...baseAttr, open: false})
            },
        });
    }

    const clickBalanceLink = () => {
        const bizsolLimitedFunctionKbn = Number(profile.bizsol_limited_function_kbn);
        // profile.bizsol_onetime_keyのカウントアップ
        let bizsol_onetime_key_count = Number(sessionStorage.getItem("bizsol_onetime_key_count")) !== 0 ? Number(sessionStorage.getItem("bizsol_onetime_key_count")) : 0;
        bizsol_onetime_key_count++;
        sessionStorage.setItem("bizsol_onetime_key_count", bizsol_onetime_key_count);

        Api.get(
            bank.realm,
            process.env.REACT_APP_ANSER_BIZ_SOL_BASE_URL + '/redirect',
            {
                o: bizsol_onetime_key_count,
                s: profile.bizsol_session_key,
                a: profile.bizsol_user_authentication_code,
                b: profile.bizsol_access_token,
                i: profile.bizsol_sub,
            },
            {},
            oidcUser,
        )
            .then((result) => {
                if (result.location.toString() === '') {
                    alert("ブラウザの「閉じる」ボタンを押して、ブラウザを閉じた後、再度ログインしてください。");
                    logout();
                } else {
                    if (bizsolLimitedFunctionKbn === 1 || bizsolLimitedFunctionKbn === 2) {
                        window.open(result.location + "&smart=b", "_blank");
                    } else {
                        window.open(result.location, "_blank");
                    }
                }
            });
    }

    const clickWebApplyLink = () => {
        const bizsolLimitedFunctionKbn = Number(profile.bizsol_limited_function_kbn);
        // profile.bizsol_onetime_keyのカウントアップ
        let bizsol_onetime_key_count = Number(sessionStorage.getItem("bizsol_onetime_key_count")) !== 0 ? Number(sessionStorage.getItem("bizsol_onetime_key_count")) : 0;
        bizsol_onetime_key_count++;
        sessionStorage.setItem("bizsol_onetime_key_count", bizsol_onetime_key_count);

        Api.get(
            bank.realm,
            process.env.REACT_APP_ANSER_BIZ_SOL_BASE_URL + '/redirect',
            {
                o: bizsol_onetime_key_count,
                s: profile.bizsol_session_key,
                a: profile.bizsol_user_authentication_code,
                b: profile.bizsol_access_token,
                i: profile.bizsol_sub,
                d: 33
            },
            {},
            oidcUser,
        )
            .then((result) => {
                if (result.location.toString() === '') {
                    alert("ブラウザの「閉じる」ボタンを押して、ブラウザを閉じた後、再度ログインしてください。");
                    logout();
                } else {
                    if (bizsolLimitedFunctionKbn === 1 || bizsolLimitedFunctionKbn === 2) {
                        window.open(result.location + "&smart=b", "_blank");
                    } else {
                        window.open(result.location, "_blank");
                    }
                }
            });
    }

    const clickAppointmentLink = () => {
        handleToggle();
        setIsEdit(false);
        props.history.push("/appointment");
    }

    const handleClickKeiei = () => {
        setOpenKeiei(!openKeiei);
    }

    const handleClickBS = () => {
        setOpenBS(!openBS);
    }

    const clickAccountSettingLink = () => {
        const bizsolLimitedFunctionKbn = Number(profile.bizsol_limited_function_kbn);
        Api.get(
            bank.realm,
            process.env.REACT_APP_ANSER_BIZ_SOL_BASE_URL + '/redirect',
            {
                o: profile.bizsol_onetime_key,
                s: profile.bizsol_session_key,
                a: profile.bizsol_user_authentication_code,
                b: profile.bizsol_access_token,
                i: profile.bizsol_sub,
                d: 10,
            },
            {},
            oidcUser,
        )
            .then((result) => {
                if (result.location.toString() === '') {
                    alert("ブラウザの「閉じる」ボタンを押して、ブラウザを閉じた後、再度ログインしてください。");
                    logout();
                } else {
                    if (bizsolLimitedFunctionKbn === 1 || bizsolLimitedFunctionKbn === 2) {
                        window.open(result.location + "&smart=b", "_blank");
                    } else {
                        window.open(result.location, "_blank");
                    }
                }
            });
    }

  const logoutAction = useCallback(() => {
    logout();
  }, [logout]);

    useEffect(() => {
        const fetchData = () => {
            Api.get(
                bank.realm,
              process.env.REACT_APP_BA_CHAT_BASE_URL + '/is_permitted',
              {},
              {},
              oidcUser
            )
              .then((result) => {
                if (!!result && !!result.isPermitted) {
                  // チャットを利用可能にする
                  setCanUseChat(true);

                  // ルーム一覧取得API
                  Api.get(bank.realm, process.env.REACT_APP_BA_CHAT_BASE_URL + '/rooms', {}, {}, oidcUser)
                    .then((result) => {
                      let rooms = result?.rooms;

                      if (!rooms) {
                        // ルーム初期化
                        Api.post(bank.realm, process.env.REACT_APP_BA_CHAT_BASE_URL + '/rooms/initialize', {}, {}, oidcUser)
                          .then((result) => {
                            if (!!result && result.status !== 500) {
                              // ルーム一覧取得API
                              Api.get(bank.realm, process.env.REACT_APP_BA_CHAT_BASE_URL + '/rooms', {}, {}, oidcUser)
                                .then((result) => {
                                  rooms = result?.rooms;

                                  // BAのチャットにアクセスし、エラーだったユーザーはチャットが使えない
                                  setCanUseChat(!!rooms);
                                });
                            }
                          });
                      }

                      // グローバルメニュー用未読バッジ処理
                      let chatUnreadCount = 0;

                      // ルーム初期化やルーム取得で失敗していてもエラーにならないように対策
                      if (!!rooms) {
                        Object.keys(rooms).forEach((key) => {
                          chatUnreadCount += rooms[key].UnreadCountByCompany;
                        });
                      }

                      setChatUnreadCount(chatUnreadCount);
                    });
                } else {
                  // チャットを利用不可能にする
                  setCanUseChat(false);
                }
              });
        };

        if (!oidcUser) {
            return;
        }

        fetchData();
    }, [bank, oidcUser, setCanUseChat, setChatUnreadCount]);

    const component = require(`./${bank.staticFilePathPrefix}/MenuList`).default;
    return React.createElement(component,
        {
            oidcUser: oidcUser,
            classes: classes, 
            profile: profile, 
            handleToggle: handleToggle,
            confirmDialog: confirmDialog,
            handleConfirmDialog: handleConfirmDialog,
            bank: bank,
            clickBalanceLink: clickBalanceLink,
            clickWebApplyLink: clickWebApplyLink,
            canUseChat: canUseChat,
            chatUnreadCount: chatUnreadCount,
            clickAppointmentLink: clickAppointmentLink,
            handleClickKeiei: handleClickKeiei,
            handleClickBS: handleClickBS,
            openKeiei: openKeiei,
            openBS: openBS,
            isResponsive: props.isResponsive,
            logoutAction: logoutAction,
            clickAccountSettingLink: clickAccountSettingLink,
        });
}

export default connect(state => state)(withRouter(MainListItems));
