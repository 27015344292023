import React from "react";

import { useBankContext } from "../util/BankContext";

const AfterLoginTopAbout = (props) => {
    const bank = useBankContext();

    return (
        <div className="about-site">
            <p className="about-site-title">{bank.baPortalName}で、できること</p>
            <ul>
                {bank.realm === 'bank0158' && (
                    <li>
                        <img src={`/img/${bank.staticFilePathPrefix}/balance_icon.png`} alt="" />
                        <p className="title">残高・明細照会</p>
                        {bank.realm === 'bank0158' ? (                            
                            <p className="body">いつでも、簡単に残高や直近1週間分の明細をご確認いただけます。</p>
                            ) : (
                            <p className="body">いつでも気軽に残高や明細をご確認いただけます。</p>
                        )}
                    </li>
                )}
                {bank.canUseAppointment && (
                    <li>
                        <img src={`/img/${bank.staticFilePathPrefix}/visit_icon.png`} alt="" />
                        <p className="title">来店予約</p>
                        {bank.realm === 'bank0158' ? (
                            <p className="body">事前に来店のご予約をいただくことで、待ち時間なしで、すぐにご案内いたします。</p>
                            ) : (
                            <p className="body">各支店への来店を事前に予約することで待ち時間なしでご案内いたします。</p>
                        )}
                    </li>
                )}
                <li>
                    <img src={`/img/${bank.staticFilePathPrefix}/chat_icon.png`} alt="" />
                    <p className="title">チャット</p>
                    {bank.realm === 'bank0158' ? (
                        <p className="body">お取引店の担当者との連絡手段として、チャットをご利用いただけます。</p>
                    ) : (
                        <p className="body">支店、運営事務局とチャットで気軽にやりとりができます。</p>
                    )}
                </li>
                <li>
                    <img src={`/img/${bank.staticFilePathPrefix}/denshi_icon.png`} alt="" />
                    <p className="title">電子交付サービス</p>
                    <p className="body">紙の郵送物に替えて電子帳票で受け取れるサービスです。<br />パソコン上で電子帳票の確認・保存ができます。（ペーパレス）</p>
                </li>

                <li>
                    <img src={`/img/${bank.staticFilePathPrefix}/seminar_icon.png`} alt="" />
                    <p className="title">セミナー予約</p>
                    {bank.realm === 'bank0158' ? (
                        <p className="body">セミナー情報を配信。簡単にセミナーへの申込予約ができます。</p>
                    ) : (
                        <p className="body">{bank.msg.app.displayName}が主催するセミナーを確認し、オンラインで予約できます。</p>
                    )}
                    
                </li>
                <li>
                    <img src={`/img/${bank.staticFilePathPrefix}/column_icon.png`} alt="" />
                    <p className="title">経営コラム</p>
                    <p className="body">専門家が執筆した経営コラムを定期的に更新しています。</p>
                </li>
            </ul>
        </div>
    )
};

export default AfterLoginTopAbout;
