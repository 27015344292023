import moment from "moment";
import 'moment/locale/ja';


const FindADayFromSundayOfTheWeek = (date) => {
  moment.locale('ja');
  let dayNum = date.getDay();

  return moment(date).subtract(dayNum, 'days');
}

export default FindADayFromSundayOfTheWeek;
