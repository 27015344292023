import React, {useEffect, createRef, useCallback, useRef} from "react";
import { useDispatch } from "react-redux";
import axios from "axios";
import * as Sentry from "@sentry/react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import clsx from "clsx";
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import PersonIcon from '@material-ui/icons/Person';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Badge from "@material-ui/core/Badge";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import moment from "moment";
import 'moment/locale/ja';
import { useReactOidc } from '@axa-fr/react-oidc-context';
import CircularProgress from '@material-ui/core/CircularProgress';

import Header from "../../components/Header";
import LeftColumn from "../../components/LeftColumn";
import Api from "../../util/api";
import { useBankContext } from "../../util/BankContext";
import useSafeState from "../../helpers/useSafeState";
import useUnmountRef from "../../helpers/useUnmountRef";


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        '& table': {
            border: "1px solid #000",
            borderCollapse: "collapse",
            width: "100%",
        },
        '& tr': {
            height: "50px",
        },
        '& th': {
            background: "#E8E8E8",
        },
        '& td': {
            textAlign: "center",
        },
    },
    content: {
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
    },
    title: {
        backgroundColor: theme.palette.text.primary,
        marginTop: 0,
        padding: "10px 8px",
        '& p': {
            color: theme.palette.background.default,
        },
    },
    spTitle: {
        padding: "10px 8px",
        position: "relative",
        '& span': {
            color: "#19AAC4",
            display: "inline-block",
            paddingLeft: 20,
            '&:before': {
                borderRight: "2px solid #19aac4",
                borderTop: "2px solid #19aac4",
                bottom: 0,
                content: '""',
                height: 6,
                left: 16,
                margin: "auto",
                position: "absolute",
                top: "-2px",
                transform: "rotate(225deg)",
                width: 6,
            },
        },
    },
    parentDiv: {
        display: "flex",
    },
    circularProgress: {
        left: 110,
        position: "absolute",
        top: 50,
    },
    parentDiv3: {
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
    },
    roomListDiv: {
        borderRight: "1px solid #ddd",
        height: "calc(100vh - 102px)",
        overflowX: "hidden",
        overflowY: "auto",
        position: "relative",
        width: 260,
        [theme.breakpoints.down('xs')]: {
            backgroundColor: "#fff",
            width: "100%",
        }
    },
    roomListUl: {
        padding: 0,
        position: "relative",
    },
    roomList: {
        borderBottom: "1px solid #f5f5f5",
        cursor: "pointer",
        display: "flex",
        justifyContent: "space-between",
        paddingRight: 12,
        '&:hover': {
            background: "#edeff2",
        },
        '&.active': {
            background: "#D8EDF6",
        },
    },
    roomListSpan: {
        display: "flex",
        padding: 12,
    },
    unreadCount: {
        paddingRight: 10,
    },
    chatDiv: {
        [theme.breakpoints.up('sm')]: {
            background: "#edeff2",
            display: "flex",
            flexDirection: "column",
            flexGrow: 1,
            height: "100%",
            width: "calc(100% - 260px)",
        },
        [theme.breakpoints.down('xs')]: {
            width: "100%",
        },
    },
    roomRemarks: {
        background: "#edeff2",
        height: "calc(100vh - 366px)",
        overflowY: "auto",
        position: "relative",
    },
    moreView: {
        marginBottom: 10,
        marginTop: 10,
        textAlign: "center",
    },
    remarksBox: {
        display: "flex",
        marginLeft: 20,
        marginTop: 20,
    },
    personIconMyRemark: {
        background: "#f9f9f9",
        borderRadius: 4,
        color: "#ddd",
        fontSize: 35,
        marginRight: 10,
    },
    personIconAnotherRemark: {
        background: "#D0E9F4",
        borderRadius: 4,
        color: "#fff",
        fontSize: 35,
        marginRight: 10,
    },
    remark: {
        marginRight: 20,
        width: "100%",
    },
    remarksInfo: {
        alignItems: "center",
        display: "flex",
        justifyContent: "space-between",
        marginRight: 4,
    },
    creatorName: {
        color: "#436475",
        fontWeight: "bold",
        lineHeight: "1.41",
        marginRight: 12,
    },
    createdAt: {
        color: "#5a6475",
        fontFamily: "'Noto Sans Japanese', sans-serif",
        fontSize: 10,
        lineHeight: "1.41",
    },
    remarksBody: {
        background: "#fff",
        borderRadius: 12,
        marginTop: 10,
        padding: "6px 12px",
        whiteSpace: "pre-wrap",
    },
    attachmentFiles: {
    },
    notSyncFile: {
        color: "#666",
        fontSize: 12,
        marginTop: 4,
    },
    linkText: {
        color: "#19AAC4",
        cursor: "pointer",
        textDecoration: "underline",
        '&:hover': {
            textDecoration: "none",
        },
    },
    chatForm: {
        background: "#edeff2",
        bottom: "auto",
        left: 0,
        lineHeight: "20px",
        padding: 10,
        position: "relative",
        right: 0,
    },
    scrollBtnArea: {
        position: "absolute",
        right: 0,
        textAlign: "right",
        width: "auto",
        zIndex: 2,
    },
    scrollBtn: {
        background: "#aabdc9",
        borderRadius: 8,
        cursor: "pointer",
        display: "inline-block",
        fontSize: 30,
        height: 34,
        lineHeight: "34px",
        marginRight: 32,
        overflow: "hidden",
        position: "relative",
        top: -60,
        width: 34,
    },
    arrowDownwardIcon: {
        color: "#fff",
        height: "100%",
        width: "100%",
    },
    formArea: {
    },
    supportPageText: {
        border: "1px solid #19AAC4",
        borderBottom: "none",
        borderRadius: 0,
        fontFamily: "'Noto Sans Japanese', sans-serif",
        fontSize: 14,
        height: 200,
        lineHeight: "1.41em",
        margin: 0,
        maxHeight: "66vh",
        minHeight: 40,
        padding: "10px 14px",
        resize: "none",
        width: "100%",
        '&::placeholder': {
            color: "#757575",
        }
    },
    fileAndSubmit: {
        alignItems: "center",
        background: "#f9f9f9",
        border: "1px solid #19AAC4",
        display: "flex",
        height: 50,
        justifyContent: "space-between",
        marginTop: -10,
        padding: "6px 10px",
    },
    fileArea: {
        height: 27,
        width: "84%",
        [theme.breakpoints.down('xs')]: {
            width: "82%",
        },
    },
    fileLabel: {
        display: "flex",
    },
    attachFileIcon: {
        height: 27,
    },
    attachmentFileBtn: {
    },
    templateArea: {
        height: 27,
    },
    templateLabel: {
        cursor: 'pointer',
        display: "flex",
    },
    template: {
        lineHeight: "27px",
    },
    templateSelector: {
        background: "none",
        border: "1px solid #ddd",
        borderRadius: 8,
        bottom: 70,
        boxShadow: "0 4px 6px 0px rgba(0, 0, 0, 0.3)",
        minWidth: 300,
        overflow: "hidden",
        position: "absolute",
        width: "auto",
    },
    templateSelectorHeader: {
        height: 24,
        textAlign: "right",
    },
    templateSelectorCloseButton: {
        cursor: "pointer",
    },
    templateSelectorContentList: {
        height: 36,
    },
    templateSelectorContent: {
        alignItems: "center",
        color: "#1c1d1c",
        display: "flex",
        height: "100%",
        padding: "6px 10px",
        textDecoration: "none",
        '&:hover': {
            background: "#f5f5f5",
            cursor: "pointer",
        }
    },
    submitButton: {
        background: "#f46060",
        border: "none",
        borderRadius: 4,
        color: "#fff",
        cursor: "pointer",
        fontSize: 14,
        padding: "4px 10px",
        textDecoration: "none",
        width: "16%",
        [theme.breakpoints.down('xs')]: {
            width: 48,
        },
        '&:focus': {
            background: "#f13030 !important",
            outline: "none",
        },
    },
    disabledSubmitButton: {
        border: "none",
        borderRadius: 4,
        fontSize: 14,
        padding: "4px 10px",
        textDecoration: "none",
        width: "16%",
        [theme.breakpoints.down('xs')]: {
            width: 48,
        },
    },
    cancelButton: {
        border: "none",
        borderRadius: 4,
        cursor: "pointer",
        fontSize: 14,
        textDecoration: "none",
        padding: "4px 10px",
        whiteSpace: "nowrap",
        marginLeft: "0.5rem",
        marginRight: "0.5rem",
        [theme.breakpoints.down('xs')]: {
            width: 120,
        },
        '&:focus': {
            background: "#f5f5f5",
            outline: "none",
        },
    },
    smallCircularProgress: {
        marginTop: "6px",
    },
    footer: {
        marginTop: "auto",
    },
}));

const RoomList = ({ classes, roomUniqueCode, roomObjects, active, handleRoomListClick }) => {
    const selected = (active ? 'active': '');
    const roomList = clsx(classes.roomList, selected);

    return (
        <li className={roomList} onClick={handleRoomListClick}>
            <span className={classes.roomListSpan}>
                {roomObjects[roomUniqueCode] !== undefined && (
                    <Badge badgeContent={roomObjects[roomUniqueCode].UnreadCountByCompany} color="error" className={classes.unreadCount}>
                        <Typography variant="body2" color="textSecondary">
                            {roomObjects[roomUniqueCode].title}
                        </Typography>
                    </Badge>
                )}
            </span>
        </li>
    )
}

const FileAttachment = ({ classes, file }) => {
    return (
        <>
            {file.isDeleted && (
                <span>
                    {file.originalName} ({file.size/1000} KB) ※削除済み
                </span>
            )}
            {!file.isSynced && (
                <span>
                    {file.originalName} ({file.size/1000} KB)
                    <p className={classes.notSyncFile}>※同期が完了したら添付が反映されます。反映にはページの更新が必要です。</p>
                </span>
            )}
            {!file.isDeleted && file.isSynced && (
                <a href={file.downloadUrl} className={classes.linkText}>
                    {file.originalName} ({file.size/1000} KB)
                </a>
            )}
        </>
    )
};

const useChatUnreadCount = () => {
    const dispatch = useDispatch();
    const setChatUnreadCount = (chatUnreadCount) =>
        dispatch({
            type: 'SET_CHAT_UNREAD_COUNT',
            chatUnreadCount: chatUnreadCount,
        });
    return { setChatUnreadCount }
}

const BaChat = (props) => {
    const unmountRef = useUnmountRef();
    const matches = useMediaQuery('(min-width:600px)');
    const bank = useBankContext();
    window.document.title = 'チャット|' + bank.baPortalName;
    moment.locale('ja');
    const classes = useStyles();
    const { setChatUnreadCount } = useChatUnreadCount();
    const [activeIndex, setActiveIndex] = useSafeState(unmountRef, -1);
    const [roomList, setRoomList] = useSafeState(unmountRef, []);
    const [roomObjects, setRoomObjects] = useSafeState(unmountRef, {});
    const [remarkList, setRemarkList] = useSafeState(unmountRef, []);
    const [roomUniqueCode, setRoomUniqueCode] = useSafeState(unmountRef, '');
    const [getBefore, setGetBefore] = useSafeState(unmountRef, '');
    const [isLastPage, setIsLastPage] = useSafeState(unmountRef, false);
    const [chatroom, setChatroom] = useSafeState(unmountRef, null);
    const [chatRemarkBody, setChatRemarkBody] = useSafeState(unmountRef, '');
    const [attachmentFiles, setAttachmentFiles] = useSafeState(unmountRef, []);
    const [openError, setOpenError] = useSafeState(unmountRef, false);
    const remarksRef = createRef();
    const { oidcUser } = useReactOidc();
    const [isChatRoom, setIsChatRoom] = useSafeState(unmountRef, false);
    const [isLoadedRoomList, setIsLoadedRoomList] = useSafeState(unmountRef, false);
    const [isUploading, setIsUploading] = useSafeState(unmountRef, false);
    const [isPosting, setIsPosting] = useSafeState(unmountRef, false);
    const [cancelSource, setCancelSource] = useSafeState(unmountRef, null);

    const fileInputMobile = useRef();
    const fileInputDesktop = useRef();
    const isCommunicating = () => isPosting || isUploading;
    const clearFileInput = () => {
        setAttachmentFiles([]);
        if (fileInputMobile && fileInputMobile.current && fileInputMobile.current.value) {
            fileInputMobile.current.value = null;
        }
        if (fileInputDesktop && fileInputDesktop.current && fileInputDesktop.current.value) {
            fileInputDesktop.current.value = null;
        }
    }

    const backRoomList = () => {
        return props.history.push('/ba_chat');
    }

    const handleRoomListClick = (roomIndex, roomUniqueCode, event) => {
        setActiveIndex(roomIndex);
        setChatRemarkBody('');
        clearFileInput();

        return props.history.push('/chat_room/' + roomUniqueCode);
    }

    const moreViewRemark = async (getBefore, event) => {
        // 発言取得API
        const newRemarks = await Api.get(bank.realm, process.env.REACT_APP_BA_CHAT_BASE_URL + '/messages', {r: roomUniqueCode, m: getBefore}, {}, oidcUser);

        if (newRemarks === null || newRemarks.length < 1) {
            setGetBefore('');
            setIsLastPage(true);
        } else {
            setRemarkList(newRemarks.concat(remarkList));
            setGetBefore(newRemarks[0].id);
            setIsLastPage(false);
        }
    };

    const scrollBottom = useCallback(() => {
        if (remarksRef.current === null) {
            return;
        }

        remarksRef.current.scrollTop = remarksRef.current.scrollHeight;
    }, [remarksRef])

    const fileChanged = async (event) => {
        if (!roomObjects[roomUniqueCode].Permissions.attachFile) {
            return;
        }
        if (event.target.files.length === 0) {
            clearFileInput();
            return;
        }

        const cancelSource = axios.CancelToken.source();
        setCancelSource(cancelSource);
        setIsUploading(true);
 
        try {
            // ファイル一時アップロード
            const params = new FormData();
            params.append('file', event.target.files[0]);

            const attachmentFiles = await Api.postBodyParam(
                bank.realm,
                process.env.REACT_APP_BA_CHAT_BASE_URL + '/file',
                params,
                {
                    'content-type': 'multipart/form-data',
                },
                oidcUser,
                cancelSource,
            );
            if (attachmentFiles?.uniqueCode) {
                setAttachmentFiles(new Array(attachmentFiles.uniqueCode));
            }
        } catch(e) {
            clearFileInput();
            setOpenError(true);
            Sentry.captureException(e);
        }

        setCancelSource(null);
        setIsUploading(false);
    }

    const cancelFileUpload = async (e) => {
        e.preventDefault();
        if (cancelSource == null) return;

        cancelSource.cancel('upload canceled');
        clearFileInput();
    }

    const changeRemarkBody = (event) => {
        setChatRemarkBody(event.target.value);
    }

    const submitRemark = async (event) => {
        event.preventDefault();

        setIsPosting(true);

        try {
            // 発言登録API
            if (!roomObjects[roomUniqueCode].Permissions.attachFile) {
                clearFileInput();
            }
            const result = await Api.postBodyParam(
                bank.realm,
                process.env.REACT_APP_BA_CHAT_BASE_URL + '/message',
                {
                    room: roomUniqueCode,
                    body: chatRemarkBody,
                    files: attachmentFiles,
                },
                {},
                oidcUser,
            );
            if (result.status === 500) {
                setOpenError(true);
            }

            // 発言の再取得
            await loadRemarks(roomUniqueCode);

            clearFileInput();
            setChatRemarkBody('');
        } catch(e) {
            setOpenError(true);
            Sentry.captureException(e);
        }

        setIsPosting(false);
    }

    const loadRemarks = async (bachatroom_unique_code) => {
        // チャット発言取得API
        let remarks = await Api.get(bank.realm, process.env.REACT_APP_BA_CHAT_BASE_URL + '/messages', {r: bachatroom_unique_code}, {}, oidcUser);
        if (remarks === null || remarks.length < 1) {
            setRemarkList([]);
            setGetBefore('');
            setIsLastPage(true);
        } else {
            remarks.sort((a, b) => {
                if (a.createdAt === b.createdAt) {
                    return 0;
                } else if (a.createdAt > b.createdAt) {
                    return 1;
                } else {
                    return -1;
                }
            });
            setRemarkList(remarks);
            setGetBefore(remarks[0].id);
            setIsLastPage(false);
        }
    }

    const handleCloseError = (event) => {
        setOpenError(false);
    }

    useEffect(() => {
        if (!oidcUser) {
            return;
        }

        if (props.match.params.bachatroom_unique_code !== undefined) {
            setRoomUniqueCode(props.match.params.bachatroom_unique_code);
        }

        fetchData(
            props.match.params.bachatroom_unique_code,
        )
            .then(r => {
            })
            .catch(e => {
                // todo エラーハンドリング
                if( e.response ){
                    if( e.response.status === 403 ){
                        // todo 「ログインしてください」を表示
                        // todo ユーザー情報の削除
                        // location.href = "/";
                    }
                    //todo エラーであることを知らせる表示
                }
            })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props, oidcUser]);

    useEffect(() => {
        scrollBottom();
    }, [scrollBottom])

    useEffect(() => {
        if (bank.canUseGA) {
            window.gtag('config', bank.gaTrackingCode, {
                'page_path': `${props.location.pathname}${props.location.search}`
            });
        }
    }, [bank.canUseGA, bank.gaTrackingCode, props.location.pathname, props.location.search]);

    const fetchData = async (bachatroom_unique_code) => {
        // ルーム一覧取得API
        const rooms = await Api.get(bank.realm, process.env.REACT_APP_BA_CHAT_BASE_URL + '/rooms', {}, {}, oidcUser);

        if (rooms.rooms === null) {
            setRoomList([]);
        } else {
            setRoomList(rooms.uniqueCodeList);
        }
        setIsLoadedRoomList(true);
        setRoomObjects(rooms.rooms);

        // グローバルメニュー用未読バッジ処理
        let chatUnreadCount = 0;
        Object.keys(rooms.rooms).forEach((key) => {
            chatUnreadCount += rooms.rooms[key].UnreadCountByCompany;
        });
        setChatUnreadCount(chatUnreadCount);

        if (bachatroom_unique_code !== undefined) {
            setChatroom(rooms.rooms[bachatroom_unique_code]);
            setIsChatRoom(true);
        } else {
            setIsChatRoom(false);
        }

        if (bachatroom_unique_code !== undefined) {
            await loadRemarks(bachatroom_unique_code);
        } else {
            setRemarkList([]);
            setGetBefore('');
            setIsLastPage(false);
        }

        // 選択されたルーム
        setActiveIndex(rooms.uniqueCodeList.findIndex((element) => element === bachatroom_unique_code));
    }

    return (
        <div className="wrapper">
            <Header />
            <div className="content">
                {(!!oidcUser && matches) && (
                    <LeftColumn />
                )}
                <div className="main">
                    {matches && (
                        <Box className={classes.title}>
                            <Typography variant="body2" color="textSecondary">
                                チャット
                            </Typography>
                        </Box>
                    )}
                    {!matches && !!isChatRoom && (
                        <Box className={classes.spTitle}>
                            <span onClick={backRoomList}>
                                一覧へ戻る
                            </span>
                        </Box>
                    )}
                    <div className={classes.parentDiv}>
                        {!matches && !isChatRoom && (
                            <div className={classes.roomListDiv}>
                                {!isLoadedRoomList && (
                                    <CircularProgress className={classes.circularProgress} />
                                )}
                                <ul className={classes.roomListUl}>
                                    {roomList.map((roomUniqueCode, roomIndex) => (
                                        <RoomList key={roomUniqueCode} classes={classes} roomUniqueCode={roomUniqueCode} roomObjects={roomObjects} active={activeIndex === roomIndex} handleRoomListClick={handleRoomListClick.bind(this, roomIndex, roomUniqueCode)} />
                                    ))}
                                </ul>
                            </div>
                        )}
                        {!matches && !!isChatRoom && (
                            <div className={classes.chatDiv}>
                                <div className={classes.roomRemarks} ref={remarksRef}>
                                    {remarkList.length !== 0 && (
                                        <>
                                            <div>
                                                {remarkList.map((remark, remarkIndex) => (
                                                <div key={remarkIndex} className={classes.remarksBox}>
                                                    <PersonIcon className={remark.isMyMessage ? classes.personIconMyRemark : classes.personIconAnotherRemark} />
                                                    <div className={classes.remark}>
                                                        <div className={classes.remarksInfo}>
                                                            <span className={classes.creatorName}>{remark.senderName}</span>
                                                            <p className={classes.createdAt}>{moment(remark.createdAt).format('YYYY年M月D日(dd) HH:mm')}</p>
                                                        </div>
                                                        {remark.isDeleted && (
                                                            <div className={classes.remarksBody}>
                                                                (発言が削除されました)
                                                            </div>
                                                        )}
                                                        {!remark.isDeleted && (
                                                            <div className={classes.remarksBody}>
                                                                <span dangerouslySetInnerHTML={{__html : remark.body}} />
                                                                <div className={classes.attachmentFiles}>
                                                                    {remark.fileAttachments !== null && (
                                                                    remark.fileAttachments.map((file) => (
                                                                        <FileAttachment key={file.uniqueCode} classes={classes} file={file} />
                                                                    ))
                                                                    )}
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                                ))}
                                            </div>
                                            {!isLastPage && (
                                                <div className={classes.moreView}>
                                                <span className={classes.linkText} onClick={moreViewRemark.bind(this, getBefore)}>さらに過去の投稿を表示</span>
                                                </div>
                                            )}
                                            {isLastPage && (
                                                <div className={classes.moreView}>
                                                <span>すべての投稿が表示されました</span>
                                                </div>
                                            )}
                                        </>
                                    )}
                                </div>
                                {chatroom !== null && (
                                    <form className={classes.chatForm} onSubmit={submitRemark}>
                                        <div className={classes.scrollBtnArea} onClick={scrollBottom}>
                                            <span className={classes.scrollBtn}>
                                                <ArrowDownwardIcon className={classes.arrowDownwardIcon} />
                                            </span>
                                        </div>
                                        <div className={classes.formArea}>
                                            <textarea value={chatRemarkBody} className={classes.supportPageText} placeholder={"ここにメッセージを入力してください"} required onChange={changeRemarkBody} />
                                            <div className={classes.fileAndSubmit}>
                                                {roomObjects[roomUniqueCode]?.Permissions.attachFile && (
                                                    <div className={classes.fileArea}>
                                                        <label className={classes.fileLabel}>
                                                        <AttachFileIcon className={classes.attachFileIcon} />
                                                        <input id="fileInput" type="file" onChange={fileChanged} ref={fileInputMobile} disabled={isCommunicating()} className={classes.attachmentFileBtn} />
                                                            {isUploading && <>
                                                                <CircularProgress className={classes.smallCircularProgress} size="1rem"/>
                                                                <button onClick={cancelFileUpload} className={classes.cancelButton}>キャンセル</button>
                                                            </>}
                                                         </label>
                                                    </div>
                                                )}
                                                <button id="submitButton" className={isCommunicating() ? classes.disabledSubmitButton : classes.submitButton} disabled={isCommunicating()}>送信</button>
                                            </div>
                                        </div>
                                    </form>
                                )}
                            </div>
                        )}
                        {matches && (
                            <>
                                <div className={classes.roomListDiv}>
                                    {!isLoadedRoomList && (
                                        <CircularProgress className={classes.circularProgress} />
                                    )}
                                    <ul className={classes.roomListUl}>
                                        {roomList.map((roomUniqueCode, roomIndex) => (
                                        <RoomList key={roomUniqueCode} classes={classes} roomUniqueCode={roomUniqueCode} roomObjects={roomObjects} active={activeIndex === roomIndex} handleRoomListClick={handleRoomListClick.bind(this, roomIndex, roomUniqueCode)} />
                                        ))}
                                    </ul>
                                </div>
                                <div className={classes.chatDiv}>
                                    <div className={classes.roomRemarks} ref={remarksRef}>
                                        {remarkList.length !== 0 && (
                                          <>
                                              <div>
                                                  {remarkList.map((remark, remarkIndex) => (
                                                    <div key={remarkIndex} className={classes.remarksBox}>
                                                        <PersonIcon className={remark.isMyMessage ? classes.personIconMyRemark : classes.personIconAnotherRemark} />
                                                        <div className={classes.remark}>
                                                            <div className={classes.remarksInfo}>
                                                                <span className={classes.creatorName}>{remark.senderName}</span>
                                                                <p className={classes.createdAt}>{moment(remark.createdAt).format('YYYY年M月D日(dd) HH:mm')}</p>
                                                            </div>
                                                            {remark.isDeleted && (
                                                              <div className={classes.remarksBody}>
                                                                  (発言が削除されました)
                                                              </div>
                                                            )}
                                                            {!remark.isDeleted && (
                                                              <div className={classes.remarksBody}>
                                                                  <span dangerouslySetInnerHTML={{__html : remark.body}} />
                                                                  <div className={classes.attachmentFiles}>
                                                                      {remark.fileAttachments !== null && (
                                                                        remark.fileAttachments.map((file) => (
                                                                          <FileAttachment key={file.uniqueCode} classes={classes} file={file} />
                                                                        ))
                                                                      )}
                                                                  </div>
                                                              </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                  ))}
                                              </div>
                                              {!isLastPage && (
                                                <div className={classes.moreView}>
                                                    <span className={classes.linkText} onClick={moreViewRemark.bind(this, getBefore)}>さらに過去の投稿を表示</span>
                                                </div>
                                              )}
                                              {isLastPage && (
                                                <div className={classes.moreView}>
                                                    <span>すべての投稿が表示されました</span>
                                                </div>
                                              )}
                                          </>
                                        )}
                                    </div>
                                    {chatroom !== null && (
                                      <form className={classes.chatForm} onSubmit={submitRemark}>
                                          <div className={classes.scrollBtnArea} onClick={scrollBottom}>
                                            <span className={classes.scrollBtn}>
                                                <ArrowDownwardIcon className={classes.arrowDownwardIcon} />
                                            </span>
                                          </div>
                                          <div className={classes.formArea}>
                                              <textarea value={chatRemarkBody} className={classes.supportPageText} placeholder={"ここにメッセージを入力してください"} required onChange={changeRemarkBody} />
                                              <div className={classes.fileAndSubmit}>
                                                  {roomObjects[roomUniqueCode]?.Permissions.attachFile && (
                                                    <div className={classes.fileArea}>
                                                        <label className={classes.fileLabel}>
                                                            <AttachFileIcon className={classes.attachFileIcon} />
                                                            <input id="fileInput" type="file" onChange={fileChanged} ref={fileInputDesktop} disabled={isCommunicating()} className={classes.attachmentFileBtn} />
                                                            {isUploading && <>
                                                                <CircularProgress className={classes.smallCircularProgress} size="1rem"/>
                                                                <button onClick={cancelFileUpload} className={classes.cancelButton}>キャンセル</button>
                                                            </>}
                                                        </label>
                                                    </div>
                                                  )}
                                                  <button id="submitButton" className={isCommunicating() ? classes.disabledSubmitButton : classes.submitButton} disabled={isCommunicating()}>送信</button>
                                              </div>
                                          </div>
                                      </form>
                                    )}
                                </div>
                            </>
                        )}
                        <Snackbar open={openError} autoHideDuration={6000} onClose={handleCloseError}>
                            <MuiAlert elevation={6} variant="filled" onClose={handleCloseError} severity="error">
                                エラーが発生しました
                            </MuiAlert>
                        </Snackbar>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BaChat;