import React, { useEffect } from "react";
import moment from "moment";
import 'moment/locale/ja';
import { Link } from "react-router-dom";
import { useReactOidc } from '@axa-fr/react-oidc-context';

import Api from "../util/api";
import { useBankContext } from "../util/BankContext";
import useSafeState from "../helpers/useSafeState";
import useUnmountRef from "../helpers/useUnmountRef";


const AfterLoginTopNews = (props) => {
    const unmountRef = useUnmountRef();
    moment.locale('ja');
    // ご案内一覧
    const [cmiList, setCmiList] = useSafeState(unmountRef, []);
    // お知らせ一覧
    const [newsList, setNewsList] = useSafeState(unmountRef, []);
    const { oidcUser } = useReactOidc();
    const bank = useBankContext();

    useEffect( () => {
        const fetchData = async () => {
          // ご案内一覧取得API
          const informationList = await Api.get(props.realm, process.env.REACT_APP_INFORMATION_BASE_URL + '/information/search', {r: bank.params.top.informationListRows, p: 1}, {}, oidcUser);

          if (informationList.informationList === null) {
            setCmiList([]);
          } else {
            setCmiList(informationList.informationList);
          }

          // お知らせ一覧取得API
          const newsList = await Api.get(props.realm, process.env.REACT_APP_NEWS_BASE_URL + '/news/search', {r: bank.params.top.newsListRows, p: 1}, {}, oidcUser);

          if (newsList.newsList === null) {
            setNewsList([]);
          } else {
            setNewsList(newsList.newsList);
          }
        };

        fetchData()
            .then()
            .catch(e => {
                // todo エラーハンドリング
                if( e.response ){
                    if( e.response.status === 403 ){
                        // todo 「ログインしてください」を表示
                        // todo ユーザー情報の削除
                        // location.href = "/";
                    }
                    //todo エラーであることを知らせる表示
                }
            });
    }, [bank, oidcUser, props, setCmiList, setNewsList]);

    return (
        <div className="information">
            <div className="guidance">
                <h2 className="title-icon guidance-title">ご案内</h2>
                <ul className="guidance-items">
                    {cmiList.map((cmi) => (
                        <li key={cmi.id}>
                            <span className="date">{moment(cmi.notifiedAt).format('YYYY年M月D日(dd)')}</span>
                            <p><Link to={"/cmi/detail/" + cmi.id} >{cmi.title}</Link></p>
                        </li>
                    ))}
                </ul>
                <Link to={"/news"} className="btn-more key-color">
                    すべてのご案内を見る
                </Link>
            </div>
            {/*.guidance*/}

            <div className="notice">
                <h2 className="title-icon notice-title">運営からのお知らせ</h2>
                <ul>
                    {newsList.map((news) => (
                        <li key={news.id}>
                            <span className="date">{moment(news.publishedDate).format('YYYY年M月D日(dd)')}</span>
                            <p><Link to={"/news/detail/" + news.id}>{news.title}</Link></p>
                        </li>
                    ))}
                </ul>
                <Link to={"/news"} className="btn-more key-color">
                    すべてのお知らせを見る
                </Link>
            </div>
            {/*.notice*/}
        </div>
    )
};

export default AfterLoginTopNews;
