import React from 'react';
import { Link } from "react-router-dom";
import { useHistory, useLocation } from 'react-router-dom';


const AppointmentBreadcrumbs = (props) => {
    let params = props.params
    const query = new URLSearchParams(useLocation().search);

    const history = useHistory();
    const currentpath = history.location.pathname;
    console.log(history.location.pathname)
    if (params === undefined) {
        params = {};
        for ( var item of query.entries()) {
            params[item[0]] = item[1]
        }
    }

    const rebuildquery = (keys) => {
        let q = [];
        for ( var k of keys ) {
            if (!params[k]) continue;
            q.push(k + "=" + params[k]);
        }
        return q.join("&");
    }

    const links = [
        {text: "来店予約", path: "/appointment" },
        {text: "日時選択", path: "/appointment/date_select", params: rebuildquery(["br", "m", "dr"]) },
        {text: "来店者情報登録", path: "/appointment/information_register", params: rebuildquery(["br", "m", "dr", "d", "f", "t"]) },
        {text: "予約内容確認", path: "/appointment/confirm"},
    ];

    const tags = [];
    for (let item of links) {
        if (item.path === currentpath) {
            tags.push(<li key={item.path}>{item.text}</li>)
            break;
        }
        const url = item.path + (item.params ? "?" + item.params : "")
        tags.push(<li key={item.path}><Link to={url}>{item.text}</Link></li>)
    }

    return (
        <ul className="breadcrumb">
        {!!props.withtop && (
          <li><Link to={"/"}>トップ</Link></li>
        )}
        {tags.map((item) => {
            return item
        })}
      </ul>
    )

}

export default AppointmentBreadcrumbs;

