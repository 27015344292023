import React from 'react';
import { Link } from "react-router-dom";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import Badge from "@material-ui/core/Badge";

    
const MenuList = (props) => {
    return (
        <ul className="menu-items">
            {!!props.isResponsive && (
                <li className="item icon user-name">
                    { !!props.oidcUser?.profile?.ba_company_name && (
                        <>{props.oidcUser?.profile.ba_company_name} </>
                    )}
                    { !!props.oidcUser?.profile?.ba_user_last_name && (
                        <>{props.oidcUser?.profile.ba_user_last_name}</>
                    )}
                    { !!props.oidcUser?.profile?.ba_user_first_name && (
                        <>{props.oidcUser?.profile.ba_user_first_name}</>
                    )}
                </li>
            )}
            <Link to={"/"} className={props.classes.nonDecorationLink} onClick={props.handleToggle}>
                <li className="item icon top">トップ</li>
            </Link>
            {(props.canUseChat && (
                <Link to={"/ba_chat"} className={props.classes.nonDecorationLink} onClick={props.handleToggle}>
                    <li className="item icon chat">
                        <Badge badgeContent={props.chatUnreadCount} color="error" className={props.classes.unreadCount}>
                            <span>金融機関連絡チャット</span>
                            </Badge>
                    </li>
                </Link>
            ))}
            <a href={`${props.bank.baUrl}login`} className={props.classes.nonDecorationLink}>
                <li className="item icon service">{props.bank.baName}</li>
            </a>
            {props.bank.canUseAppointment && (
                <span onClick={props.clickAppointmentLink} className={props.classes.nonDecorationLink}>
                    <li className="item icon shop">来店予約</li>
                </span>
            )}
            <Link to={"/seminar"} className={props.classes.nonDecorationLink} onClick={props.handleToggle}>
                <li className="item icon seminar">{props.bank.msg.seminar.displayName}</li>
            </Link>
            <Link to={"/management_column"} className={props.classes.nonDecorationLink} onClick={props.handleToggle}>
                <li className="item icon column">経営コラム</li>
            </Link>
            <Link to={"/news"} className={props.classes.nonDecorationLink} onClick={props.handleToggle}>
                <li className="item icon info">ご案内/お知らせ</li>
            </Link>
            {/*{oidcUser?.profile?.bizsol_sub && (*/}
            {/*    <Link to={"/settings"} className={classes.nonDecorationLink} onClick={handleToggle}>*/}
            {/*        <li className="item icon setting">アカウント設定</li>*/}
            {/*    </Link>*/}
            {/*)}*/}
            {!!props.isResponsive && (
                <Link to={"/"} onClick={ props.logoutAction } className="logout">
                    <li className="item icon logout">ログアウト</li>
                </Link>
            )}
        </ul>
    );
}

export default withRouter(connect(state => state)(MenuList));
