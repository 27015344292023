import React, { useCallback } from "react";
import { useHistory } from 'react-router-dom';
import clsx from "clsx";

import topStyle from '../../css/okba/custom_top.less';
import { useBankContext } from "../../util/BankContext";

const BeforeLoginHeader = () => {
    const history = useHistory();
    const bank = useBankContext();
    const btnKeyColorBorder = clsx(topStyle.btn, topStyle.keyColorBorder);

    const loginAction = useCallback((event) => {
        event.preventDefault();
        history.push("/login");
      }, [history]);

    return (<>
            <header className={topStyle.header}>
                <div className={topStyle.logo}>
                    <a href="/">
                        <img src={`/img/${bank.staticFilePathPrefix}/top/logo.png`} alt="" height="42px" />
                    </a>
                </div>
                <div className={topStyle.btns}>
                    <a href={`${bank.baUrl}register/portal`} className={btnKeyColorBorder}>新規登録</a>
                    <a href="/login" onClick={loginAction} className={btnKeyColorBorder}>ログイン</a>
                </div>
            </header>
        </>
    );
};

export default BeforeLoginHeader;
