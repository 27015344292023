import { Link } from "react-router-dom";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useBankContext } from "../util/BankContext";


const useStyles = makeStyles((theme) => ({
    root: {
        background: "#fff",
        marginTop: 30,
        textAlign: "center",
        width: "100%",
    },
    caption: {
        marginBottom: 0,
    },
    annotationBox: {
        display: "inline-box",
        margin: "0 calc((100% - 450px) / 2)",
        width: 450,
    },
    annotation: {
        fontSize: 12,
        marginBottom: 10,
        marginTop: 20,
        textAlign: "left",
    },
    formWrapper: {
        [theme.breakpoints.down('xs')]: {
            width: 256,
            margin: "0 auto",
        },
    },
    formControl: {
        background: "#f9f9f9",
        borderRadius: 4,
        fontSize: 16,
        height: 40,
        letterSpacing: 1,
        lineHeight: "40px",
        padding: 0,
        width: 300,
        [theme.breakpoints.down('xs')]: {
            width: "100%",
        },
        '& div': {
            padding: "0 16px",
        },
    },
    button: {
        background: "#fff",
        margin: "20px 0",
        width: 300,
    },
    linkText: {
        textDecoration: "none",
    },
    autocomplete: {
        background: "#f9f9f9",
    },
    autocompleteTextField: {
        '& div': {
            height: 40,
            paddingLeft: "16px !important",
        },
        '& div input': {
            padding: "0 !important",
        },
    },
    linkbranchmap: {
        height: "40px",
        lineHeight: "40px",
        paddingLeft: "10px",
    }
}));

const yagiBranchId = 324;

const ShopMenuSelect = (props) => {
    const bank = useBankContext();
    const classes = useStyles();

    const handleChangeBranch = (event, branch) => {
        if (branch === null || branch === '') {
            props.setBranch(null);
            props.setBranchId('');
            props.setMenuId('');
            props.setMenuList([]);
            props.setBranchName('');
            props.setMenuName('');
            props.setMenuDuration('');
        } else {
            props.setBranch(branch);
            props.setBranchId(branch.id);
            props.setBranchName(branch.name);

            // メニュー一覧設定
            if (branch.menus !== null && branch.menus.length > 0) {
                props.setMenuList(branch.menus);
            } else {
                props.setMenuList([]);
                props.setMenuId('');
            }
        }
    };

    const handleChangeMenu = (event) => {
        if (event.target.value === null || event.target.value === '' ) {
            props.setMenuId('');
            props.setMenuName('');
            props.setMenuDuration('');
        } else {
            props.setMenuId(event.target.value);

            const menu = props.menuList.find(menu => menu.id === event.target.value);
            props.setMenuName(menu.name);
            props.setMenuDuration(menu.duration);
        }
    };

    return (
        <>
            {props.branchList.length > 0 && (
                <>
                    <h3 className="box-title">予約フォーム</h3>
                    <form>
                        <div className={classes.formWrapper}>
                        <div className="input-group">
                            <p className="input-label">店舗を選択してください</p>
                            <Autocomplete
                                className={classes.autocomplete}
                                id="combo-box-branch"
                                options={props.branchList}
                                getOptionLabel={(option) => option.name?option.name:''}
                                style={{ width: '100%', maxWidth: 300 }}
                                renderInput={(params) => <TextField placeholder={'支店名'} {...params} variant="outlined" className={classes.autocompleteTextField} />}
                                onChange={handleChangeBranch}
                                value={props.branch}
                                getOptionSelected={(option, value) => {
                                    return value === null || option?.name === value?.name || option?.id === value?.id
                                }}
                            />
                            {bank.realm === 'bank0158' && 
                                <p className={classes.linkbranchmap}><a href="https://www.kyotobank.co.jp/kojin/branch/index.html#branch_search" target="_blank">店舗検索はこちら</a></p>
                            }
                        </div>
                        {bank.realm === 'bank0158' && props.branchId === yagiBranchId &&(
                            <div className="yagi-branch-attention">※ご予約の際のご注意<br/>「八木支店」は京都府南丹市の店舗です。奈良県橿原市八木町の店舗は「橿原支店」ですのでご注意ください。</div>
                        )}
                        {props.menuList.length > 0 && (
                            <div className="input-group">
                                <p className="input-label">メニューを選択してください</p>
                                <div className="input-wrapper">
                                    <Select
                                        value={props.menuId}
                                        onChange={handleChangeMenu}
                                        variant="outlined"
                                        className={classes.formControl}
                                    >
                                        <MenuItem value="">
                                            <em>未選択</em>
                                        </MenuItem>
                                        {props.menuList.map((menu) => (
                                            <MenuItem key={menu.id} value={menu.id}>{menu.name}（{menu.duration}分）</MenuItem>
                                        ))}
                                    </Select>
                                </div>
                            </div>
                        )}
                        {props.branch !== null && props.branch !== '' && props.menuId !== null && props.menuId !== '' && props.branch.id !== 0 &&(
                            <>
                                <p className="check-message">上記でよろしければ日時選択に進んでください</p>
                                <div className="text-center">
                                    <Link className={classes.linkText} to={{
                                        pathname: "/appointment/date_select",
                                        search: `?br=${props.branchId}&m=${props.menuId}&dr=${props.menuDuration}`
                                    }}>
                                        <button className="btn key-color size-l arrow mt16 btnSp">日時選択へ</button>
                                    </Link>
                                </div>
                            </>
                        )}
                        </div>
                    </form>
                </>
            )}
        </>
    )
}

export default ShopMenuSelect;
