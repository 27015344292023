import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useBankContext } from "../../util/BankContext";
import StaticPageLayout from "../StaticPageLayout";


const useStyles = makeStyles((theme) => ({
  notLoginMain: {
    width: "100vw !important",
    height: "100vh !important",
  },
  contents:  {
    "&> h2": {
      paddingLeft: "0",
    },
    "&> h3": {
      letterSpacing: "2px",
      fontSize: "18px",
      borderBottom: "1px solid #ccc",
      paddingBottom: "4px",
      marginTop: "30px",
      marginBottom: "32px",
      position: "relative",
    },
    "&> h4": {
      fontWeight: "700",
      display: "block",
      marginBlockStart: "1.33em",
      marginBlockEnd: "1.33em",
      marginInlineStart: "0px",
      marginInlineEnd: "0px",
    },
    "&> p": {
      textAlign: "right",
      lineHeight: "1.41em",
      marginBottom: "16px",
    }
  }
}));

const Rule = (props) => {
  const bank = useBankContext();
  const classes = useStyles();
  return (
    <StaticPageLayout title="利用規約">
      
      <div className={"box " + classes.contents}>
        <h2>利用規約</h2>
        <h3>第１章 総則･共通事項</h3>
        <h4>第１条　本規定の適用</h4>
        本規定は、契約者と株式会社京都銀行（以下「当行」といいます）との間でインターネットに接続可能なパーソナルコンピュータ（以下「パソコン」といいます）、またはスマートフォン等を利用して、第２条に定めるサービスをおこなうことができる「京銀ビジネスポータルサイト」(以下「本サービス」といいます)の利用に関して定めたものです。本サービスの提供に際しては、当行と契約者との間に以下の規定が適用されるものとします。<br />
        なお、本規定において、契約者とは、当行に円建普通預金口座（以下単に「普通預金口座」といいます）または当座預金口座を持つ法人および個人事業主であって、当行所定のＷＥＢ申し込みにより契約者として指定された者をいいます。<br />
        <br />
        <h4>第２条　サービス内容</h4>
        （１）基本サービスと個別サービス<br />
        本サービスにて提供するサービス内容には基本サービスと個別サービスがあります。なお、当行はこれらのサービス内容を契約者に事前に通知することなく変更することができるものとします。<br />
        <br />
        （２）基本サービス等<br />
        本サービスを申し込むことにより、当行から無償で提供されるサービス等です。なお、サービス等の内容は以下のとおりです。<br />
        ① 取引照会サービス<br />
        ② 電子交付サービス<br />
        ③ 情報提供サービス<br />
        ④ コミュニケーション機能<br />
        ⑤ 各種ＷＥＢ申込<br />
        <br />
        （３）個別サービス<br />
        本規定を承諾の上、個別サービス毎の規定・ルール（以下「個別サービス規定」といいます）に基づいて当行により提供されるサービスです。個別サービスを利用する際は、個別サービス規定の承諾が必要となります。また、個別サービスの利用にあたって、別途個別サービスについて当行所定の申込が必要となる場合があります。また、当該サービスの規定に定められた手数料の支払いが必要になる場合があります。<br />
        <br />
        （４）サービス内容の詳細<br />
        本サービスの詳細（技術的事項を含みます）につきましては、本サービスのホームページ等に提示いたしますので、内容をよくご確認のうえご利用ください。<br />
        <br />
        <h4>第３条　利用申し込み</h4>
        （１）利用申込者<br />
        本サービスは､当行に普通預金口座または当座預金口座を持つ法人および個人事業主の方が利用できます。ただし､外国為替および外国貿易法 第６条第１項第６号に定める非居住者の方を除きます。<br />
        <br />
        （２）利用申し込み<br />
        本サービスの利用を希望する場合は､当行所定のＷＥＢ申し込みにより申込手続きを行うものとします。当行が申し込みを適当と判断し､承諾した場合に､本サービスの利用契約（以下「本契約」といいます）が成立するものとします。利用申し込みについては、「反社会的勢力でないことの表明・確約に関する同意」への同意が必要です。<br />
        なお、京銀インターネットＥＢサービスを利用中の契約者は、本申込手続きは不要です。<br />
        <br />
        （３）キャッシュカード暗証番号等の使用<br />
        前項のＷＥＢ申込手続きにおける本人確認には、当行普通預金口座のキャッシュカード暗証番号またはお客様の申し出に基づき当行よりご案内する確認用番号を使用します。なお、キャッシュカード暗証番号の利用については、別途定める「京銀ICキャッシュカード規定」「京銀キャッシュカード規定」「京銀キャッシュカード・マネジメント規定」によることとします。<br />
        <br />
        <h4>第４条　契約者の利用者設定</h4>
        （１）利用者の指定､操作権限の設定<br />
        契約者は､パソコンにより本サービスの全部または一部の取引を行う者として次の利用者を指定し､利用者別に操作権限を設定します。<br />
        <br />
        ① マスターユーザ<br />
        契約者を代表する利用者として､契約者がマスターユーザを登録します。マスターユーザは１名のみ登録可能で初回のログインＩＤ取得を行うとともに､すべての取引を行うことができます。また､自身を含むすべての利用者を管理することができます｡<br />
        <br />
        ② 管理者ユーザ<br />
        管理者権限を有する利用者としてマスターユーザが管理者ユーザを登録します｡<br />
        <br />
        ③ 一般ユーザ<br />
        管理者権限を有しない利用者としてマスターユーザまたは管理者ユーザが一般ユーザを登録します。<br />
        （２）電子メールアドレスの登録<br />
        すべての利用者は､本サービスの利用にあたり電子メールアドレスの登録が必要です。<br />
        <br />
        （３）契約者の責任負担<br />
        契約者は利用者の行為を監督し本規定を遵守させるとともに､利用者が行った行為に基づく一切の責任は契約者が負うものとします。<br />
        <br />
        <h4>第５条　動作環境</h4>
        （１）利用環境の準備､維持<br />
        契約者は､当行で推奨するオペレーティングシステムやブラウザを確認のうえ､契約者の負担および責任において本サービスの利用に適したパソコンまたはスマートフォン等の動作環境を準備し維持するものとします。<br />
        <br />
        （２）利用にあたっての費用負担<br />
        本サービスの利用にあたり必要となる通信料金､インターネット接続料金､パソコン、スマートフォン等、その他機器等の導入費用等については､契約者が負担するものとします。<br />
        <br />
        <h4>第６条　サービス利用口座</h4>
        （１）サービス利用口座の登録<br />
        契約者は､本サービスで利用する預金口座（以下「サービス利用口座」といいます）を当行所定の方法により登録することとします。<br />
        <br />
        （２）登録可能口座数<br />
        サービス利用口座として登録できる預金口座数は当行所定の口座数とします。<br />
        <br />
        （３）登録可能な預金口座名義<br />
        １契約に登録できる預金口座名義は契約者と同一名義のもの､または当行が認めたものに限ります。<br />
        <br />
        <h4>第７条　サービス利用可能日･利用可能時間</h4>
        （１）利用可能日･利用可能時間<br />
        本サービスの利用可能日､利用可能時間は､いずれも当行所定の日･時間帯とします。<br />
        <br />
        （２）当行都合によるサービスの一時停止･中止<br />
        回線障害､回線工事､システムの維持､安全性の維持､その他必要な事由がある場合は､利用可能時間内であっても契約者に予告することなく､当行は本サービスの全部または一部を一時停止または中止することがあります。なお、一時停止または中止により契約者等に損害が生じたとしても、当行は責任を負いません。<br />
        <br />
        <h4>第８条　本人確認</h4>
        （１）認証方式（ログイン方法）<br />
        本サービスを利用する際の認証方法（ログイン方法）は、ログインＩＤおよびログインパスワードにより契約者であることを確認する「ＩＤ認証方式」となります。なお、京銀インターネットＥＢサービスを利用中の契約者は、電子証明書により契約者であることを確認する「電子証明書方式」によりログインすることもできます。<br />
        <br />
        （２）本人確認情報の登録<br />
        第３条（３）に記載の方法により本人確認ができた場合、「ログインＩＤ」「ログインパスワード」「確認用パスワード」が登録できます。なお、京銀インターネットＥＢサービスを利用中の契約者は、これらの情報を新たに登録する必要はありません。<br />
        <br />
        （３）本人確認･取引意思の確認<br />
        ①当行は受信した「ログインＩＤ」､「電子証明書」（京銀インターネットＥＢサービスを利用中の契約者が電子証明書方式を利用の場合）「ログインパスワード」、「確認用パスワード」(以下､総称して「本人確認情報」といいます)により本人確認を行います。<br />
        <br />
        ②当行所定の方法により送信された本人確認情報と､契約者が当行に事前に登録した本人確認情報との一致を当行が確認した場合､当行は次の事項を確認できたものとみなして取り扱います。<br />
        ア　本サービスの利用依頼が契約者の意思による有効な申し込みであること。<br />
        イ　当行が受信した処理依頼内容が真正なものであること。<br />
        <br />
        （４）本人確認情報の利用に関する留意事項<br />
        ① 本人確認情報の厳重な管理<br />
        本人確認情報は､第三者に知られたり盗まれたりしないよう契約者本人の責任において厳重に管理することとします。安全性を高めるため、生年月日、電話番号、連続番号など類推されやすい番号を本人確認情報等として使用することや、他のサイトとの使い回しを避けてください。なお、当行からこれらの内容をお聞きすることはありません。<br />
        <br />
        ② 本人確認情報の漏洩等の届け出<br />
        ア　本人確認情報を第三者に知られたり盗まれたりした場合､またはそのおそれがある場合は､契約者は速やかに当行所定の書面により届け出ることとします。本届け出を怠ったことにより契約者等に生じた損害については、当行は一切責任を負いません。<br />
        <br />
        イ　上記の届け出に基づき､当行は遅滞なく本サービスの利用を停止します。この場合､当行が本サービスの利用を停止する前に当行が依頼を受け付けた取引により契約者に生じた損害については､当行の過失によるものでない限り当行は責任を負いません。<br />
        <br />
        ③ 本人確認情報の失念<br />
        本人確認情報を失念した場合は､当行所定の方法によりパスワードの再発行の手続き等､当行所定の手続きを行うこととします。<br />
        <br />
        ④ 連続誤入力による利用停止<br />
        誤った本人確認情報の入力､送信を､当行所定の回数以上連続して行ったときは､当行は安全のため本サービスの利用を停止します。この場合契約者は､前号に準じてパスワードの再発行の手続き等､当行所定の手続きを行うこととします。なお、利用停止により契約者等に損害が生じたとしても、当行は責任を負いません。<br />
        また、当行が認めた場合､上記手続きを行わずに利用停止を解除する場合があります。<br />
        <br />
        ⑤ 本人確認情報の定期的な変更<br />
        安全性を高めるため、契約者は本人確認情報を定期的に変更することとします。<br />
        <br />
        ⑥その他<br />
        本人確認情報の再発行手続きにより、それまで本サービス上に保持していた情報等が消去される場合があります。この場合､当行の過失によるものでない限り当行は責任を負いません。<br />
        <br />
        <h4>第９条　電子メール</h4>
        （１）電子メールアドレスの登録<br />
        本サービスの利用開始にあたり､契約者はあらかじめインターネットを介してパソコンにより当行のシステムにサービス利用登録を行うこととし､その際､ユーザ名と利用者の電子メール(Ｅメール)アドレスを当行のシステムに登録することとします(当行のシステムに登録した電子メールアドレスを以下「登録アドレス」といいます)。<br />
        <br />
        （２）電子メールの利用<br />
        ① 当行は取引依頼の受付結果やその他の告知を登録アドレスあてに送信します。<br />
        ② 登録アドレスを変更する場合は､利用者がサービス画面から変更登録を行うこととします。<br />
        <br />
        （３）電子メールの利用に関する留意事項<br />
        ①当行が登録アドレスあてに送信した電子メールについて､通信障害その他の理由による未着･延着が発生しても､通常到達すべき時に到達したものとみなし、それによって生じた損害について、当行は責任を負いません。<br />
        ②当行が登録アドレスあてに送信した電子メールを利用者以外が受信または閲覧したとしても、それによって生じた損害について、当行は責任を負いません。<br />
        <br />
        <h4>第10条　届出事項の変更</h4>
        （１）変更の届け出<br />
        契約者は､印章､名称､商号､代表者､住所､電話番号その他届出事項に変更があった場合は､直ちに当行所定の方法により当行へ届け出ることとします。なお､サービス利用口座を変更する場合は､変更前のサービス利用口座の登録を解除し､あらためて変更後のサービス利用口座を登録することとします。これらの届け出に基づき､当行は遅滞なく変更処理をするものとします。この場合､当行が変更処理を完了する前に当行が依頼を受け付けた取引により契約者等に生じた損害については､当行の過失によるものでない限り､当行は責任を負いません。<br />
        <br />
        （２）通知等の延着･未着<br />
        前項に定める届出事項の変更の届け出がなかった等､契約者の責めに帰すべき事由により､当行からの通知または送付する書類等が延着しまたは到達しなかった場合には､通常到達すべき時に契約者に到達したものとみなし、それによって生じた損害について、当行は責任を負いません。<br />
        <br />
        <h4>第11条　禁止行為</h4>
        （１）譲渡､質入れ<br />
        契約者は､理由の如何を問わず､本契約における権利を譲渡･質入れすることはできません。<br />
        <br />
        （２）不適当･不適切な行為<br />
        契約者は､次の行為をしないこととします。<br />
        ① 公序良俗に反する行為<br />
        ② 犯罪に結びつく行為<br />
        ③ 法律に反する行為<br />
        ④ 本サービスの運営を妨げる行為<br />
        ⑤ 当行の信用を毀損する行為<br />
        ⑥ その他､当行が不適当･不適切と判断する行為<br />
        <br />
        <h4>第12条　解約等</h4>
        （１）解約<br />
        本サービスは、当事者の一方の都合によりいつでも解約することができます。ただし、契約者から当行に対する解約の通知は、当行所定の方法によるものとします。<br />
        <br />
        （２）解約通知<br />
        当行の都合により本サービスを解約する場合は、届出の住所に解約の通知を行います。その場合に、その通知が住所変更等の事由により契約者に到達しなかったときは、通常到達すべきときに契約者に到達したものとみなし、それによって生じた損害について、当行は責任を負いません。<br />
        <br />
        （３）当行の判断によるサービスの解約<br />
        契約者が次の各号のいずれかに該当したときは、当行はいつでも、契約者に何らの催告をすることなく本サービスを解約することができます。なお、解約により契約者等に損害が生じたとしても、当行は責任を負いません。<br />
        ①支払停止または、破産手続開始、民事再生手続開始、会社更生手続開始、特別清算開始等の申立てを受け、若しくは自ら申立てまたはその準備を行ったとき<br />
        ②手形交換所の取引停止処分を受けたとき<br />
        ③住所変更等の届出を怠る等契約者の責に帰すべき事由により、当行において契約者の所在が不明となったとき<br />
        ④本利用規定に違反した場合等、当行が解約を必要とする相当の事由が生じたとき<br />
        ⑤１年以上にわたり本サービスのご利用がないとき<br />
        <br />
        （４）処理の中止<br />
        本契約が終了した場合､その時点までに処理が完了していない依頼について､当行はその処理を継続する義務を負いません。<br />
        <br />
        <h4>第13条　免責事項等</h4>
        （１）本人確認<br />
        第８条第３項に定める本人確認を行った場合､パソコン､スマートフォン等、本人確認情報について偽造･変造･盗用･不正使用その他の事故があっても､それによって生じた損害について､当行は責任を負いません。<br />
        <br />
        （２）免責事由<br />
        以下の事由に起因して契約者等に損害が生じても､当行は責任を負いません。<br />
        ①災害･事変､裁判所等公的機関の措置等のやむをえない事由があったとき<br />
        ②当行または金融機関の共同利用システムの運営体が相当の安全措置を講じたにもかかわらず､電子機器､通信機器､通信回線またはコンピュータ等に障害が生じたとき<br />
        ③当行以外の金融機関の責に帰すべき事由があったとき<br />
        ④本規定に基づく解約、一時停止、サービスの中断<br />
        ⑤その他、当行の責に帰すべき事由がないとき<br />
        <br />
        （３）通信手段等の障害等<br />
        通信機器、専用電話回線、公衆電話回線、インターネットおよびコンピュータ等の障害等、当行の責によらない事由により、本サービスの利用が不能となっても、当行は責任を負いません。<br />
        <br />
        （４）通信経路における取引情報の漏洩等<br />
        公衆電話回線、専用電話回線、インターネット等の通信経路において盗聴・不正アクセス等、当行の責によらない事由により本人確認情報、取引情報等が漏洩しても、それによって生じた損害について、当行は責任を負いません。<br />
        <br />
        （５）キャッシュカード暗証番号等の照合<br />
        契約者が利用申込時に使用したキャッシュカード暗証番号（または、契約者が当行からの案内に基づき利用申込時に使用した確認用番号）を､当行が契約者とのキャッシュカード取引に関し契約者より届け出を受けたキャッシュカード暗証番号（または、当行が契約者に案内した確認用番号）と照合し､相違ないと認めて処理を行ったうえは､暗証番号等につき盗用その他事故があっても､それによって生じた損害について､当行は責任を負いません。<br />
        <br />
        <h4>第14条　取引内容の確認</h4>
        契約者と当行の間で取引内容について疑義が生じた場合には､当行が保存する電磁的記録等の記録内容を正当なものとして取り扱います。<br />
        <br />
        <h4>第15条　関係規定の適用･準用</h4>
        本規定に定めのない事項については、当行の各種預金規定、当座勘定規定等により取扱います。なお、各規定は必要に応じて当行ホームページにて確認いただくか、当行にご請求ください。<br />
        また､これらの規定と本規定との間に齟齬がある場合には､本規定を優先して適用するものとします。<br />
        <br />
        <h4>第16条　規定の変更</h4>
        本規定は、民法第548条の4の規定にもとづき改定されることがあります。改定を行う旨および改定後の規定の内容ならびにその効力発生時期は、効力発生時期が到来するまでに当行ホームページへの掲載による公表その他相当の方法で周知することにより変更できるものとします。<br />
        <br />
        <h4>第17条　準拠法と管轄</h4>
        本契約の準拠法は日本法とします。本契約に基づく取引に関して紛争が生じた場合には、当行本店の所在地を管轄する裁判所を専属的合意管轄裁判所とします。<br />
        <br />
        <h3>第２章 取引照会サービス</h3>
        <h4>第18条　サービスの内容</h4>
        あらかじめ指定された当行本支店の契約者名義の口座の残高等の照会を行う取引をいいます。<br />
        （１）受入証券類の不渡、その他相当の事情がある場合には、すでに応答した内容について、訂正または取消をすることがあります。この場合、訂正または取消により生じた損害については、当行は責任を負いません。<br />
        （２）契約者は、残高等の口座情報が当行所定の時刻における内容であり、契約者が取引照会を行った時点での内容とは異なる場合があることを異議なく承認し、それによって生じた損害について、当行は責任を負いません。<br />
        <br />
        <h3>第３章 電子交付サービス</h3>
        <h4>第19条　サービスの内容</h4>
        電子交付サービスとは､第20条に定める対象書類について､紙媒体に代えて電磁的に交付（以下「電子交付」といいます）するサービスをいいます。<br />
        <br />
        <h4>第20条　対象書類</h4>
        電子交付サービスの対象書類は、当行ホームページに掲げる書類であって、サービス利用口座のある取引店での取引に関する書類等を対象とします。<br />
        なお､契約者の本サービスのご利用状況等により、サービス利用口座のある取引店での取引に関する書類であっても､電子交付の対象とならない場合があります。<br />
        また、当行は対象書類を変更することができるものとします。<br />
        <br />
        <h4>第21条　対象書類の閲覧可能期間</h4>
        対象書類は､当行が定めた期間において閲覧できます。<br />
        <br />
        <h4>第22条　電子交付の方法等</h4>
        （１）交付方法<br />
        電子交付の方法は､対象書類の記載事項をＰＤＦ形式のファイルで記録して､契約者の画面上で閲覧に供します。なお､契約書類を閲覧するためには､使用するパソコン等にＰＤＦ閲覧ソフトが必要になります。<br />
        <br />
        （２）契約者における保存方法等<br />
        対象書類については､契約者のプリンター等で印刷すること､契約者のパソコン等にＰＤＦ形式のファイルを保存することも可能です。<br />
        <br />
        （３）交付の通知<br />
        対象書類が新しく電子交付された場合は､その都度､登録アドレスに通知します。<br />
        <br />
        <h4>第23条　交付方法の切り替え</h4>
        （１）契約当初の交付方法､切り替え可否<br />
        本サービス提供時は､当行は対象書類をすべて電子交付しますが､契約者は､対象書類の全部を紙媒体での交付（以下「書面交付」といいます）へ切り替えることができます。また､一旦、書面交付とした対象書類の全部を電子交付に切り替えることもできます。<br />
        ただし、書面交付には、所定の手数料をいただく場合があります。<br />
        <br />
        （２）切り替え方法<br />
        切り替えの手続きは､電子交付サービスの画面上での操作による等､当行所定の方法によることとします。<br />
        <br />
        （３）切り替えのタイミング<br />
        切り替えは当行所定の時間帯に実施するので､場合により切り替え前の方法で対象書類が交付されることがあります。<br />
        <br />
        （４）電子交付済み書類の再交付<br />
        一旦、電子交付された対象書類について、重ねて書面交付を受けることはできません。<br />
        <br />
        <h4>第24条　電子交付サービスの終了</h4>
        次の各号のいずれかに該当する場合は､電子交付サービスは終了し､引き続き交付する対象書類が存在する場合は､書面交付に切り替えて交付します。この場合、所定の手数料をいただく場合があります。<br />
        なお、この書面交付への切替えにも、第23条第3項および第4項の規定を適用します。<br />
        ①本サービスが停止、中止または終了した場合。<br />
        ②当行が電子交付サービスの提供を終了した場合。<br />
        ③当行が契約者につき電子交付サービスの利用を停止または中止することが適当であると判断した場合。<br />
        <br />
        <h3>第４章　情報提供サービス</h3>
        <h4>第25条　情報提供サービスに関する留意事項</h4>
        （１）情報提供サービスで掲載する情報は当行が勧奨するものではございません。<br />
        （２）当行は、情報提供サービスに掲載している情報について、その内容の完全性・正確性・有用性・安全性等についていかなる保証を行うものでもありません。又、当該情報を利用したことによって生じたトラブルについて、当行は一切の責任を負いません。<br />
        <br />
        <h3>第５章　コミュニケーション機能</h3>
        <h4>第26条　コミュニケーション機能に関する留意事項</h4>
        （１）コミュニケーション機能を利用した資料の誤送信、情報の誤送信などによって損失等が発生しても、当行は一切の責任を負いません。<br />
        （２）コミュニケーション機能を利用したことによって生じたトラブルについて、当行は一切の責任を負いません。<br />
        <br />
        <h3>第６章　各種ＷＥＢ申込</h3>
        <h4>第27条　サービスの内容</h4>
        各種ＷＥＢ申込とは､当行所定の申込書、依頼書、届出書、その他の書類（以下「ＷＥＢ申込書」といいます）を作成し、電磁的方法により当行に提出するサービスをいいます。<br />
        <br />
        <h4>第28条　各種ＷＥＢ申込に関する留意事項</h4>
        （１）本サービスにより作成・提出することができるＷＥＢ申込書は、当行ホームページに掲げる書類とします。なお、当行は、契約者に事前に通知することなく、ＷＥＢ申込書の追加・変更・削除等を行うことができるものとします。<br />
        （２）ＷＥＢ申込書により申込等を行う商品・サービスに規定・規約等が定められている場合は、当該規定・規約等の承諾が必要となります。<br />
        （３）ＷＥＢ申込書による申込等は、当行所定の手続が完了した時点でその効力が発生するものとします。なお、当行は当該申込等の全部または一部を承諾せず、その効力を発生させないことができるものとします。<br />
        （４）作成・提出されたＷＥＢ申込書について、当行は契約者の正当な権限者により有効に作成・提出がされたものとみなして取扱います。この場合、契約者は、当該ＷＥＢ申込書にもとづき行われた一切の取引についてその責任を負うものとし、これによって生じた損害について、当行は一切の責任を負いません。<br />
        （５）ＷＥＢ申込書の内容により、別途正式な申込書等の提出が必要となる場合があります。この場合、契約者は当該申込書等を当行所定の方法により提出するものとします。<br />
        <br />
        <p>以上<br />（2023年8月21日現在）</p>
      </div>
    </StaticPageLayout>
  )
}

export default Rule;
