import React, { useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Box from '@material-ui/core/Box';
import moment from 'moment';
import 'moment/locale/ja';
import { useReactOidc } from '@axa-fr/react-oidc-context';
import { Link, useLocation } from "react-router-dom";
import clsx from "clsx";
import useMediaQuery from '@material-ui/core/useMediaQuery';

import Header from '../../components/Header';
import Footer from '../../components/Footer';
import LeftColumn from "../../components/LeftColumn";
import DatePicker from "../../components/DatePicker";
import AppointmentBreadcrumbs from '../../components/AppointmentBreadcrumbs';
import Api from "../../util/api";
import TimeMathSum from '../../util/TimeMathSum';
import { useBankContext } from "../../util/BankContext";
import FindADayFromSundayOfTheWeek from "../../util/FindADayFromSundayOfTheWeek";
import useSafeState from "../../helpers/useSafeState";
import useUnmountRef from "../../helpers/useUnmountRef";


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        '& table': {
            border: "1px solid #000",
            borderCollapse: "collapse",
            width: "100%",
        },
        '& tr': {
            height: "50px",
        },
        '& th': {
            background: "#E8E8E8",
        },
        '& td': {
            textAlign: "center",
        },
    },
    content: {
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        height: '100vh',
        minHeight: "100vh",
        overflow: 'auto',
    },
    container: {
        paddingTop: theme.mixins.toolbar.lineHeight,
    },
    title: {
        backgroundColor: theme.palette.text.primary,
        padding: "10px 8px",
        '& p': {
            color: theme.palette.background.default,
        },
    },
    paper: {
        background: "#E8E8E8",
        display: 'flex',
        flexDirection: 'column',
        marginTop: 50,
        overflow: 'auto',
    },
    height100: {
        height: "auto",
    },
    height250: {
        height: 260,
    },
    white: {
        background: "#fff",
    },
    information: {
        padding: 20,
    },
    paperBoxCaption: {
        background: "#606060",
        color: "#fff",
        height: 50,
        textAlign: "center",
    },
    captionHeight: {
        lineHeight: "50px",
    },
    center: {
        textAlign: "center",
    },
    p4: {
        padding: 10,
    },
    subTitle: {
        margin: "30px 0",
    },
    description: {
        marginTop: 20,
        textAlign: "center",
    },
    buttonBox: {
        textAlign: "center",
    },
    button: {
        background: "#fff",
        margin: "20px 0",
        width: 300,
    },
    footer: {
        marginTop: "auto",
    },
    notLoginMain: {
        width: "100vw !important",
        height: "100vh !important",
    },
    explanatoryNote: {
        "& li": {
            display: "inline",
            paddingRight: "12px"
        }
    }
}));

const useReservedDate = () => {
    const dispatch = useDispatch();
    const setReservedDate = (reservedDate) =>
        dispatch({
            type: 'SET_RESERVED_DATE',
            reservedDate: reservedDate,
        });
    return { setReservedDate }
}

const useBranchId = () => {
    const branchId = useSelector((state) => state.branchId);
    const dispatch = useDispatch();
    const setBranchId = (branchId) =>
        dispatch({
            type: 'SET_BRANCH_ID',
            branchId: branchId,
        });
    return { branchId, setBranchId }
}

const useMenuId = () => {
    const menuId = useSelector((state) => state.menuId);
    const dispatch = useDispatch();
    const setMenuId = (menuId) =>
        dispatch({
            type: 'SET_MENU_ID',
            menuId: menuId,
        });
    return { menuId, setMenuId }
}

const useMenuDuration = () => {
    const menuDuration = useSelector((state) => state.menuDuration);
    const dispatch = useDispatch();
    const setMenuDuration = (menuDuration) =>
        dispatch({
            type: 'SET_MENU_DURATION',
            menuDuration: menuDuration,
        });
    return { menuDuration, setMenuDuration }
}

const DateSelect = (props) => {
    const unmountRef = useUnmountRef();
    const matches = useMediaQuery('(min-width:600px)');
    const location = useLocation();
    const bank = useBankContext();
    window.document.title = '来店予約|' + bank.baPortalName;
    moment.locale('ja');
    const classes = useStyles();
    const { oidcUser } = useReactOidc();

    const { branchId, setBranchId } = useBranchId();
    const [branchName, setBranchName] = useSafeState(unmountRef, '');
    const { menuId, setMenuId } = useMenuId();
    const { menuDuration, setMenuDuration } = useMenuDuration();
    const notLoginMainClass = (!oidcUser ? classes.notLoginMain : '');
    const mainClass = clsx("main", notLoginMainClass);

    const { setReservedDate } = useReservedDate();
    const [calendar, setCalendar] = useSafeState(unmountRef, {
        timeTable: [],
        availabilities: {}
    });

    const handleSelectDate = (branch, menu, day, time, duration) => {
        const toTime = TimeMathSum((time + ':00'), ('00:' + duration + ':00'));
        setReservedDate({
            day: day,
            fromTime: time,
            toTime: toTime,
        });
        return props.history.push(`/appointment/information_register?br=${branch}&m=${menu}&d=${day}&f=${time}&t=${toTime}&dr=${duration}`);
    };

    useEffect(() => {
        if (typeof window !== 'undefined' && bank.canUseGA && window.gtag) {
            window.gtag('config', bank.gaTrackingCode, {
                'page_path': `${props.location.pathname}${props.location.search}`
            });
        }

        let params = new URLSearchParams(location.search)
        let branchId = Number(params.get("br"));
        let menuId = Number(params.get("m"));
        if (branchId === ''|| menuId === '') {
            return props.history.push('/404'); 
        }
        setBranchId(branchId);
        setMenuId(menuId);
        if (bank.realm) {
            Api.get(bank.realm, process.env.REACT_APP_APPOINTMENT_BASE_URL + '/branches/withmenus', {}, {}, oidcUser)
                .then(branchList =>   {
                    const br = branchList.branches.find(branch => { return branch.id === branchId });
                    if (br === '' || br.menus === null) {
                        // todo エラー処理
                        props.history.push("/404");
                    }
                    const m = br.menus.find(m => { return m.id === menuId });
                    setMenuDuration(m.duration);
                    setBranchName(br.name);
                })
                .catch(e => {
                    console.log(e);
                    // todo エラー処理
                    props.history.push("/404");
                })
        }

        let f;
        let t;
        if (props.isEdit) {
            const reservedDate = moment(props.reservedDate.day).toDate();
            f = FindADayFromSundayOfTheWeek(reservedDate).format('YYYY-MM-DD');
            t = FindADayFromSundayOfTheWeek(reservedDate).add(6, 'd').format('YYYY-MM-DD');
        } else {
            const today = new Date()
            f = FindADayFromSundayOfTheWeek(today).format('YYYY-MM-DD');
            t = FindADayFromSundayOfTheWeek(today).add(6, 'd').format('YYYY-MM-DD');
        }

        fetchData(
            branchId,
            menuId,
            f,
            t
        )
            .then()
            .catch(e => {
                console.log(e);
                props.history.push("/404");
            })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props]);

    const fetchData = async (br, m, f, t) => {
        // 予約状況一覧
        if (bank.realm) {
            Api.get(bank.realm, process.env.REACT_APP_APPOINTMENT_BASE_URL + '/availabilities', {br: br, m: m, f: f, t: t}, {}, oidcUser)
                .then(availabilityList => {
                    let timeTable = [];
                    if (('timeTable' in availabilityList.calendar) && availabilityList.calendar.timeTable !== null) {
                        timeTable = availabilityList.calendar.timeTable;
                    }

                    let availabilities = {};
                    if (('availabilities' in availabilityList.calendar) && availabilityList.calendar.availabilities !== null) {
                        availabilities = availabilityList.calendar.availabilities;
                    }
                    setCalendar({
                        timeTable: timeTable,
                        availabilities: availabilities
                    });
                })
                .catch(e => {
                    console.log(e);
                    // todo エラー処理
                    props.history.push("/404");
                })
            }
    }

    return (
        <div className="wrapper">
            {!!oidcUser && (
                <Header />
            )}
            <div className="content">
                {(!!oidcUser && matches) && (
                    <LeftColumn />
                )}
                <div className={mainClass}>
                    <AppointmentBreadcrumbs withtop={!!oidcUser} />

                    <div className="box">
                        <h2 className="box-title shop">来店予約</h2>
                        <h3 className="box-title">日時を選択してください</h3>
                        <DatePicker
                            calendar={calendar}
                            setCalendar={setCalendar}
                            handleSelectDate={handleSelectDate}
                            duration={menuDuration}
                            br={branchId}
                            m={menuId}
                            realm={bank.realm}
                        />
                        <ul className={classes.explanatoryNote}>
                            <li>「◯：予約可」</li>
                            <li>「△：予約可(残りわずか)」</li>
                            <li>「✕：予約不可」</li>
                            <li>「TEL：お問い合わせください」</li>
                            <br/>
                            <li>TEL：「<a target="blank" href="https://www.kyotobank.co.jp/kojin/branch/#branch_search">店舗・ATMのご案内</a>」のページより「{branchName}」の電話番号をご確認の上、お問い合わせください。</li>
                        </ul>
                        {/*<div className="text-center">*/}
                        {/*    <button className="btn key-color size-l arrow mt40">来店者情報の登録へ</button>*/}
                        {/*</div>*/}
                    </div>
                    <Box className={classes.footer}>
                        <Footer />
                    </Box>
                </div>
            </div>
        </div>
    )
}

export default connect(state => state)(DateSelect);