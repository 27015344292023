import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import Helmet from 'react-helmet';

import { AuthenticationProvider } from '@axa-fr/react-oidc-context';

import Router from "./Router";
import { BankContext } from "./util/BankContext";
import Favicon from './components/Favicon';


const App = () => {
  // eslint-disable-next-line no-unused-vars
  const [cookie, setCookie] = useCookies();
  const [bank, setBank] = useState({
    bankName: '',
    bankUrl: '',
    baName: '',
    baPortalName: '',
    baUrl: '',
    bapUrl: '',
    baManagerUrl: '',
    realm: '',
    baCookieDomain: '',
    staticFilePathPrefix: '',
    canUseAppointment: true,
    accountGetUrl: '',
    params: {},
    msg: {},
    canUseGA: false,
    canUseBS: false,
    gaTrackingCode: '',
  });

  const hostname = process.env.REACT_APP_BANK_SETTING || window.location.hostname;
  const enviroment = hostname.endsWith('test.kkdv.tech') ? 'staging' : process.env.NODE_ENV;

  let bankName;
  let bankUrl;
  let baName;
  let baPortalName;
  let baUrl;
  let bapUrl;
  let baManagerUrl;
  let realm;
  let baCookieDomain;
  let params;
  let msg;
  let canUseGA = false;
  let canUseBS = false;
  let gaTrackingCode = '';
  let staticFilePathPrefix;
  let canUseAppointment = true;
  let accountGetUrl = '';
  let idps;

  // OIDC configuration
  let oidcAuthority;

  let configDir = '';
  if (['ktba', 'portal.kyotobank-ba.jp', 'portal.ktba1.test.kkdv.tech', 'ktba.staging.portal.bigadvance.app'].includes(hostname)) {
    configDir = 'ktba';
  } else if (['okba', 'portal.okigin-ba.jp', 'portal.okba1.test.kkdv.tech', 'okba.staging.portal.bigadvance.app'].includes(hostname)) {
    configDir = 'okba';
  }

  import(`./css/${configDir}/custom_common.css`)
    .then()
    .catch(e => {
      console.log(e);
  });

  const settings = require(`./config/${configDir}/${enviroment}.json`);
  bankName = settings.bankName;
  bankUrl = settings.bankUrl;
  bapUrl = settings.bapUrl;
  baCookieDomain = settings.baCookieDomain;
  realm = settings.realm;
  baName = settings.baName;
  baUrl = settings.baUrl;
  baPortalName = settings.baPortalName;
  staticFilePathPrefix = settings.staticFilePathPrefix;
  canUseAppointment = settings.canUseAppointment;
  params = settings.params;
  msg = settings.msg;
  idps = settings.idps;
  oidcAuthority = settings.oidcAuthority;
  canUseGA = settings.canUseGA;
  canUseBS = settings.canUseBS;
  gaTrackingCode = settings.gaTrackingCode;
  accountGetUrl = settings.accountGetUrl;
  baManagerUrl = settings.baManagerUrl;

  useEffect(() => {
    setBank({
      bankName,
      bankUrl,
      baName,
      baPortalName,
      baUrl,
      bapUrl,
      baManagerUrl,
      realm,
      baCookieDomain,
      staticFilePathPrefix,
      canUseAppointment,
      accountGetUrl,
      params,
      msg,
      oidcAuthority,
      idps,
      canUseGA,
      canUseBS,
      gaTrackingCode,
    });
    setCookie('ba_login_redirect_uri', bapUrl, { domain: baCookieDomain });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hostname, setCookie]);

  const oidcConfiguration = {
    client_id: 'big-advance-portal',
    redirect_uri: `${bapUrl}authentication/callback`,
    response_type: 'code',
    scope: 'openid profile',
    authority: oidcAuthority,
    silent_redirect_uri: `${bapUrl}authentication/silent_callback`,

    // post_logout_redirect_uri はKeycloakでは必要。
    // BA IDPでは、logout後にこちらにredirectされるため不要。
    // ログアウト時のちらつきがおきるため、設定を外しておくが、
    // ログアウト後にポータルに戻ってこないようであればコメントを外すとよい。
    // post_logout_redirect_uri: bapUrl,
  };

  const Authenticating = () => (<div>{msg.auth.authenticating}</div>);
  const AuthCallback = () => (<div>{msg.auth.authCallback}</div>);
  const NotAuthenticated = () => (<div>{msg.auth.notAuthenticated}</div>);
  const NotAuthorized = () => (<div>{msg.auth.notAuthorized}</div>);

  return (
    <AuthenticationProvider
      configuration={oidcConfiguration}
      notAuthenticated={NotAuthenticated}
      notAuthorized={NotAuthorized}
      authenticating={Authenticating}
      callbackComponentOverride={AuthCallback}
    >
      {canUseGA && (
        <Helmet>
          <script async src={`https://www.googletagmanager.com/gtag/js?id=${gaTrackingCode}`} />
          <script>
            {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${gaTrackingCode}');
          `}
          </script>
        </Helmet>
      )}
      <BankContext.Provider value={bank}>
        <Favicon />
        <div className="App">
            <Router />
        </div>
      </BankContext.Provider>
    </AuthenticationProvider>
  );
};

export default App;
