const AppointmentValidation = (
  isAdmin,
  oidcUser,
  setIsErrorLastName,
  setIsErrorFirstName,
  setIsErrorLastNameKana,
  setIsErrorFirstNameKana,
  setIsErrorTelNo,
  setIsErrorEmailAddress,
  setIsErrorConfirmEmailAddress,
  setIsErrorBirthday,
  lastName,
  firstName,
  lastNameKana,
  firstNameKana,
  telNo,
  emailAddress,
  confirmEmailAddress,
  birthday,
) => {
  setIsErrorLastName(false);
  setIsErrorFirstName(false);
  setIsErrorLastNameKana(false);
  setIsErrorFirstNameKana(false);
  setIsErrorTelNo(false);
  setIsErrorEmailAddress(false);
  setIsErrorConfirmEmailAddress(false);
  setIsErrorBirthday(false);

  let isValid = true;
  let messages = [];

  // 性
  if (lastName === '') {
    isValid = false;
    messages.push('お名前（性）を入力してください');
    setIsErrorLastName(true);
  }
  // 名
  if (firstName === '') {
    isValid = false;
    messages.push('お名前（名）を入力してください');
    setIsErrorFirstName(true);
  }
  // セイ
  if (lastNameKana === '') {
    isValid = false;
    messages.push('お名前（セイ）を入力してください');
    setIsErrorLastNameKana(true);
  }
  // メイ
  if (firstNameKana === '') {
    isValid = false;
    messages.push('お名前（メイ）を入力してください');
    setIsErrorFirstNameKana(true);
  }
  if (birthday === '') {
    isValid = false;
    messages.push('生年月日を入力してください');
    setIsErrorBirthday(true);
  }
  // 電話番号
  if (telNo === '') {
    isValid = false;
    messages.push('電話番号を入力してください');
    setIsErrorTelNo(true);
  } else {
    // eslint-disable-next-line no-useless-escape
    const validateTelNo = telNo.replace(/[━.*‐.*―.*－.*\-.*ー.*\-]/gi,'');
    if (!validateTelNo.match(/^(0[5-9]0[0-9]{8}|0[1-9][1-9][0-9]{7})$/)) {
      isValid = false;
      messages.push('正しい形式で電話番号を入力してください');
      setIsErrorTelNo(true);
    }
  }
  // 管理画面ではない
  if (!isAdmin) {
    // メールアドレス
    if (emailAddress === '') {
      isValid = false;
      messages.push('メールアドレスを入力してください');
      setIsErrorEmailAddress(true);
    } else {
      const reg = /^[A-Za-z0-9]{1}[A-Za-z0-9+_.-]*@{1}[A-Za-z0-9_.-]{1,}\.[A-Za-z0-9]{1,}$/;
      if (!reg.test(emailAddress)) {
        isValid = false;
        messages.push('正しい形式でメールアドレスを入力してください');
        setIsErrorEmailAddress(true);
      }
    }
    // 確認用メールアドレス
    if (confirmEmailAddress === '') {
      isValid = false;
      messages.push('メールアドレス確認を入力してください');
      setIsErrorConfirmEmailAddress(true);
    } else {
      const reg = /^[A-Za-z0-9]{1}[A-Za-z0-9+_.-]*@{1}[A-Za-z0-9_.-]{1,}\.[A-Za-z0-9]{1,}$/;
      if (!reg.test(confirmEmailAddress)) {
        isValid = false;
        messages.push('正しい形式でメールアドレス確認を入力してください');
        setIsErrorConfirmEmailAddress(true);
      }
      if (emailAddress !== confirmEmailAddress) {
        isValid = false;
        messages.push('メールアドレスが一致しません');
        setIsErrorConfirmEmailAddress(true);
      }
    }
  } else if (isAdmin && !oidcUser) {
    // メールアドレス
    if (emailAddress !== '') {
      const reg = /^[A-Za-z0-9]{1}[A-Za-z0-9+_.-]*@{1}[A-Za-z0-9_.-]{1,}\.[A-Za-z0-9]{1,}$/;
      if (!reg.test(emailAddress)) {
        isValid = false;
        messages.push('正しい形式でメールアドレスを入力してください');
        setIsErrorEmailAddress(true);
      }
    }
  }

  if (!isValid) {
    return {
      isValid: false,
      errorMessages: messages,
    };
  } else {
    return {
      isValid: true,
    }
  }
}

export default AppointmentValidation;
