import React, {useEffect, useState} from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import moment from "moment";
import 'moment/locale/ja';
import { useReactOidc } from '@axa-fr/react-oidc-context';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Header from "../../components/Header";
import LeftColumn from "../../components/LeftColumn";
import Footer from "../../components/Footer";
import Api from "../../util/api";
import { useBankContext } from "../../util/BankContext";


const useStyles = makeStyles((theme) => ({
    linkText: {
        color: "#159E43",
        cursor: "pointer",
        textDecoration: "underline",
        '&:hover': {
            textDecoration: "none",
        },
    },
    footer: {
        marginTop: "auto",
    },
}));

const ManagementColumn = (props) => {
    const matches = useMediaQuery('(min-width:600px)');
    const bank = useBankContext();
    window.document.title = '経営コラム|' + bank.baPortalName;
    moment.locale('ja');
    const classes = useStyles();
    const [managementColumnListPagingData, setManagementColumnListPagingData] = useState({});
    const [managementColumnList, setManagementColumnList] = useState([]);
    const { oidcUser } = useReactOidc();

    const moreViewManagementColumn = async (nextPage, event) => {
        // 経営コラム一覧取得API
        const newManagementColumnList = await Api.get(bank.realm, process.env.REACT_APP_COLUMN_BASE_URL + '/columns/search', {r: bank.params.column.listRows, p: nextPage}, {}, oidcUser);
        setManagementColumnListPagingData(newManagementColumnList.pagingInfo);
        setManagementColumnList(managementColumnList.concat(newManagementColumnList.columns));
    };

    useEffect( () => {
        if (!oidcUser) {
            return;
        }
        if (bank.canUseGA) {
            window.gtag('config', bank.gaTrackingCode, {
                'page_path': `${props.location.pathname}${props.location.search}`
            });
        }

        const fetchData = async () => {
            // 経営コラム一覧取得API
            const managementColumnList = await Api.get(bank.realm, process.env.REACT_APP_COLUMN_BASE_URL + '/columns/search', {r: bank.params.column.listRows, p: 1}, {}, oidcUser);
            if (managementColumnList.columns === null) {
                setManagementColumnList([]);
            } else {
                setManagementColumnList(managementColumnList.columns);
            }
            setManagementColumnListPagingData(managementColumnList.pagingInfo);
        };

        fetchData()
            .then()
            .catch(e => {
                // todo エラーハンドリング
                if( e.response ){
                    if( e.response.status === 403 ){
                        // todo 「ログインしてください」を表示
                        // todo ユーザー情報の削除
                        // location.href = "/";
                    }
                    //todo エラーであることを知らせる表示
                }
            })
    }, [oidcUser, bank, props]);

    return (
        <div className="wrapper">
            <Header />
            <div className="content">
                {(!!oidcUser && matches) && (
                    <LeftColumn />
                )}
                <div className="main">
                    <ul className="breadcrumb">
                        <li><Link to={"/"}>トップ</Link></li>
                        <li>経営コラム</li>
                    </ul>
                    <div className="box">
                        <h2 className="box-title column">経営コラム</h2>
                        <div className="article">
                            <ul>
                                {managementColumnList.map((managementColumn) => (
                                    <li key={managementColumn.uniqueCode}>
                                        <div className="wrapper-image">
                                            <img src={`${bank.baManagerUrl}picture/bank/${managementColumn.imageFileUniqueCode}`} alt={managementColumn.title} />
                                        </div>
                                        <div className="wrapper-body">
                                            <div className="category-and-date">
                                                <p className="category">経営</p>
                                                <p className="date">{moment(managementColumn.createdAt).format('YYYY.M.D')}</p>
                                            </div>
                                            <p className="title">
                                                {managementColumn.title}
                                            </p>
                                            <div className="btn-area">
                                                <Link to={"/management_column/detail/" + managementColumn.uniqueCode} className="btn white-color size-m">
                                                    記事の続きを見る
                                                </Link>
                                            </div>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                            {!managementColumnListPagingData.isLastPage && (
                                <div className="text-center">
                                    <span className={classes.linkText} onClick={moreViewManagementColumn.bind(this, managementColumnListPagingData.nextPage)}>もっと見る</span>
                                </div>
                            )}
                        </div>
                        {/*/.article*/}
                    </div>
                    <Box className={classes.footer}>
                        <Footer />
                    </Box>
                </div>
            </div>
        </div>
    )
}

export default ManagementColumn;
