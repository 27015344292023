import React, { useCallback } from 'react';
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { makeStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Box from '@material-ui/core/Box';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useReactOidc } from '@axa-fr/react-oidc-context';

import { useBankContext } from "../util/BankContext";
import HeaderMenu from "./HeaderMenu";

const useStyles = makeStyles((theme) => ({
    header: {
        lineHeight: theme.mixins.toolbar.lineHeight,
    },
    headerContainer: {
        background: "#fff",
        borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
        left: 0,
        position: "fixed",
        top: 0,
        zIndex: 1201,
    },
    flex: {
        display: "flex",
    },
    headerNav: {
        height: "calc(" + theme.mixins.toolbar.lineHeight + " + 1px)",
        listStyleType: "none",
        position: "absolute",
        right: 0,
        top: 0,
    },
    headerNavLi: {
        backgroundColor: "#606060",
        borderLeft: "1px solid #000",
        color: "#fff",
        fontSize: "12px",
        padding: "0 15px",
        textAlign: "center",
        width: 120,
        '&:hover': {
            color: "#000",
        }
    },
}));

function Button(props) {
    const { oidcUser, logout } = useReactOidc();

    const loginAction = useCallback(() => {
        props.history.push("/login");
    }, [props.history]);

    const logoutAction = useCallback(() => {
        logout();
    }, [logout]);

    if (oidcUser) {
        return (
            <Link to={"/"} onClick={ logoutAction } className="logout">ログアウト</Link>
        );
    } else {
        return (
        <Box className={props.classes.flex + " " + props.classes.headerNav}>
            <ListItem button className={props.classes.headerNavLi}>
                <ListItemText primary="ログイン" onClick={ loginAction } />
            </ListItem>

            <ListItem button className={props.classes.headerNavLi}>
                <ListItemText primary="新規登録" />
            </ListItem>
        </Box>
        );
    }
}

    
const Header = (props) => {
    const matches = useMediaQuery('(min-width:600px)');
    const classes = useStyles();
    const { oidcUser } = useReactOidc();
    const bank = useBankContext();

    return (
        <>
        <div className="header">
            {!matches && (
                <HeaderMenu />
            )}
            <Link to="/" className="logo">
                <img src={`/img/${bank.staticFilePathPrefix}/logo.png`} alt={bank.baPortalName} />
            </Link>
            <div className="sub-menu">
                <p>
                    { !!oidcUser?.profile?.ba_company_name && (
                        <>{oidcUser?.profile.ba_company_name} </>
                    )}
                    { !!oidcUser?.profile?.ba_user_last_name && (
                        <>{oidcUser?.profile.ba_user_last_name}</>
                    )}
                    { !!oidcUser?.profile?.ba_user_first_name && (
                        <>{oidcUser?.profile.ba_user_first_name}</>
                    )}
                </p>
                <Button history={props.history} classes={classes} />
            </div>
        </div>
        </>
    );
}

export default withRouter(connect(state => state)(Header));
