import React from "react";
import moment from 'moment';
import 'moment/locale/ja';
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";


const useStyles = makeStyles((theme) => ({
  reservedDate: {
    [theme.breakpoints.down('xs')]: {
      height: "30px !important",
      lineHeight: "30px !important",
    },
  },
  reservedTime: {
    [theme.breakpoints.down('xs')]: {
      height: "30px !important",
      lineHeight: "30px !important",
      padding: "0 !important",
      width: "auto !important",
    },
  },
}));

export default function ReservationConfirm(props) {
  const classes = useStyles();
  const reservedTime = clsx("dIB", classes.reservedTime);
  const reservedDate = clsx("size-l", classes.reservedDate);
  moment.locale('ja');

  return (
    <>
      <div className="confirm-group">
        <p className="confirm-label">店舗</p>
        <div className="confirm-wrapper">
          <span className="size-l">{props.branchName}</span>
        </div>
      </div>
      <div className="confirm-group">
        <p className="confirm-label">来店日時</p>
        <div className="confirm-wrapper">
          <span className={reservedDate}>{moment(props.day).format('YYYY/M/D(dd)')} <span className={reservedTime}>{props.fromTime} 〜 {props.toTime}</span></span>
        </div>
      </div>
      <div className="confirm-group">
        <p className="confirm-label">メニュー</p>
        <div className="confirm-wrapper">
          <span className="size-l">{props.menuName}({props.menuDuration}分)</span>
        </div>
      </div>
      <div className="confirm-group">
        <p className="confirm-label">お名前</p>
        <div className="confirm-wrapper">
          <span className="size-s">{props.lastName}</span>
          <span className="size-s">{props.firstName}</span>
        </div>
      </div>
      <div className="confirm-group">
        <p className="confirm-label">お名前(カナ)</p>
        <div className="confirm-wrapper">
          <span className="size-s">{props.lastNameKana}</span>
          <span className="size-s">{props.firstNameKana}</span>
        </div>
      </div>
      <div className="confirm-group">
        <p className="confirm-label">生年月日</p>
        <div className="confirm-wrapper">
          <span className="size-l">{props.birthday}</span>
        </div>
      </div>
      <div className="confirm-group">
        <p className="confirm-label">会社名</p>
        <div className="confirm-wrapper">
          <span className="size-l">{props.companyName}</span>
        </div>
      </div>
      <div className="confirm-group">
        <p className="confirm-label">電話番号</p>
        <div className="confirm-wrapper">
          <span className="size-s">{props.telNo}</span>
        </div>
      </div>
      <div className="confirm-group">
        <p className="confirm-label">メールアドレス</p>
        <div className="confirm-wrapper">
          <span className="size-l">{props.emailAddress}</span>
        </div>
      </div>
      <div className="confirm-group">
        <p className="confirm-label">ご用件</p>
        <div className="confirm-wrapper">
          <span className="size-l">{props.note}</span>
        </div>
      </div>
    </>
  )
}
