import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useBankContext } from "../../util/BankContext";
import StaticPageLayout from "../StaticPageLayout";


const useStyles = makeStyles((theme) => ({
  notLoginMain: {
    width: "100vw !important",
    height: "100vh !important",
  },
  policyContents:  {
    
    "& h2.box-title:before": {
      "width": "0 !important",
      "marginRight": "0 !important",
    }
  },
  policyBody: {
    marginTop: "3em",
    "& ol": {
      listStyleType: "decimal",
      paddingLeft: "1.1rem",
      "&>li": {
        listStyle: "decimal",
        listStylePosition: "outside",
        margin: "2.4em auto"
      }
    },
    "& table": {
        marginTop: "2.4em",
        "& td, & th": {
          borderSpacing: "1",
          border: "1px #333 solid",
          padding: "0.4em",
          verticalAlign: "top"
        },
        "& th": {
          width: "25%",
          textAlign: "center",
          fontWeight: "bold",
          backgroundColor: "#e0e0e0",
        }
    }
  }
}));

const CookiePolicy = (props) => {
  const bank = useBankContext();
  const classes = useStyles();
  return (
    <StaticPageLayout title="クッキーポリシー">
      <div className={"box " + classes.policyContents}>
        <h2 className="box-title">クッキーポリシー</h2>
        <div className={classes.policyBody}>
          <ol>
            <li>当行が運営・管理するWebサイト「{bank.baPortalName}」及び当該サイトで提供される各種機能（以下総称して「本サービス」といいます）においては、クッキー（Cookie）、ウェブビーコン、端末識別子、SDK（以下「クッキー等」といいます）を使用しています。当行はクッキー等を通じて、本サービスへの利用者のアクセス情報、閲覧情報等を取得することができます。なお、クッキー等を通じて取得するこれらの情報には、単独で利用者自身を識別し特定できる情報は含まれていません。</li>
            <li>利用者は本サービス上でのクッキー等の使用を許可しない場合には、利用者のブラウザの設定においてクッキー等を無効にしてください。なお、クッキー等を無効にした場合、本サービスの利便性が損なわれたり、本サービスで提供するサービスのご利用範囲が限定されたりすることがあります。</li>
            <li>本サービスで利用しているクッキー等の内容については、以下をご参照ください。</li>
          </ol>
          <table>
            <thead>
              <tr>
                <th>クッキー等の名称</th>
                <th>送信先</th>
                <th>取得する情報</th>
                <th>利用目的</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Google Analytics</td>
                <td>Google LLC</td>
                <td>利用者が閲覧したページのURL、滞在時間等</td>
                <td>
                  本サービスの利用状況を把握することにより、サービス向上及び利用者の興味やニーズにより適したサービスを提供するため<br />※Google Analyticsにおけるデータ取り扱いの詳細は<a href="https://policies.google.com/technologies/partner-sites?hl=ja" target="_blank">こちら</a>をご確認ください
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </StaticPageLayout>
  )
}

export default CookiePolicy;
