import React from "react";
import Header from './Header';
import Footer from './Footer';
import LeftColumn from "./LeftColumn";
import AfterLoginTopImage from "./AfterLoginTopImage";
import AfterLoginTopNotification from "./AfterLoginTopNotification";
import {useSelector} from "react-redux";
import AfterLoginTopNews from "./AfterLoginTopNews";
import AfterLoginTopManagementColumn from "./AfterLoginTopManagementColumn";
import AfterLoginTopPrBanner from "./AfterLoginTopPrBanner";
import AfterLoginTopAbout from "./AfterLoginTopAbout";
import { useBankContext } from "../util/BankContext";
import useMediaQuery from '@material-ui/core/useMediaQuery';


const useChatUnreadCount = () => {
    const chatUnreadCount = useSelector((state) => state.chatUnreadCount);
    return { chatUnreadCount }
}

export default function AfterLogin(props) {
    const matches = useMediaQuery('(min-width:600px)');
    const bank = useBankContext();
    const { chatUnreadCount } = useChatUnreadCount();

    return (
        <div className="wrapper">
            <Header />
            <div className="content">
                {(matches) && (
                    <LeftColumn />
                )}
                <div className="main">
                    <AfterLoginTopImage realm={bank.realm} />
                    {chatUnreadCount > 0 && (
                        <AfterLoginTopNotification chatUnreadCount={chatUnreadCount} />
                    )}
                    <AfterLoginTopNews realm={bank.realm} />
                    {/*.information*/}

                    {/*<AfterLoginTopSeminar />*/}

                    <div className="wrapper-2column">
                        <div className="inner-2column">

                            <AfterLoginTopManagementColumn realm={bank.realm} />
                            {/*.left-column*/}

                            <AfterLoginTopPrBanner realm={bank.realm} />
                            {/*.right-column*/}
                        </div>
                        {/*.inner-2column*/}
                    </div>

                    <AfterLoginTopAbout />
                    {/*.about-site*/}

                    <Footer />
                </div>
            </div>
        </div>
    )
}
