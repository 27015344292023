import React, {useEffect, useState} from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import moment from "moment";
import 'moment/locale/ja';
import { useReactOidc } from '@axa-fr/react-oidc-context';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Header from "../../components/Header";
import LeftColumn from "../../components/LeftColumn";
import Footer from "../../components/Footer";
import Api from "../../util/api";
import { useBankContext } from "../../util/BankContext";
import XbaApi from "../../util/xbaapi";

const useStyles = makeStyles((theme) => ({
    linkText: {
        color: "#159E43",
        cursor: "pointer",
        textDecoration: "underline",
        '&:hover': {
            textDecoration: "none",
        },
    },
    footer: {
        marginTop: "auto",
    },
    categoryAndDate: {
        marginTop: '10px',
        marginLeft: '12px',
        fontSize: '10px',
        color: '#666',
        fontWeight: 'bold'
    },
    title: {
        marginTop: '8px',
        marginLeft: '12px',
        fontWeight: 'bold',
        fontSize: '14px',
        letterSpacing: '1px',
        lineHeight: '1.7em',
        color: '#444'
    },
    companyImageArea:{
        width:'70px',
        height:'30px',
        backgroundColor:'white',
        margin:'8px',
        padding: '5px 8px'
    },
    companyNameArea:{
        width:'218px',
        height:'30px',
        marginTop:'8px',
        fontSize: '10px'
    },
    btnArea: {
        marginTop: '10px',
        textAlign: 'center'
    }
}));

const BusinessSuccessionColumn = (props) => {
    const matches = useMediaQuery('(min-width:600px)');
    const bank = useBankContext();
    window.document.title = '事業承継コラム|' + bank.baPortalName;
    moment.locale('ja');
    const classes = useStyles();
    const [businessSuccessionColumnListPagingData, setBusinessSuccessionColumnListPagingData] = useState({});
    const [businessSuccessionColumnList, setBusinessSuccessionColumnList] = useState([]);
    const { oidcUser } = useReactOidc();

    const moreViewBusinessSuccessionColumn = async (nextPage, event) => {
        // 経営コラム一覧取得API
        const newBusinessSuccessionColumnList = await XbaApi.get(`${bank.baUrl}api/v1/ba/succession/column/search`, {row: bank.params.column.listRows, page: nextPage}, oidcUser);
        setBusinessSuccessionColumnListPagingData(newBusinessSuccessionColumnList.result.paging_data);
        setBusinessSuccessionColumnList(businessSuccessionColumnList.concat(newBusinessSuccessionColumnList.result.list));
    };

    useEffect( () => {
        if (!oidcUser) {
            return;
        }
        if (bank.canUseGA) {
            window.gtag('config', bank.gaTrackingCode, {
                'page_path': `${props.location.pathname}${props.location.search}`
            });
        }

        const fetchData = async () => {
            // 経営コラム一覧取得API
            const businessSuccessionColumnList = await XbaApi.get(`${bank.baUrl}api/v1/ba/succession/column/search`, {}, oidcUser);
            if (businessSuccessionColumnList.result.list.length <= 0) {
                setBusinessSuccessionColumnList([]);
            } else {
                setBusinessSuccessionColumnList(businessSuccessionColumnList.result.list);
            }
            setBusinessSuccessionColumnListPagingData(businessSuccessionColumnList.result.paging_data);
        };

        fetchData()
            .then()
            .catch(e => {
                console.log(e)
                if( e.response && e.response.status === 401  ){
                    // ログインが必要なとき
                    sessionStorage.clear();
                    window.location.href = "/";
                }
            });
    }, [oidcUser, bank, props]);

    return (
        <div className="wrapper">
            <Header />
            <div className="content">
                {(!!oidcUser && matches) && (
                    <LeftColumn />
                )}
                <div className="main">
                    <ul className="breadcrumb">
                        <li><Link to={"/"}>トップ</Link></li>
                        <li>事業承継コラム</li>
                    </ul>
                    <div className="box">
                        <h2 className="box-title business-succession">事業承継コラム</h2>
                        <div className="article">
                            <ul>
                                {businessSuccessionColumnList.map((businessSuccessionColumn) => (
                                    <li key={businessSuccessionColumn.ba_succession_column_unique_code}>
                                        <div className="wrapper-image">
                                            <img 
                                                src={`${bank.baManagerUrl}picture/bank/${businessSuccessionColumn.a_cover_image_file_unique_code}`}
                                                alt={businessSuccessionColumn.ba_succession_column_title}
                                            />
                                        </div>
                                        <div>
                                            <div style={{backgroundColor: '#F6F6F6'}}>
                                                <Grid container spacing={0}>
                                                    <Grid className={classes.companyImageArea}>
                                                    <img
                                                        src={`${bank.baManagerUrl}picture/bank/${businessSuccessionColumn.succession_column_posting_company_image_file_unique_code}`} 
                                                        style={{width:'54px',height:'20px'}}
                                                    />
                                                    </Grid>
                                                    <Grid className={classes.companyNameArea}>
                                                        {businessSuccessionColumn.succession_column_posting_company_name}
                                                    </Grid>
                                                </Grid>
                                            </div>
                                            <div className={classes.categoryAndDate}>
                                                <p className="date">{moment(businessSuccessionColumn.ba_succession_column_created_at).format('YYYY.M.D')}</p>
                                            </div>
                                            <p className={classes.title}>
                                                {businessSuccessionColumn.ba_succession_column_title}
                                            </p>
                                            <div className={classes.btnArea}>
                                                <Link to={"/succession_column/detail/" + businessSuccessionColumn.ba_succession_column_unique_code} className="btn white-color size-m">
                                                    記事の続きを見る
                                                </Link>
                                            </div>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                            {!businessSuccessionColumnListPagingData.is_last_page && (
                                <div className="text-center">
                                    <span className={classes.linkText} onClick={moreViewBusinessSuccessionColumn.bind(this, businessSuccessionColumnListPagingData.next_page)}>もっと見る</span>
                                </div>
                            )}
                        </div>
                        {/*/.article*/}
                    </div>
                    <Box className={classes.footer}>
                        <Footer />
                    </Box>
                </div>
            </div>
        </div>
    )
}

export default BusinessSuccessionColumn;
