import React, { useEffect } from "react";
import { useReactOidc } from '@axa-fr/react-oidc-context';

import Api from "../util/api";
import { useBankContext } from "../util/BankContext";
import useSafeState from "../helpers/useSafeState";
import useUnmountRef from "../helpers/useUnmountRef";


const AfterLoginTopPrBanner = (props) => {
    const unmountRef = useUnmountRef();
    const [prBannerList, setPrBannerList] = useSafeState(unmountRef, []);
    const { oidcUser } = useReactOidc();
    const bank = useBankContext();

    const clickPrBannerLink = (prBannerUniqueCode) => {
        let params = {};

        // ログインユーザーの所属する企業のユニークコード
        params["c"] = oidcUser.profile.ba_company_unique_code;
        // 押下したバナーのユニークコード
        params["b"] = prBannerUniqueCode;

        Api.post(bank.realm, process.env.REACT_APP_INFORMATION_BASE_URL + '/notify/bannerclick', params, {}, oidcUser);
    }

    useEffect( () => {

        const fetchData = async () => {
            // PRバナー一覧取得API
            const prBannerList = await Api.get(props.realm, process.env.REACT_APP_INFORMATION_BASE_URL + '/banners', {}, {}, oidcUser);
    
            if (prBannerList.banners === null) {
                setPrBannerList([]);
            } else {
                setPrBannerList(prBannerList.banners);
            }
        }

        fetchData()
            .then()
            .catch(e => {
                // todo エラーハンドリング
                if( e.response ){
                    if( e.response.status === 403 ){
                        // todo 「ログインしてください」を表示
                        // todo ユーザー情報の削除
                        // location.href = "/";
                    }
                    //todo エラーであることを知らせる表示
                }
            })
    }, [oidcUser, props, setPrBannerList]);

    return (
        <div className="right-column pr">
            <h2 className="title-icon pr-title">
                PR
            </h2>
            <ul>
                {prBannerList.map((prBanner) => (
                    <li key={prBanner.id}>
                        <div className="wrapper-image">
                            <a onClick={clickPrBannerLink.bind(this, prBanner.banner_unique_code)} href={prBanner.url} target={"_blank"} rel="noopener noreferrer">
                                <img src={`${bank.baManagerUrl}picture/bank/${prBanner.file_unique_code}`} alt={prBanner.title} />
                            </a>
                        </div>
                        <div className="wrapper-prlink">
                            <a onClick={clickPrBannerLink.bind(this, prBanner.banner_unique_code)} href={prBanner.url} target={"_blank"} rel="noopener noreferrer">{prBanner.title}</a>
                        </div>
                    </li>
                ))}
            </ul>
        </div>
    )
};

export default AfterLoginTopPrBanner;
