const initialState = {
    loggedIn: false,
    isEdit: false,
    branchId: '',
    menuId: '',
    branchName: '',
    menuName: '',
    menuDuration: '',
    reservedDate: '',
    appointmentId: '',
    chatUnreadCount: 0,
}

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case 'SET_LOGGED_IN':
            return {
                ...state,
                loggedIn: state.loggedIn = action.loggedIn,
            }
        case 'SET_IS_EDIT':
            return {
                ...state,
                isEdit: state.isEdit = action.isEdit,
            }
        case 'SET_BRANCH_ID':
            return {
                ...state,
                branchId: state.branchId = action.branchId,
            }
        case 'SET_MENU_ID':
            return {
                ...state,
                menuId: state.menuId = action.menuId,
            }
        case 'SET_BRANCH_NAME':
            return {
                ...state,
                branchName: state.branchName = action.branchName,
            }
        case 'SET_MENU_NAME':
            return {
                ...state,
                menuName: state.menuName = action.menuName,
            }
        case 'SET_MENU_DURATION':
            return {
                ...state,
                menuDuration: state.menuDuration = action.menuDuration,
            }
        case 'SET_RESERVED_DATE':
            return {
                ...state,
                reservedDate: state.reservedDate = action.reservedDate,
            }
        case 'SET_APPOINTMENT_ID':
            return {
                ...state,
                appointmentId: state.appointmentId = action.appointmentId,
            }
        case 'SET_CHAT_UNREAD_COUNT':
            return {
                ...state,
                chatUnreadCount: state.chatUnreadCount = action.chatUnreadCount,
            }
        default:
            return state
    }
}
