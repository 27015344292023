import React from 'react';
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";

import { useReactOidc } from '@axa-fr/react-oidc-context';

import Api from "../util/api";
import { useBankContext } from "../util/BankContext";


const useStyles = makeStyles((theme) => ({
    linkText: {
        color: "rgba(0, 0, 0, 0.87) !important",
        cursor: "pointer",
        '&:hover': {
            textDecoration: "underline",
        },
    },
}));

export default function Footer() {
    const classes = useStyles();
    const { oidcUser, logout } = useReactOidc();
    const bank = useBankContext();

    const clickBalanceLink = () => {
        const bizsolLimitedFunctionKbn = Number(oidcUser.oidcUser?.profile.bizsol_limited_function_kbn);
        Api.get(
            bank.realm,
            process.env.REACT_APP_ANSER_BIZ_SOL_BASE_URL + '/redirect',
            {
                o: oidcUser?.profile.bizsol_onetime_key,
                s: oidcUser?.profile.bizsol_session_key,
                a: oidcUser?.profile.bizsol_user_authentication_code,
                b: oidcUser?.profile.bizsol_access_token,
                i: oidcUser?.profile.bizsol_sub,
            },
            {},
            oidcUser,
        )
          .then((result) => {
              if (result.location.toString() === '') {
                  alert("ブラウザの「閉じる」ボタンを押して、ブラウザを閉じた後、再度ログインしてください。");
                  //keycloak.logout({redirectUri: window.location.protocol + "//" + window.location.host + "/login"});
                  logout();
              } else {
                  if (bizsolLimitedFunctionKbn === 1 || bizsolLimitedFunctionKbn === 2) {
                      window.open(result.location + "&smart=b", "_blank");
                  } else {
                      window.open(result.location, "_blank");
                  }
              }
          });
    };

    return (
        <div className="footer">
            <div className="footer-wrapper">
                <div className="bank-logo">
                    <img src={`/img/${bank.staticFilePathPrefix}/bank_logo.png`} alt={bank.bankName} />
                </div>
                {oidcUser && (
                    <>
                        <ul>
                            {bank.realm === 'bank0158' && (
                                <li><span onClick={clickBalanceLink} className={classes.linkText}>残高照会</span></li>
                            )}
                            {bank.canUseAppointment && (
                                <li><Link to={"/appointment"}>来店予約</Link></li>
                            )}
                            <li><Link to={"/ba_chat"}>
                                { bank.realm === 'bank0188' ? (
                                  <span>金融機関連絡チャット</span>
                                ) : (
                                  <span>チャット</span>
                                )}
                            </Link></li>
                        </ul>
                        <ul>
                            <li><Link to={"/seminar"}>セミナー予約</Link></li>
                            <li><Link to={"/management_column"}>経営コラム</Link></li>
                            <li><Link to={"/news"}>お知らせ</Link></li>
                        </ul>
                        <ul>
                            <li><Link to={"/page/cookie_policy"}>クッキーポリシー</Link></li>
                            <li><Link to={"/page/rule"}>利用規約</Link></li>
                        </ul>
                    </>
                )}
            </div>
            {bank.realm === 'bank0158' && (
                <div className="footer-other">
                    <p className="about-bank">
                        株式会社京都銀行 登録金融機関　近畿財務局長（登金）第10号　加入協会 日本証券業協会、一般社団法人金融先物取引業協会
                    </p>
                    <p className="copy-right">
                        Copyright © 2020 Bank of Kyoto, Ltd. All Rights Reserved.
                    </p>
                </div>
            )}
            {bank.realm === 'bank0188' && (
                <div>
                    <p className="copy-right">
                        Copyright ©2020 The Bank of Okinawa,Ltd. All Rights Reserved.
                    </p>
                </div>
            )}
        </div>
    );
}
