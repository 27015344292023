import React, { useCallback, useEffect } from "react";
import { useHistory } from 'react-router-dom';
import clsx from "clsx";

import topStyle from '../../css/okba/custom_top.less';
import effectStyle from '../../css/effect.less';
import { useBankContext } from "../../util/BankContext";
import BeforeLoginFooter from "./BeforeLoginFooter";
import BeforeLoginHeader from "./BeforeLoginHeader";



const BeforeLogin =  () => {
    const bank = useBankContext();
    const history = useHistory();

    window.document.title = 'おきぎん Big Advance Basic';

    const btnKeyColorBorder = clsx(topStyle.btn, topStyle.keyColorBorder);
    const goHowToBtn = clsx(topStyle.serviceStart, topStyle.btn, topStyle.keyColorFill);
    const contentMv = clsx(topStyle.content, topStyle.mv);
    const innerVisualAndCopy = clsx(topStyle.contentInner, topStyle.visualAndCopy);
    const effectFadePos80 = clsx(effectStyle.effectFade, effectStyle.pos80, "effect-fade");
    const contentMessage = clsx(topStyle.content, topStyle.message);
    const mainMessageEffectFadePos40 = clsx(topStyle.mainMessage, effectStyle.effectFade, effectStyle.pos40);
    const subMessageEffectFadePos40 = clsx(topStyle.subMessage, effectStyle.effectFade, effectStyle.pos40);
    const contentHowto = clsx(topStyle.content, topStyle.howto);
    const contentInnerHowtoInner = clsx(topStyle.contentInner, topStyle.howtoInner);
    const attentionEffectFadePos80Fast = clsx(topStyle.attention, effectStyle.effectFade, effectStyle.pos80, effectStyle.fast);
    const boxesEffectFadePos80 = clsx(topStyle.howtoBoxes, effectStyle.effectFade, effectStyle.pos80);
    const boxLeft = clsx(topStyle.box, "left");
    const boxRight = clsx(topStyle.box, "right");
    const contentFunctions = clsx(topStyle.content, topStyle.functions);
    const boxEffectFadePos80 = clsx(topStyle.box, effectStyle.effectFade, effectStyle.pos80);
    const contentScheme = clsx(topStyle.content, topStyle.scheme);
    const contentStart = clsx(topStyle.content, topStyle.start);
    const getAccountBtnSizeXl = clsx(topStyle.btn, topStyle.sizeXl, topStyle.getAccountBtn);

    const loginAction = useCallback((event) => {
      event.preventDefault();
      history.push("/login");
    }, [history]);

    const scrollEffect = () => {
        const effectFadeElements = document.getElementsByClassName(effectStyle.effectFade);
        Array.from(effectFadeElements).forEach(function(val, index) {
            const userAgent = window.navigator.userAgent.toLowerCase();

            // IEの場合スクロール位置の取得が上手くできていないため、スクロール位置に関係なくclassの付与を行い画像を表示させる
            if (userAgent.indexOf('msie') !== -1 || userAgent.indexOf('trident') !== -1) {
                val.classList.add(effectStyle.effectScroll);
            } else {
                const rect = val.getBoundingClientRect();
                const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
                const elemPos = rect.top + scrollTop;
                const scroll = window.scrollY;
                const windowHeight = window.innerHeight;

                if (scroll > elemPos - windowHeight) {
                    val.classList.add(effectStyle.effectScroll);
                }
            }
        })
    };

    useEffect( () => {
        scrollEffect();
        document.addEventListener('scroll', scrollEffect);
    }, []);

    return (
        <>
            <BeforeLoginHeader />
            <main>
                <div className={contentMv}>
                    <div className={innerVisualAndCopy}>
                        <div className={topStyle.copy}>
                            <p className={topStyle.mainCopy}>
                                お客様とともに未来を創る<br />
                                <span>-Create the Future-</span>
                            </p>
                            <a href="#howto" className={goHowToBtn}>サービスのご利用はこちらから</a>
                        </div>
                        <div className={topStyle.scroll}>
                            <span /><span /><span />
                            <p>SCROLL</p>
                        </div>
                    </div>
                </div>
                <div className={contentMessage}>
                    <p className={mainMessageEffectFadePos40}>
                        “<strong>今、おきぎんが</strong>できること”
                    </p>
                    <p className={subMessageEffectFadePos40}>
                        デジタルを活用して今、おきぎんにできること。<br />
                        それはもっと身近に、もっと便利に金融サービスを活用してもらうこと。<br />
                        おきぎんが提供する様々なサービスへの総合入り口として、<br />
                        おきぎん Big Advance Basicは誕生しました。
                    </p>
                </div>
                <div id="howto" className={contentHowto}>
                    <div className={contentInnerHowtoInner}>
                        <p className={attentionEffectFadePos80Fast}>
                            “おきぎん Big Advance のアカウントが必要です”
                        </p>
                        <div className={boxesEffectFadePos80}>
                            <div className={topStyle.boxWrapper}>
                                <div className={boxLeft}>
                                    <p className={topStyle.boxTitle}>
                                        ご利用中<strong>のお客様</strong>
                                    </p>
                                    <p className={topStyle.boxBody}>
                                        おきぎん Big Advance をご利用中の方であれば同じアカウントでご利用いただけます。下記から利用開始の手続きをお願いいたします。
                                    </p>
                                    <a href="/login" onClick={loginAction}  className={btnKeyColorBorder}>ログイン(利用開始)</a>
                                </div>
                            </div>
                            <div className={topStyle.boxWrapper}>
                                <div className={boxRight}>
                                    <p className={topStyle.boxTitle}>
                                        未登録<strong>のお客様</strong>
                                    </p>
                                    <p className={topStyle.boxBody}>
                                        おきぎん Big Advance のアカウント取得をお願いいたします。取得したアカウントでおきぎん Big Advance Basicもご利用いただけます。
                                    </p>
                                    <a href={`${bank.baUrl}register/portal`} className={btnKeyColorBorder}>新規登録</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={contentFunctions}>
                    <div className={topStyle.contentInner}>
                        <p className={topStyle.functionsTitle}>
                            “<strong>おきぎん Big Advance Basicで、</strong>できること”
                        </p>
                        <p className={topStyle.functionsMessage}>
                            おきぎん Big Advance Basic はセミナーの予約や支店担当者とのチャットなど、おきぎんとのコミュニケーションツールとしてご利用いただけます。
                        </p>
                        <ul className={topStyle.functionsBoxes}>
                            <li className={boxEffectFadePos80}>
                                <img src={`/img/${bank.staticFilePathPrefix}/menu_chat.png`} alt="" /><br />
                                <p className={topStyle.title}>チャット</p>
                                <p className={topStyle.body}>支店の担当者、本部とチャットで気軽に<br />やりとりができます。</p>
                            </li>
                            <li className={boxEffectFadePos80}>
                                <img src={`/img/${bank.staticFilePathPrefix}/menu_seminar.png`} alt="" /><br />
                                <p className={topStyle.title}>セミナー予約</p>
                                <p className={topStyle.body}>金融機関が主催するセミナー情報を配信。<br />参加予約もかんたんです。</p>
                            </li>
                            <li className={boxEffectFadePos80}>
                                <img src={`/img/${bank.staticFilePathPrefix}/menu_column.png`} alt="" /><br />
                                <p className={topStyle.title}>経営コラム</p>
                                <p className={topStyle.body}>税務財務・法務・労務・知財・経営の各分野の<br />専門家コラムを無料で配信します。</p>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className={contentScheme}>
                    <div className={topStyle.contentInner}>
                        <p className={topStyle.schemeTitle}>
                            “<strong>各金融サービスとの</strong>連携”
                        </p>
                        <p className={topStyle.schemeMessage}>
                            おきぎん Big Advance Basicを窓口として様々なサービスへのアクセスが可能になります。
                        </p>
                        <div className={topStyle.schemeImage}>
                            <img src={`/img/${bank.staticFilePathPrefix}/top/pc_01.png`} alt="" className={effectFadePos80} />
                        </div>
                    </div>
                </div>
                <div className={contentStart}>
                    <div className={topStyle.contentInner}>
                        <p className={topStyle.text}>
                            おきぎん Big Advance のアカウントをお持ちでない場合はこちらからID取得(無料)をお願いいたします
                        </p>
                        <a href={`${bank.baUrl}register/portal`} className={getAccountBtnSizeXl}>新規登録はこちらから</a>
                    </div>
                </div>
            </main>
            <BeforeLoginFooter />
        </>
    );
};

export default BeforeLogin;
