import React, {useEffect} from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import moment from "moment";
import 'moment/locale/ja';
import { useReactOidc } from '@axa-fr/react-oidc-context';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import Header from "../../components/Header";
import LeftColumn from "../../components/LeftColumn";
import Footer from "../../components/Footer";
import Api from "../../util/api";
import { useBankContext } from "../../util/BankContext";


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        '& table': {
            border: "1px solid #000",
            borderCollapse: "collapse",
            width: "100%",
        },
        '& tr': {
            height: "50px",
        },
        '& th': {
            background: "#E8E8E8",
        },
        '& td': {
            textAlign: "center",
        },
    },
    content: {
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        height: '100vh',
        minHeight: "100vh",
        overflow: 'auto',
    },
    title: {
        backgroundColor: theme.palette.text.primary,
        padding: "10px 8px",
        '& p': {
            color: theme.palette.background.default,
        },
    },
    image: {
        position: "relative",
    },
    noImage: {
        background: "#efefef",
        color: "#aaa",
        height: "100%",
        letterSpacing: 2,
        lineHeight: "500px",
        textAlign: "center",
    },
    managementColumnDescBox: {
        position: "relative",
        top: "-20px",
        '& h2': {
            color: "rgba(0, 0, 0, 0.87)",
        },
    },
    managementColumnTitle: {
        backgroundColor: "#444",
        lineHeight: "1.75em",
        margin: "0 auto",
        padding: theme.spacing(2, 2),
        width: 850,
        '& p': {
            color: theme.palette.background.default,
        },
    },
    managementColumnContent: {
        lineHeight: "1.75em",
        marginTop: "30px",
        paddingBottom: "15px",
        whiteSpace: "normal",
        '& h2.caption': {
            display: "block",
            fontFamily: "'Noto Sans Japanese', sans-serif",
            fontSize: 18,
            letterSpacing: 1,
            lineHeight: "1.41",
            padding: "15px 15px 15px 42px",
            position: "relative",
            fontWeight: 200,
        },
        '& h2:not(.caption)': {
            borderBottom: "1px solid #aaa",
            fontFamily: "'Noto Sans Japanese', sans-serif",
            fontSize: 18,
            fontWeight: 400,
            letterSpacing: 1,
            marginBottom: 10,
            padding: "15px 15px 15px 25px",
            paddingBottom: 4,
            position: "relative",
            '&:before': {
                background: "#19AAC4",
                content: "''",
                height: 8,
                left: 0,
                position: "absolute",
                top: 26,
                width: 8,
            },
            '&:after': {
                background: "#D1D1D1",
                content: "''",
                height: 8,
                left: 5,
                position: "absolute",
                top: 21,
                width: 8,
            },
        },
    },
    date: {
        textAlign: "right",
    },
    backManagementColumnListLinkBox: {
        marginTop: 20,
        textAlign: "center",
    },
    backManagementColumnListLink: {
        color: "#19AAC4",
        '&:hover': {
            textDecoration: "none",
        },
    },
    footer: {
        marginTop: "auto",
    },
}));

const ManagementColumnDetail = (props) => {
    const matches = useMediaQuery('(min-width:600px)');
    const bank = useBankContext();
    window.document.title = '経営コラム|' + bank.baPortalName;
    moment.locale('ja');
    const classes = useStyles();
    const { oidcUser } = useReactOidc();

    const [managementColumnDetail, setManagementColumnDetail] = React.useState({});

    useEffect( () => {
        if (!oidcUser) {
            return;
        }

        if (bank.canUseGA) {
            window.gtag('config', bank.gaTrackingCode, {
                'page_path': `${props.location.pathname}${props.location.search}`
            });
        }

        const fetchData = async (uniqueCode) => {
            // 経営コラム詳細取得API
            const managementColumnDetail = await Api.get(bank.realm, process.env.REACT_APP_COLUMN_BASE_URL + '/column', {c: uniqueCode}, {}, oidcUser);
            setManagementColumnDetail(managementColumnDetail.column);
        };

        fetchData(
            props.match.params.unique_code,
        )
            .then()
            .catch(e => {
                // todo エラーハンドリング
                if( e.response ){
                    if( e.response.status === 403 ){
                        // todo 「ログインしてください」を表示
                        // todo ユーザー情報の削除
                        // location.href = "/";
                    }
                    //todo エラーであることを知らせる表示
                }
            });
    }, [props, oidcUser, bank]);

    return (
        <div className="wrapper">
            <Header />
            <div className="content">
                {(!!oidcUser && matches) && (
                    <LeftColumn />
                )}
                <div className="main">
                    <ul className="breadcrumb">
                        <li><Link to={"/"}>トップ</Link></li>
                        <li><Link to={"/management_column"}>経営コラム</Link></li>
                        <li>詳細</li>
                    </ul>
                    <div className="box">
                        <div className="column-detail">
                            <div className="image-wrapper">
                                <img src={`${bank.baManagerUrl}picture/bank/${managementColumnDetail.imageFileUniqueCode}`} alt={managementColumnDetail.title} />
                            </div>
                            <div className="column-title">
                                <div className="panel">
                                    <p className="title">{managementColumnDetail.title}</p>
                                </div>
                            </div>
                        </div>
                        <div className="column-body" dangerouslySetInnerHTML={{__html : managementColumnDetail.content}} />
                        <div className=" text-center mt40">
                            <Link to={"/management_column"} className=" key-color">一覧に戻る</Link>
                        </div>
				    </div>
                    <Box className={classes.footer}>
                        <Footer />
                    </Box>
                </div>
            </div>
        </div>
    )
}

export default ManagementColumnDetail;
