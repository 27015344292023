import React, { useState, useEffect } from "react";
import useSafeState from "../../helpers/useSafeState";
import Api from "../../util/api";
import useUnmountRef from "../../helpers/useUnmountRef";
import { useReactOidc } from "@axa-fr/react-oidc-context";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import AdminHeader from "../../components/AdminHeader";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Switch from "@material-ui/core/Switch";
import { useBankContext } from "../../util/BankContext";

// スタイル指定
const useStyles = makeStyles((theme) => ({
    root: {
        padding: "0 15px",
        margin: "0 auto",
    },
    content: {
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        height: "100vh",
        minHeight: "100vh",
    },
    titleBox: {
        marginBottom: 10,
        padding: "0 15px",
    },
    title: {
        color: "inherit",
        fontFamily: "inherit",
        fontSize: 18,
        fontWeight: 500,
        lineHeight: "1.1",
        marginTop: 10,
        marginBottom: 10,
    },
    table: {
        width: "90%",
        margin: "0 auto",
        minWidth: 650,
        whiteSpace: "nowrap",
    },
    tableHead: {
        background: "#e1e0e1",
    },
    button: {
        background: "#457ab2",
        color: theme.palette.primary.color,
        height: "30%",
        margin: "10px 0",
        width: "60%",
    },
    linkText: {
        textDecoration: "none",
    },
}));

const ImageSetting = (props) => {
    const classes = useStyles();
    const bank = useBankContext();
    const bankID = bank.realm.replace("bank", "");
    window.document.title = "BA Portal";
    const unmountRef = useUnmountRef();
    const { oidcUser } = useReactOidc();
    const [imageList, setImageList] = useSafeState(unmountRef, []);

    useEffect(() => {
        fetchData();
    }, []);

    // 空のオブジェクトを判定する関数
    const isEmpty = (item) => {
        return Object.keys(item).length === 0;
    }

    const fetchData = async () => {
        const response = await Api.get(
            bank.realm,
            process.env.REACT_APP_BANNER_IMAGE_URL + "/banner_images",
            {
                bank_id: bankID,
            },
            {},
            oidcUser
        );
        if (response === null) {
            alert("正常にデータを読み込みませんでした");
        } else {
            // DisplayOrderが連番でない場合、空のオブジェクトを挿入する
            var formatData = [];
            var sortNumber = 1;
            for (var i = 0; i < response.length; i++) {
                while (response[i].DisplayOrder !== sortNumber) {
                    formatData.push({});
                    sortNumber++;
                }
                formatData.push(response[i]);
                if (!isEmpty(response[i])) {
                    sortNumber++;
                }
            }
            // 登録画像が５以下の場合からの配列を足す
            while (formatData.length < 5) {
                formatData.push({});
            }
            setImageList(formatData);
        }
    };

    const handleChangeDisplayFlag = async (event, index) => {
        const updateImageList = [...imageList];
        updateImageList[index].DisplayFlag = event.target.checked;
        setImageList(updateImageList);
        await Api.putBodyParam(
            bank.realm,
            process.env.REACT_APP_BANNER_IMAGE_URL + "/switch_display_flag",
            {
                id: updateImageList[index].ID,
                display_flag: updateImageList[index].DisplayFlag,
            },
            {},
            oidcUser
        );
    };

    return (
        <div className={classes.root}>
            <AdminHeader />
            <main className={classes.content}>
                <Box maxWidth="lg" className={classes.titleBox}>
                    <h4 className={classes.title}>TOP画面一覧</h4>
                </Box>
                <Table className={classes.table}>
                    <TableHead className={classes.tableHead}>
                        <TableRow>
                            <TableCell align="center">表示順</TableCell>
                            <TableCell align="center">表示ON/OFF</TableCell>
                            <TableCell align="center">画像</TableCell>
                            <TableCell align="center">URL</TableCell>
                            <TableCell align="center">編集/削除</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {imageList.map((image, index) => (
                            <TableRow key={image.ID}>
                                <TableCell
                                    align="center"
                                    style={{ width: "10%" }}
                                >
                                    {index + 1}
                                </TableCell>
                                <TableCell
                                    align="center"
                                    style={{ width: "20%" }}
                                    white-space="nowrap"
                                >
                                    OFF
                                    <Switch
                                        onChange={(event) =>
                                            image.ImagePath &&
                                            handleChangeDisplayFlag(
                                                event,
                                                index
                                            )
                                        }
                                        checked={image.DisplayFlag || false}
                                        color="primary"
                                        value={image.ID}
                                    />
                                    ON
                                </TableCell>
                                <TableCell
                                    align="center"
                                    style={{ width: "30%" }}
                                >
                                    {image.ImagePath && (
                                        <img
                                            src={image.ImagePath || "-"}
                                            height="150px"
                                            objectfit="contain"
                                        />
                                    )}
                                </TableCell>
                                <TableCell
                                    align="center"
                                    style={{ width: "20%" }}
                                >
                                    <a
                                        href={image.DestinationURL || "-"}
                                        target="_blank"
                                    >
                                        {image.DestinationURL}
                                    </a>
                                </TableCell>
                                <TableCell
                                    align="center"
                                    style={{ width: "20%" }}
                                >
                                    <Link
                                        className={classes.linkText}
                                        to={{
                                            pathname: image.DisplayOrder
                                                ? `/admin/image_setting_edit/${image.DisplayOrder}`
                                                : `/admin/image_setting_register/${
                                                      index + 1
                                                  }`,
                                            state: {
                                                image: image.DisplayOrder
                                                    ? image
                                                    : index + 1,
                                            },
                                        }}
                                    >
                                        <Button
                                            variant="outlined"
                                            className={classes.button}
                                        >
                                            編集
                                        </Button>
                                    </Link>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </main>
        </div>
    );
};

export default ImageSetting;
