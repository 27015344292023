import React, {useEffect, useState} from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { useReactOidc } from '@axa-fr/react-oidc-context';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import Header from "../../components/Header";
import LeftColumn from "../../components/LeftColumn";
import Footer from "../../components/Footer";
import Api from "../../util/api";
import { useBankContext } from "../../util/BankContext";


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        '& table': {
            border: "1px solid #000",
            borderCollapse: "collapse",
            width: "100%",
        },
        '& tr': {
            height: "50px",
        },
        '& th': {
            background: "#E8E8E8",
        },
        '& td': {
            textAlign: "center",
        },
    },
    content: {
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        height: '100vh',
        minHeight: "100vh",
        overflow: 'auto',
    },
    title: {
        backgroundColor: theme.palette.text.primary,
        padding: "10px 8px",
        '& p': {
            color: theme.palette.background.default,
        },
    },
    image: {
        position: "relative",
    },
    noImage: {
        background: "#efefef",
        color: "#aaa",
        height: "100%",
        letterSpacing: 2,
        lineHeight: "500px",
        textAlign: "center",
    },
    date: {
        bottom: 115,
        color: "#fff",
        fontFamily: "'Noto Sans Japanese', sans-serif",
        fontSize: 48,
        fontWeight: 400,
        left: 40,
        letterSpacing: 1,
        margin: 0,
        position: "absolute",
        textShadow: "0px 0px 6px rgba(0, 0, 0, 0.5)",
    },
    timeAndPlace: {
        bottom: 60,
        color: "#fff",
        fontFamily: "'Noto Sans Japanese', sans-serif",
        fontSize: 16,
        fontWeight: 300,
        left: 40,
        letterSpacing: 1,
        margin: 0,
        position: "absolute",
        textShadow: "0px 0px 4px rgba(0, 0, 0, 0.5)",
    },
    seminarDescBox: {
        position: "relative",
        top: "-20px",
    },
    seminarTitle: {
        backgroundColor: "#444",
        lineHeight: "1.75em",
        padding: theme.spacing(2, 2),
        '& p': {
            color: theme.palette.background.default,
        },
    },
    seminarContent: {
        borderBottom: "1px dotted #ccc",
        marginTop: "15px",
        paddingBottom: "15px",
        whiteSpace: "pre-wrap",
    },
    seminarDescription: {
        paddingBottom: "15px",
    },
    seminarDescriptionCaption: {
        display: "block",
        padding: "15px 15px 15px 20px",
        position: "relative",
        '&::before': {
            background: "#19AAC4",
            content: "''",
            height: 8,
            left: 0,
            position: "absolute",
            top: 26,
            width: 8,
        },
        '&::after': {
            background: "#D1D1D1",
            content: "''",
            height: 8,
            left: 4,
            position: "absolute",
            top: 21,
            width: 8,
        },
    },
    full: {
        color: "#F18D06",
    },
    seminarDocument: {
        borderTop: "1px dotted #ccc",
        paddingTop: "15px",
    },
    linkText: {
        color: "#1aabc3",
        cursor: "pointer",
        textDecoration: "underline",
        '&:hover': {
            color: "#262626",
        },
    },
    subTitle: {
        backgroundColor: theme.palette.text.primary,
        height: 50,
        marginTop: "10px",
        padding: theme.spacing(2, 6),
        '& p': {
            color: theme.palette.background.default,
        },
    },
    caption: {
        display: "block",
        position: "relative",
        '&::before': {
            background: "#19AAC4",
            content: "''",
            height: 8,
            left: -26,
            position: "absolute",
            top: 9,
            width: 8,
        },
        '&::after': {
            background: "#fff",
            content: "''",
            height: 8,
            left: -22,
            position: "absolute",
            top: 4,
            width: 8,
        },
    },
    dl: {
        margin: 0,
        padding: 0,
        position: "relative",
        width: "100%",
    },
    dd: {
        display: "inline-block",
        fontSize: "100%",
        fontWeight: "bold",
        margin: 0,
        padding: "20px 40px 0 40px",
        textAlign: "left",
        width: "100%",
    },
    titleBox: {
        float: "left",
        height: 40,
        lineHeight: "40px",
        listStyleType: "none",
        padding: 0,
        width: "30%",
    },
    seminarSubmitButtonBox: {
        textAlign: "center",
    },
    seminarSubmitButton: {
        background: "#19AAC4",
        color: theme.palette.primary.color,
        height: 40,
        margin: "20px 10px",
        width: 220,
    },
    memberCountBox: {
        marginTop: 40,
        textAlign: "center",
    },
    seminarCancelButtonBox: {
        textAlign: "center",
    },
    seminarCancelButton: {
        background: "#19AAC4",
        color: theme.palette.primary.color,
        height: 40,
        margin: "20px 10px",
        width: 115,
    },
    backSeminarListLinkBox: {
        marginTop: 20,
        textAlign: "center",
    },
    backSeminarListLink: {
        color: "#19AAC4",
        '&:hover': {
            textDecoration: "none",
        },
    },
    footer: {
        marginTop: "auto",
    },
}));

const SeminarDetail = (props) => {
    const matches = useMediaQuery('(min-width:600px)');
    const bank = useBankContext();
    window.document.title = 'セミナー予約|' + bank.baPortalName;
    const classes = useStyles();

    const { oidcUser } = useReactOidc();
    const [seminarDetail, setSeminarDetail] = useState({});
    const [seminarUser, setSeminarUser] = useState({
        memberCount: 0,
    });
    const [emailAddress, setEmailAddress] = useState('');
    const [participants, setParticipants] = useState('');
    const [openError, setOpenError] = useState(false);

    const handleChangeEmailAddress = (event) => {
        setEmailAddress(event.target.value);
    }

    const handleChangeParticipants = (event) => {
        if (event.target.value < 0) {
            setParticipants('');
            return;
        }

        setParticipants(event.target.value);
    }

    const handleClickSeminarSubmit = async (event) => {
        event.preventDefault();

        // セミナー予約処理
        const result = await Api.post(bank.realm, process.env.REACT_APP_SEMINAR_BASE_URL + '/reservation', {s: seminarDetail.id, m: emailAddress, c: participants}, {}, oidcUser);
        if (result.status === 500) {
            setOpenError(true);
        } else {
            props.history.push("/seminar");
        }
    }

    const handleCloseError = (event) => {
        setOpenError(false);
    }

    const handleClickSeminarCancel = async () => {
        // セミナーキャンセル処理
        await Api.delete(bank.realm, process.env.REACT_APP_SEMINAR_BASE_URL + '/reservation', {s: seminarDetail.id}, {}, oidcUser);
        props.history.push("/seminar");
    }

    useEffect( () => {
        if (!!oidcUser?.profile?.ba_user_email) {
            setEmailAddress(oidcUser.profile.ba_user_email);
        }

        const fetchData = async (uniqueCode) => {
            // セミナー詳細取得API
            const seminar = await Api.get(bank.realm, process.env.REACT_APP_SEMINAR_BASE_URL + '/seminar', {c: uniqueCode}, {}, oidcUser);
            setSeminarDetail(seminar);

            // 参加予定セミナー取得API
            const reservation = await Api.get(bank.realm, process.env.REACT_APP_SEMINAR_BASE_URL + '/reservation', {s: seminar.id}, {}, oidcUser);
            setSeminarUser(reservation);
        }

        fetchData(
            props.match.params.unique_code,
        )
            .then()
            .catch(e => {
                // todo エラーハンドリング
                if( e.response ){
                    if( e.response.status === 403 ){
                        // todo 「ログインしてください」を表示
                        // todo ユーザー情報の削除
                        // location.href = "/";
                    }
                    //todo エラーであることを知らせる表示
                }
            })
    }, [props, oidcUser, bank]);

    useEffect(() => {
        if (bank.canUseGA) {
            window.gtag('config', bank.gaTrackingCode, {
                'page_path': `${props.location.pathname}${props.location.search}`
            });
        }
    }, [bank.canUseGA, bank.gaTrackingCode, props.location.pathname, props.location.search]);

    return (
        <div className="wrapper">
            <Header />
            <div className="content">
                {(!!oidcUser && matches) && (
                    <LeftColumn />
                )}
                <div className="main">
                    <ul className="breadcrumb">
                        <li><Link to={"/"}>トップ</Link></li>
                        <li><Link to={"/seminar"}>セミナー予約</Link></li>
                        <li>詳細</li>
                    </ul>
                    <div className="box">
                        <h2 className="box-title seminar">セミナー予約</h2>
                        <div className="seminar-detail">
                            <div className="image-wrapper">
                                <img src={`${bank.baManagerUrl}picture/bank/${seminarDetail.imageFileCode}/1200x`} alt={seminarDetail.title} />
                            </div>
                            <div className="seminar-info-title">
                                <div className="panel">
                                    <p className="date">日時：{seminarDetail.date}</p>
                                    <p className="time">時間：{seminarDetail.time}</p>
                                    <p className="location">開催場所：{seminarDetail.location}</p>
                                </div>
                            </div>
                        </div>
                        <div className="seminar-summary">
                            <h3 className="box-title">{seminarDetail.title}</h3>
                            <p className="body" dangerouslySetInnerHTML={{__html : seminarDetail.content}} />
                        </div>
                        <div className="seminar-detail-info">
                            <h3 className="box-title">詳細</h3>
                            <p className="body">
                                参加費：{seminarDetail.fee}<br />
                                定員：{seminarDetail.capacity}名
                                {(seminarDetail.available <= 0) && (
                                    <span className={classes.full}>（満席）</span>
                                )}
                                {!(seminarDetail.available <= 0) && (seminarDetail.fewRemaining) && (
                                    <span className={classes.full}>（残りあとわずか）</span>
                                )}
                                <br />
                                日時：{seminarDetail.time}<br />
                                開催場所：{seminarDetail.location}<br />
                                主催：{seminarDetail.organizer}<br />
                            </p>
                        </div>
                        {seminarDetail.materialsFileCode !== null && seminarDetail.materialsFileCode !== "" && (
                            <div className="pdf">
                                <p className="body">
                                    セミナーの詳細資料は下記のPDFをご参照ください。ダウンロードして印刷可能です。<br />
                                    <a href={`${bank.baManagerUrl}file/download/bank/${seminarDetail.materialsFileCode}`} 
                                        className="key-color" 
                                        target="_blank" 
                                        rel="noopener noreferrer">
                                        セミナー詳細資料(PDF)
                                    </a>
                                </p>
                            </div>
                        )}
                        {seminarDetail.showApplicationForm && Number(seminarUser.memberCount) === 0 && (
                            <>
                                <h3 className="box-title">申込フォーム</h3>
                                <form className="seminar">
                                    <div className="input-group">
                                        <p className="input-label">メールアドレス</p>
                                        <div className="input-wrapper">
                                            <input type="text" onChange={handleChangeEmailAddress} value={emailAddress} />
                                        </div>
                                    </div>
                                    <div className="input-group">
                                        <p className="input-label">参加人数</p>
                                        <div className="input-wrapper">
                                            <input type="number" className="size-xs" onChange={handleChangeParticipants} value={participants} />名
                                        </div>
                                    </div>

                                    <div className="text-center mt24">
                                        <button className="btn key-color size-l arrow mt16 btnSp" onClick={handleClickSeminarSubmit}>このセミナーに参加する</button>
                                    </div>
                                    <Snackbar open={openError} autoHideDuration={6000} onClose={handleCloseError}>
                                        <MuiAlert elevation={6} variant="filled" onClose={handleCloseError} severity="error">
                                            定員を超えたため、申込できませんでした
                                        </MuiAlert>
                                    </Snackbar>
                                </form>
                            </>
                        )}
                        {Number(seminarUser.memberCount) !== 0 && (
                            <>
                                <Box className={classes.memberCountBox}>
                                    <Typography variant="body1" color="textPrimary">
                                        {seminarUser.memberCount}名様の参加でお申込み済みです。
                                    </Typography>
                                </Box>
                                <Box className={classes.seminarCancelButtonBox}>
                                    <button className="btn key-color size-m mt16 btnSp" onClick={handleClickSeminarCancel}>キャンセル</button>
                                </Box>
                            </>
                        )}
                        <div className="text-center mt40">
                            <Link to={"/seminar"} className="key-color">一覧に戻る</Link>
                        </div>
                    </div>
                    <Box className={classes.footer}>
                        <Footer />
                    </Box>
                </div>
            </div>
        </div>
    )
}

export default SeminarDetail;
