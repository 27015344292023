import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from "@material-ui/core/Box";
import clsx from "clsx";
import { useReactOidc } from '@axa-fr/react-oidc-context';

import AdminHeaderList from "./AdminHeaderList";
import { useOnClickOutside } from "../util/useOutSideClick";
import { useBankContext } from "../util/BankContext";
import useSafeState from "../helpers/useSafeState";
import useUnmountRef from "../helpers/useUnmountRef";


const useStyles = makeStyles((theme) => ({
    header: {
        lineHeight: theme.mixins.toolbar.lineHeight,
    },
    headerContainer: {
        background: "#f8f8f8",
        border: "1px solid transparent",
        left: 0,
        position: "fixed",
        top: 0,
        zIndex: 1201,
        padding: 0,
        margin: "0 15px",
        borderRadius: 4,
        borderColor: "#e7e7e7",
        height: 52,
    },
    headerBox: {
        display: "flex",
        height: "calc(" + theme.mixins.toolbar.lineHeight + " + 1px)",
        listStyleType: "none",
        position: "absolute",
        right: 0,
        top: 0,
    },
    headerNavLi: {
        backgroundColor: "#606060",
        borderLeft: "1px solid #000",
        color: "#fff",
        fontSize: "12px",
        padding: "0 15px",
        textAlign: "center",
        width: 120,
        '&:hover': {
            color: "#000",
        }
    },

    navbar: {
        backgroundColor: "#f8f8f8",
        borderColor: "#e7e7e7",
        position: "relative",
        minHeight: "50px",
        marginBottom: 20,
        border: "1px solid transparent",
        borderRadius: 4,
        boxSizing: "unset",
        '&:before': {
            display: "table",
            content: '" "',
        },
    },
    navbarHeader: {
        float: "left",
        '&:before': {
            display: "table",
            content: '" "',
        },
        '&:after': {
            clear: "both",
            display: "table",
            content: '" "',
        },
    },
    navbarBrand: {
        color: "#777",
        float: "left",
        height: 50,
        padding: 15,
        fontSize: 18,
        lineHeight: "20px",
        textDecoration: "none",
    },
    navbarCollapse: {
        borderColor: "#e7e7e7",
        display: "block!important",
        height: "auto!important",
        paddingBottom: 0,
        overflow: "visible!important",
        width: "auto",
        borderTop: 0,
        boxShadow: "none",
        padding: "0 15px",
        '&:before': {
            display: "table",
            content: '" "',
        },
        '&:after': {
            clear: "both",
            display: "table",
            content: '" "',
        },
    },
    navbarNav: {
        float: "left",
        margin: 0,
        paddingLeft: 0,
        listStyle: "none",
        display: "block",
        '&:before': {
            display: "table",
            content: '" "',
        },
        '&:after': {
            clear: "both",
            display: "table",
            content: '" "',
        },
        '&>.open>a': {
            color: "#555",
            backgroundColor: "#e7e7e7",
        },
    },
}));

export default function Header(props) {
    const unmountRef = useUnmountRef();
    const { oidcUser } = useReactOidc();
    const bank = useBankContext();
    const classes = useStyles();
    const navbarNav = clsx("navbar-bav", classes.navbarNav);
    const [activeChildIndex, setActiveChildIndex] = useSafeState(unmountRef, -1);
    let appointmentChildTitleList = ["予約一覧","支店設定"];
    if (oidcUser?.profile?.ba_user_type === "system_admin" || oidcUser?.profile?.ba_user_type === "bank_admin") {
        appointmentChildTitleList = [...appointmentChildTitleList, ...["全体設定","自動返信メール設定","カルーセル画像設定"]]
    }
    
    const appointmentChildUrlList = ["/admin/appointment_list","/admin/branch_settings","/admin/all_settings","/admin/auto_reply_mail_settings","/admin/image_setting"];
    const ref = React.useRef(null);
    useOnClickOutside(ref, () => onChangeActive(-1));

    const onChangeActive = active => {
        if (active === activeChildIndex) {
            active = -1;
        }
        setActiveChildIndex(active);
    }

    return (
        <nav className={classes.navbar} ref={ref}>
            <Box className={classes.navbarHeader}>
                <a className={classes.navbarBrand} href={bank.baUrl}>{bank.baName}</a>
            </Box>
            <Box className={classes.navbarCollapse}>
                <ul className={navbarNav}>
                    <AdminHeaderList parentTitle={"来店予約"} childTitleList={appointmentChildTitleList} childUrlList={appointmentChildUrlList} onChangeActive={() => onChangeActive(1)} activeChildIndex={activeChildIndex === 1} />
                </ul>
            </Box>
        </nav>
    );
}
