import React, { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { useCookies } from 'react-cookie';
import { makeStyles } from "@material-ui/core/styles";
import Box from '@material-ui/core/Box';
import { useReactOidc } from '@axa-fr/react-oidc-context';
import moment from 'moment';
import 'moment/locale/ja';
import { Link, useLocation } from "react-router-dom";
import clsx from "clsx";
import uuid from 'react-uuid';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import Header from '../../components/Header';
import Footer from '../../components/Footer';
import LeftColumn from "../../components/LeftColumn";
import ReservationConfirm from "../../components/ReservationConfirm";
import AppointmentBreadcrumbs from '../../components/AppointmentBreadcrumbs';
import Api from "../../util/api";
import { useBankContext } from "../../util/BankContext";
import useSafeState from "../../helpers/useSafeState";
import useUnmountRef from "../../helpers/useUnmountRef";


const useStyles = makeStyles(() => ({
  footer: {
    marginTop: "auto",
  },
  notLoginMain: {
    width: "100vw !important",
    height: "100vh !important",
  },
  hoverButton: {
    color: "#159E43 !important",
    background: "#fff !important",
    '&:after': {
      color: "#159E43 !important",
    },
  },
}));

const useIsEdit = () => {
  const dispatch = useDispatch();
  const setIsEdit = (isEdit) =>
    dispatch({
      type: 'SET_IS_EDIT',
      isEdit: isEdit,
    });
  return { setIsEdit }
}

const Confirm = (props) => {
  const unmountRef = useUnmountRef();
  const matches = useMediaQuery('(min-width:600px)');
  const bank = useBankContext();
  window.document.title = '来店予約|' + bank.baPortalName;
  moment.locale('ja');
  const classes = useStyles();

  const { oidcUser } = useReactOidc();
  const { setIsEdit } = useIsEdit();
  const [branchName, setBranchName] = useSafeState(unmountRef, '');
  const [menuName, setMenuName] = useSafeState(unmountRef, '');
  const [menuDuration, setMenuDuration] = useSafeState(unmountRef, 0);
  const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] = useSafeState(unmountRef, false);
  const [cookie, setCookie] = useCookies();

  const notLoginMainClass = (!oidcUser ? classes.notLoginMain : '');
  const mainClass = clsx("main", notLoginMainClass);
  const hoverButtonClass = isSubmitButtonDisabled ? classes.hoverButton : '';
  const submitButton = clsx("btn key-color size-l arrow mt16 btnSp", hoverButtonClass);

  let result = {};
  let userId = null;
  let isGuest = false;
  if (!oidcUser) {
    userId = uuid();
    isGuest = true;
  } else {
    userId = oidcUser;
  }

  const submitReserve = async (event) => {
    // submitイベントをキャンセル
    event.preventDefault();

    const button = window.document.getElementById("submitButton");
    button.disabled = true;
    setIsSubmitButtonDisabled(true);

    let params = {};

    params["a.br"] = props.location.state.branchId;
    params["a.m"] = props.location.state.menuId;
    params["a.d"] = props.location.state.day;
    params["a.s.f"] = props.location.state.fromTime;
    params["a.s.t"] = props.location.state.toTime;
    params["a.g.n.ln"] = props.location.state.lastName;
    params["a.g.n.fn"] = props.location.state.firstName;
    params["a.g.kn.ln"] = props.location.state.lastNameKana;
    params["a.g.kn.fn"] = props.location.state.firstNameKana;
    params["a.g.cn"] = props.location.state.companyName;
    params["a.g.b"] = props.location.state.birthday;
    params["a.g.t"] = props.location.state.telNo;
    params["a.g.m"] = props.location.state.emailAddress;
    params["a.g.nt"] = props.location.state.note;

    if (!!oidcUser && props.isEdit) {
      params["a.i"] = props.appointmentId;
      result = await Api.put(bank.realm, process.env.REACT_APP_APPOINTMENT_BASE_URL + '/appointment/change', params, {}, userId);
      setIsEdit(false);
    } else {
      result = await Api.post(bank.realm, process.env.REACT_APP_APPOINTMENT_BASE_URL + '/appointment', params, {}, userId, isGuest);
    }

    // クッキーに入力情報を格納する
    // 90日間有効とする
    const expires = new Date();
    expires.setDate(expires.getDate() + 90);
    setCookie('last_appointment', props.location.state, { expires: expires });

    return props.history.push({
      pathname: `/appointment/complete/${result.appointment.id}`,
    });
  }

  useEffect(() => {
    if (bank.realm) {
      // 支店一覧
      Api.get(bank.realm, process.env.REACT_APP_APPOINTMENT_BASE_URL + '/branches/withmenus', {}, {}, userId, isGuest)
        .then(branchListResult => {
          const branch = branchListResult.branches.find(branch => branch.id === Number(props.location.state.branchId));
          if (branch.menus !== null) {
            const menu = branch.menus.find(menu => menu.id === Number(props.location.state.menuId));
            setMenuName(menu.name);
            setMenuDuration(menu.duration);
          }
          setBranchName(branch.name);
        })
        .catch(e => {
          console.log(e);
          props.history.push("/");
        })
    }
  }, [userId, isGuest, bank, props, setBranchName, setMenuName, setMenuDuration]);

  useEffect(() => {
    if (typeof window !== 'undefined' && bank.canUseGA && window.gtag) {
      window.gtag('config', bank.gaTrackingCode, {
        'page_path': `${props.location.pathname}${props.location.search}`
      });
    }
  }, [bank.canUseGA, bank.gaTrackingCode, props.location.pathname, props.location.search]);

  return (
    <div className="wrapper">
      {!!oidcUser && (
        <Header />
      )}
      <div className="content">
        {(!!oidcUser && matches) && (
          <LeftColumn />
        )}
        <div className={mainClass}>
          <AppointmentBreadcrumbs withtop={!!oidcUser} params={{
            br : props.location.state.branchId,
            m : props.location.state.menuId,
            d : props.location.state.day,
            f : props.location.state.fromTime,
            t : props.location.state.toTime,
          }} />
          <div className="box">
            <h2 className="box-title shop">来店予約</h2>
            <h3 className="box-title">予約内容をご確認ください</h3>
            <form className="left">
              <ReservationConfirm
                branchName={branchName}
                menuName={menuName}
                menuDuration={menuDuration}
                day={props.location.state.day}
                fromTime={props.location.state.fromTime}
                toTime={props.location.state.toTime}
                lastName={props.location.state.lastName}
                firstName={props.location.state.firstName}
                lastNameKana={props.location.state.lastNameKana}
                firstNameKana={props.location.state.firstNameKana}
                companyName={props.location.state.companyName}
                birthday={props.location.state.birthday}
                telNo={props.location.state.telNo}
                emailAddress={props.location.state.emailAddress}
                note={props.location.state.note}
              />
              <p className="check-message">※ご予約の開始時間までにご来店いただけなかった場合、店頭状況により順番が前後する場合がございます。<br />あらかじめご了承くださいますようお願い申し上げます。
              </p>
              <p className="check-message">上記でよろしければ予約を確定してください。</p>
              <div className="text-center">
                <button id="submitButton" className={submitButton} onClick={submitReserve}>予約を確定する</button>
              </div>
            </form>
          </div>
          <Box className={classes.footer}>
            <Footer />
          </Box>
        </div>
      </div>
    </div>
  )
}

export default connect(state => state)(Confirm);
