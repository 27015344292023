import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useCookies } from 'react-cookie';
import { makeStyles } from "@material-ui/core/styles";
import Box from '@material-ui/core/Box';
import { useReactOidc } from '@axa-fr/react-oidc-context';
import moment from 'moment';
import 'moment/locale/ja';
import { Link, useLocation } from "react-router-dom";
import clsx from "clsx";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from "@material-ui/lab/Alert";
import useMediaQuery from '@material-ui/core/useMediaQuery';

import Header from '../../components/Header';
import Footer from '../../components/Footer';
import LeftColumn from "../../components/LeftColumn";
import VisitorInformationInputForm from "../../components/VisitorInformationInputForm";
import AppointmentBreadcrumbs from '../../components/AppointmentBreadcrumbs';
import Api from "../../util/api";
import { useBankContext } from "../../util/BankContext";
import AppointmentValidation from "../../util/AppointmentValidation";
import useSafeState from "../../helpers/useSafeState";
import useUnmountRef from "../../helpers/useUnmountRef";


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        '& table': {
            border: "1px solid #000",
            borderCollapse: "collapse",
            width: "100%",
        },
        '& tr': {
            height: "50px",
        },
        '& th': {
            background: "#E8E8E8",
        },
        '& td': {
            textAlign: "center",
        },
    },
    content: {
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        height: '100vh',
        minHeight: "100vh",
        overflow: 'auto',
    },
    container: {
        paddingTop: theme.mixins.toolbar.lineHeight,
    },
    title: {
        backgroundColor: theme.palette.text.primary,
        padding: "10px 8px",
        '& p': {
            color: theme.palette.background.default,
        },
    },
    paper: {
        background: "#E8E8E8",
        display: 'flex',
        flexDirection: 'column',
        marginTop: 50,
        overflow: 'auto',
    },
    height100: {
        height: "auto",
    },
    height250: {
        height: 260,
    },
    white: {
        background: "#fff",
    },
    information: {
        padding: 20,
    },
    annotationBox: {
        display: "inline-box",
        margin: "0 calc((100% - 700px) / 2)",
        width: 700,
    },
    annotation: {
        lineHeight: "40px",
        textAlign: "left",
    },
    message: {
        lineHeight: "40px",
        textAlign: "center",
    },
    paperBoxCaption: {
        background: "#606060",
        color: "#fff",
        height: 50,
        textAlign: "center",
    },
    captionHeight: {
        lineHeight: "50px",
    },
    center: {
        textAlign: "center",
    },
    p4: {
        padding: 10,
    },
    subTitle: {
        margin: "30px 0 0 0",
    },
    description: {
        marginTop: 20,
        textAlign: "center",
    },
    buttonBox: {
        textAlign: "center",
    },
    button: {
        background: "#fff",
        margin: "20px 0",
        width: 300,
    },
    footer: {
        marginTop: "auto",
    },
    notLoginMain: {
        width: "100vw !important",
        height: "100vh !important",
    },
    errorMessage: {
        color: "#ff6e6e",
        fontSize: 13,
        textAlign: "center",
    },
}));

const InformationRegister = (props) => {
    const unmountRef = useUnmountRef();
    const matches = useMediaQuery('(min-width:600px)');
    const location = useLocation();
    const bank = useBankContext();
    window.document.title = '来店予約|' + bank.baPortalName;
    moment.locale('ja');
    const classes = useStyles();

    const [lastName, setLastName] = useSafeState(unmountRef, '');
    const [firstName, setFirstName] = useSafeState(unmountRef, '');
    const [lastNameKana, setLastNameKana] = useSafeState(unmountRef, '');
    const [firstNameKana, setFirstNameKana] = useSafeState(unmountRef, '');
    const [companyName, setCompanyName] = useSafeState(unmountRef, '');
    const [birthdate, setBirthdate] = useSafeState(unmountRef, '');
    const [birthday, setBirthday] = useSafeState(unmountRef, '');
    const [telNo, setTelNo] = useSafeState(unmountRef, '');
    const [emailAddress, setEmailAddress] = useSafeState(unmountRef,'');
    const [confirmEmailAddress, setConfirmEmailAddress] = useSafeState(unmountRef, '');
    const [note, setNote] = useSafeState(unmountRef, '');
    const { oidcUser } = useReactOidc();
    const [isErrorLastName, setIsErrorLastName] = useSafeState(unmountRef, false);
    const [isErrorFirstName, setIsErrorFirstName] = useSafeState(unmountRef, false);
    const [isErrorLastNameKana, setIsErrorLastNameKana] = useSafeState(unmountRef, false);
    const [isErrorFirstNameKana, setIsErrorFirstNameKana] = useSafeState(unmountRef, false);
    const [isErrorTelNo, setIsErrorTelNo] = useSafeState(unmountRef, false);
    const [isErrorEmailAddress, setIsErrorEmailAddress] = useSafeState(unmountRef, false);
    const [isErrorConfirmEmailAddress, setIsErrorConfirmEmailAddress] = useSafeState(unmountRef, false);
    const [isErrorBirthday, setIsErrorBirthday] = useSafeState(unmountRef, false);
    const [errorMessages, setErrorMessages] = useSafeState(unmountRef, []);
    const [errorMessage, setErrorMessage] = useSafeState(unmountRef, '');
    const [openError, setOpenError] = useSafeState(unmountRef, false);
    const [cookie, _] = useCookies();

    const notLoginMainClass = (!oidcUser ? classes.notLoginMain : '');
    const mainClass = clsx("main", notLoginMainClass);
    
    let branchId = props.branchId;
    let menuId = props.menuId;
    let day = props.reservedDate.day;
    let fromTime = props.reservedDate.fromTime;
    let toTime = props.reservedDate.toTime;
    if (branchId === '' || menuId === '' || day === '' || fromTime === '' || toTime === '') {
        let query = new URLSearchParams(location.search)
        branchId = query.get("br");
        menuId = query.get("m");
        day = query.get("d");
        fromTime = query.get("f");
        toTime = query.get("t");
    }

    const confirmReserve = (event) => {
        // submitイベントをキャンセル
        event.preventDefault();

        // 入力情報バリデーション
        const validation = AppointmentValidation(
          false,
          oidcUser,
          setIsErrorLastName,
          setIsErrorFirstName,
          setIsErrorLastNameKana,
          setIsErrorFirstNameKana,
          setIsErrorTelNo,
          setIsErrorEmailAddress,
          setIsErrorConfirmEmailAddress,
          setIsErrorBirthday,
          lastName,
          firstName,
          lastNameKana,
          firstNameKana,
          telNo,
          emailAddress,
          confirmEmailAddress,
          birthday,
        );

        // バリデーション結果がfalseの場合はエラーメッセージを表示して終了
        if (!validation.isValid) {
            setErrorMessages(validation.errorMessages);
            setErrorMessage('入力内容に不備があります');
            setOpenError(true);

            return false;
        }

        if(emailAddress === '' && !!oidcUser) {
            setEmailAddress(oidcUser.profile.ba_user_email);
        }

        return props.history.push({
            pathname: '/appointment/confirm',
            state: {
                branchId: branchId,
                menuId: menuId,
                day: day,
                fromTime: fromTime,
                toTime: toTime,
                lastName: lastName,
                firstName: firstName,
                lastNameKana: lastNameKana,
                firstNameKana: firstNameKana,
                companyName: companyName,
                birthday: birthday,
                telNo: telNo,
                emailAddress: emailAddress,
                note: note,
            },
        });
    }

    const handleCloseError = (event) => {
        setOpenError(false);
    }

    useEffect(() => {
        // 渡された日付パラメータのバリデーション
        const now = moment();
        const targetDate = moment(day + ' ' + toTime);
        if (now.isAfter(targetDate)) {
            return props.history.push('/404');
        }
        
        if (!oidcUser) {
            // 未ログイン時
            if (!!cookie.last_appointment) {
                // クッキーがあれば前回入力値を復元する
                setLastName(cookie.last_appointment.lastName);
                setFirstName(cookie.last_appointment.firstName);
                setLastNameKana(cookie.last_appointment.lastNameKana);
                setFirstNameKana(cookie.last_appointment.firstNameKana);
                setEmailAddress(cookie.last_appointment.emailAddress);
                setTelNo(cookie.last_appointment.telNo);
            }
            return;
        }
        if (typeof window !== 'undefined' && bank.canUseGA && window.gtag) {
            window.gtag('config', bank.gaTrackingCode, {
                'page_path': `${props.location.pathname}${props.location.search}`
            });
        }

        const fetchData = async () => {
            if (props.isEdit) {
                // 編集時は予約内容から入力値を復元する
                const appointment = await Api.get(bank.realm, process.env.REACT_APP_APPOINTMENT_BASE_URL + '/appointment', {a: props.appointmentId});
                setLastName(appointment.appointment.guest.kanjiName.lastName);
                setFirstName(appointment.appointment.guest.kanjiName.firstName);
                setLastNameKana(appointment.appointment.guest.kanaName.lastName);
                setFirstNameKana(appointment.appointment.guest.kanaName.firstName);
                setCompanyName(appointment.appointment.guest.companyName);
                setBirthday(appointment.appointment.guest.birthday);
                setTelNo(appointment.appointment.guest.tel);
                if (!oidcUser) {
                    setEmailAddress(appointment.appointment.guest.mail);
                    setConfirmEmailAddress(appointment.appointment.guest.mail);
                }
                setNote(appointment.appointment.guest.note);
            } else if (!!cookie.last_appointment) {
                // クッキーがあれば前回入力値を復元する
                setLastName(cookie.last_appointment.lastName);
                setFirstName(cookie.last_appointment.firstName);
                setLastNameKana(cookie.last_appointment.lastNameKana);
                setFirstNameKana(cookie.last_appointment.firstNameKana);
                setEmailAddress(cookie.last_appointment.emailAddress);
                setTelNo(cookie.last_appointment.telNo);
            } else if (!!oidcUser) {
                // ユーザー情報から初期値を設定する
                setLastName(oidcUser.profile.ba_user_last_name);
                setFirstName(oidcUser.profile.ba_user_first_name);
                setLastNameKana(oidcUser.profile.ba_user_last_name_kana);
                setFirstNameKana(oidcUser.profile.ba_user_first_name_kana);
                setCompanyName(oidcUser.profile.ba_company_name);
                setEmailAddress(oidcUser.profile.ba_user_email);
            }
        }
    
        fetchData()
            .then()
            .catch(e => {
                // todo エラーハンドリング
                if( e.response ){
                    if( e.response.status === 403 ){
                        // todo 「ログインしてください」を表示
                        // todo ユーザー情報の削除
                        // location.href = "/";
                    }
                    //todo エラーであることを知らせる表示
                }
            })
    }, [bank, oidcUser, props, setCompanyName, setConfirmEmailAddress, setEmailAddress, setFirstName, setFirstNameKana, setLastName, setLastNameKana, setNote, setTelNo]);

    return (
        <div className="wrapper">
            {!!oidcUser && (
                <Header />
            )}
            <div className="content">
                {(!!oidcUser && matches) && (
                    <LeftColumn />
                )}
                <div className={mainClass}>
                    <ul className="breadcrumb">
                        <AppointmentBreadcrumbs withtop={!!oidcUser} />
                    </ul>
                    <div className="box">
                        <h2 className="box-title shop">来店予約</h2>
                        <h3 className="box-title">来店される方の情報を登録してください</h3>
                        <form className="left">
                            {errorMessages.map((errorMessage, index) => (
                                <p key={index} className={classes.errorMessage}>{errorMessage}</p>
                            ))}
                            <VisitorInformationInputForm
                                lastName={lastName}
                                firstName={firstName}
                                lastNameKana={lastNameKana}
                                firstNameKana={firstNameKana}
                                companyName={companyName}
                                birthdate={birthdate}
                                birthday={birthday}
                                telNo={telNo}
                                emailAddress={emailAddress}
                                confirmEmailAddress={confirmEmailAddress}
                                note={note}
                                setLastName={setLastName}
                                setFirstName={setFirstName}
                                setLastNameKana={setLastNameKana}
                                setFirstNameKana={setFirstNameKana}
                                setCompanyName={setCompanyName}
                                setBirthday={setBirthday}
                                setBirthdate={setBirthdate}
                                setTelNo={setTelNo}
                                setEmailAddress={setEmailAddress}
                                setConfirmEmailAddress={setConfirmEmailAddress}
                                setIsErrorBoirthday={setIsErrorBirthday}
                                setNote={setNote}
                                isErrorLastName={isErrorLastName}
                                isErrorFirstName={isErrorFirstName}
                                isErrorLastNameKana={isErrorLastNameKana}
                                isErrorFirstNameKana={isErrorFirstNameKana}
                                isErrorTelNo={isErrorTelNo}
                                isErrorEmailAddress={isErrorEmailAddress}
                                isErrorConfirmEmailAddress={isErrorConfirmEmailAddress}
                                isErrorBirthday={isErrorBirthday}
                            />
                            <p className="check-message">※ご予約の開始時間までにご来店いただけなかった場合、店頭状況により順番が前後する場合がございます。<br />あらかじめご了承くださいますようお願い申し上げます。
                            </p>
                            <div className="text-center">
                                <button className="btn key-color size-l arrow mt16 btnSp" onClick={confirmReserve}>次へ</button>
                            </div>
                            <Snackbar open={openError} autoHideDuration={6000} onClose={handleCloseError}>
                                <MuiAlert elevation={6} variant="filled" onClose={handleCloseError} severity="error">
                                    {errorMessage}
                                </MuiAlert>
                            </Snackbar>
                        </form>
                    </div>
                    <Box className={classes.footer}>
                        <Footer />
                    </Box>
                </div>
            </div>
        </div>
    )
}

export default connect(state => state)(InformationRegister);
