import React from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import { withStyles } from '@material-ui/core/styles';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import moment from "moment";
import 'moment/locale/ja';
import { useReactOidc } from '@axa-fr/react-oidc-context';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Header from "../../components/Header";
import LeftColumn from "../../components/LeftColumn";
import Footer from "../../components/Footer";
import yesnoImage from "../../img/succession/yes_no01.png";
import buttonImage1 from "../../img/succession/yes_no_btn01.png";
import buttonImage2 from "../../img/succession/yes_no_btn02.png";
import buttonImage3 from "../../img/succession/yes_no_btn03.png";
import bannerImage from "../../img/succession/banner_ktba03_640_200 1.png";
import { useBankContext } from "../../util/BankContext";

const useStyles = makeStyles((theme) => ({
  linkText: {
      cursor: "pointer",
      textDecoration: "underline",
      '&:hover': {
          textDecoration: "none",
      },
  },
  buttonImage: {
    maxWidth: 260
  },
  footer: {
      marginTop: "auto",
  },
  bannerImageBox: {
    width: "1080px",
    margin: "48px auto",
    textAlign: "center"
  }
}));

const Accordion = withStyles({
  root: {
    boxShadow: 'none',
    width: '100%',
    "&:before": {
      display: "none"
    },
    marginBottom: '20px'
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
    borderRadius: '27px',
    border: '1px solid #019B43',
    fontSize: '16px',
    letterSpacing: '2px',
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    margin: '0px 22px',
    padding: theme.spacing(2),
    borderBottomLeftRadius: '10px',
    borderBottomRightRadius: '10px',
    border: '1px solid #019B43',
  },
}))(MuiAccordionDetails);

const BusinessSuccessionFaq = (props) => {
  const matches = useMediaQuery('(min-width:600px)');
  const bank = useBankContext();
  window.document.title = '事業承継方針の検討パターン|' + bank.baPortalName;
  moment.locale('ja');
  const classes = useStyles();
  const { oidcUser } = useReactOidc();

  // Q&Aデータ
  const qaData = require('../../data/succession.json');

  return (
    <div className="wrapper">
      <Header />
      <div className="content">
        {(!!oidcUser && matches) && (
          <LeftColumn />
        )}
        <div className="main">
          <ul className="breadcrumb">
              <li><Link to={"/"}>トップ</Link></li>
              <li>事業承継方針の検討パターン</li>
          </ul>
          <div className="box" id="top">
            <h2 className="box-title seminar">事業承継方針の検討パターン</h2>
            <div style={{margin: "1.5em 0"}}>事業承継の種類は大きく分けると「親族内承継」「親族外事業承継」「M＆A」の３種類があります。それぞれで事業承継の方法や進め方が違ってきますので、会社がどの方法で事業承継を行うか、予め知っておく事がとても重要です。</div>
            <div style={{maxWidth: "840px", margin: "auto"}}>
              <h3 className="box-title" style={{marginBottom: "1em"}}>事業承継の種類調査早見表</h3>
              <Grid container spacing={0} justify="space-between">
                <Grid item xs={12}>
                  <img src={yesnoImage} width="840"/>
                </Grid>
                <Grid item xs={4}>
                  <a href="#relatives">
                    <img src={buttonImage1} className={classes.buttonImage} />
                  </a>
                </Grid>
                <Grid item xs={4} style={{textAlign: "center"}}>
                  <a href="#employee">
                    <img src={buttonImage2} className={classes.buttonImage} />
                  </a>
                </Grid>
                <Grid item xs={4} style={{textAlign: "right"}}>
                  <a href="#other">
                    <img src={buttonImage3} className={classes.buttonImage} />
                  </a>
                </Grid>
                <Grid item xs={12} style={{textAlign: "center", marginTop:'40px'}}>
                  ※本コンテンツは株式会社ココペリが税理士法人ブリッジフィールド国際会計事務所の監修を受け作成しております。
                </Grid>
              </Grid>
            </div>
          </div>
          <div className={classes.bannerImageBox}>
            <a href="https://sites.google.com/kokopelli-inc.com/ktba-fromportal/" target="_blank">
              <img src={bannerImage}/>
            </a>
          </div>
          {
            Object.keys(qaData).map(qaDataKey => {
              return <div className="box" id={qaDataKey} key={qaDataKey}>
                <h2 className="box-title seminar">{qaData[qaDataKey].title}</h2>
                <div style={{margin: "20px 0px 38px"}}>
                  {
                    Object.keys(qaData[qaDataKey].list).map(qaDataKeyListKey => {
                      return <Accordion key={qaDataKeyListKey}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                          <Box style={{marginLeft: '7px', marginTop: '3px', marginBottom: '3px'}}>
                            Ｑ
                          </Box>
                          <Box style={{marginLeft: '15px', marginTop: '3px', marginBottom: '3px'}}>
                           {qaData[qaDataKey].list[qaDataKeyListKey].question}
                          </Box>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Box style={{marginLeft: '7px'}}>
                            Ａ
                          </Box>
                          <Box style={{marginLeft: '15px'}}>
                            <span dangerouslySetInnerHTML={{__html: qaData[qaDataKey].list[qaDataKeyListKey].answer}}/>
                          </Box>
                        </AccordionDetails>
                      </Accordion>  
                    })
                  }
                </div>
                <Grid style={{textAlign: "center"}}>
                  <a href="#top" style={{color: "#159E43"}} className={classes.linkText}>
                    ▲ 事業承継パターンを選び直す ▲
                  </a>
                </Grid>
              </div>  
            })
          }
          <Box className={classes.footer}>
            <Footer />
          </Box>
        </div>
      </div>
    </div>
  )
}

export default BusinessSuccessionFaq;
