import React, { useCallback, useEffect } from "react";
import { useHistory } from 'react-router-dom';
import clsx from "clsx";
import effectStyle from '../../css/effect.less';

import topStyle from '../../css/ktba/custom_top.less';
import { useBankContext } from "../../util/BankContext";

const BeforeLoginHeader = () => {
    const history = useHistory();
    const bank = useBankContext();
    const btnKeyColorBorder = clsx(topStyle.btn, topStyle.keyColorBorder);

    const loginAction = useCallback((event) => {
        event.preventDefault();
        history.push("/login");
      }, [history]);

    const scrollEffect = () => {
        const effectFadeElements = document.getElementsByClassName(effectStyle.effectFade);
        Array.from(effectFadeElements).forEach(function(val, index) {
            const userAgent = window.navigator.userAgent.toLowerCase();
        
            // IEの場合スクロール位置の取得が上手くできていないため、スクロール位置に関係なくclassの付与を行い画像を表示させる
            if (userAgent.indexOf('msie') !== -1 || userAgent.indexOf('trident') !== -1) {
                val.classList.add(effectStyle.effectScroll);
            } else {
                const rect = val.getBoundingClientRect();
                const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
                const elemPos = rect.top + scrollTop;
                const scroll = window.scrollY;
                const windowHeight = window.innerHeight;
        
                if (scroll > elemPos - windowHeight) {
                    val.classList.add(effectStyle.effectScroll);
                }
            }
        })
    };
    
    useEffect( () => {
        scrollEffect();
        document.addEventListener('scroll', scrollEffect);
    }, []);
      
    return (<>
            <header className={topStyle.header}>
                <div className={topStyle.logo}>
                    <div>
                        <a href="/">
                            <p className={topStyle.ja}><span>京銀</span>ビジネスポータルサイト</p>
                            <p className={topStyle.en}>KYOGIN BUSINESS PORTAL SITE</p>
                        </a>
                    </div>
                </div>
                <div className={topStyle.btns}>
                    <a href={bank.accountGetUrl} className={btnKeyColorBorder} target="_blank" rel="noopener noreferrer">アカウント取得</a>
                    <a href="/login" onClick={loginAction} className={btnKeyColorBorder}>ログイン</a>
                </div>
            </header>
        </>
    );
};

export default BeforeLoginHeader;
