import React, {useEffect} from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import moment from "moment";
import 'moment/locale/ja';
import { useReactOidc } from '@axa-fr/react-oidc-context';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import Header from "../../components/Header";
import LeftColumn from "../../components/LeftColumn";
import Footer from "../../components/Footer";
import { useBankContext } from "../../util/BankContext";
import XbaApi from "../../util/xbaapi";
import bannerImage from "../../img/succession/banner_ktba03_640_200 1.png";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        '& table': {
            border: "1px solid #000",
            borderCollapse: "collapse",
            width: "100%",
        },
        '& tr': {
            height: "50px",
        },
        '& th': {
            background: "#E8E8E8",
        },
        '& td': {
            textAlign: "center",
        },
    },
    content: {
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        height: '100vh',
        minHeight: "100vh",
        overflow: 'auto',
    },
    title: {
        backgroundColor: theme.palette.text.primary,
        padding: "10px 8px",
        '& p': {
            color: theme.palette.background.default,
        },
    },
    image: {
        position: "relative",
    },
    noImage: {
        background: "#efefef",
        color: "#aaa",
        height: "100%",
        letterSpacing: 2,
        lineHeight: "500px",
        textAlign: "center",
    },
    successionColumnDescBox: {
        position: "relative",
        top: "-20px",
        '& h2': {
            color: "rgba(0, 0, 0, 0.87)",
        },
    },
    successionColumnTitle: {
        backgroundColor: "#444",
        lineHeight: "1.75em",
        margin: "0 auto",
        padding: theme.spacing(2, 2),
        width: 850,
        '& p': {
            color: theme.palette.background.default,
        },
    },
    successionColumnContent: {
        lineHeight: "1.75em",
        marginTop: "30px",
        paddingBottom: "15px",
        whiteSpace: "normal",
        '& h2.caption': {
            display: "block",
            fontFamily: "'Noto Sans Japanese', sans-serif",
            fontSize: 18,
            letterSpacing: 1,
            lineHeight: "1.41",
            padding: "15px 15px 15px 42px",
            position: "relative",
            fontWeight: 200,
        },
        '& h2:not(.caption)': {
            borderBottom: "1px solid #aaa",
            fontFamily: "'Noto Sans Japanese', sans-serif",
            fontSize: 18,
            fontWeight: 400,
            letterSpacing: 1,
            marginBottom: 10,
            padding: "15px 15px 15px 25px",
            paddingBottom: 4,
            position: "relative",
            '&:before': {
                background: "#19AAC4",
                content: "''",
                height: 8,
                left: 0,
                position: "absolute",
                top: 26,
                width: 8,
            },
            '&:after': {
                background: "#D1D1D1",
                content: "''",
                height: 8,
                left: 5,
                position: "absolute",
                top: 21,
                width: 8,
            },
        },
    },
    date: {
        textAlign: "right",
    },
    backSuccessionColumnListLinkBox: {
        marginTop: 20,
        textAlign: "center",
    },
    backSuccessionColumnListLink: {
        color: "#19AAC4",
        '&:hover': {
            textDecoration: "none",
        },
    },
    columnPostingCompanyBox: {
        backgroundColor: "#F5F5F5", 
        margin: "20px 140px 0px"
    },
    columnPostingCompanyImageBox: {
        backgroundColor:"white", 
        maxWidth:"140px", 
        height:"80x"
    },
    columnPostingCompanyImage: {
        width:"108px", 
        height:"40px", 
        margin: "20px 16px"
    },
    columnPostingCompanyTitle: {
        color: "#666666", 
        fontWeight:"bold", 
        fontSize:"12px"
    },
    columnPostingCompanyName: {
        color: "#666666", 
        fontWeight:"bold"
    },
    columnPostingCompanyDivider: {
        width:"500px", 
        margin:"10px 0px"
    },
    columnPostingCompanyAddress: {
        fontSize:"10px"
    },
    footer: {
        marginTop: "auto",
    },
    bannerImageBox: {
        marginTop: "72px",
        textAlign: "center"
    }
}));

const SuccessionColumnDetail = (props) => {
    const matches = useMediaQuery('(min-width:600px)');
    const bank = useBankContext();
    window.document.title = '事業承継コラム|' + bank.baPortalName;
    moment.locale('ja');
    const classes = useStyles();
    const { oidcUser } = useReactOidc();

    const [successionColumnDetail, setSuccessionColumnDetail] = React.useState({});

    useEffect( () => {
        if (!oidcUser) {
            return;
        }

        if (bank.canUseGA) {
            window.gtag('config', bank.gaTrackingCode, {
                'page_path': `${props.location.pathname}${props.location.search}`
            });
        }

        const fetchData = async (uniqueCode) => {
            // 経営コラム詳細取得API
            const successionColumnDetail = await XbaApi.get(`${bank.baUrl}api/v1/ba/succession/column/search`, {ba_succession_column_unique_code: uniqueCode}, oidcUser);
            if (successionColumnDetail.result.list.length >= 0) {
              setSuccessionColumnDetail(successionColumnDetail.result.list[0]);
            }
        };

        fetchData(
            props.match.params.unique_code,
        )
            .then()
            .catch(e => {
                // todo エラーハンドリング
                if( e.response ){
                    if( e.response.status === 403 ){
                        // todo 「ログインしてください」を表示
                        // todo ユーザー情報の削除
                        // location.href = "/";
                    }
                    //todo エラーであることを知らせる表示
                }
            });
    }, [props, oidcUser, bank]);

    return (
        <div className="wrapper">
            <Header />
            <div className="content">
                {(!!oidcUser && matches) && (
                    <LeftColumn />
                )}
                <div className="main">
                    <ul className="breadcrumb">
                        <li><Link to={"/"}>トップ</Link></li>
                        <li><Link to={"/succession_column"}>事業承継コラム</Link></li>
                        <li>詳細</li>
                    </ul>
                    <div className="box">
                        <div className="column-detail">
                            <div className="image-wrapper">
                                <img src={`${bank.baManagerUrl}picture/bank/${successionColumnDetail.a_cover_image_file_unique_code}`} alt={successionColumnDetail.ba_succession_column_title} />
                            </div>
                            <div className="column-title">
                                <div className="panel">
                                  <p className="title">{successionColumnDetail.ba_succession_column_title}</p>
                                </div>
                            </div>
                        </div>
                        <div className={classes.columnPostingCompanyBox}>
                            <Grid container spacing={0} style={{padding:"20px"}}>
                                <Grid className={classes.columnPostingCompanyImageBox}>
                                    <img
                                        src={`${bank.baManagerUrl}/picture/bank/${successionColumnDetail.succession_column_posting_company_image_file_unique_code}/800x`}
                                        className={classes.columnPostingCompanyImage}
                                    />
                                </Grid>
                                <Grid style={{marginLeft:"20px", marginTop:"1px"}}>
                                    <Box className={classes.columnPostingCompanyTitle}>
                                        <Grid container spacing={0}>
                                            <Grid style={{marginTop:"-2px"}}>
                                                <img src={`/img/${bank.staticFilePathPrefix}/pen_icon.png`} width="13" height="13" />
                                            </Grid>
                                            <Grid style={{marginLeft:"7px"}}>
                                                記事制作
                                            </Grid>
                                        </Grid>
                                    </Box>
                                    <Box className={classes.columnPostingCompanyName} style={{marginTop:"4px"}}>
                                        {successionColumnDetail.succession_column_posting_company_name}
                                    </Box>
                                    <Divider className={classes.columnPostingCompanyDivider} />
                                    <Box className={classes.columnPostingCompanyAddress}>
                                        〒{successionColumnDetail.succession_column_posting_company_post_code}
                                        <span style={{marginLeft:"10px"}}>
                                            {successionColumnDetail.succession_column_posting_company_address}
                                        </span>
                                    </Box>
                                </Grid>
                            </Grid>
                        </div>
                        <div className="column-body" dangerouslySetInnerHTML={{__html : successionColumnDetail.a_content}} />
                        <div style={{marginTop:"20px"}}>
                        {successionColumnDetail.succession_column_posting_company_name}<span style={{marginLeft:"15px"}}>制作</span>
                        </div>
                        <div className="text-center mt40">
                            <Link to={"/succession_column"} className=" key-color">一覧に戻る</Link>
                        </div>
                        <div className={classes.bannerImageBox}>
                            <a href="https://sites.google.com/kokopelli-inc.com/ktba-fromportal/" target="_blank">
                                <img src={bannerImage}/>
                            </a>
                        </div>
                    </div>
                    <Box className={classes.footer}>
                        <Footer />
                    </Box>
                </div>
            </div>
        </div>
    )
}

export default SuccessionColumnDetail;
