import React from "react";
import { useBankContext } from "../../util/BankContext";

const BeforeLoginFooter = () => {
    const bank = useBankContext();
    return (
        <>
            <div className="footer">
                <div className="footer-wrapper">
                    <div className="bank-logo">
                        <img src={`/img/${bank.staticFilePathPrefix}/bank_logo.png`} alt={bank.bankName} />
                    </div>
                    <ul>
                        <li><a href={bank.bankUrl}>京都銀行公式サイト</a></li>
                        <li><a href={bank.bankUrl + "privacy/index.html"}>プライバシーポリシー</a></li>
                        <li><a href="/page/rule">利用規約</a></li>
                        <li><a href="https://www.kyotobank.co.jp/houjin/business-portal/users-manual/index.html" target="_blank">よくあるご質問</a></li>
                        <li><a href="/page/cookie_policy">クッキーポリシー</a></li>
                    </ul>
                </div>
                <div className="footer-other">
                    <p className="about-bank">
                        株式会社京都銀行 登録金融機関　近畿財務局長（登金）第10号　加入協会 日本証券業協会、一般社団法人金融先物取引業協会
                    </p>
                    <p className="copy-right">
                        Copyright © 2020 Bank of Kyoto, Ltd. All Rights Reserved.
                    </p>
                </div>
            </div>
        </>
    );
};

export default BeforeLoginFooter;
