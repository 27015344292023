import React, { useEffect, useState } from "react";
import {Link} from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Box from '@material-ui/core/Box';
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import clsx from "clsx";
import Button from "@material-ui/core/Button";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import { useReactOidc } from '@axa-fr/react-oidc-context';
import moment from "moment";
import 'moment/locale/ja';
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ja from 'date-fns/locale/ja'
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useSnackbar } from "notistack";
import { parse } from 'date-fns';

import AdminHeader from '../../components/AdminHeader';
import Api from "../../util/api";
import SearchedList from "../../components/SearchedList";
import { useBankContext } from "../../util/BankContext";
import AppointmentSearchValidation from "../../util/AppointmentSearchValidation";
import XbaApi from "../../util/xbaapi";
import CustomBirthdatePicker from "../../components/CustomBirthdatePicker";

const useStyles = makeStyles((theme) => ({
    root: {
        padding: "0 15px",
        margin: "0 auto",
        '&:before': {
            display: "table",
            content: '" "',
        },
    },
    content: {
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        height: '100vh',
        minHeight: "100vh",
    },
    titleBox: {
        marginBottom: 10,
        padding: "0 15px",
    },
    title: {
        color: "inherit",
        fontFamily: "inherit",
        fontSize: 18,
        fontWeight: 500,
        lineHeight: "1.1",
        marginTop: 10,
        marginBottom: 10,
    },
    panel: {
        backgroundColor: "#fff",
        border: "1px solid transparent",
        borderColor: "#ddd",
        borderRadius: 4,
        boxShadow: "0 1px 1px rgba(0,0,0,.05)",
        marginBottom: 20,
    },
    panelBody: {
        padding: 15,
    },
    panelBodyWithCleaBoth: {
        clear: "both",
        padding: 15,
    },
    column: {
        float: "left",
        marginBottom: 15,
    },
    formControl: {
        margin: "0 10px",
        minWidth: 120,
    },
    label: {
        height: 36,
        lineHeight: "36px",
        listStyleType: "none",
        marginLeft: 10,
        padding: 0,
    },
    textFieldBox: {
        listStyleType: "none",
        margin: "0 10px",
        padding: 0,
        width: "auto",
    },
    w140: {
        width: 140,
    },
    w300: {
        width: 300,
    },
    button: {
        background: "#457ab2",
        color: theme.palette.primary.color,
        height: 40,
        margin: "20px 10px",
        width: 100,
    },
    searchButtonBox: {
        clear: "both",
        textAlign: "center",
    },
    reactDatepickerWrapper: {
        '& .react-datepicker-wrapper': {
            float: "left",
        },
    },
    lDatePicker: {
        marginRight: 10,
        width: 130,
        zIndex: 1000,
    },
    stretchStick: {
        float: "left",
        lineHeight: "40px",
        textAlign: "center",
        width: 20,
    },
    rDatePicker: {
        marginLeft: 10,
        width: 130,
        zIndex: 1000,
    },
    autocomplete: {
        margin: "0 10px",
        '& label': {
            transform: "translate(14px, 13px) scale(1)",
        },
    },
    autocompleteTextField: {
        height: 40,
        '& div': {
            height: 40,
        },
        '& div input': {
            padding: "0 !important",
        },
    },
    errorMessage: {
        color: "#ff6e6e",
        fontSize: 13,
        textAlign: "center",
    },
    csvDownloadLink: {
        textAlign: "right",
    },
}));

const AppointmentList  = (props) => {
    const bank = useBankContext();
    window.document.title = 'BA Portal';
    moment.locale('ja');
    registerLocale('ja', ja);
    const classes = useStyles();
    const visitPeriodBox = clsx(classes.textFieldBox, classes.w300, classes.reactDatepickerWrapper);
    const textFieldBoxW140 = clsx(classes.textFieldBox, classes.w140);
    const textFieldBoxW300 = clsx(classes.textFieldBox, classes.w300);
    const formControlW300 = clsx(classes.formControl, classes.w300);
    const { enqueueSnackbar } = useSnackbar();
    const [errorMessages, setErrorMessages] = useState([]);
    const [isErrorBranchId, setIsErrorBranchId] = useState(false);
    const [isErrorReservationFromDate, setIsErrorReservationFromDate] = useState(false);
    const [isErrorReservationToDate, setIsErrorReservationToDate] = useState(false);

    // 支店一覧
    const [branchList, setBranchList] = React.useState([]);
    // メニュー一覧
    const [menuList, setMenuList] = React.useState([]);
    // メニュー種別
    const [menuGroupList, setMenuGroupList] = React.useState([]);
    // 予約一覧
    const [ reservedList, setReservedList ] = React.useState([]);
    // 支店オブジェクト
    const [ branch, setBranch ] = React.useState(null);
    // 支店id
    const [ branchId, setBranchId ] = React.useState('');
    // 来店日（From）
    const [ reservationFromDate, setReservationFromDate ] = React.useState('');
    // 来店日（To）
    const [ reservationToDate, setReservationToDate ] = React.useState('');
    // datepicker用
    const [fromDate, setFromDate] = React.useState('');
    const [toDate, setToDate] = React.useState('');
    const [birthdate, setBirthdate] = React.useState('');
    // 会社名
    const [ companyName, setCompanyName ] = React.useState('');
    // 氏
    const [ lastName, setLastName ] = React.useState('');
    // 名
    const [ firstName, setFirstName ] = React.useState('');
    // 生年月日
    const [ birthday, setBirthday ] = React.useState('');
    // メニューid
    const [ menuId, setMenuId ] = React.useState('');
    // メニューグループid
    const [ menuGroupId, setMenuGroupId ] = React.useState('');
    // 電話番号
    const [ telNo, setTelNo ] = React.useState('');
    // メールアドレス
    const [ emailAddress, setEmailAddress ] = React.useState('');

    const { oidcUser } = useReactOidc();

    const handleChangeBranchId = async (event, branch) => {
        if (branch === null || branch === '') {
            setMenuList([]);
            setMenuId('');
            setMenuGroupList([]);
            setMenuGroupId('');
            setBranchId('');
            setBranch(null);
        } else {
            setBranch(branch);
            setBranchId(branch.id);

            // メニュー一覧取得
            const menuList = await Api.get(bank.realm, process.env.REACT_APP_APPOINTMENT_BASE_URL + '/menus', {br: branch.id}, {}, oidcUser);
            if (!('menus' in menuList) || menuList.menus === null) {
                setMenuList([]);
            } else {
                setMenuList(menuList.menus);
            }

            // メニュー種別一覧取得
            const menuGroupList = await Api.get(bank.realm, process.env.REACT_APP_APPOINTMENT_BASE_URL + '/menu_groups', {br: branch.id}, {}, oidcUser)
            if (!('groups' in menuGroupList) || menuGroupList.groups === null) {
                setMenuGroupList([]);
            } else {
                setMenuGroupList(menuGroupList.groups);
            }
        }
    };

    const handleChangeReservationFromDate = (fromDate) => {
        if (fromDate === null) {
            setFromDate('');
            setReservationFromDate('');
            return;
        }

        setFromDate(moment.utc(fromDate, 'YYYY-MM-DDTHH:mm:00Z', 'ja').utcOffset(9).toDate());
        const formatDate = moment(fromDate).format('YYYY-MM-DD');
        setReservationFromDate(formatDate);
    }

    const handleChangeReservationToDate = (toDate) => {
        if (toDate === null) {
            setToDate('');
            setReservationToDate('');
            return;
        }

        setToDate(moment.utc(toDate, 'YYYY-MM-DDTHH:mm:00Z', 'ja').utcOffset(9).toDate());
        const formatToDate = moment(toDate).format('YYYY-MM-DD');
        setReservationToDate(formatToDate);
    }

    const handleChangeCompanyName = (event) => {
        setCompanyName(event.target.value);
    }

    const handleChangeLastName = (event) => {
        setLastName(event.target.value);
    }

    const handleChangeFirstName = (event) => {
        setFirstName(event.target.value);
    }
    
    const handleChangeBirthday = (birthday) => {
        if (birthday === null) {
            setBirthdate('');
            setBirthday('');
            return;
        }
    
        setBirthdate(birthday);
        const formatDate = moment(birthday).format('YYYY-MM-DD');
        setBirthday(formatDate);
    }

    const handleChangeMenuId = (event) => {
        setMenuId(event.target.value);
    };

    const handleChangeMenuGroupId = (event) => {
        setMenuGroupId(event.target.value);
    };

    const handleChangeTelNo = (event) => {
        setTelNo(event.target.value);
    }

    const handleChangeEmailAddress = (event) => {
        setEmailAddress(event.target.value);
    }

    const handleSearchButtonClick = async () => {
        setErrorMessages([]);

        // 入力情報バリデーション
        const validation = AppointmentSearchValidation(
          setIsErrorBranchId,
          setIsErrorReservationFromDate,
          setIsErrorReservationToDate,
          branchId,
          reservationFromDate,
          reservationToDate,
        );

        // バリデーション結果がfalseの場合はエラーメッセージを表示して終了
        if (!validation.isValid) {
            setErrorMessages(validation.errorMessages);
            enqueueSnackbar('入力内容に不備があります', { variant: 'error' });

            return false;
        }

        let param = {};
        if( branchId !== '' ) param.br = branchId;
        if( menuId !== '' ) param.m = menuId;
        if( menuGroupId !== '' ) param.gr = menuGroupId;
        if( reservationFromDate !== '' ) {
            param.f = reservationFromDate;
        } else {
            param.f = moment().subtract(1, 'months').format('YYYY-MM-DD');
        }
        if( reservationToDate !== '' ) param.t = reservationToDate;
        if( lastName !== '' ) param['g.n.ln'] = lastName;
        if( firstName !== '' ) param['g.n.fn'] = firstName;
        if( companyName !== '' ) param['g.cn'] = companyName;
        if( telNo !== '' ) param['g.t'] = telNo;
        if( emailAddress !== '' ) param['g.m'] = emailAddress;
        if( birthday !== '' ) param['g.b'] = birthday;
        
        const reservedList = await Api.get(bank.realm, process.env.REACT_APP_APPOINTMENT_BASE_URL + '/appointments/search', param, {}, oidcUser);
        if (!('appointments' in reservedList) || reservedList.appointments === null) {
            setReservedList([]);
        } else {
            setReservedList(reservedList.appointments);
        }

        // 支店名 初期値用にローカルストレージに値を保存する
        localStorage.setItem("default_branch_id", parseInt(branchId));
    }

    const handleCSVDownloadButtonClick = () => {
        let param = {};
        if( branchId !== '' ) param.br = branchId;
        if( menuId !== '' ) param.m = menuId;
        if( menuGroupId !== '' ) param.gr = menuGroupId;
        if( reservationFromDate !== '' ) param.f = reservationFromDate;
        if( reservationToDate !== '' ) param.t = reservationToDate;
        if( lastName !== '' ) param['g.n.ln'] = lastName;
        if( firstName !== '' ) param['g.n.fn'] = firstName;
        if( companyName !== '' ) param['g.cn'] = companyName;
        if( birthday !== '' ) param['g.b'] = birthday;
        if( telNo !== '' ) param['g.t'] = telNo;
        if( emailAddress !== '' ) param['g.m'] = emailAddress;
        Api.get(bank.realm, process.env.REACT_APP_APPOINTMENT_BASE_URL + '/appointments/export', param, {}, oidcUser)
            .then(response => {
                let bom  = new Uint8Array([0xEF, 0xBB, 0xBF]);
                var blob = new Blob([bom, response.data], {type: 'text/csv'});
                if (window.navigator.msSaveBlob) {
                    window.navigator.msSaveBlob(blob, response.filename);
                } else {
                    var url = window.URL || window.webkitURL;
                    var blobURL = url.createObjectURL(blob);
                    var csv = document.createElement('a');
                    csv.download = response.filename;
                    csv.href = blobURL;
                    csv.click();
                    URL.revokeObjectURL(blobURL);
                }
            })
            .catch(e => {
                console.log(e);
                if (e.status === 500) {
                    alert(e);
                }
            });
    }

    useEffect(() => {
        if (bank.canUseGA) {
            if (!!window.gtag) {
                window.gtag('config', bank.gaTrackingCode, {
                    'page_path': `${props.location.pathname}${props.location.search}`
                });
            }
        }

        const fetchData = async () => {
            // 店舗一覧
            const branchList = await Api.get(bank.realm, process.env.REACT_APP_APPOINTMENT_BASE_URL + '/branches', {}, {}, oidcUser);
    
            let branches = branchList.branches || [];

            const me = await XbaApi.get(`${bank.baUrl}api/v1/user/me`, {}, oidcUser)

            // 担当支店に絞る
            if (oidcUser.profile.ba_user_type != 'bank_admin' && oidcUser.profile.ba_user_type != 'system_admin') {
                let user_branches = [];
                user_branches = me.result.user.user_branche_codes.map((id) => parseInt(id));
                branches = [];
                if (('branches' in branchList) && branchList.branches) {
                    branches = branchList.branches.filter((branch) => {
                        // 担当支店または表示用支店(表示用支店はID値がないので文字列で比較する)
                        return user_branches.includes(branch.id) || me.result.user.user_bb_name === branch.name
                    })
                }
            }
            setBranchList(branches);

            // 支店名 初期値
            // ローカルストレージに保存されている前回検索時の支店を選択状態にする
            let defaultBranch = null;
            defaultBranch = branches.find((item) => item.id === parseInt(localStorage.getItem("default_branch_id")))

            // ローカルストレージに値がない場合は表示用支店を選択状態にする
            if (!defaultBranch && branches && branches.length > 0) {
                defaultBranch =  branchList.branches.find((branch) => me.result.user.user_bb_name === branch.name)
            }

            // 表示用支店が設定されていなければ、選択肢の最初の項目を選択状態にする
            if (!defaultBranch && branches && branches.length > 0) {
                defaultBranch = branches[0]
            }
            if (defaultBranch) {
                handleChangeBranchId(null, defaultBranch);
            }
        }
    
        fetchData()
            .then()
            .catch(e => {
                // todo エラーハンドリング
                if( e.response ){
                    if( e.response.status === 403 ){
                        // todo 「ログインしてください」を表示
                        // todo ユーザー情報の削除
                        // location.href = "/";
                    }
                    //todo エラーであることを知らせる表示
                }
            })

        // 来店日付(from) 初期値
        handleChangeReservationFromDate(moment().toDate());
    }, [bank, oidcUser, props]);

    return (
        <div className={classes.root}>
            <AdminHeader />
            <main className={classes.content}>
                <Box maxWidth="lg" className={classes.titleBox}>
                    <h4 className={classes.title}>来店予約一覧</h4>
                </Box>
                <Box className={classes.panel}>
                    <Box className={classes.panelBody}>
                        <Box className={classes.column}>
                            <Box className={classes.label}>
                                <Typography variant="overline" color="textSecondary" className={classes.title}>
                                    支店名*
                                </Typography>
                            </Box>
                            <Autocomplete
                                className={classes.autocomplete}
                                id="combo-box-branch"
                                options={branchList}
                                getOptionLabel={(option) => option.name?option.name:''}
                                style={{ width: 300 }}
                                renderInput={(params) => <TextField label={'支店名'} {...params} variant="outlined" className={classes.autocompleteTextField} error={isErrorBranchId} />}
                                onChange={handleChangeBranchId}
                                value={branch}
                                getOptionSelected={(option, value) => {
                                    return value === null || option?.name === value?.name || option?.id === value?.id
                                }}
                            />
                        </Box>
                        <Box className={classes.column}>
                            <Box className={classes.label}>
                                <Typography variant="overline" color="textSecondary" className={classes.title}>
                                    来店日*
                                </Typography>
                            </Box>
                            <Box className={visitPeriodBox}>
                                <DatePicker
                                    className={classes.lDatePicker}
                                    locale="ja"
                                    selected={fromDate}
                                    dateFormat="yyyy/MM/dd"
                                    onChange={handleChangeReservationFromDate}
                                    customInput={<TextField value={reservationFromDate} id="reservation-from-date" label="From" variant="outlined" fullWidth={true} size={"small"} error={isErrorReservationFromDate} />}
                                />
                                <div className={classes.stretchStick}>〜</div>
                                <DatePicker
                                  className={classes.rDatePicker}
                                  locale="ja"
                                  selected={toDate}
                                  dateFormat="yyyy/MM/dd"
                                  onChange={handleChangeReservationToDate}
                                  customInput={<TextField value={reservationToDate} id="reservation-to-date" label="To" variant="outlined" fullWidth={true} size={"small"} error={isErrorReservationToDate} />}
                                />
                            </Box>
                        </Box>
                        <Box className={classes.column}>
                            <Box className={classes.label}>
                                <Typography variant="overline" color="textSecondary" className={classes.title}>
                                    会社名
                                </Typography>
                            </Box>
                            <Box className={textFieldBoxW300}>
                                <TextField onChange={handleChangeCompanyName} id="company-name" label="会社名" variant="outlined" fullWidth={true} size={"small"} />
                            </Box>
                        </Box>
                        <Box className={classes.column}>
                            <Box className={classes.label}>
                                <Typography variant="overline" color="textSecondary" className={classes.title}>
                                    氏名
                                </Typography>
                            </Box>
                            <Box className={textFieldBoxW140}>
                                <TextField onChange={handleChangeLastName} id="last-name" label="氏" variant="outlined" fullWidth={true} size={"small"} />
                            </Box>
                        </Box>
                        <Box className={classes.column}>
                            <Box className={classes.label} />
                            <Box className={textFieldBoxW140}>
                                <TextField onChange={handleChangeFirstName} id="first-name" label="名" variant="outlined" fullWidth={true} size={"small"} />
                            </Box>
                        </Box>
                        <Box className={classes.column}>
                            <Box className={classes.label}>
                                <Typography variant="overline" color="textSecondary" className={classes.title}>
                                    生年月日
                                </Typography>
                            </Box>
                            <CustomBirthdatePicker
                                isAdmin={true}
                                classes={classes}
                                birthday={birthday}
                                birthdate={birthdate}
                                handleChangeBirthday={handleChangeBirthday}
                            />
                        </Box>
                        <Box className={classes.column}>
                            <Box className={classes.label}>
                                <Typography variant="overline" color="textSecondary" className={classes.title}>
                                    メニュー
                                </Typography>
                            </Box>
                            <FormControl variant="outlined" className={formControlW300} size={"small"}>
                                <InputLabel>メニュー</InputLabel>
                                <Select
                                    value={menuId}
                                    onChange={handleChangeMenuId}
                                    label="MenuId"
                                >
                                    <MenuItem value="">
                                        <em>未選択</em>
                                    </MenuItem>
                                    {menuList.map((menu) => (
                                        <MenuItem key={menu.master.id} value={menu.id}>{menu.master.name}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Box>
                        <Box className={classes.column}>
                            <Box className={classes.label}>
                                <Typography variant="overline" color="textSecondary" className={classes.title}>
                                    メニュー種別
                                </Typography>
                            </Box>
                            <FormControl variant="outlined" className={formControlW300} size={"small"}>
                                <InputLabel>メニュー種別</InputLabel>
                                <Select
                                    value={menuGroupId}
                                    onChange={handleChangeMenuGroupId}
                                    label="MenuGroupId"
                                >
                                    <MenuItem value="">
                                        <em>未選択</em>
                                    </MenuItem>
                                    {menuGroupList.map((group) => (
                                        <MenuItem key={group.id} value={group.id}>{group.name}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Box>
                        <Box className={classes.column}>
                            <Box className={classes.label}>
                                <Typography variant="overline" color="textSecondary" className={classes.title}>
                                    電話番号
                                </Typography>
                            </Box>
                            <Box className={textFieldBoxW300}>
                                <TextField onChange={handleChangeTelNo} id="tel-no" label="電話番号" variant="outlined" fullWidth={true} size={"small"} />
                            </Box>
                        </Box>
                        <Box className={classes.column}>
                            <Box className={classes.label}>
                                <Typography variant="overline" color="textSecondary" className={classes.title}>
                                    メールアドレス
                                </Typography>
                            </Box>
                            <Box className={textFieldBoxW300}>
                                <TextField onChange={handleChangeEmailAddress} id="email-address" label="メールアドレス" variant="outlined" fullWidth={true} size={"small"} />
                            </Box>
                        </Box>
                    </Box>
                    <Box className={classes.panelBodyWithCleaBoth}>
                        {errorMessages.map((errorMessage, index) => (
                          <p key={index} className={classes.errorMessage}>{errorMessage}</p>
                        ))}
                        <Box className={classes.searchButtonBox}>
                            <Button variant="contained" className={classes.button} onClick={handleSearchButtonClick}>検索</Button>
                        </Box>
                    </Box>
                    <Box className={classes.csvDownloadLink}>
                        <Link to="#" onClick={() => handleCSVDownloadButtonClick()} >
                            検索結果をCSVでダウンロードする
                        </Link>
                    </Box>
                </Box>
                <SearchedList searchedItemList={reservedList} />
            </main>
        </div>
    );
}

export default (AppointmentList);
