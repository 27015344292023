import React, {useEffect} from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from '@material-ui/core/Box';
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import clsx from "clsx";
import { useReactOidc } from '@axa-fr/react-oidc-context';

import AdminHeader from '../../components/AdminHeader';
import Api from "../../util/api";
import { useBankContext } from "../../util/BankContext";


const useStyle = makeStyles((theme) => ({
    root: {
        padding: "0 15px",
        margin: "0 auto",
        '&:before': {
            display: "table",
            content: '" "',
        },
    },
    content: {
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        height: '100vh',
        minHeight: "100vh",
    },
    titleBox: {
        marginBottom: 10,
        padding: "0 15px",
    },
    title: {
        color: "inherit",
        fontFamily: "inherit",
        fontSize: 18,
        fontWeight: 500,
        lineHeight: "1.1",
        marginTop: 10,
        marginBottom: 10,
    },
    panel: {
        backgroundColor: "#fff",
        border: "1px solid transparent",
        borderColor: "#ddd",
        borderRadius: 4,
        boxShadow: "0 1px 1px rgba(0,0,0,.05)",
        marginBottom: 20,
    },
    panelHeader: {
        backgroundColor: "#e8e8e9",
        height: 56,
        lineHeight: "56px",
        paddingLeft: 15,
    },
    panelBody: {
        padding: 15,
    },
    settingsButton: {
        background: "#52a451",
        borderRadius: 12,
        color: theme.palette.primary.color,
        height: 25,
    },
    textFieldBox: {
        float: "left",
        listStyleType: "none",
        margin: "0 10px",
        padding: 0,
        width: "auto",
    },
    dl: {
        margin: 0,
        padding: "13px 0 0 0",
        position: "relative",
        width: "100%",
    },
    dd: {
        color: "#5f5f5f",
        display: "inline-block",
        fontSize: "100%",
        fontWeight: "bold",
        margin: 0,
        padding: "36px 0 0 0",
        textAlign: "left",
        width: 650,
    },
    dialogContentTitleBox: {
        float: "left",
        height: 56,
        lineHeight: "56px",
        listStyleType: "none",
        margin: "0 10px 0 0",
        padding: 0,
        width: 150,
    },
    buttonBox: {
        textAlign: "center",
        display: "block",
    },
    w450: {
        width: 450,
    },
}))

const AutoReplyMailSetting = (props) => {
    const bank = useBankContext();
    window.document.title = 'BA Portal';
    const { oidcUser } = useReactOidc();
    const classes = useStyle();
    // const [mailTypeName, setMailTypeName] = React.useState('');
    const [mailSettingId, setMailSettingId] = React.useState('');
    const [mailTitle, setMailTitle] = React.useState('');
    const [mailBody, setMailBody] = React.useState('');
    const [mailSettingList, setMailSettingList] = React.useState({});
    const [openMailSettings, setOpenMailSettings] = React.useState(false);

    const mailSettingsDialogTextBox = clsx(classes.textFieldBox, classes.w450);

    const handleClickOpenMailSettings = async (mailSettingId, event) => {
        setMailSettingId(mailSettingId);

        // 自動返信メール設定詳細
        const mailSetting = await Api.get(bank.realm, process.env.REACT_APP_APPOINTMENT_BASE_URL + '/automail_setting', {i: mailSettingId}, {}, oidcUser);

        if (!('template' in mailSetting) || mailSetting.template === null) {
            setMailTitle('');
            setMailBody('');
        } else {
            setMailTitle(mailSetting.template.title);
            setMailBody(mailSetting.template.body);
        }

        setOpenMailSettings(true);
    };

    const handleCloseMailSettings = () => {
        setOpenMailSettings(false);
    };

    // const handleChangeMailTypeName = (event) => {
    //     setMailTypeName(event.target.value);
    // }

    const handleChangeMailTitle = (event) => {
        setMailTitle(event.target.value);
    }

    const handleChangeMailBody = (event) => {
        setMailBody(event.target.value);
    }

    // メール設定編集
    const updateMailSettings = async () => {
        if (mailTitle === '') return;
        if (mailBody === '') return;

        await Api.putBodyParam(
            bank.realm,
            process.env.REACT_APP_APPOINTMENT_BASE_URL + '/automail_setting',
            {
                template: {
                    title: mailTitle,
                    body: mailBody,
                },
            },
            {},
            oidcUser,
            {
                i: mailSettingId,
            },
        );

        setOpenMailSettings(false);
    }

    useEffect( () => {
        if (bank.canUseGA) {
            if (!!window.gtag) {
                window.gtag('config', bank.gaTrackingCode, {
                    'page_path': `${props.location.pathname}${props.location.search}`
                });
            }
        }

        const fetchData = async () => {
            // 自動返信メール設定一覧
            const mailSettingIds = await Api.get(bank.realm, process.env.REACT_APP_APPOINTMENT_BASE_URL + '/automail_settings', {}, {}, oidcUser);

            if (!('ids' in mailSettingIds) || mailSettingIds.ids === null) {
                setMailSettingList({});
            } else {
                setMailSettingList(mailSettingIds.ids);
            }
        }

        fetchData()
            .then()
            .catch(e => {
                // todo エラーハンドリング
                if( e.response ){
                    if( e.response.status === 403 ){
                        // todo 「ログインしてください」を表示
                        // todo ユーザー情報の削除
                        // location.href = "/";
                    }
                    //todo エラーであることを知らせる表示
                }
            })
    }, [bank, oidcUser, props]);

    return (
        <div className={classes.root}>
            <AdminHeader />
            <main className={classes.content}>
                <Box maxWidth="lg" className={classes.titleBox}>
                    <h4 className={classes.title}>自動返信メール設定</h4>
                </Box>
                <Grid container spacing={4}>
                    <Grid item xs={6}>
                        <Box className={classes.panel}>
                            <Box className={classes.panelHeader}>
                                メール文言設定
                            </Box>
                            <Box className={classes.panelBody}>
                                <TableContainer component={Paper}>
                                    <Table aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>メール種類</TableCell>
                                                <TableCell></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {Object.keys(mailSettingList).map(key => (
                                                <React.Fragment key={key}>
                                                    {key === 'AcceptAppointment' && (
                                                        <TableRow>
                                                            <TableCell>予約受付メール</TableCell>
                                                            <TableCell>
                                                                <Button
                                                                    onClick={handleClickOpenMailSettings.bind(this, mailSettingList[key])}
                                                                    variant="contained"
                                                                    className={classes.settingsButton}
                                                                >
                                                                    設定
                                                                </Button>
                                                            </TableCell>
                                                        </TableRow>
                                                    )}
                                                    {key === 'ChangeAppointment' && (
                                                        <TableRow>
                                                            <TableCell>予約内容変更連絡メール</TableCell>
                                                            <TableCell>
                                                                <Button
                                                                    onClick={handleClickOpenMailSettings.bind(this, mailSettingList[key])}
                                                                    variant="contained"
                                                                    className={classes.settingsButton}
                                                                >
                                                                    設定
                                                                </Button>
                                                            </TableCell>
                                                        </TableRow>
                                                    )}
                                                </React.Fragment>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <Dialog
                                    open={openMailSettings}
                                    onClose={handleCloseMailSettings}
                                    aria-labelledby="simple-modal-title"
                                    aria-describedby="simple-modal-description"
                                    maxWidth="auto"
                                >
                                    <DialogTitle id="alert-dialog-slide-title">{"自動返信メール設定"}</DialogTitle>
                                    <DialogContent>
                                        {/*<Box className={classes.dl}>*/}
                                        {/*    <Box className={classes.dd}>*/}
                                        {/*        <Box className={classes.dialogContentTitleBox}>*/}
                                        {/*            <Typography variant="overline" color="textSecondary">*/}
                                        {/*                メール種類名称*/}
                                        {/*            </Typography>*/}
                                        {/*        </Box>*/}
                                        {/*        <Box className={mailSettingsDialogTextBox}>*/}
                                        {/*            <TextField*/}
                                        {/*                value={mailTypeName}*/}
                                        {/*                onChange={handleChangeMailTypeName}*/}
                                        {/*                label="メール種類名称"*/}
                                        {/*                variant="outlined"*/}
                                        {/*                fullWidth={true}*/}
                                        {/*                size={"small"}*/}
                                        {/*            />*/}
                                        {/*        </Box>*/}
                                        {/*    </Box>*/}
                                        {/*</Box>*/}
                                        <Box className={classes.dl}>
                                            <Box className={classes.dd}>
                                                <Box className={classes.dialogContentTitleBox}>
                                                    <Typography variant="overline" color="textSecondary">
                                                        メールタイトル
                                                    </Typography>
                                                </Box>
                                                <Box className={mailSettingsDialogTextBox}>
                                                    <TextField
                                                        value={mailTitle}
                                                        onChange={handleChangeMailTitle}
                                                        label="メールタイトル"
                                                        variant="outlined"
                                                        fullWidth={true}
                                                        size={"small"}
                                                    />
                                                </Box>
                                            </Box>
                                        </Box>
                                        <Box className={classes.dl}>
                                            <Box className={classes.dd}>
                                                <Box className={classes.dialogContentTitleBox}>
                                                    <Typography variant="overline" color="textSecondary">
                                                        メール内容
                                                    </Typography>
                                                </Box>
                                                <Box className={mailSettingsDialogTextBox}>
                                                    <TextField
                                                        value={mailBody}
                                                        onChange={handleChangeMailBody}
                                                        label="メール内容"
                                                        multiline
                                                        variant="outlined"
                                                        fullWidth={true}
                                                        size={"small"}
                                                        rows={20}
                                                    />
                                                </Box>
                                            </Box>
                                        </Box>
                                    </DialogContent>
                                    <DialogActions className={classes.buttonBox}>
                                        <Button variant="contained" onClick={updateMailSettings} color="primary">
                                            設定
                                        </Button>
                                    </DialogActions>
                                </Dialog>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </main>
        </div>
    );
}

export default AutoReplyMailSetting;
