import React  from 'react';
import {Dialog, DialogContent} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useBankContext } from "../util/BankContext";

const useStyles = makeStyles(() => ({
  ConfirmDialog: {

  },
  ConfirmDialogContent: {
    padding: "40px 42px 32px!important",
    fontSize: "18px",
    "& div": {
      lineHeight: "1.75em !important",
      "& img.close": {
        position: "absolute",
        top: "20px",
        right: "20px",
        width: "48px",
      },
      "& p": {
        textAlign: "center",
        marginTop: "4px",
        fontSize: "16px",
        "& a": {
          display: "inline-flex",
          color: "#26AB9D",
          textDecoration: "none",
          "&:hover": {
            color: "#1B867B",
          }
        }
      },
      "& div": {
        marginTop: "32px",
        textAlign: 'center',
        "& button": {
          width: "190px",
          height: "60px",
          lineHeight: "20px",
          borderRadius: "7px",
          fontSize: "18px",
          letterSpacing: "2px",

          border: "1px solid #159E43",
          color: "#fff",
          background: "#159E43",
          margin: "8px 18px",
          "& span.sub": {
            fontSize: '75%',
            letterSpacing: "1px",
          },
          "&.negative": {
            border: "1px solid #b5b5b5",
            background: "#b5b5b5",
          }
        }
      }
    },
  },
  ieDialogLaunchIcon: {
    width: "1rem",
  },
}));

const ConfirmDialog = (props) => {
  const bank = useBankContext();
  const classes = useStyles();
  const message = props.message || 'よろしいですか？';
  const posi = props.positive || 'はい';
  const nega = props.negative || 'いいえ';
  const posiSub = props.positiveSub || '';
  const negaSub = props.negativeSub || '';
  const onPositive = props.onPositive || (() => {});
  const open = !!props.open;
  const handleClose = props.handleClose || (() => {});

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="md"
      className={classes.ConfirmDialog}
    >
      <DialogContent className={classes.ConfirmDialogContent}>
        <div>
          <p>{message}</p>
          <div>
            <button className="positive" onClick={onPositive}>{posi}{posiSub && <><br /><span className="sub">{posiSub}</span></>}</button>
            <button className="negative" onClick={props.handleClose}>{nega}{negaSub  && <><br /><span className="sub">{negaSub}</span></>}</button>
          </div>

        </div>
      </DialogContent>
    </Dialog>
  );
};

export default ConfirmDialog;
