import React from 'react';
import { makeStyles } from "@material-ui/core/styles";
import Box from '@material-ui/core/Box';
import Typography from "@material-ui/core/Typography";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import clsx from "clsx";
import { useReactOidc } from '@axa-fr/react-oidc-context';
import { useSnackbar } from 'notistack';

import TimeMathSum from "../util/TimeMathSum";
import Api from "../util/api";
import FindADayFromSundayOfTheWeek from "../util/FindADayFromSundayOfTheWeek";
import moment from "moment/moment";
import CustomBirthdatePicker from "./CustomBirthdatePicker";


const useStyles = makeStyles((theme) => ({
    panel: {
        backgroundColor: "#fff",
        border: "1px solid transparent",
        borderColor: "#ddd",
        borderRadius: 4,
        boxShadow: "0 1px 1px rgba(0,0,0,.05)",
        marginBottom: 20,
    },
    panelBody: {
        padding: 15,
    },
    column: {
        float: "left",
        marginBottom: 15,
    },
    label: {
        height: 36,
        lineHeight: "36px",
        listStyleType: "none",
        marginLeft: 10,
        padding: 0,
    },
    title: {
        color: "inherit",
        fontFamily: "inherit",
        fontSize: 18,
        fontWeight: 500,
        lineHeight: "1.1",
        marginTop: 10,
        marginBottom: 10,
    },
    textFieldBox: {
        listStyleType: "none",
        margin: "0 10px",
        padding: 0,
        width: "auto",
    },
    formControl: {
        margin: "0 10px",
        minWidth: 120,
    },
    w140: {
        width: 140,
    },
    w300: {
        width: 300,
    },
    autocomplete: {
        margin: "0 10px",
    },
    autocompleteTextField: {
        height: 40,
        '& div': {
            height: 40,
        },
        '& div input': {
            padding: "0 !important",
        },
    },
}));

const AppointmentForm = (props) => {
    const classes = useStyles();
    const lastNameTextFieldBox = clsx(classes.textFieldBox, classes.w140);
    const firstNameTextFieldBox = clsx(classes.textFieldBox, classes.w140);
    const lastNameKanaTextFieldBox = clsx(classes.textFieldBox, classes.w140);
    const firstNameKanaTextFieldBox = clsx(classes.textFieldBox, classes.w140);
    const companyNameTextFieldBox = clsx(classes.textFieldBox, classes.w300);
    const telNoTextFieldBox = clsx(classes.textFieldBox, classes.w300);
    const menuFormControl = clsx(classes.formControl, classes.w300);
    const emailAddressTextFieldBox = clsx(classes.textFieldBox, classes.w300);
    const remarkTextFieldBox = clsx(classes.textFieldBox, classes.w300);
    const { enqueueSnackbar } = useSnackbar();
    const { oidcUser } = useReactOidc();

    const handleChangeLastName = (event) => {
        props.setLastName(event.target.value);
    };

    const handleChangeFirstName = (event) => {
        props.setFirstName(event.target.value);
    };

    const handleChangeLastNameKana = (event) => {
        props.setLastNameKana(event.target.value);
    };

    const handleChangeFirstNameKana = (event) => {
        props.setFirstNameKana(event.target.value);
    };

    const handleChangeCompanyName = (event) => {
        props.setCompanyName(event.target.value);
    };
    
    const handleChangeBirthday = (birthday) => {
        if (birthday === null) {
            props.setBirthdate('');
            props.setBirthday('');
            return;
        }
    
        props.setBirthdate(moment.utc(birthday, 'YYYY-MM-DDTHH:mm:00Z', 'ja').utcOffset(9).toDate());
        const formatDate = moment(birthday).format('YYYY-MM-DD');
        props.setBirthday(formatDate);
    };

    const handleChangeTelNo = (event) => {
        props.setTelNo(event.target.value);
    };

    const handleChangeBranchId = async (event, branch) => {
        props.setMenuId('');
        props.setShowDatePicker(false);
        props.setDisplayReservedDate('');

        if (branch === null) {
            props.setBranchId('');
            props.setBranch(null);
            props.setMenuList([]);
        } else {
            props.setBranchId(branch.id);
            props.setBranch(branch);
            const menuList = await Api.get(props.realm, process.env.REACT_APP_APPOINTMENT_BASE_URL + '/menus', {br: branch.id}, {}, oidcUser);
            if (!('menus' in menuList) || menuList.menus === null) {
                props.setMenuList([]);
            } else {
                props.setMenuList(menuList.menus);
            }
        }
    };

    const handleChangeMenuId = async (event) => {
        props.setMenuId(event.target.value);

        if (event.target.value === '') {
            props.setDuration('');
            props.setCalendar({
                timeTable: [],
                availabilities: {}
            });
            props.setShowDatePicker(false);
            return;
        }
        const selectedMenu = props.menus.find((menu) => {
            return (menu.id === event.target.value);
        });
        props.setDuration(selectedMenu.duration);

        if(event.target.value !== '' && props.branchId !== '') {
            props.setShowDatePicker(true);
        } else {
            props.setShowDatePicker(false);
        }

        if(props.displayReservedDate !== '') {
            const toTime = TimeMathSum((props.reservationFromTime+':00'), ('00:' + selectedMenu.duration + ':00'));
            props.setDisplayReservedDate(props.reservationDate + " " + props.reservationFromTime + " 〜 " + toTime);
            props.setReservationToTime(toTime);
        }

        const today = new Date()
        let f = FindADayFromSundayOfTheWeek(today).format('YYYY-MM-DD');
        let t = FindADayFromSundayOfTheWeek(today).add(6, 'd').format('YYYY-MM-DD');

        // 予約状況一覧
        const availabilityList = await Api.get(
            props.realm,
        process.env.REACT_APP_APPOINTMENT_BASE_URL + '/availabilities',
            {
                br: props.branchId,
                m: event.target.value,
                f: f,
                t: t
            }, {}, oidcUser
        );
        if (!!availabilityList.error) {
            props.setCalendar({
                timeTable: [],
                availabilities: {}
            });
            enqueueSnackbar('エラーが発生しました', { variant: 'error' });
            return;
        }
        props.setAppointment({ 'branchId': props.branchId, 'menuId': event.target.value });
        props.setCalendar(availabilityList.calendar);
    };

    const handleChangeEmailAddress = (event) => {
        props.setEmailAddress(event.target.value);
    };

    const handleChangeNote = (event) => {
        props.setNote(event.target.value);
    };

    return (
        <Box className={classes.panel}>
            <Box className={classes.panelBody}>
                <Box>
                    <Box className={classes.column}>
                        <Box className={classes.label}>
                            <Typography variant="overline" color="textSecondary" className={classes.title}>
                                氏名
                            </Typography>
                        </Box>
                        <Box className={lastNameTextFieldBox}>
                            <TextField value={props.lastName} onChange={handleChangeLastName} id="last-name" label="氏" variant="outlined" fullWidth={true} size={"small"} error={props.isErrorLastName} required={true}/>
                        </Box>
                    </Box>
                    <Box className={classes.column}>
                        <Box className={classes.label} />
                        <Box className={firstNameTextFieldBox}>
                            <TextField value={props.firstName} onChange={handleChangeFirstName} id="first-name" label="名" variant="outlined" fullWidth={true} size={"small"} error={props.isErrorFirstName} required={true} />
                        </Box>
                    </Box>
                    <Box className={classes.column}>
                        <Box className={classes.label}>
                            <Typography variant="overline" color="textSecondary" className={classes.title}>
                                氏名カナ
                            </Typography>
                        </Box>
                        <Box className={lastNameKanaTextFieldBox}>
                            <TextField value={props.lastNameKana} onChange={handleChangeLastNameKana} id="last-name-kana" label="セイ" variant="outlined" fullWidth={true} size={"small"} error={props.isErrorLastNameKana} required={true} />
                        </Box>
                    </Box>
                    <Box className={classes.column}>
                        <Box className={classes.label} />
                        <Box className={firstNameKanaTextFieldBox}>
                            <TextField value={props.firstNameKana} onChange={handleChangeFirstNameKana} id="first-name-kana" label="メイ" variant="outlined" fullWidth={true} size={"small"} error={props.isErrorFirstNameKana} required={true} />
                        </Box>
                    </Box>
                    <Box className={classes.column}>
                        <Box className={classes.label}>
                            <Typography variant="overline" color="textSecondary" className={classes.title}>
                                生年月日
                            </Typography>
                        </Box>
                        <CustomBirthdatePicker
                            isErrorBirthday={props.isErrorBirthday}
                            isAdmin={true}
                            classes={classes}
                            birthday={props.birthday}
                            birthdate={props.birthdate}
                            handleChangeBirthday={handleChangeBirthday}
                        />
                    </Box>
                    <Box className={classes.column}>
                        <Box className={classes.label}>
                            <Typography variant="overline" color="textSecondary" className={classes.title}>
                                企業名
                            </Typography>
                        </Box>
                        <Box className={companyNameTextFieldBox}>
                            <TextField value={props.companyName} onChange={handleChangeCompanyName} id="company-name" label="企業名" variant="outlined" fullWidth={true} size={"small"} />
                        </Box>
                    </Box>
                    <Box className={classes.column}>
                        <Box className={classes.label}>
                            <Typography variant="overline" color="textSecondary" className={classes.title}>
                                電話番号
                            </Typography>
                        </Box>
                        <Box className={telNoTextFieldBox}>
                            <TextField value={props.telNo} onChange={handleChangeTelNo} id="tel-no" label="電話番号" variant="outlined" fullWidth={true} size={"small"} error={props.isErrorTelNo} required={true} />
                        </Box>
                    </Box>
                    <Box className={classes.column}>
                        <Box className={classes.label}>
                            <Typography variant="overline" color="textSecondary" className={classes.title}>
                                支店名
                            </Typography>
                        </Box>
                        <Autocomplete
                            className={classes.autocomplete}
                            id="combo-box-branch"
                            options={props.branches}
                            getOptionLabel={(option) => option.name?option.name:''}
                            style={{ width: 300 }}
                            renderInput={(params) => <TextField placeholder={'支店名'} {...params} variant="outlined" className={classes.autocompleteTextField} />}
                            onChange={handleChangeBranchId}
                            value={props.branch}
                            getOptionSelected={(option, value) => {
                                return value === null || option?.name === value?.name || option?.id === value?.id
                            }}
                        />
                    </Box>
                    <Box className={classes.column}>
                        <Box className={classes.label}>
                            <Typography variant="overline" color="textSecondary" className={classes.title}>
                                メニュー
                            </Typography>
                        </Box>
                        <FormControl variant="outlined" className={menuFormControl} size={"small"}>
                            <InputLabel>メニュー</InputLabel>
                            <Select
                                value={props.menuId}
                                onChange={handleChangeMenuId}
                                label="MenuId"
                            >
                                <MenuItem value="">
                                    <em>未選択</em>
                                </MenuItem>
                                {props.menus?.map((menu) => (
                                    <MenuItem key={menu.master.id} value={menu.id}>{menu.master.name}（{menu.duration}分）</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>
                    <Box className={classes.column}>
                        <Box className={classes.label}>
                            <Typography variant="overline" color="textSecondary" className={classes.title}>
                                メールアドレス
                            </Typography>
                        </Box>
                        <Box className={emailAddressTextFieldBox}>
                            <TextField value={props.emailAddress} onChange={handleChangeEmailAddress} id="email-address" label="メールアドレス" variant="outlined" fullWidth={true} size={"small"} error={props.isErrorEmailAddress} />
                        </Box>
                    </Box>
                    <Box className={classes.column}>
                        <Box className={classes.label}>
                            <Typography variant="overline" color="textSecondary" className={classes.title}>
                                ご用件
                            </Typography>
                        </Box>
                        <Box className={remarkTextFieldBox}>
                            <TextField value={props.note} onChange={handleChangeNote} id="note" label="ご用件" variant="outlined" fullWidth={true} size={"small"} />
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

export default AppointmentForm;
