import React, { useEffect } from "react";
import moment from "moment";
import 'moment/locale/ja';
import { Link } from "react-router-dom";
import { useReactOidc } from '@axa-fr/react-oidc-context';

import Api from "../util/api";
import { useBankContext } from "../util/BankContext";
import useSafeState from "../helpers/useSafeState";
import useUnmountRef from "../helpers/useUnmountRef";


const AfterLoginTopManagementColumn = (props) => {
    const unmountRef = useUnmountRef();
    moment.locale('ja');
    const [managementColumnList, setManagementColumnList] = useSafeState(unmountRef, []);
    const { oidcUser } = useReactOidc();
    const bank = useBankContext();

    useEffect( () => {
        const fetchData = async () => {
          // 経営コラム一覧取得API
          const managementColumnList = await Api.get(props.realm, process.env.REACT_APP_COLUMN_BASE_URL + '/columns/search', {r: bank.params.top.columnListRows, p: 1}, {}, oidcUser);

          if (managementColumnList.columns === null) {
            setManagementColumnList([]);
          } else {
            setManagementColumnList(managementColumnList.columns);
          }
        };

        fetchData()
            .then()
            .catch(e => {
                // todo エラーハンドリング
                if( e.response ){
                    if( e.response.status === 403 ){
                        // todo 「ログインしてください」を表示
                        // todo ユーザー情報の削除
                        // location.href = "/";
                    }
                    //todo エラーであることを知らせる表示
                }
            });

    }, [bank, oidcUser, props, setManagementColumnList]);

    return (
        <div className="left-column article">
            <h2 className="title-icon column-title">経営コラム</h2>
            <ul>
                {managementColumnList.map((managementColumn) => (
                    <li key={managementColumn.uniqueCode}>
                        <div className="wrapper-image">
                            <img src={`${bank.baManagerUrl}picture/bank/${managementColumn.imageFileUniqueCode}/1000x`} alt={managementColumn.title} />
                        </div>
                        <div className="wrapper-body">
                            <div className="category-and-date">
                                <p className="category">経営</p>
                                <p className="date">{moment(managementColumn.createdAt).format('YYYY.M.D')}</p>
                            </div>
                            <p className="title">
                                {managementColumn.title}
                            </p>
                            <div className="btn-area">
                                {managementColumn.isExternalArticle && (
                                    <a href={managementColumn.externalURL} className="btn white-color size-m" target={"_blank"} rel="noopener noreferrer">
                                        記事の続きを見る
                                    </a>
                                )}
                                {!managementColumn.isExternalArticle && (
                                    <Link to={"/management_column/detail/" + managementColumn.uniqueCode} className="btn white-color size-m">
                                        記事の続きを見る
                                    </Link>
                                )}
                            </div>
                        </div>
                    </li>
                ))}
            </ul>
        </div>
    )
};

export default AfterLoginTopManagementColumn;
