// 時間を秒に変換
const toSecond = (hour, minute, second) => {
    if ((!hour && hour !== 0) || (!minute && minute !== 0) || (!second && second !== 0) ||
        hour === null || minute === null || second === null ||
        typeof hour === 'boolean' ||
        typeof minute === 'boolean' ||
        typeof second === 'boolean' ||
        isNaN(Number(hour)) ||
        isNaN(Number(minute)) ||
        isNaN(Number(second))) return;

    return (Number(hour) * 60 * 60) + (Number(minute) * 60) + Number(second);
}

// 秒を時間（hh:mm:ss）のフォーマットに変換
const toTimeFormat = (fullSecond) => {
    let hour, minute;

    if ((!fullSecond && fullSecond !== 0) || !String(fullSecond).match(/^[-0-9][0-9]*?$/)) return;

    let paddingZero = function(n) {
        return (n < 10)  ? '0' + n : n;
    };

    hour   = Math.floor(Math.abs(fullSecond) / 3600);
    minute = Math.floor(Math.abs(fullSecond) % 3600 / 60);

    hour = paddingZero(hour);
    minute = paddingZero(minute);

    return ((fullSecond < 0) ? '-' : '') + hour + ':' + minute;
}

// 加算
const TimeMathSum = (...args) => {
    let result, times, second, i,
        len = args.length;

    if (len === 0) return;

    for (i = 0; i < len; i++) {
        if (!args[i] || !args[i].match(/^[0-9]+:[0-9]{2}:[0-9]{2}$/)) continue;

        times = args[i].split(':');

        second = toSecond(times[0], times[1], times[2]);

        if ((!second && second !== 0)) continue;

        if (i === 0) {
            result = second;
        } else {
            result += second;
        }
    }

    return toTimeFormat(result);
}

export default TimeMathSum;

