import React, { useCallback, useEffect } from "react";
import { useHistory } from 'react-router-dom';
import clsx from "clsx";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Helmet } from "react-helmet";

import topStyle from '../../css/ktba/custom_top.less';
import effectStyle from '../../css/effect.less';
import { useBankContext } from "../../util/BankContext";
import BeforeLoginFooter from "./BeforeLoginFooter";
import BeforeLoginHeader from "./BeforeLoginHeader";


const BeforeLogin = () => {
    const matches = useMediaQuery('(min-width:601px)');
    const bank = useBankContext();
    const history = useHistory();

    window.document.title = '京銀ビジネスポータルサイト';

    const btnKeyColorBorder = clsx(topStyle.btn, topStyle.keyColorBorder);
    const goHowToBtn = clsx(topStyle.serviceStart, topStyle.btn, topStyle.keyColorFill);
    const contentMv = clsx(topStyle.content, topStyle.mv);
    const innerVisualAndCopy = clsx(topStyle.contentInner, topStyle.visualAndCopy);
    const effectFadePos80 = clsx(effectStyle.effectFade, effectStyle.pos80, "effect-fade");
    const copyEffectFadePos40Fast = clsx(topStyle.copy, effectStyle.effectFade, effectStyle.pos40, effectStyle.fast, "effect-fade");
    const contentMessage = clsx(topStyle.content, topStyle.message);
    const mainMessageEffectFadePos40 = clsx(topStyle.mainMessage, effectStyle.effectFade, effectStyle.pos40);
    const subMessageEffectFadePos40 = clsx(topStyle.subMessage, effectStyle.effectFade, effectStyle.pos40);
    const contentHowto = clsx(topStyle.content, topStyle.howto);
    const contentInnerHowtoInner = clsx(topStyle.contentInner, topStyle.howtoInner);
    const attentionEffectFadePos80Fast = clsx(topStyle.attention, effectStyle.effectFade, effectStyle.pos80, effectStyle.fast);
    const boxesEffectFadePos80 = clsx(topStyle.howtoBoxes, effectStyle.effectFade, effectStyle.pos80);
    const boxLeft = clsx(topStyle.box, topStyle.left, "left");
    const boxRight = clsx(topStyle.box, topStyle.right, "right");
    const contentFunctions = clsx(topStyle.content, topStyle.functions);
    const boxEffectFadePos80 = clsx(topStyle.box, effectStyle.effectFade, effectStyle.pos80);
    const contentScheme = clsx(topStyle.content, topStyle.scheme);
    const contentStart = clsx(topStyle.content, topStyle.start);
    const getAccountBtnSizeXl = clsx(topStyle.btn, topStyle.sizeXl, topStyle.getAccountBtn);

    const loginAction = useCallback((event) => {
      event.preventDefault();
      history.push("/login");
    }, [history]);

    const scrollEffect = () => {
        const effectFadeElements = document.getElementsByClassName(effectStyle.effectFade);
        Array.from(effectFadeElements).forEach(function(val, index) {
            const userAgent = window.navigator.userAgent.toLowerCase();

            // IEの場合スクロール位置の取得が上手くできていないため、スクロール位置に関係なくclassの付与を行い画像を表示させる
            if (userAgent.indexOf('msie') !== -1 || userAgent.indexOf('trident') !== -1) {
                val.classList.add(effectStyle.effectScroll);
            } else {
                const rect = val.getBoundingClientRect();
                const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
                const elemPos = rect.top + scrollTop;
                const scroll = window.scrollY;
                const windowHeight = window.innerHeight;

                if (scroll > elemPos - windowHeight) {
                    val.classList.add(effectStyle.effectScroll);
                }
            }
        })
    };

    useEffect( () => {
        scrollEffect();
        document.addEventListener('scroll', scrollEffect);
    }, []);

    return (
        <>
            <BeforeLoginHeader />
            <main>
                <div className={contentMv}>
                    <div className={topStyle.bgLeft} />
                    <div className={innerVisualAndCopy}>
                        {matches && (
                          <img src={`/img/${bank.staticFilePathPrefix}/top/mv_01.png`} alt="" className={effectFadePos80} />
                        )}
                        <div className={copyEffectFadePos40Fast}>
                            <p className={topStyle.mainCopy}>
                                もっと<strong>身近</strong>に、<br />
                                もっと<strong>便利</strong>に
                            </p>
                            <p className={topStyle.subCopy}>
                                法人・個人事業主の皆様をサポートする<br />
                                京銀ビジネスポータルサイト
                            </p>
                            <a href="#howto" className={goHowToBtn}>サービスのご利用はこちらから</a>
                        </div>
                    </div>
                    <div className={topStyle.scroll}>
                        <span /><span /><span />
                        <p>SCROLL</p>
                    </div>
                </div>
                <div className={contentMessage}>
                    <p className={mainMessageEffectFadePos40}>
                        “<strong>今、京銀に</strong>できること”
                    </p>
                    <p className={subMessageEffectFadePos40}>
                        ”もっと身近に、もっと便利に金融サービスを活用いただきたい”<br />
                        ”お客さまのビジネスをサポートしたい”<br />
                        その想いから「京銀ビジネスポータルサイト」は誕生しました。<br />
                        京銀ビジネスポータルサイトは、<br />
                        様々な金融ソリューションサービスをワンストップでご提供いたします。
                    </p>
                </div>
                <div id="howto" className={contentHowto}>
                    <div className={contentInnerHowtoInner}>
                        <p className={attentionEffectFadePos80Fast}>
                            京銀ビジネスポータルサイトのご利用開始方法
                        </p>
                        <div className={boxesEffectFadePos80}>
                            <div className={topStyle.boxWrapper}>
                                <div className={boxLeft}>
                                    <p className={topStyle.boxTitle}>
                                        京銀インターネットＥＢサービス<br />
                                        <strong>ご契約中</strong>のお客さま
                                    </p>
                                    <p className={topStyle.boxBody}>
                                        京銀インターネットＥＢサービスのアカウントで、京銀ビジネスポータルサイトをご利用いただけます。下記の「ログイン（利用開始）」からお手続きください。
                                    </p>
                                    <a href="/login" onClick={loginAction}  className={btnKeyColorBorder}>ログイン(利用開始)</a>
                                </div>
                            </div>
                            <div className={topStyle.boxWrapper}>
                                <div className={boxRight}>
                                    <p className={topStyle.boxTitle}>
                                        京銀インターネットＥＢサービス<br />
                                        を<strong>未契約</strong>のお客さま
                                    </p>
                                    <p className={topStyle.boxBody}>
                                        京銀ビジネスポータルサイトの専用アカウントの取得が必要です。下記の「アカウント取得」からお手続きください。
                                    </p>
                                    <a href={bank.accountGetUrl}  className={btnKeyColorBorder} target="_blank" rel="noopener noreferrer">アカウント取得</a>
                                    <p className={topStyle.boxBody}>
                                    ※この機会に”京銀インターネットＥＢサービス”のご契約もあわせてご検討ください。お申し込み方法については、お取引店までご連絡ください。
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className={topStyle.guide}>
                            <a href={"https://www.kyotobank.co.jp/houjin/business-portal/users-manual/pdf/guide.pdf"}>詳細なご利用開始ガイドはこちら</a>
                        </div>
                    </div>
                </div>
                <div className={contentFunctions}>
                    <div className={topStyle.contentInner}>
                        <p className={topStyle.functionsTitle}>
                            “<strong>京銀ビジネスポータルサイトで、</strong>できること”
                        </p>
                        <p className={topStyle.functionsMessage}>
                        京銀ビジネスポータルサイトは、法人・個人事業主のお客さまと京都銀行をデジタルでつなぎ、様々な金融ソリューションサービスをワンストップでご提供いたします。
                        </p>
                        <ul className={topStyle.functionsBoxes}>
                            <li className={boxEffectFadePos80}>
                                <img src={`/img/${bank.staticFilePathPrefix}/menu_eb.png`} alt="" /><br />
                                <p className={topStyle.title}>残高・明細照会</p>
                                <p className={topStyle.body}>いつでも、簡単に残高や直近1週間分の明細をご確認いただけます。<br />※ただし、無料で確認できる口座は1口座に限ります。</p>
                            </li>
                            <li className={boxEffectFadePos80}>
                                <img src={`/img/${bank.staticFilePathPrefix}/menu_denshi.png`} alt="" /><br />
                                <p className={topStyle.title}>電子交付サービス</p>
                                <p className={topStyle.body}>紙の郵送物に替えて電子帳票で受け取れるサービスです。<br />パソコン上で電子帳票の確認・保存ができます。（ペーパレス）</p>
                            </li>

                            <li className={boxEffectFadePos80}>
                                <img src={`/img/${bank.staticFilePathPrefix}/menu_shop.png`} alt="" /><br />
                                <p className={topStyle.title}>来店予約</p>
                                <p className={topStyle.body}>事前に来店のご予約をいただくことで、待ち時間なしで、<br />すぐにご案内いたします。</p>
                            </li>
                            <li className={boxEffectFadePos80}>
                                <img src={`/img/${bank.staticFilePathPrefix}/menu_chat.png`} alt="" /><br />
                                <p className={topStyle.title}>チャット</p>
                                <p className={topStyle.body}>お取引店の担当者との連絡手段として、<br />チャットをご利用いただけます。</p>
                            </li>
                            <li className={boxEffectFadePos80}>
                                <img src={`/img/${bank.staticFilePathPrefix}/menu_seminar.png`} alt="" /><br />
                                <p className={topStyle.title}>セミナー予約</p>
                                <p className={topStyle.body}>セミナー情報を配信。簡単にセミナーへの申込予約ができます。<br />&nbsp;</p>
                            </li>
                            <li className={boxEffectFadePos80}>
                                <img src={`/img/${bank.staticFilePathPrefix}/menu_column.png`} alt="" /><br />
                                <p className={topStyle.title}>経営コラム</p>
                                <p className={topStyle.body}>専門家が執筆した経営コラムを定期的に更新しています。<br />&nbsp;</p>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className={contentScheme}>
                    <div className={topStyle.contentInner}>
                        <p className={topStyle.schemeTitle}>
                            “<strong>各金融サービスとの</strong>連携”
                        </p>
                        <p className={topStyle.schemeMessage}>
                        京銀ビジネスポータルサイトにログインいただくだけで、「京銀インターネットＥＢサービス」や「京銀Big Advance」などの各種サービスを簡単にご利用いただけます。
                        </p>
                        <div className={topStyle.schemeImage}>
                            {matches && (
                                <img src={`/img/${bank.staticFilePathPrefix}/top/pc_01.png`} alt="" className={effectFadePos80} />
                            )}
                            {!matches && (
                                <img src={`/img/${bank.staticFilePathPrefix}/top/sp_01.png`} alt="" className={effectFadePos80} />
                            )}
                        </div>
                    </div>
                </div>
                <div className={contentStart}>
                    <div className={topStyle.contentInner}>
                        <p className={topStyle.text}>
                            京銀インターネットEBサービスのご契約がないお客さまは、こちらから京銀ビジネスポータルサイトのアカウントを取得してください。
                        </p>
                        <a href={bank.accountGetUrl} className={getAccountBtnSizeXl} target="_blank" rel="noopener noreferrer">アカウント取得はこちらから</a>
                    </div>
                </div>
            </main>
            <BeforeLoginFooter />
            <Helmet>
                <script id="bedore-webagent" charset="utf-8" src="https://webagent.bedore.jp/init.js" data-token="386a5521-9fb9-45fa-b0d1-1115b5022dcb"></script>
            </Helmet>
        </>
    );
};

export default BeforeLogin;
