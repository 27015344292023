import React, {useEffect, useState} from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import { useReactOidc } from '@axa-fr/react-oidc-context';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Header from "../../components/Header";
import LeftColumn from "../../components/LeftColumn";
import Footer from "../../components/Footer";
import Api from "../../util/api";
import { useBankContext } from "../../util/BankContext";


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        '& table': {
            border: "1px solid #000",
            borderCollapse: "collapse",
            width: "100%",
        },
        '& tr': {
            height: "50px",
        },
        '& th': {
            background: "#E8E8E8",
        },
        '& td': {
            textAlign: "center",
        },
    },
    content: {
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        height: '100vh',
        minHeight: "100vh",
        overflow: 'auto',
    },
    title: {
        backgroundColor: theme.palette.text.primary,
        padding: "10px 8px",
        '& p': {
            color: theme.palette.background.default,
        },
    },
    subTitle: {
        backgroundColor: theme.palette.text.primary,
        height: 50,
        marginTop: "10px",
        padding: theme.spacing(2, 6),
        '& p': {
            color: theme.palette.background.default,
        },
    },
    caption: {
        display: "block",
        position: "relative",
        '&::before': {
            background: "#19AAC4",
            content: "''",
            height: 8,
            left: -26,
            position: "absolute",
            top: 9,
            width: 8,
        },
        '&::after': {
            background: "#fff",
            content: "''",
            height: 8,
            left: -22,
            position: "absolute",
            top: 4,
            width: 8,
        },
    },
    ul: {
        padding: 0,
    },
    list: {
        borderBottom: "1px dotted #ccc",
        display: "flex",
        padding: "15px 20px 15px 0",
    },
    image: {
        height: 100,
        marginRight: 20,
        width: 130,
    },
    noImage: {
        background: "#efefef",
        color: "#aaa",
        height: "100%",
        letterSpacing: 2,
        lineHeight: "100px",
        textAlign: "center",
    },
    moreView: {
        marginTop: 30,
        textAlign: "center",
    },
    linkTitleText: {
        color: "#333 !important",
        cursor: "pointer",
        fontSize: 16,
        lineHeight: "1.75em",
        textDecoration: "underline",
    },
    linkText: {
        color: "#3039f1",
        cursor: "pointer",
        textDecoration: "underline",
        '&:hover': {
            color: "#262626",
        },
    },
    footer: {
        marginTop: "auto",
    },
}));

const AppliedSeminarList = (props) => {
    const bank = useBankContext();
    const { oidcUser } = useReactOidc();
    const { profile } = oidcUser;
    const [seminarUser, setSeminarUser] = useState({
        memberCount: 0,
    });

    useEffect( () => {
        const fetchData = async () => {
            const reservation = await Api.get(bank.realm, process.env.REACT_APP_SEMINAR_BASE_URL + '/reservation', {s: props.appliedSeminar.id}, {}, oidcUser);
            setSeminarUser(reservation);
        }

        fetchData()
            .then()
            .catch(e => {
                // todo エラーハンドリング
                if( e.response ){
                    if( e.response.status === 403 ){
                        // todo 「ログインしてください」を表示
                        // todo ユーザー情報の削除
                        // location.href = "/";
                    }
                    //todo エラーであることを知らせる表示
                }
            })
    }, [bank.realm, oidcUser, props.appliedSeminar.id]);

    return (
        <>
            <li>
                <div className="status-label">予約済み</div>
                <div className="body">
                    <div className="detail">
                        <p className="branch">セミナー名：{props.appliedSeminar.title}</p>
                        <p className="date">日時：{props.appliedSeminar.date}</p>
                        <p className="location">場所：{props.appliedSeminar.location}</p>
                    </div>
                    <div className="user-info">
                        <p className="company-name">会社名：{profile.ba_company_name}</p>
                        <p className="user-name">氏名：{profile.ba_user_last_name}{profile.ba_user_first_name}</p>
                        <p className="participants">予約人数：{Number(seminarUser.memberCount)}名</p>
                    </div>
                </div>
            </li>
            <div className="cancel-and-edit">
                <Link to={"/seminar/detail/" + props.appliedSeminar.uniqueCode}>セミナー詳細／キャンセル</Link>
            </div>
        </>
    )
};

const Seminar = (props) => {
    const matches = useMediaQuery('(min-width:600px)');
    const bank = useBankContext();
    window.document.title = 'セミナー予約|' + bank.baPortalName;
    const classes = useStyles();
    // TODO 受講セミナーのページング
    // const [appliedSeminarListPagingData, setAppliedSeminarListPagingData] = React.useState({});
    const [appliedSeminarList, setAppliedSeminarList] = React.useState([]);
    const [seminarListPagingData, setSeminarListPagingData] = React.useState({});
    const [seminarList, setSeminarList] = React.useState([]);
    const { oidcUser } = useReactOidc();

    /*
    const moreViewAppliedSeminar = async (nextPage, event) => {
        const newAppliedSeminarList = await Api.get(bank.realm, process.env.REACT_APP_SEMINAR_BASE_URL + '/seminars/applied', {r: bank.params.seminar.listRows, p: nextPage}, {}, oidcUser);
        setAppliedSeminarListPagingData(newAppliedSeminarList.pagingInfo);
        setAppliedSeminarList(appliedSeminarList.concat(newAppliedSeminarList.seminars));
    }; */

    const moreViewSeminar = async (nextPage, event) => {
        const newSeminarList = await Api.get(bank.realm, process.env.REACT_APP_SEMINAR_BASE_URL + '/seminars/unapplied', {r: bank.params.seminar.listRows, p: nextPage}, {}, oidcUser);
        setSeminarListPagingData(newSeminarList.pagingInfo);
        setSeminarList(seminarList.concat(newSeminarList.seminars));
    };

    useEffect( () => {
        if (!oidcUser) {
            return;
        }

        const fetchData = async () => {
            // 参加予定セミナー一覧
            const appliedSeminarList = await Api.get(bank.realm, process.env.REACT_APP_SEMINAR_BASE_URL + '/seminars/applied', {r: bank.params.seminar.listRows, p: 1}, {}, oidcUser);
            // setAppliedSeminarListPagingData(appliedSeminarList.pagingInfo);
            if (appliedSeminarList.seminars === null) {
                setAppliedSeminarList([]);
            } else {
                setAppliedSeminarList(appliedSeminarList.seminars);
            }

            // 開催予定セミナー一覧
            const seminarList = await Api.get(bank.realm, process.env.REACT_APP_SEMINAR_BASE_URL + '/seminars/unapplied', {r: bank.params.seminar.listRows, p: 1}, {}, oidcUser);
            setSeminarListPagingData(seminarList.pagingInfo);
            if (seminarList.seminars === null) {
                setSeminarList([]);
            } else {
                setSeminarList(seminarList.seminars);
            }
        }

        fetchData()
            .then()
            .catch(e => {
                // todo エラーハンドリング
                if( e.response ){
                    if( e.response.status === 403 ){
                        // todo 「ログインしてください」を表示
                        // todo ユーザー情報の削除
                        // location.href = "/";
                    }
                    //todo エラーであることを知らせる表示
                }
            })
    }, [bank, oidcUser]);

    useEffect(() => {
        if (bank.canUseGA) {
            window.gtag('config', bank.gaTrackingCode, {
                'page_path': `${props.location.pathname}${props.location.search}`
            });
        }
    }, [bank.canUseGA, bank.gaTrackingCode, props.location.pathname, props.location.search]);

    return (
        <div className="wrapper">
            <Header />
            <div className="content">
                {(!!oidcUser && matches) && (
                    <LeftColumn />
                )}
                <div className="main">
                    <ul className="breadcrumb">
                        <li><Link to={"/"}>トップ</Link></li>
                        <li>セミナー予約</li>
                    </ul>
                    <div className="box">
                        <h2 className="box-title seminar">セミナー予約</h2>
                        <h3 className="box-title">参加予定</h3>
                        <div className="reserved-info">
                            {appliedSeminarList.length !== 0 && (
                                <ul>
                                    {appliedSeminarList.map((appliedSeminar) => (
                                        <AppliedSeminarList key={appliedSeminar.id} appliedSeminar={appliedSeminar} />
                                    ))}
                                </ul>
                            )}
                            {appliedSeminarList.length === 0 && (
                                <div className="no-reserved">
                                    参加予定のセミナーはありません
                                </div>
                            )}
                        </div>
                        <h3 className="box-title">開催予定</h3>
                        <div className="seminar-list">
                            {seminarList.length !== 0 && (
                                <ul>
                                    {seminarList.map((seminar) => (
                                        <li key={seminar.id}>
                                            <div className="image-wrapper">
                                                <img src={`${bank.baManagerUrl}picture/bank/${seminar.imageFileCode}/800x`} alt={seminar.title} />
                                            </div>
                                            <div className="detail">
                                                <p className="title"><Link to={"/seminar/detail/" + seminar.uniqueCode}>{seminar.title}</Link></p>
                                                <p className="info">
                                                    日時：{seminar.date}<br />
                                                    場所：{seminar.location}<br />
                                                    主催：{seminar.organizer}
                                                </p>
                                                <Link to={"/seminar/detail/" + seminar.uniqueCode} className="key-color">詳細を見る</Link>
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            )}
                            {seminarList.length === 0 && (
                                <div className="no-reserved">
                                    開催予定のセミナーはありません
                                </div>
                            )}
                        </div>
                        {!seminarListPagingData.isLastPage && (
                            <div className="text-center">
                                <button className="btn key-color size-l arrow mt40 btnSp" onClick={moreViewSeminar.bind(this, seminarListPagingData.nextPage)}>もっと見る</button>
                            </div>
                        )}
                    </div>
                    <Box className={classes.footer}>
                        <Footer />
                    </Box>
                </div>
            </div>
        </div>
    )
}

export default Seminar;
