import React from 'react';
import { Link } from "react-router-dom";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import Badge from "@material-ui/core/Badge";
import Collapse from '@material-ui/core/Collapse';
import List from '@material-ui/core/List';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { makeStyles } from "@material-ui/core/styles";
import { useReactOidc } from '@axa-fr/react-oidc-context';
import ConfirmDialog from '../ConfirmDialog';

const useStyles = makeStyles(() => ({
    row2: {
        height: "120px !important",
        paddingTop: "20px",
        paddingBottom: "20px",
        "&.icon:before": {
            top: "46px",
        },
        "& p": {
            "lineHeight": "40px",
            "height": "40px",
        }
    },
    row2Mikatano: {
        paddingTop: "12px",
        "height": "70px",
        "& p.row2sub": {
            "height": "20px",
            "lineHeight": "18px",
            "fontSize": "85%",
        },
        "& p.row2main": {
            "height": "30px",
            "lineHeight": "18px",
        }
    },
}));

const MenuList = (props) => {
    const classes = useStyles();
    const { oidcUser, logout } = useReactOidc();

    function isOwnerUser() {
        const ba_user_type = oidcUser?.profile?.ba_user_type ?? "";
        return ba_user_type === "company_owner" ||
          ba_user_type === "partner_company_owner" ||
          ba_user_type === "company_user";
    }

    // BAP-463 bizsolユーザー属性が得られない場合(BAログインしてBAPへ遷移した場合等)、メニュー表示の判別ができないので再ログインさせる
    if (!('bizsol_limited_function_kbn' in props.profile)) {
        logout();
        window.location.href = `/login`;
        return;
    }

    return (
    <>
        <ul className="menu-items">
            {!!props.isResponsive && (
                <li className="item icon user-name">
                    { !!props.oidcUser?.profile?.ba_company_name && (
                        <>{props.oidcUser?.profile.ba_company_name} </>
                    )}
                    { !!props.oidcUser?.profile?.ba_user_last_name && (
                        <>{props.oidcUser?.profile.ba_user_last_name}</>
                    )}
                    { !!props.oidcUser?.profile?.ba_user_first_name && (
                        <>{props.oidcUser?.profile.ba_user_first_name}</>
                    )}
                </li>
            )}
            <Link to={"/"} className={props.classes.nonDecorationLink} onClick={props.handleToggle}>
                <li className="item icon top">トップ</li>
            </Link>
            {[1, 2].includes(Number(props.profile.bizsol_limited_function_kbn)) && (
                <>
                    <span onClick={props.clickWebApplyLink} className={props.classes.nonDecorationLink}>
                        <li className={`item icon apply`}>
                            <span>各種WEB申込</span>
                        </li>
                    </span>
                    <span onClick={props.clickBalanceLink} className={props.classes.nonDecorationLink}>
                        <li className={`item icon denshi ${classes.row2}`}>
                            <p>残高・明細照会</p>
                            <p>電子交付サービス</p>
                        </li>
                    </span>
                </>
            )}
            {(props.canUseChat && (
                <Link to={"/ba_chat"} className={props.classes.nonDecorationLink} onClick={props.handleToggle}>
                    <li className="item icon chat">
                        <Badge badgeContent={props.chatUnreadCount} color="error" className={props.classes.unreadCount}>
                            <span>金融機関連絡チャット</span>
                        </Badge>
                    </li>
                </Link>
            ))}
            {props.bank.canUseAppointment && (
                <span onClick={props.clickAppointmentLink} className={props.classes.nonDecorationLink}>
                    <li className="item icon shop">来店予約</li>
                </span>
            )}
            {[1, 2].includes(Number(props.profile.bizsol_limited_function_kbn)) && (
                <a href={"https://www.kyotobank.co.jp/houjin/webeb/index.html"} className={props.classes.nonDecorationLink}>
                    <li className="item icon service">京銀インターネットEB</li>
                </a>
            )}
            {Number(props.profile.bizsol_limited_function_kbn) === 3 && (
                <>
                    <span onClick={props.clickWebApplyLink} className={props.classes.nonDecorationLink}>
                        <li className={`item icon apply`}>
                            <span>各種WEB申込</span>
                        </li>
                    </span>
                    <span onClick={props.clickBalanceLink} className={props.classes.nonDecorationLink}>
                        <li className={`item icon denshi ${classes.row2}`}>
                            <p>京銀インターネットEB</p>
                            <p>電子交付サービス</p>
                        </li>
                    </span>
                </>
            )}
            <a href={`${props.bank.baUrl}login`} className={props.classes.nonDecorationLink}>
                <li className="item icon service">{props.bank.baName}</li>
            </a>
            <span className={props.classes.nonDecorationLink} onClick={() => {props.handleConfirmDialog({
                    title: '京銀 Mikatano 資金管理',
                    url: 'https://bfm.lp.x.moneyforward.com/kyotobank/index_alt.html'
                })}}>
                <li className={`item icon money ${classes.row2Mikatano}`}><p className="row2sub">京銀&nbsp;Mikatano</p><p className="row2main">資金管理</p></li>
            </span>
            <span className={props.classes.nonDecorationLink} onClick={() => {props.handleConfirmDialog({
                    title: '京銀 Mikatano インボイス管理',
                    url: 'https://bill.lp.x.moneyforward.com/kyotobank/index_alt.html'
                })}}>
                <li className={`item icon invoice ${classes.row2Mikatano}`}><p className="row2sub">京銀&nbsp;Mikatano</p><p className="row2main">インボイス管理</p></li>
            </span>
            <span className={props.classes.nonDecorationLink} onClick={() => {props.handleConfirmDialog({
                    title: '京銀 Mikatano ワークス',
                    url: 'https://dxf.lp.x.moneyforward.com/kyotobank/index_alt.html'
                })}}>
                <li className={`item icon works ${classes.row2Mikatano}`}><p className="row2sub">京銀&nbsp;Mikatano</p><p className="row2main">ワークス</p></li>
            </span>
            <a href={"https://elcon.kyotobank.co.jp/DIGITAL/login"} className={props.classes.nonDecorationLink} target="_blank" rel="noopener noreferrer">
                <li className="item icon service">京銀電子契約サービス</li>
            </a>
            <a href="#!" className={props.classes.nonDecorationLink} onClick={props.handleClickKeiei}>
                <li className="item icon top">
                    経営支援情報
                    {props.openKeiei ? <ExpandLess className={props.classes.expandIcon} /> : <ExpandMore className={props.classes.expandIcon} />}
                </li>
            </a>
                <Collapse in={props.openKeiei} timeout="auto" unmountOnExit className={props.classes.expandList}>
                    <List component="div" disablePadding>
                        <Link to={"/seminar"} className={props.classes.nonDecorationLink} onClick={props.handleToggle}>
                            <li className="item icon seminar">セミナー予約</li>
                        </Link>
                        <Link to={"/management_column"} className={props.classes.nonDecorationLink} onClick={props.handleToggle}>
                            <li className="item icon column">経営コラム</li>
                        </Link>
                    </List>
                </Collapse>
            {isOwnerUser() && (
                <>
                    <a href="#!" className={props.classes.nonDecorationLink} onClick={props.handleClickBS}>
                        <li className="item icon business-succession">
                            事業承継
                            {props.openBS ? <ExpandLess className={props.classes.expandIcon} /> : <ExpandMore className={props.classes.expandIcon} />}
                        </li>
                    </a>
                    <Collapse in={props.openBS} timeout="auto" unmountOnExit className={props.classes.expandList}>
                        <List component="div" disablePadding>
                            <Link to={"/succession_column"} className={props.classes.nonDecorationLink} onClick={props.handleToggle}>
                                <li className="item icon business-succession">事業承継コラム</li>
                            </Link>
                            <Link to={"/succession_faq"} className={props.classes.nonDecorationLink} onClick={props.handleToggle}>
                            <li className="item icon seminar">事業承継方針の<div style={{marginTop:'-30px'}}>検討パターン</div></li>
                            </Link>
                        </List>
                    </Collapse>
                </>
            )}
            <Link to={"/news"} className={props.classes.nonDecorationLink} onClick={props.handleToggle}>
                <li className="item icon info">ご案内/お知らせ</li>
            </Link>
            {/*{oidcUser?.profile?.bizsol_sub && (*/}
            {/*    <Link to={"/settings"} className={classes.nonDecorationLink} onClick={handleToggle}>*/}
            {/*        <li className="item icon setting">アカウント設定</li>*/}
            {/*    </Link>*/}
            {/*)}*/}
            {!!props.isResponsive && (
                <Link to={"/"} onClick={ props.logoutAction } className="logout">
                    <li className="item icon logout">ログアウト</li>
                </Link>
            )}
        </ul>
        <ConfirmDialog {...props.confirmDialog} />
    </>
    );
}

export default withRouter(connect(state => state)(MenuList));
