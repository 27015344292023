import React from "react";


const AfterLoginTopNotification = (props) => {
    return (
        <p className="message">
            ご確認ください：<span>未読チャットが{props.chatUnreadCount}件あります</span>
        </p>
    )
};

export default AfterLoginTopNotification;
